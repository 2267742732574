import {
	Component,
	OnInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	Renderer2,
	OnDestroy,
} from '@angular/core'
import { Title } from '@angular/platform-browser'

import {
	AppVersion,
	DatabaseTableName,
	DataAccessRequest,
	GenericEvent,
	UserPermissionsList,
	UserPermissions,
	PermissionTableName,
	DialogManager,
	ScheduleViewTabState,
	Global,
} from '@app/models'
import { AnalyticsService, UserRegistrationService, CoreService } from '@app/services'

import { DeviceDetectorService } from 'ngx-device-detector'
import { DisplayHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { Router } from '@angular/router'
import _ from 'lodash'

import { Subscription } from 'rxjs'
import { Location } from '@angular/common'

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
	progress: DashboardProgressLoader

	loadingStarted = false
	isLoading = true
	isGlobalCompany = false
	isAdpIvrAdmin = false
	isAnalyticsEnabled = false
	isEmpAccessRestricted = false
	isSiteAccessRestricted = false
	isJobAccessRestricted = false
	isVacRestricted = false

	isRouting = false
	currentRoute = null

	dialogManager = new DialogManager()

	currentVersion = AppVersion.version

	accessHelper: AccessHelper
	permList: UserPermissionsList

	// Used for counters
	empSupId: number
	siteSupId: number
	supEmpIds: Array<number> = null
	supSiteIds: Array<number> = null
	managedSupIds: Array<number> = null
	isManager = false

	// Used to show exclamation triangle icon on section card
	empAlertCount = 0
	jobSiteAlertCount = 0
	jobAlertCount = 0

	// Managing alert messages
	showProvisioningErrorAlert = false
	showBillingErrorAlert = false
	flashAlertMessage = true
	billingErrorMessage = 'Billing Issue Encountered'
	buttonText = 'Fix'

	// Supervisor selector
	usersDropdown = []
	selectedUserId
	currentUserId

	showUserSelector = false
	showReportsDialog = false
	showSchedulingDialog = false
	showLogsDialog = false

	@ViewChild('progressBarElem', { static: false }) progressBarElem: ElementRef

	private subs = new Subscription()

	get isVacationMerged(): boolean {
		return this.coreSrvc.dbSrvc.mergeVacations
	}
	get isJobSiteMerged(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.companyDataLoaded && this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
	}

	get cardExpirationDayCount(): number {
		const count = this.coreSrvc.dbSrvc.billSrvc.cardExpirationDayCount
		const expirationThreshold = this.coreSrvc.dbSrvc.billSrvc.cardExpirationThreshold
		if (count < expirationThreshold) {
			return count
		}
		return null
	}

	get showSelectors(): boolean {
		return this.showCompanySelector || this.showUserSelector
	}

	get showCompanySelector(): boolean {
		return this.coreSrvc.dbSrvc.companySrvc.canShowCompanySelector
	}

	constructor(
		titleSrvc: Title,
		deviceSrvc: DeviceDetectorService,
		private renderer: Renderer2,
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
		private analyticsSrvc: AnalyticsService,
		private regSrvc: UserRegistrationService,
		private location: Location,
	) {
		titleSrvc.setTitle('Timesheets')
		log('Dashboard: DatabaseService UUID', this.coreSrvc.dbSrvc.uuid)
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc)

		const permList = this.accessHelper.getUserPermissionsList()
		this.permList = permList

		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()

		const supId = myUser.id

		// Setup for manager
		this.managedSupIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
		this.isManager = myUser.role === 'MANAGER'

		this.isEmpAccessRestricted = !permList.employee.access.read && permList.employee.owner.read
		this.isSiteAccessRestricted = !permList.site.access.read && permList.site.owner.read
		this.isJobAccessRestricted = !permList.job.access.read && permList.job.owner.read
		this.isVacRestricted = !permList.vacation.access.read && permList.vacation.owner.read

		if (this.isEmpAccessRestricted) {
			this.empSupId = supId
		} else {
			this.empSupId = null
		}
		if (this.isSiteAccessRestricted) {
			this.siteSupId = supId
		} else {
			this.siteSupId = null
		}
		if (this.isJobAccessRestricted) {
			this.supSiteIds = this.isManager
				? this.coreSrvc.dbSrvc.siteSrvc.getAllManagedJobSites(this.managedSupIds).map((js) => js.id)
				: this.coreSrvc.dbSrvc.siteSrvc.getJobSites(supId).map((js) => js.id)
		} else {
			this.supSiteIds = null
		}
		if (this.isVacRestricted) {
			this.supEmpIds = this.isManager
				? this.coreSrvc.dbSrvc.empSrvc.getManagedAllEmployees(this.managedSupIds).map((e) => e.id)
				: this.coreSrvc.dbSrvc.empSrvc.getAllEmployees(supId).map((e) => e.id)
		} else {
			this.supEmpIds = null
		}
	}

	canAccess(table: PermissionTableName) {
		const userPermissions = this.permList[table] as UserPermissions
		const access = userPermissions.access
		const owner = userPermissions.owner
		if (access.read || owner.read) {
			return true
		}
		return false
	}

	ngOnInit() {
		log('Initialize DashboardComponent')
		log('PBProgress', this.progress)
		if (!this.loadingStarted) {
			this.loadingStarted = true
			this.startLoaderStageOne()
			this.regSrvc.clearRegInfo()
		}
	}

	ngAfterViewInit() {
		scrollTo(0, 0)
		this.progress = new DashboardProgressLoader(this.renderer, this.progressBarElem)
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.cardExpirationEvent.subscribe((daysLeft) => this.cd.markForCheck()))
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	exceptionsCount(): number {
		return this.coreSrvc.dbSrvc.excptSrvc.transactionCount() + this.coreSrvc.dbSrvc.excptSrvc.locationCount()
	}

	// Checks for making sure data is loaded for each section
	employeesDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.empSrvc.dataLoaded
	}
	jobSitesDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.siteSrvc.dataLoaded
	}
	jobsDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.jobSrvc.dataLoaded
	}
	// scheduleDataLoaded(): boolean { return this.coreSrvc.dbSrvc.schedSrvc.dataLoaded }
	schedulerDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.schedulerSrvc.dataLoaded
	}
	vacationsDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.vacSrvc.dataLoaded
	}
	notificationDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.npSrvc.dataLoaded && this.coreSrvc.dbSrvc.vacSrvc.dataLoaded
	}
	transactionsDataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.tranSrvc.dataLoaded
	}

	showVacationCard() {
		return true
	}

	startLoaderStageOne() {
		const tables: Array<DatabaseTableName> = ['employee']

		this.coreSrvc.dbSrvc
			.bulkRead(tables)
			.then((result) => {
				this.logLoaderStageOne()
				this.progress.nextStage()
				this.startLoaderStageTwo()
			})
			.catch((error) => {
				log('Got an error', error)
			})
	}

	logLoaderStageOne() {
		log('Employees Loaded', this.coreSrvc.dbSrvc.empSrvc.getAllEmployees().length) // employee
	}

	startLoaderStageTwo() {
		const tables: Array<DatabaseTableName> = ['location']

		this.coreSrvc.dbSrvc
			.bulkRead(tables)
			.then((result) => {
				this.logLoaderStageTwo()
				this.progress.nextStage()
				this.startLoaderStageThree()
			})
			.catch((error) => {
				log('Got an error', error)
			})
	}

	logLoaderStageTwo() {
		log('Job Sites Loaded', this.coreSrvc.dbSrvc.siteSrvc.getAllJobSites().length) // location
	}

	startLoaderStageThree() {
		// Company and user records should be loaded in the last stage before primary loader is called
		const tables: Array<DatabaseTableName> = ['job', 'vacation', 'company', 'users']

		this.coreSrvc.dbSrvc
			.bulkRead(tables)
			.then((result) => {
				this.logLoaderStageThree()
				this.progress.nextStage()
				this.startLoaderStageFour()
			})
			.catch((error) => {
				log('Got an error', error)
			})
	}

	logLoaderStageThree() {
		log('Jobs Loaded', this.coreSrvc.dbSrvc.jobSrvc.getAllJobs().length) // job
		log('Vacations Loaded', this.coreSrvc.dbSrvc.vacSrvc.getVacations().length) // vacation (time-off)
		log('Company Loaded', this.coreSrvc.dbSrvc.settingSrvc.getCompany()) // company
		log('Users Loaded', this.coreSrvc.dbSrvc.settingSrvc.getUsers().length) // users
		log('My User Record', this.coreSrvc.dbSrvc.settingSrvc.getMyUser()) // Just logging current user record
	}

	startLoaderStageFour() {
		const fullCompanyList = !this.coreSrvc.dbSrvc.settingSrvc.globalCompanyDataLoaded
		const loadAllowedCompanies = !this.coreSrvc.dbSrvc.settingSrvc.allowedCompaniesDataLoaded
		const loadPhoneNumbers = !this.coreSrvc.dbSrvc.settingSrvc.callInNumbersDataLoaded
		const loadTimezones = !this.coreSrvc.dbSrvc.settingSrvc.timezoneDataLoaded
		const loadOnboardDefinitions = !this.coreSrvc.dbSrvc.onboardSrvc.onboardDefinitionDataLoaded

		// Mark transactions as loaded to skip loading by default but also work around an
		// unknown issue with the loader where it gets stuck on dashboard screen and does not finish
		this.coreSrvc.dbSrvc.tranSrvc.dataLoaded = true
		const loadTransactions = !this.coreSrvc.dbSrvc.tranSrvc.dataLoaded

		const tables: Array<DatabaseTableName> = []
		if (fullCompanyList) tables.push('company_globals')
		if (loadAllowedCompanies) tables.push('company_switch_list')
		if (loadPhoneNumbers) tables.push('phone_number')
		if (loadTimezones) tables.push('timezones')
		if (loadOnboardDefinitions) tables.push('onboard_definition')
		if (loadTransactions) tables.push('transaction_log')

		this.coreSrvc.dbSrvc
			.bulkRead(tables)
			.then((result) => {
				this.logLoaderStageFour()
				this.progress.nextStage()
				this.startLoaderPrimary()
			})
			.catch((error) => {
				log('Got an error', error)
			})
	}

	logLoaderStageFour() {
		log('Global Company Loaded', this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompany()) // company_globals
		log('Allowed Companies Loaded', this.coreSrvc.dbSrvc.settingSrvc.getAllowedCompanies().length) // company_switch_list
		log('Phone Numbers Loaded', this.coreSrvc.dbSrvc.settingSrvc.getCallInPhoneNumberRecords().length) // phone_number
		log('Timezones Loaded', this.coreSrvc.dbSrvc.settingSrvc.getSupportedTimezones().length) // timezones
		log('Onboard Definitions Loaded', this.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecords().length) // onboard_definition
		log('Transactions Loaded', this.coreSrvc.dbSrvc.tranSrvc.getTransactions().length) // transaction_log
	}

	startLoaderPrimary() {
		const loadNewNumbers = !this.coreSrvc.dbSrvc.excptSrvc.locationDataLoaded
		const loadAdpDepartmentCodes = !this.coreSrvc.dbSrvc.adpSrvc.adpDepartmentCodeDataLoaded
		const loadAdpRateCodes = !this.coreSrvc.dbSrvc.adpSrvc.adpRateCodeDataLoaded
		const loadAdpSync = !this.coreSrvc.dbSrvc.adpSrvc.adpSyncDataLoaded
		const loadQboCustomer = !this.coreSrvc.dbSrvc.qbSrvc.qboCustomerDataLoaded
		const loadQboVendor = !this.coreSrvc.dbSrvc.qbSrvc.qboVendorDataLoaded
		const loadQboServiceItem = !this.coreSrvc.dbSrvc.qbSrvc.qboServiceItemDataLoaded
		const loadQboSync = !this.coreSrvc.dbSrvc.qbSrvc.qboSyncDataLoaded
		const loadSupervisorGroups = !this.coreSrvc.dbSrvc.settingSrvc.userGroupsDataLoaded
		const loadWIWSync = !this.coreSrvc.dbSrvc.wiwSrvc.wiwSyncDataLoaded
		const loadW2WSync = !this.coreSrvc.dbSrvc.w2wSrvc.w2wSyncDataLoaded
		const loadW2WSyncLog = !this.coreSrvc.dbSrvc.w2wSrvc.w2wLogDataLoaded

		// Schedule Recur required in initial load because Time Entries has dependency with schedule details and emp count exceeded check
		const loadSchedules = !this.coreSrvc.dbSrvc.schedulerSrvc.dataLoaded

		const tables: Array<DatabaseTableName> = []

		if (loadNewNumbers) tables.push('location_exception')
		if (loadAdpDepartmentCodes) tables.push('adp_department_code')
		if (loadAdpRateCodes) tables.push('adp_rate_code')
		if (loadAdpSync) tables.push('adp_sync')
		if (loadQboCustomer) tables.push('qbo_customer')
		if (loadQboVendor) tables.push('qbo_vendor')
		if (loadQboServiceItem) tables.push('qbo_service_item')
		if (loadQboSync) tables.push('qbo_sync')
		if (loadW2WSync) tables.push('google_cal_sync')
		if (loadW2WSyncLog) tables.push('google_sync_log')
		if (loadWIWSync) tables.push('wiw_sync')
		if (loadSupervisorGroups) tables.push('supervisor_group')
		if (loadSchedules) tables.push('schedule_recur')

		this.coreSrvc.dbSrvc
			.bulkRead(tables)
			.then((result) => {
				this.logLoaderPrimary()
				this.primaryLoadingHasFinished()
				this.progress.nextStage()
				this.startLoaderSecondary()
			})
			.catch((error) => {
				log('Got an error', error)
			})
	}

	logLoaderPrimary() {
		log('New Phone Numbers', this.coreSrvc.excptSrvc.getLocations().length) // location_exception
		log('ADP Department Codes Loaded', this.coreSrvc.dbSrvc.adpSrvc.getAdpDepartmentCodes().length) // adp_department_code
		log('ADP Rate Codes Loaded', this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodes().length) // adp_rate_code
		log('ADP Sync Loaded', this.coreSrvc.dbSrvc.adpSrvc.adpSyncDataLoaded) // adp_sync
		log('QBO Customer Loaded', this.coreSrvc.dbSrvc.qbSrvc.qboCustomerDataLoaded) // qbo_customer
		log('QBO Service Item Loaded', this.coreSrvc.dbSrvc.qbSrvc.qboServiceItemDataLoaded) // qbo_service_item
		log('QBO Sync Loaded', this.coreSrvc.dbSrvc.qbSrvc.qboSyncDataLoaded) // qbo_sync
		log('W2W Sync Loaded', this.coreSrvc.dbSrvc.w2wSrvc.w2wSyncDataLoaded) // google_cal_sync
		log('W2W Sync Log Loaded', this.coreSrvc.dbSrvc.w2wSrvc.getW2WSyncLog().length) // google_sync_log
		log('WIW Sync Loaded', this.coreSrvc.dbSrvc.wiwSrvc.wiwSyncDataLoaded) // wiw_sync
		log('Supervisor Groups Loaded', this.coreSrvc.dbSrvc.settingSrvc.getUserGroups().length) // supervisor_group
		log('Schedule Recur Loaded', this.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().length) // schedule_recur
	}

	primaryLoadingHasFinished() {
		this.setupAccessPermissions()

		// Setup check for ADP IVR company / user
		this.isAdpIvrAdmin = this.coreSrvc.dbSrvc.settingSrvc.getCompany().account_billing === 'ADP_IVR'

		// Check for alert alerts
		this.empAlertCount = this.coreSrvc.dbSrvc.empSrvc.alertCount()
		this.jobSiteAlertCount = this.coreSrvc.dbSrvc.siteSrvc.alertCount()
		this.jobAlertCount = this.coreSrvc.dbSrvc.jobSrvc.alertCount()

		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		this.analyticsSrvc.setCompanyId(companyId)

		this.setupAllowedCompaniesDropdown()
		this.checkAllowedCompaniesForGlobalIssues()
		this.setupUsersDropdown()
		this.setupCurrentVersion()

		// Setup User Prefs - Company and user records must be loaded first
		this.coreSrvc.dbSrvc.settingSrvc.setUserAdminPrefs()

		// Check for expiring credit card
		const accountStatus = this.coreSrvc.dbSrvc.settingSrvc.getCompany().account_status
		const isStripeCustomer = this.coreSrvc.dbSrvc.settingSrvc.isStripeCustomer()
		const isPayPalCustomer = this.coreSrvc.dbSrvc.settingSrvc.isPayPalCustomer()

		const shouldCheck =
			accountStatus === 'BILLING_OK' ||
			accountStatus === 'BILLING_PAYMENT_FAILED' ||
			accountStatus === 'BILLING_PORTAL_LOCKED' ||
			accountStatus === 'BILLING_ACCOUNT_LOCKED' ||
			accountStatus === 'BILLING_NEED_INFO'

		if (shouldCheck && (isStripeCustomer || isPayPalCustomer)) {
			if (this.coreSrvc.dbSrvc.settingSrvc.isPayPalCustomer()) {
				this.coreSrvc.dbSrvc.billSrvc.paypalCheckForCardExpiration()
			} else {
				this.coreSrvc.dbSrvc.billSrvc.stripeCheckForCardExpiration()
			}
		}
	}

	startLoaderSecondary() {
		log('Start secondary loader')
		const loadNotificationProfiles = !this.coreSrvc.dbSrvc.npSrvc.dataLoaded // Could be defered but not worth it
		const loadTours = !this.coreSrvc.dbSrvc.tourSrvc.dataLoaded
		const loadReports = !this.coreSrvc.dbSrvc.reportSrvc.reportsDataLoaded
		this.coreSrvc.dbSrvc.reportSrvc.reportsHistoryDataLoaded = true // LOADER_TEST
		const loadReportHistory = !this.coreSrvc.dbSrvc.reportSrvc.reportsHistoryDataLoaded
		const loadReportTypes = !this.coreSrvc.dbSrvc.reportSrvc.reportTypesDataLoaded
		const loadContacts = !this.coreSrvc.dbSrvc.contactSrvc.dataLoaded
		const loadOrganizations = !this.coreSrvc.dbSrvc.orgSrvc.dataLoaded
		const loadChecklists = !this.coreSrvc.dbSrvc.empChklstSrvc.empChecklistDataLoaded
		const loadHelpVideos = !this.coreSrvc.dbSrvc.helpVidSrvc.dataLoaded
		const loadOpenShifts = !this.coreSrvc.dbSrvc.schedulerSrvc.openShiftsDataLoaded
		const loadStations = !this.coreSrvc.dbSrvc.stationSrvc.dataLoaded

		const tables: Array<DatabaseTableName> = ['employee_login']

		if (loadNotificationProfiles) tables.push('notification_profile')
		if (loadTours) tables.push('tour')
		if (loadReports) tables.push('reports')
		if (loadReportHistory) tables.push('reports_log')
		if (loadReportTypes) tables.push('reports_definition')
		if (loadContacts) tables.push('contact')
		if (loadOrganizations) tables.push('vendor_client_org')
		if (loadChecklists) tables.push('employee_checklist')
		if (loadHelpVideos) tables.push('help_video')
		if (loadOpenShifts) tables.push('open_shifts')
		if (loadStations) tables.push('station')

		this.progress.nextStage()
		this.coreSrvc.dbSrvc
			.bulkRead(tables)
			.then((result) => {
				this.secondaryLoadingHasFinished()
				this.logLoaderSecondary(result)
			})
			.catch((error) => {
				log('Got an error', error)
			})
	}

	logLoaderSecondary(result: any) {
		log('Employee Logins Loaded', this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginRecords().length) // employee_login
		log('Notification Profiles Loaded', this.coreSrvc.dbSrvc.npSrvc.getProfiles().length) // notification_profile
		log('Reports Loaded', this.coreSrvc.dbSrvc.reportSrvc.reportsDataLoaded) // reports
		log('Report History Loaded', this.coreSrvc.dbSrvc.reportSrvc.reportsHistoryDataLoaded) // reports_log
		log('Custom Report Types Loaded', this.coreSrvc.dbSrvc.reportSrvc.reportTypesDataLoaded) // reports_definition
		log('Contacts Loaded', this.coreSrvc.dbSrvc.contactSrvc.getContacts().length) // contact
		log('Organizations Loaded', this.coreSrvc.dbSrvc.orgSrvc.getOrganizations().length) // vendor_client_org
		log('Checklists Loaded', this.coreSrvc.dbSrvc.empChklstSrvc.getEmployeeChecklists().length) // employee_checklist
		log('Help Videos Loaded', this.coreSrvc.dbSrvc.helpVidSrvc.getHelpVideos().length) // help_videos
		log('Open Shifts', this.coreSrvc.dbSrvc.schedulerSrvc.getOpenShifts().length) // open_shifts
		log('Stations', this.coreSrvc.dbSrvc.stationSrvc.getStations().length) // station
	}

	secondaryLoadingHasFinished() {
		log('Secondary Loading Finished')
		const primaryUser = this.coreSrvc.dbSrvc.settingSrvc.getPrimaryUser()
		const hasBeenNotified = this.coreSrvc.notifySrvc.noPrimaryAdminNotificationPresented
		if (!primaryUser && !hasBeenNotified && !this.isAdpIvrAdmin) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'No Primary User',
				'This account does not have a primary user setup. Please contact support to report this issue.',
				8,
			)
			this.coreSrvc.notifySrvc.noPrimaryAdminNotificationPresented = true
		}

		// Setup global flag for merged job sites
		Global.areJobSitesMerged = this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite

		// Setup Health Center banner visibility and analytics setup
		this.isAnalyticsEnabled =
			this.coreSrvc.dbSrvc.settingSrvc.isInternalUser() || this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()?.globalEnableAnalytics

		if (this.isAnalyticsEnabled) {
			const healthCenterPrefs = this.coreSrvc.dbSrvc.settingSrvc.getHealthCenterPrefsForCompany()
			this.coreSrvc.dbSrvc.vizSrvc.healthCenterManager.setupManager()
			this.coreSrvc.dbSrvc.vizSrvc.healthCenterManager.configureAlertContributorStatusFromPrefs(healthCenterPrefs)
		}

		this.executePostLoadProcessing()
	}

	executePostLoadProcessing() {
		this.isGlobalCompany = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()

		// Post database finished loading event
		this.coreSrvc.notifySrvc.databaseFinishedLoading.next(true)

		// We've logged in so don't show the startup banner on login screen anymore
		this.coreSrvc.loginSrvc.showStartupBanner = false

		// Check for companyChangeReload flag in local storage. This is set in lambda service when a
		// company change exception is thrown
		const companyChangeReload = localStorage.getItem('companyChangeReloadTriggered')
		if (companyChangeReload) {
			localStorage.removeItem('companyChangeReloadTriggered')
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Company Changed',
				'The application was reloaded because your company was changed in another tab or browser.',
			)
		}

		// Run initial version check
		this.coreSrvc.appSrvc.getVersionAndProcessResults()

		// Setup banner style
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.coreSrvc.bannerSrvc.viewManager.groupAllBanners = userPrefs.globalGroupNotificationBanners
		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)

		// Setup announcement icon in nav bar
		if (!this.isAdpIvrAdmin) this.coreSrvc.dbSrvc.annSrvc.installNavBarIcon.next(true)

		// Hide expired schedules if W2W integrated
		const isW2WIntegrated = this.coreSrvc.dbSrvc.w2wSrvc.isW2WIntegrated()
		if (isW2WIntegrated) {
			this.coreSrvc.dbSrvc.schedulerSrvc.statusFilter.showExpired = false
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.showExpiredSchedules = false

			// W2WAPIKEYCHECK - BEGIN // Also in analytics service
			const w2wSync = this.coreSrvc.dbSrvc.w2wSrvc.getW2WSync()
			const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isActualUserInternal()
			const isForcingW2WApiKey = this.coreSrvc.dbSrvc.settingSrvc.forceRouteToW2WIntegration
			if (w2wSync && !w2wSync.partner_api && !isInternalUser && !isForcingW2WApiKey) {
				this.coreSrvc.dbSrvc.settingSrvc.forceRouteToW2WIntegration = true
				this.router.navigate(['/admin/integrations/whentowork'])
				return
			}
			// W2WAPIKEYCHECK - END
		}
		// Check for enrollment
		this.checkUserEnrollmentStatus()

		// The next part needs to be done because of the potential redirects
		this.progress.nextStage()
		this.cd.markForCheck()
		setTimeout(() => {
			const hasRedirectBlockingAlerts = this.hasRedirectBlockingAlert() || this.showProvisioningErrorAlert || this.showBillingErrorAlert
			if (!hasRedirectBlockingAlerts) this.setupRedirect()
			setTimeout(() => {
				this.isLoading = false
				this.cd.markForCheck()
			}, 100)
		}, 250)
	}

	private checkUserEnrollmentStatus() {
		if (this.coreSrvc.dbSrvc.settingSrvc.shouldSkipEnrollmentCheck) return
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()

		// Skip if they are read only.
		// if (user.role === 'READONLY') return

		const status = AppVersion.getEnrollmentNeedsUpdateStatus(user)
		if (status.vTos || status.vPrivacy) {
			this.coreSrvc.dbSrvc.settingSrvc.displayEnrollmentDialog.next(true)
		}
	}

	// Loading stages finished

	setupRedirect() {
		const requiredTransId = this.coreSrvc.dbSrvc.tranSrvc.requiredId
		this.coreSrvc.dbSrvc.tranSrvc.requiredId = null

		setTimeout(() => {
			// Process required transaction_log record
			if (requiredTransId) {
				log('Processing show transaction redirect')
				this.coreSrvc.dbSrvc.readRecord('transaction_log', requiredTransId).then((result) => {
					// Set filter dates to use day view for this record
					const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(requiredTransId)
					if (trans) {
						const jobDate = trans.job_date
						// Need to force setup transaction today view for the jobsdate
						this.coreSrvc.setTimeEntriesDayViewDate(jobDate)
					}
					log('Result of transaction read', result)
					if (result === 'DELETED') {
						log('Record deleted')
						this.coreSrvc.dbSrvc.tranSrvc.filterText = null
						this.coreSrvc.dbSrvc.settingSrvc.setRedirectUrl('')
					}
					if (result === 'UPDATED') {
						log('Loaded transaction ID', requiredTransId)
						this.processRedirect()
					}
				})
				return
			}

			// If day view is set and default view is set for today view then setup today view parameters,
			// otherwise clear today view paramaters
			const defaultToTodayView = this.coreSrvc.prefSrvc.data.transDefaultToTodayView
			if (defaultToTodayView) {
				const dayViewDate = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
				this.coreSrvc.setTimeEntriesDayViewDate(dayViewDate)
			} else {
				this.coreSrvc.setTimeEntriesDayViewDate(null)
			}
			this.processRedirect()
		})
	}

	processRedirect() {
		const redirectBackLinkRoute = this.coreSrvc.appSrvc.redirectBackLinkRoute
		if (redirectBackLinkRoute) {
			this.location.replaceState(redirectBackLinkRoute)
			this.coreSrvc.appSrvc.redirectBackLinkRoute = null
		}
		this.coreSrvc.dbSrvc.settingSrvc.followRedirectUrl()
	}

	setupCurrentVersion() {
		const lastVersionSaved = localStorage.getItem('currentAppVersion') || ''
		const lastSavedMajorVersion = lastVersionSaved.split('b')?.[0]
		const currentMajorVersion = this.currentVersion.split('b')?.[0]
		const manualUpdateRequested = localStorage.getItem('manualUpdateCheck')
		const versionsMatch = lastSavedMajorVersion === currentMajorVersion
		if (!versionsMatch || manualUpdateRequested) {
			if (versionsMatch) {
				this.coreSrvc.notifySrvc.notify(
					'success',
					'Application Version',
					`Your admin portal is at the newest version - v${this.currentVersion}`,
					5,
				)
			} else {
				this.coreSrvc.notifySrvc.notify(
					'success',
					'Application Update',
					`Your admin portal has been updated to version v${this.currentVersion}`,
					5,
				)
			}
			this.cd.detectChanges()
			localStorage.setItem('currentAppVersion', this.currentVersion)
			localStorage.removeItem('manualUpdateCheck')
		}
		const versionEvent = new GenericEvent('currentAppVersion', this.currentVersion)
		this.coreSrvc.eventSrvc.postGenericEvent(versionEvent)
	}

	setupAllowedCompaniesDropdown() {
		this.coreSrvc.dbSrvc.companySrvc.setupAllowedCompaniesDropdown()
	}

	checkAllowedCompaniesForGlobalIssues() {
		if (this.coreSrvc.notifySrvc.tracker.globalBillingNoticePosted) return
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const globalCompany = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompany()
		if (company.id === globalCompany?.id) return
		const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		const isPrimaryOrInternal = this.coreSrvc.dbSrvc.settingSrvc.isPrimaryOrInternalUser()
		if (isPrimaryOrInternal && globalCompany) {
			const accountStatus = globalCompany.account_status
			if (accountStatus === 'BILLING_NEED_INFO') {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Check Billing',
					'Potential billing issue found. Please switch to your global account and select the Billing option from the Admin menu.',
				)
				this.coreSrvc.notifySrvc.tracker.globalBillingNoticePosted = true
				return
			}
			if (accountStatus === 'BILLING_PORTAL_LOCKED' || accountStatus === 'BILLING_ACCOUNT_LOCKED' || accountStatus === 'BILLING_PAYMENT_FAILED') {
				if (isInternalUser) {
					this.coreSrvc.notifySrvc.notify('error', 'Internal User Notice', 'Billing issue found in global account.')
					this.coreSrvc.notifySrvc.tracker.globalBillingNoticePosted = true
					return
				}
				const request = new DataAccessRequest('company_switch_list', 'switch_company', { id: globalCompany.id })
				this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
					this.coreSrvc.dbSrvc.settingSrvc.clearSettingsForCompanySwitch()
					this.reloadApplication()
				})
			}
		}
	}

	setupUsersDropdown() {
		log('Setup up users dropdown')
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const myRoleAllowsAccess = myActualUser.role === 'INTERNAL' || myActualUser.role === 'PRIMARY' || myActualUser.role === 'MANAGER'

		// Setup view as user
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		this.currentUserId = user.id
		this.selectedUserId = user.id

		if (myRoleAllowsAccess) {
			const users = this.filterUserListForUserDropdown()

			const dropdownData = users.map((u) => ({ label: u.first_name + ' ' + u.last_name, value: u.id }))
			this.usersDropdown = _.sortBy(dropdownData, 'label')

			// If the list has more than add internal user if necessary and show it
			if (users.length > 1) {
				if (myActualUser.role === 'INTERNAL') {
					log('Add internal user to user switcher')
					this.usersDropdown.unshift({
						label: myActualUser.first_name + ' ' + myActualUser.last_name,
						value: myActualUser.id,
					})
				}
				this.showUserSelector = true
			}
		} else {
			this.showUserSelector = false
		}
	}

	filterUserListForUserDropdown() {
		log('Filtering user list')
		const users = this.coreSrvc.dbSrvc.settingSrvc.getUsers().filter((u) => u.role !== 'INTERNAL' && u.role !== 'NO_COGNITO')
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const filteredUsers = [myActualUser]
		if (myActualUser.role === 'MANAGER') {
			log('Filtering user list for a manager')
			users.forEach((user) => {
				log('Filtering User', user.first_name, user.managed_by)
				if (user.managed_by === myActualUser.id) {
					filteredUsers.push(user)
				}
			})
			return filteredUsers
		}
		return users
	}

	hasRedirectBlockingAlert(): boolean {
		// Return true if there is a redirect blocking alert
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const accountStatus = company.account_status
		const hasRoutedToBilling = this.coreSrvc.dbSrvc.settingSrvc.billingAlertHasRoutedToBilling
		const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		if (!company) {
			this.showProvisioningErrorAlert = true
			return
		}

		if (!hasRoutedToBilling) {
			switch (accountStatus) {
				// case 'BILLING_PAYMENT_FAILED': - DEPRECATED 20241104
				case 'BILLING_PORTAL_LOCKED':
				case 'BILLING_ACCOUNT_LOCKED':
				case 'LOCKED_SUSPENDED':
					// If not INTERNAL user then route to billing and return true to prevent default redirect processing
					if (!isInternalUser) {
						this.coreSrvc.dbSrvc.settingSrvc.billingAlertHasRoutedToBilling = true
						this.router.navigate(['/admin/settings/billing'])
						return true // Return true to block redirects
					}
			}
		}
		return false // Return false to allow redirects
	}

	changeUser() {
		if (this.currentUserId === this.selectedUserId) {
			return
		}
		log('Change to user', this.selectedUserId)
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		if (myActualUser.id === this.selectedUserId) {
			this.coreSrvc.dbSrvc.settingSrvc.viewAsUserId = null
			this.currentUserId = this.selectedUserId
			this.coreSrvc.notifySrvc.notify('success', 'Supervisor Switched', `You are now viewing the site as your normal user.`, 3)
			this.coreSrvc.eventSrvc.displayUserChanged(null)
		} else {
			this.coreSrvc.dbSrvc.settingSrvc.viewAsUserId = this.selectedUserId
			this.currentUserId = this.selectedUserId
			const currentUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
			const userName = currentUser.first_name + ' ' + currentUser.last_name
			this.coreSrvc.notifySrvc.notify('success', 'Supervisor Switched', `You are now viewing the site as ${userName}.`, 3)
			this.coreSrvc.eventSrvc.displayUserChanged(userName)
		}
		this.setupAccessPermissions()
		this.coreSrvc.dbSrvc.settingSrvc.setUserAdminPrefs()
	}

	reloadApplication() {
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
	}

	showManual() {
		window.open('https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/', '_blank')
	}

	switchToScheduleView(view: ScheduleViewTabState) {
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.setScheduleViewSection(view)
		this.router.navigate(['/admin/scheduler'])
		setTimeout(() => {
			this.coreSrvc.notifySrvc.navBarNeedsUpdate.next(true)
		}, 100)
	}

	routeTo(table: PermissionTableName, route: string) {
		const userPermissions = this.permList[table] as UserPermissions
		const access = userPermissions?.access
		const owner = userPermissions?.owner
		const canAccess = table === null ? true : access.read || (owner ? owner.read : false)

		if (canAccess) {
			this.isRouting = true
			this.currentRoute = route

			setTimeout(() => {
				scrollTo(0, 0)
				this.router.navigate([`${route}`]).then((success) => {
					if (!success) {
						this.currentRoute = '/admin/dashboard'
						this.cd.markForCheck()
					}
					return success
				})
			}, 150)
		} else {
			this.coreSrvc.notifySrvc.notify('error', 'Not Authorized', 'You are not authorized to access this section.', 2)
		}
	}

	reportsBtnClicked() {
		this.showReportsDialog = true
		// const canAccessShiftReports = true // this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()?.reportEnableEmpReports // DEPRECATED 20240514
		// if (canAccessShiftReports) {
		// 	this.showReportsDialog = true
		// } else {
		// 	this.routeTo('report', '/admin/reports/business', null)
		// }
	}

	logsBtnClicked() {
		this.showLogsDialog = true
	}

	testBtnClicked() {
		this.coreSrvc.dbSrvc.bulkRead(['onboard_definition']).then((result) => {
			log('onboard_definition', result)
		})
	}
}

class DashboardProgressLoader {
	currentStage = 0
	totalStages = 6

	cd: ChangeDetectorRef
	renderer: Renderer2
	progressBarElem: ElementRef

	nextStage() {
		this.currentStage++
		this.updateProgressBar()
	}
	reset() {
		this.currentStage = 1
	}

	constructor(renderer: Renderer2, progressBarElem: ElementRef) {
		this.renderer = renderer
		this.progressBarElem = progressBarElem
	}

	private updateProgressBar(): void {
		const percent = Math.ceil((this.currentStage / this.totalStages) * 100)
		const elm = this.progressBarElem?.nativeElement as HTMLElement
		this.renderer.setStyle(elm, 'width', `${percent}%`)
	}
}
