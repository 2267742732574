import { SafeHtml, DomSanitizer } from '@angular/platform-browser'
import {
	Component,
	OnInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	AfterContentInit,
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms'

import {
	JobRecord,
	DataAccessRequest,
	DialogManager,
	NotificationProfile,
	JobSiteRecord,
	Timezone,
	OrganizationSelectItem,
	AdpRateCodeSelectItem,
	LocationAddress,
	Incident,
	IncidentReadDataAccessRequest,
	HelpDialogMessage,
	EditFormJobSiteLinkedNumbersManager,
	EditFormJobSiteLinkedNumbersManagerConfig,
	EditFormJobSiteLocationManager,
	EditFormJobSiteLocationManagerConfig,
} from '@app/models'
import { CoreService } from '@app/services'

import { log, Helper, DateTimeHelper, FormHelper, JobTableFormatter } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'

import { CountryCode } from 'libphonenumber-js/types'

import { NumberHelper } from '@app/helpers/number'

import _ from 'lodash'
import moment from 'moment-timezone'
import { environment } from '@env/environment'
import { FormTagsComponent } from '@app/components/form-tags/form-tags.component'

@Component({
	selector: 'app-job-detail',
	templateUrl: './job-edit.component.html',
	styleUrls: ['./job-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class JobEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	// Component instance properties
	environment = environment
	accessHelper: AccessHelper

	title: string
	isNew: boolean
	isUpdating: boolean
	isEditingNotifications: boolean
	jobForm: UntypedFormGroup
	isRecordValid = true
	isPureSchedule = true
	is12HourFormat = true
	isJobDefaultJobForJobSite = false
	isJobQRCodeAvailable = false
	isInternalUser = false
	isNcciCodeAvailable = false
	isPayRateAvailable = false
	isSystemJob = false
	isTravelJob = false
	isSyncLocked = false
	isCallCenterEnabled = false

	overageCalculationsEnabled = false
	useJobCodesEnabled = false

	jobSitesDropdown: SelectItem[]
	showMultiDayJobOption = false

	profileEdit = { recordId: null, action: 'edit', incrementLinkCount: false }
	pinnedReportAction = { recordId: null, showDialog: false }

	// isShowingNotifications = false // DEPRECATED 2024-11-26 - No longer including notifications edit in job edit

	showTaxLocations = false
	taxLocationList: Array<string> = []
	taxLocationOptions: Array<string> = []

	showSupervisors = false
	showAdvancedOptions = false
	showUserGroups = false
	supervisorOptions: Array<SelectItem> = []
	userGroupOptions: Array<SelectItem> = []

	timezoneOptions: Array<SelectItem> = []
	profilesDropdown: Array<SelectItem> = []
	toursDropdown: Array<SelectItem> = []
	adjustHoursRuleDropdown: Array<SelectItem> = []
	adpDepartmentCodes: Array<SelectItem> = []
	adpRateCodes: Array<AdpRateCodeSelectItem> = []
	qbCustomerDropdown: Array<SelectItem> = []
	qbVendorDropdown: Array<SelectItem> = []
	qbServiceItemDropdown: Array<SelectItem> = []
	clientDropdown: Array<OrganizationSelectItem> = []
	vendorDropdown: Array<OrganizationSelectItem> = []

	tagOptionsList = this.coreSrvc.dbSrvc.jobSrvc.getTagLabels()

	newSupEmailInput: string
	supervisorEmails: Array<string> = []

	pinnedReports: Array<PinnedReport> = []

	currencySymbol = ''
	ncciCode
	ncciOptions = []

	trackOverage = false
	exampleDescription = 1
	supPhoneValid = false
	countryCodeData = Helper.countryIsoData

	noteMinInterval = 5
	noteMaxDuration = 120

	pvm: JobDetailProfileViewModel

	@Input() action: string
	@Input() jobId: number

	@Input() dialogManager = new DialogManager()

	@Output() editActionCancelled = new EventEmitter<boolean>()
	@Output() saveActionComplete = new EventEmitter<number>()

	job: JobRecord

	// Merged Job Site Properties

	jobSite: JobSiteRecord
	isJobSiteMerged = false
	linkedNumberManager: EditFormJobSiteLinkedNumbersManager
	locationManager: EditFormJobSiteLocationManager

	defaultTimezoneId: number
	timezoneData: Array<Timezone> = []

	// View Child References

	@ViewChild('tagComp') tagComp: FormTagsComponent
	@ViewChild('endTimeInput') endTimeInputElement
	@ViewChild('startTimeInput') startTimeInputElement

	// Component constructor and initialization

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private ds: DomSanitizer,
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
		private changeDetector: ChangeDetectorRef,
	) {
		this.pvm = new JobDetailProfileViewModel(ds)
		this.setupAccessPermissions()

		// Setup various conditions for display of UI
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.isJobSiteMerged = company?.merge_jobsite
		this.isNcciCodeAvailable = company?.ncci_codes
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.role === 'INTERNAL'

		// Pay rate setup
		this.isPayRateAvailable = company?.pay_rate
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()

		// Setup location and linked number managers
		this.locationManager = new EditFormJobSiteLocationManager(this.coreSrvc.dbSrvc)
		this.linkedNumberManager = new EditFormJobSiteLinkedNumbersManager(this.coreSrvc)

		// Setup tax location list
		this.showTaxLocations = company.work_tax_location
		this.taxLocationList = this.coreSrvc.dbSrvc.settingSrvc.getTaxLocations(this.coreSrvc.dbSrvc)

		// Setup ADP rate code options
		this.adpRateCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodeDropdown(false)

		// Setup Job QR Code availability
		this.isJobQRCodeAvailable = this.coreSrvc.dbSrvc.settingSrvc.getCompany().validate_qr_codes

		// Call center availability
		const hasGlobal = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		if (hasGlobal) {
			const globalCompany = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompany()
			this.isCallCenterEnabled = globalCompany.cc_enable
		} else {
			this.isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.getCompany().cc_enable
		}
	}

	get hasClients(): boolean {
		return this.clientDropdown.length > 1
	}
	get hasVendors(): boolean {
		return this.vendorDropdown.length > 1
	}

	// Link job site to landline numbers and Link job site to landline numbers are split across job site and job
	// when sites not merged and only available on job if you have a phone number set on the site. If job sites
	// are merged then both are available on the job.

	get showLinkOnlyCheckbox(): boolean {
		if (this.isJobSiteMerged) {
			return this.linkedNumberManager.linkLandlineCheckbox
		} else {
			const siteId = this.jobForm?.get('location_id').value
			const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
			if (site) {
				const linkedNumbers = site.phone_number_regex_e164
				if (!!linkedNumbers && linkedNumbers !== '+18888888888') return true
			}
		}
		return false
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'job')
	}

	isFormValid(): boolean {
		const isTimezoneValid = this.isJobSiteMerged ? this.jobForm.get('jsTimezoneId').value : true
		const isSupValid = this.isJobSiteMerged ? this.jobForm.get('jsSupervisor').value : true
		const isFormValid = this.jobForm.valid
		return isFormValid && isTimezoneValid && isSupValid
	}

	public detectChanges() {
		this.cd?.detectChanges()
	}

	// Component lifecycle methods

	ngOnInit() {
		this.setupComponent()
		this.setupForm()
		this.setupAdvancedOptions()

		this.setupLocationManager()
		this.setupLinkedNumberManager()
		this.setupSupervisorsDropdown()
		this.setupUserGroupDropdown()

		this.setupTimezoneDropdown()
		this.setupClientDropdown()
		this.setupVendorDropdown()
		this.setupAdpDropdowns()
		this.setupJobSitesDropdown()
		this.setupProfilesDropdown()
		this.setupToursDropdown()
		this.setupAdjustHoursRuleDropdown()
		this.setupClientDropdown()

		this.setNcciCode()
	}

	getFormValidationErrors() {
		FormHelper.getFormValidationErrors(this.jobForm)
	}

	ngAfterViewInit() {
		if (!this.isRecordValid) {
			return
		}
	}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private enableAllTooltips() {
		// Enable Tooltips
		setTimeout(() => {
			const itemTooltip: any = $('.item-tooltip')
			itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
		}, 500)
	}

	private disableAllTooltips() {
		const itemTooltip: any = $('.item-tooltip')
		itemTooltip.tooltip('hide')
	}

	private setupComponent() {
		const action = this.action

		switch (action) {
			case 'new':
				this.title = 'Add Job'
				this.isNew = true
				break
			default:
				this.job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(this.jobId)
				log('Current job is:', this.job)
				this.title = 'Edit Job'
				this.isNew = false
				if (!this.job) {
					this.isRecordValid = false
					return
				}
				break
		}

		if (!this.isNew) this.fetchPinnedReports()

		// See if this is a system job and set the flag if it is
		this.isSystemJob = this.coreSrvc.dbSrvc.jobSrvc.isSystemJob(this.job)
		this.isTravelJob = this.coreSrvc.dbSrvc.jobSrvc.isTravelJob(this.job)
		this.isSyncLocked = this.job?.sync_lock

		// If we're clonging, then tweak some properties to support that.
		// Will also need to tweak a few things after form setup
		if (this.action === 'clone') {
			this.title = 'Copy Job'
			this.isNew = true
		}

		// Setup merged job site
		this.timezoneData = this.coreSrvc.dbSrvc.settingSrvc.getSupportedTimezones()

		const siteId = this.job?.location_id
		this.jobSite = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId) || new JobSiteRecord()
		log('Job Site Setup', this.jobSite)

		// Check to see if multi-day should be enabled
		// Change to look for multi-day option instead of QBId
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()

		if (company) {
			this.overageCalculationsEnabled = company.overage
			this.useJobCodesEnabled = company.useJobCodes
			this.showMultiDayJobOption = company.useMultiDay
		}

		// Check to see if QBO customer
		const isQBOCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBOCustomer()
		const isQBDCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBDCustomer()
		if (isQBOCustomer || isQBDCustomer) {
			this.qbCustomerDropdown = this.coreSrvc.dbSrvc.qbSrvc.getCustomerDropdownData()
			this.qbVendorDropdown = this.coreSrvc.dbSrvc.qbSrvc.getVendorDropdownData()
			this.qbServiceItemDropdown = this.coreSrvc.dbSrvc.qbSrvc.getServiceItemDropdownData()
			log('QBO Customer Dropdown', this.qbCustomerDropdown.length)
			log('QBO Vendor Dropdown', this.qbVendorDropdown.length)
			log('QBO Service Item Dropdown', this.qbServiceItemDropdown.length)
			// this.qbCustomerDropdown.forEach(i => i.label = 'Replace')
		}

		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.is12HourFormat = userPrefs.globalFormatTime12Hours
	}

	private fetchPinnedReports() {
		const request = new IncidentReadDataAccessRequest(this.jobId, 'JOB')
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data: Array<any> = result.data
			const incidents = data?.map((rec) => new Incident(rec)) ?? []
			const sortedIncidents = _.orderBy(incidents, ['updatedOn'], ['desc'])
			const reports = sortedIncidents.filter((i) => i.pinned)
			this.pinnedReports = reports.map((rep) => new PinnedReport(rep))
			this.cd.markForCheck()
			log('Pinned Reports', this.pinnedReports)
		})
	}

	public formatReportDate(incident: Incident) {
		const timestamp = incident.updated ?? incident.created
		return DateTimeHelper.mediumDateFromDateString(timestamp)
	}

	public viewPinnedReport(report: PinnedReport) {
		this.coreSrvc.eventSrvc.showFullScreenReport(report.incident)
	}

	public gotoTimeEntry(report: PinnedReport) {
		if (!report.incident.transaction_log_id) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'No Time Entry',
				'The time entry associated with this report has been deleted or is currently unavailable.',
			)
			return
		}
		const incident = report.incident
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const url = `/#/redirect/record/${incident.transaction_log_id}/transactions/${companyId}`
		log('URL', url)
		return

		// get the current domain and open in new window
		const domain = window.location.origin
		window.open(`${domain}${url}`, '_blank')
	}

	public confirmUnpinReport(report: PinnedReport) {
		const incident = report.incident
		incident.pinned = false
		this.coreSrvc.dbSrvc.updateRecord('incident_log', incident).then(() => {
			this.pinnedReports = this.pinnedReports.filter((p) => p !== report)
			this.cd.markForCheck()
		})
	}

	private setupForm() {
		// Check if this job was auto created for job site
		this.isJobDefaultJobForJobSite = this.isNew ? false : this.job.auto_created

		this.jobForm = this.fb.group({
			// Form ID Fields
			id: [this.job ? this.job.id : null],

			//////////////////
			// Main Section //
			//////////////////

			job_code: [this.job ? this.job.job_code : null],
			description: [this.job ? this.job.description : '', Validators.required],
			location_id: [this.job ? this.job.location_id : null],
			tags_json: [this.job ? this.job.tags_json : null],

			//////////////////////
			// Advanced Options //
			//////////////////////

			external_id: [this.job ? this.job.external_id : null],
			job_details: [this.job ? this.job.job_details : null],
			notification_profile_id: [this.action === 'new' ? null : this.job.notification_profile_id],
			tour_id: [this.action === 'new' ? null : (this.job?.tour_id ?? null)],

			// Dispatcher Checkpoints
			outbound_call_interval: [this.job ? this.job.outbound_call_interval : null],

			// Break Options
			break_time: [this.job ? this.setupFormBreakTime(this.job.break_time) : null],
			break_time_worked: [this.job ? this.makeBreakTimeWorked(this.job.break_time_worked) : null],

			// Client / Vendor Options
			client_id: [this.job ? this.job.client_id : null],
			vendor_id: [this.job ? this.job.vendor_id : null],

			// Bill / Pay Rate Options
			employee_pay_rate: [this.job ? this.job.employee_pay_rate?.toFixed(2) : null],
			client_pay_rate: [this.job ? this.job.client_pay_rate?.toFixed(2) : null],
			vendor_pay_rate: [this.job ? this.job.vendor_pay_rate?.toFixed(2) : null],

			// Budgeted Time / Overage
			flat_rate: [this.isNew ? false : this.job.flat_rate], // DEPRECATED - Use adjust_hours_rule
			overage_time: [this.job ? this.setupJobDuration(this.job.overage_time) : null],
			adjust_hours_rule: [this.job ? this.job.adjust_hours_rule : null],

			// ADP Options
			adp_department_code_id: [this.job ? this.job.adp_department_code_id : null],
			adp_rate_code_id: [this.job ? this.job.adp_rate_code_id : null],

			// QuickBooks Options
			qbo_customer_id: [this.job ? this.job.qbo_customer_id : null],
			qbo_vendor_id: [this.job ? this.job.qbo_vendor_id : null],
			qbo_service_item_id: [this.job ? this.job.qbo_service_item_id : null],

			// General Payroll Options
			ncci_code: [this.job ? this.job.ncci_code : null],
			work_tax_location: [this.job ? this.job.work_tax_location : null],

			// Miscellaneous Options
			active: [this.job ? this.job.active : true],
			multi_day: [this.job ? this.job.multi_day : false],
			require_photo_checkin: [this.job ? this.job.require_photo_checkin : false],
			require_qrc_checkin: [this.job ? this.job.require_qrc_checkin : false],
			chkpts_require_photo: [this.job ? this.job.chkpts_require_photo : false],
			require_photo_checkout: [this.job ? this.job.require_photo_checkout : false],
			require_qrc_checkout: [this.job ? this.job.require_qrc_checkout : false],
			linked_only: [this.job ? this.job.linked_only : false],

			/////////////////////
			// Job Site Fields //
			/////////////////////

			dialingCode: [this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso],
			jsLocationDetails: [this.isJobSiteMerged ? this.jobSite?.location_details : ''],
			jsSupervisor: [this.isJobSiteMerged ? this.jobSite?.supervisor : null],
			jsClientId: [this.isJobSiteMerged ? this.jobSite?.client_id : null],
			jsTimezoneId: [this.jobSite?.timezone_id ?? this.coreSrvc.dbSrvc.settingSrvc.getCompanyDefaultTimezoneId()],
			jsUnionZone: [this.jobSite?.union_zone ?? null],
			jsSupervisorGroupId: [this.jobSite?.supervisor_group_id ?? null],

			////////////////
			// Deprecated //
			////////////////
			anytime: [true], // All jobs are now anytime jobs
			allow_breaktime: [this.job ? this.job.allow_breaktime : true],
		})

		// Disable name and location inputs
		if (this.isJobDefaultJobForJobSite) {
			const siteControl = this.jobForm.get('location_id') as UntypedFormControl
			siteControl.disable({ onlySelf: true })
			const nameControl = this.jobForm.get('description') as UntypedFormControl
			nameControl.disable({ onlySelf: true })
		}

		// Update Notification Profile
		const profileId = this.jobForm.get('notification_profile_id').value
		const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(profileId) || new NotificationProfile()
		this.pvm.updateViewModel(profile)
		this.enableAllTooltips()

		// Tweak propertites if this is a clone action
		if (this.action === 'clone') {
			const jobName = this.job.description + ' (Copy)'
			this.jobForm.get('id').setValue(null)
			this.jobForm.get('description').setValue(jobName)
			this.jobForm.get('external_id').setValue(null)
			this.jobForm.get('job_code').setValue(null)
			this.isSyncLocked = false
		}

		// Disable changing job name if sync locked
		if (this.job?.sync_lock && this.action !== 'clone') {
			this.jobForm.get('description').disable()
			this.jobForm.get('location_id').disable()
		}
	}

	private setupFormBreakTime(value: string) {
		return value ? moment.duration(value).asMinutes() : null
	}

	private setupAdvancedOptions() {
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const showAdvancedOptions = myUserPrefs.globalExpandAdvancedOptions
		this.showAdvancedOptions = showAdvancedOptions
	}

	private setupLocationManager() {
		const config = new EditFormJobSiteLocationManagerConfig()
		const myAdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		config.enableAddressByMapPin = myAdminPrefs.enableAddressByMapPin
		const jobId = this.jobId
		const jobSite = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
		if (jobSite) {
			config.locationAddress = this.action === 'new' ? new LocationAddress() : LocationAddress.buildFromSite(jobSite)
			config.geoCodeReverse = jobSite.geo_code_reverse
			config.geoCoded = jobSite.geo_coded
			config.geoLatitude = jobSite.geo_latitude
			config.geoLongitude = jobSite.geo_longitude
		} else {
			config.locationAddress = new LocationAddress()
		}
		this.locationManager.setupManager(config)
		log('setupLocationManager locationManager', this.locationManager)
	}

	private setupLinkedNumberManager() {
		const phoneRegexp = this.jobSite?.phone_number_regex_e164 ?? null
		const phoneNumbers = phoneRegexp ? phoneRegexp.split(',').filter((num) => num !== '+18888888888') : []
		const config = new EditFormJobSiteLinkedNumbersManagerConfig()
		config.allowCallsFromEmployeeMobilePhones = this.coreSrvc.dbSrvc.settingSrvc.getCompany().allow_cell_calls
		config.numbers = phoneNumbers
		config.countryCode = this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso as CountryCode
		this.linkedNumberManager.setupManager(config)
		log('Linked Number Manager', this.linkedNumberManager)
	}

	public updateProfile() {
		this.disableAllTooltips()
		const profileId = this.jobForm.get('notification_profile_id').value
		const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(profileId) || new NotificationProfile()
		this.pvm.updateViewModel(profile)
		this.enableAllTooltips()
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.onSubmit(this.jobForm)
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.resetCancelBtnAction()
	}

	private setupDialogManagerForProfileEdit(action: string) {
		log('Setting up dialog')
		const actionString = action === 'edit' ? 'Edit' : 'New'
		this.dialogManager.headerLabel = `${actionString} Notification Profile`
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
	}

	public profileAction(action: string) {
		log('Profile Action', action)
		this.dialogManager.saveScrollPosition('jobEdit')
		if (action === 'edit') {
			const currentProfileId = this.jobForm.get('notification_profile_id').value
			const shouldIncrement = this.isNew || (currentProfileId && currentProfileId !== this.job.notification_profile_id)
			this.profileEdit.incrementLinkCount = shouldIncrement
		} else {
			this.profileEdit.incrementLinkCount = false
		}
		this.setupDialogManagerForProfileEdit(action)
		this.dialogManager.cancelBtnAction = () => this.restoreDialogManager()
		const profileId = this.jobForm.get('notification_profile_id').value
		log('Current Profile ID', profileId)
		this.profileEdit.recordId = profileId
		this.profileEdit.action = action
		this.isEditingNotifications = true
		this.dialogManager.scrollToTop()
	}

	public profileUpdated() {
		log('Job Detail got profileUpdated')
		this.setupProfilesDropdown()
		this.restoreDialogManager()
		this.updateProfile()
		this.cd.detectChanges()
	}

	private restoreDialogManager() {
		log('Restore Dialog Manager')
		this.setupDialogManager()
		const action = this.action
		this.isEditingNotifications = false
		if (action === 'new') {
			this.dialogManager.headerLabel = 'Add Job'
		}
		if (action === 'edit') {
			this.dialogManager.headerLabel = 'Edit Job'
		}
		if (action === 'clone') {
			this.dialogManager.headerLabel = 'Copy Job'
		}
		this.dialogManager.restoreScrollPosition('jobEdit')
		this.cd.detectChanges()
	}

	private setupProfilesDropdown() {
		const profiles = this.coreSrvc.dbSrvc.npSrvc.getProfiles().map((p) => ({ label: p.name, value: p.id }))
		log('Profiles', profiles)
		profiles.unshift({ label: 'Select a Profile', value: null })
		this.profilesDropdown = profiles
	}

	private setupToursDropdown() {
		const tours = this.coreSrvc.dbSrvc.tourSrvc
			.getTourRecords()
			.map((t) => ({ label: t.description ?? '< no description >', value: t.id, data: t }))
		const sortedTours = _.orderBy(tours, ['label'], ['asc'])
		sortedTours.unshift({ label: 'Select a Tour', value: null, data: null })
		this.toursDropdown = sortedTours
	}

	private setupAdjustHoursRuleDropdown() {
		this.adjustHoursRuleDropdown = [
			{ label: 'No Adjustment / Report Only', value: null },
			{ label: 'Match Always', value: 'FLAT_RATE' },
			{ label: 'Match Unless Over', value: 'FLAT_RATE_ALLOW_OVER' },
			{ label: 'Match Unless Over/Tardy', value: 'ADJUST_FOR_TARDY' },
		]
	}

	private setupAdpDropdowns() {
		this.adpDepartmentCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpDepartmentCodesDropdown(true)
		this.adpRateCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodeDropdown(false)
	}

	private setupClientDropdown() {
		const dropdown = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationDropdownData('CLIENT')
		// if (dropdown.length > 0) { this.hasClients = true }
		dropdown.unshift({ label: 'Select Client', value: null, data: null })
		this.clientDropdown = dropdown
		log('Client Dropdown', dropdown)
	}

	private setupVendorDropdown() {
		const dropdown = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationDropdownData('VENDOR')
		// if (dropdown.length > 0) { this.hasClients = true }
		dropdown.unshift({ label: 'Select Vendor', value: null, data: null })
		this.vendorDropdown = dropdown
		log('Vendor Dropdown', dropdown)
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_id }
			})
		// timezones.unshift({ label: 'Select a Timezone', value: null })
		this.timezoneOptions = timezones
	}

	private setupSupervisorsDropdown() {
		const userPermissions = this.accessHelper.getPermissionsFor('site')
		const access = userPermissions.access
		const owner = userPermissions.owner
		const supervisors = this.coreSrvc.dbSrvc.settingSrvc.getUsers()
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		const primaryUserId = this.coreSrvc.dbSrvc.settingSrvc.getPrimaryUser()?.id
		// if (!primaryUserId) { alert('Error encountered. This account does not have a primary user set.') }
		const isSupRestricted = this.isNew ? owner.create && !access.create : owner.update && !access.update
		const isUserAManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const selectedSupId = isSupRestricted ? myUserId : primaryUserId
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(this.jobId)
		const isSystemJob = job ? this.coreSrvc.dbSrvc.jobSrvc.isSystemJob(job) : false

		const users =
			isUserAManager && isSupRestricted ? this.coreSrvc.dbSrvc.settingSrvc.getManagedUsers() : this.coreSrvc.dbSrvc.settingSrvc.getUsers()
		log('USERS', users)
		const dropdown = users.map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Supervisor', value: null })
		this.supervisorOptions = sortedDropdown

		// Autofill supervisor when only one option and not looking at system job
		// if ((supervisors.length === 1 || isSupRestricted) && !isUserAManager && !isSystemJob) {
		if (supervisors.length === 1 && !isSystemJob) {
			this.jobForm.get('jsSupervisor').setValue(myUserId)
			this.showSupervisors = false
		} else {
			this.showSupervisors = true
		}
	}

	setupUserGroupDropdown() {
		this.userGroupOptions = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupsDropdownData(true)
		if (this.userGroupOptions.length > 1) {
			this.showUserGroups = true
		}
		log('User Group Options', this.userGroupOptions)
	}

	// Manage Job Site Dropdown

	private setupJobSitesDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('job')
		const isRestricted = permissions.owner.read && !permissions.access.read // permissions.isSelectorRestrictedFor(SelectorPermissionName.site)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		this.jobSitesDropdown = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, [])
		if (this.job?.description !== 'W2W-TEMPLATE') {
			this.jobSitesDropdown = this.jobSitesDropdown.filter((di) => di.label !== 'W2W-TEMPLATE')
		}
		this.jobSitesDropdown.unshift({ label: 'Select a Job Site', value: null })
	}

	makeBreakTimeWorked(dur: string) {
		if (!dur) {
			return null
		}
		const duration = moment.duration(dur)
		return DateTimeHelper.formatDuration('H:mm', duration)
	}

	searchTaxLocation(event) {
		const text: string = this.jobForm.get('work_tax_location').value || ''
		if (!text) {
			this.taxLocationOptions = [...this.taxLocationList]
			return
		}
		const filteredOptions = this.taxLocationList.filter((opt) => opt.toLowerCase().includes(text.toLowerCase()))
		this.taxLocationOptions = filteredOptions
	}

	setNcciCode() {
		log('Setting NCCI Code')
		const ncciCodeId = this.jobForm.get('ncci_code').value
		log('Current NCCI Code ID', ncciCodeId)
		if (ncciCodeId) {
			const request = new DataAccessRequest('ncci_codes', null, { id: ncciCodeId }, null)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('SetupNcciCode Result', result)
				const data = result.data.map((d) => ({
					id: d.id,
					code: d.code,
					description: d.description,
					descLong: d.code + ': ' + d.description,
				}))
				const value = data[0]
				if (value) {
					log('Got ncci code id', value.id)
					this.ncciOptions = data
					this.ncciCode = value
				}
				this.changeDetector.detectChanges()
			})
		}
	}

	setupJobDuration(jobDuration: string): string {
		if (!jobDuration) {
			return ''
		}
		const duration = moment.duration(jobDuration)
		return DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', duration)
	}

	showDescription(value): boolean {
		const selection = parseInt(value, 10)
		if (selection) {
			this.exampleDescription = selection
		}
		return false
	}

	// Determines if form should be shown or not
	get showForm(): boolean {
		if (!this.isRecordValid) {
			return false
		}
		return true
	}

	showAdpOptions(): boolean {
		const isIntegrated = this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()
		const hasDeptCodes = this.adpDepartmentCodes.length > 1
		const hasRateCodes = this.adpRateCodes.length > 1

		return isIntegrated && (hasDeptCodes || hasRateCodes)
	}

	// Show UI for QBO if registered as QBO customer
	showQBOOptions(): boolean {
		const isQBOCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBOCustomer()
		const isQBDCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBDCustomer()
		if (isQBOCustomer || isQBDCustomer) {
			if (this.coreSrvc.dbSrvc.qbSrvc.hasCustomerData() || this.coreSrvc.dbSrvc.qbSrvc.hasServiceItemData()) {
				return true
			}
		}
		return false
	}

	// When multi-day is selected this changes the label for start time
	endTimeLabel(): string {
		if (this.showMultiDayJobOption && this.jobForm.value.multi_day) {
			return 'Latest Check-In'
		}
		return 'End Time'
	}

	formToggle(option: string) {
		if (option === 'anytime') {
			this.jobForm.value.anytime = !this.jobForm.value.anytime
		}
		this.changeDetector.detectChanges()
	}

	// Event methods

	onSubmit({ value, valid }: { value: JobRecord; valid: boolean }): boolean {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.jobForm)

		// Check for tag being edited
		if (this.tagComp && !this.tagComp?.addTag()) return

		if (this.tagComp?.selectedTag) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Action Required',
				'You are curretnly modifying a tag. You must cancel or confirm your changes before you can save this record.',
			)
			return
		}

		// Check for number in linked numbers and add if valid
		const submitMsg = this.linkedNumberManager.prepareForSubmission()
		if (submitMsg) {
			alert(submitMsg)
			return
		}

		const record: JobRecord = this.makeUpdateRecord()
		log('Update Record To Submit', record)
		// return

		if (record) {
			this.isUpdating = true
			this.cd.detectChanges()
			if (this.isNew) {
				this.coreSrvc.dbSrvc.insertRecord('job', record).then((insSuccess) => {
					if (insSuccess) {
						this.coreSrvc.dbSrvc.readTable('location').then((siteReadSuccess) => {
							this.coreSrvc.dbSrvc.readTable('job').then((result) => {
								this.saveActionComplete.emit(null)
								this.dialogManager.isDialogVisible = false
							})
						})
					} else {
						this.isUpdating = false
						this.cd.detectChanges()
					}
				})
			} else {
				this.coreSrvc.dbSrvc.updateRecord('job', record).then((upSuccess) => {
					if (upSuccess) {
						const siteId = record.location_id
						this.coreSrvc.dbSrvc.readRecord('location', siteId).then((siteReadSuccess) => {
							this.coreSrvc.dbSrvc.readTable('job').then((result) => {
								this.saveActionComplete.emit(this.jobId)
								this.dialogManager.isDialogVisible = false
							})
						})
					} else {
						this.isUpdating = false
						this.cd.detectChanges()
					}
				})
			}
		}
		return false
	}

	onCancel(): boolean {
		this.editActionCancelled.emit(true)
		return false
	}

	toggleCheckbox(prop: string) {
		this.jobForm.get(prop).setValue(!this.jobForm.get(prop).value)
	}

	searchNcci(value: any) {
		log('NCCI Search:', value)
		const searchText: string = value['query']
		log('Search Text', searchText)
		if (!searchText) {
			return
		}
		const request = new DataAccessRequest('ncci_codes', null, { search: searchText }, null)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data.map((d) => ({
				id: d.id,
				code: d.code,
				description: d.description,
				descLong: d.code + ': ' + d.description,
			}))
			this.ncciOptions = data
			log('Lambda Result', result)
			this.changeDetector.detectChanges()
		})
	}

	handleNcciSelect() {
		log('Selected Code', this.ncciCode)
		log(this.ncciCode)
	}

	handleNcciClear() {
		log('Clearing Code')
		this.ncciCode = null
	}

	formatRateInput(prop: string) {
		const value = this.jobForm.get(prop).value
		const newValue = NumberHelper.formatPayRate(value)
		const formattedValue = newValue?.toFixed(2) ?? null
		this.jobForm.get(prop).setValue(formattedValue)
	}

	formatDuration(input: string): string {
		if (!input) return null

		const intValue = parseInt(input, 10)
		if (!intValue) return null

		return moment.duration(intValue, 'minutes').toISOString() || null
	}

	makeBreakTimeWorkedForUpdateRecord(dur: string): string {
		let breakTimeWorkedDuration = moment.duration(dur)
		if (dur && !dur.includes(':')) {
			const intValue = parseInt(dur, 10)
			if (intValue > 16) {
				breakTimeWorkedDuration = moment.duration(intValue, 'minutes')
			} else {
				breakTimeWorkedDuration = moment.duration(intValue, 'hours')
			}
		}
		const breakTimeWorkedIsValid = breakTimeWorkedDuration.asMinutes() > 0
		return breakTimeWorkedIsValid ? breakTimeWorkedDuration.toISOString() : null
	}

	makeUpdateRecord(): any {
		const form = this.jobForm.getRawValue()
		const record = new JobRecord(form)

		//////////////////
		// Main Section //
		//////////////////

		const jobCode = this.jobForm.get('job_code').value
		record.job_code = jobCode ? parseInt(jobCode, 10) : null
		record.description = this.jobForm.get('description').value
		record.location_id = this.jobForm.get('location_id').value
		record.tags_json = this.jobForm.get('tags_json').value

		//////////////////////
		// Advanced Options //
		//////////////////////

		record.external_id = this.jobForm.get('external_id').value
		record.job_details = this.jobForm.get('job_details').value
		record.notification_profile_id = this.jobForm.get('notification_profile_id').value
		record.tour_id = this.jobForm.get('tour_id').value

		////////////////////////////
		// Dispatcher Checkpoints //
		////////////////////////////

		const outboundCallValue = this.jobForm.get('outbound_call_interval').value
		const outboundCallInterval = outboundCallValue ? parseInt(outboundCallValue, 10) : null
		record.outbound_call_interval = outboundCallInterval ? outboundCallInterval : null
		if (outboundCallInterval && outboundCallInterval < 30) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Value', 'Outbound Call Interval must be at least 30 minutes.')
			return null
		}

		////////////////////
		// Break Options //
		///////////////////

		const breakTimeField: any = this.jobForm.get('break_time').value
		const breakTimeString = breakTimeField || breakTimeField === 0 ? `${breakTimeField}` : ''
		const isBreakTimeValid = !breakTimeString || breakTimeString === '0' || parseInt(breakTimeString, 10)
		const breakTime = this.formatDuration(record.break_time)
		const breakTimeWorkedField = this.jobForm.get('break_time_worked').value
		const breakTimeWorked = this.makeBreakTimeWorkedForUpdateRecord(breakTimeWorkedField)

		record.break_time = breakTime
		record.break_time_worked = breakTimeWorked
		record.allow_breaktime = true

		if (!isBreakTimeValid) {
			alert(`Please enter a valid value for 'Break Length' or clear the field.`)
			return null
		}
		if (breakTimeWorkedField && !breakTimeWorked) {
			alert(`Please enter a valid value for 'Auto-apply Break' or clear the field.`)
			return null
		}
		if (breakTimeWorked && !breakTime) {
			alert(`You must explicitly set a valid 'Break Length' if you wish to auto apply break times to this job.`)
			return null
		}

		// Setup Pay Rate
		const empPayRateField = this.jobForm.get('employee_pay_rate').value
		const clientPayRateField = this.jobForm.get('client_pay_rate').value
		const vendorPayRateField = this.jobForm.get('vendor_pay_rate').value
		record.employee_pay_rate = parseFloat(empPayRateField)
		record.client_pay_rate = parseFloat(clientPayRateField)
		record.vendor_pay_rate = parseFloat(vendorPayRateField)

		// Setup Overage Time
		if (this.overageCalculationsEnabled) {
			const jobDuration = this.jobForm.get('overage_time').value
			const formattedDuration = jobDuration && !jobDuration.includes(':') ? jobDuration + ':00' : jobDuration
			const duration = moment.duration(formattedDuration)
			const isValidInput = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(formattedDuration)

			if (jobDuration && !isValidInput) {
				const msg = `Please enter a valid duration for 'Budgeted time for this job' in the 'Budgeted Time / Overage' section.`
				this.coreSrvc.notifySrvc.notify('error', 'Invalid Value', msg)
				return null
			}
			if (duration.asMinutes() >= 0 && !!jobDuration) {
				record.overage_time = duration.toISOString()
			} else {
				record.overage_time = null
			}

			// Flat rate can only be enabled when an overage time is set
			if (record.overage_time) {
				record.flat_rate = this.jobForm.get('flat_rate').value
			} else {
				record.flat_rate = false
			}
		}

		// Set NCCI Code
		if (this.ncciCode) {
			const ncciId = this.ncciCode['id']
			record.ncci_code = ncciId
		} else {
			record.ncci_code = null
		}

		// Setup jobsite_json
		if (this.isJobSiteMerged) {
			const siteRecord = new JobSiteRecord(this.jobSite)

			const locResult = this.locationManager.result
			const locationAddress = locResult.locationAddress

			siteRecord.address = null
			siteRecord.address_1 = locationAddress.address_1
			siteRecord.address_2 = locationAddress.address_2
			siteRecord.city = locationAddress.city
			siteRecord.district = locationAddress.district
			siteRecord.postal_code = locationAddress.postal_code

			siteRecord.client_id = this.jobForm.get('jsClientId').value
			siteRecord.description = this.jobForm.get('description').value
			siteRecord.geo_code_reverse = locResult.geoCodeReverse
			siteRecord.geo_latitude = locResult.latitude
			siteRecord.geo_longitude = locResult.longitude
			siteRecord.location_details = this.jobForm.get('jsLocationDetails').value
			siteRecord.phone_number_regex = null
			siteRecord.phone_number_regex_e164 = this.linkedNumberManager.phoneNumberString
			siteRecord.supervisor = this.jobForm.get('jsSupervisor').value
			siteRecord.timezone_id = this.jobForm.get('jsTimezoneId').value
			siteRecord.union_zone = this.jobForm.get('jsUnionZone').value
			siteRecord.supervisor_group_id = this.jobForm.get('jsSupervisorGroupId').value

			delete siteRecord.phone_number_regex
			const jobSiteJson = JSON.stringify(siteRecord)
			record['jobsite_json'] = jobSiteJson
			log('Embeded Site Record', siteRecord)
		}

		// Allow only linked numbers
		const linkedOnlyCheckbox = this.jobForm.get('linked_only').value
		if (this.isJobSiteMerged) {
			record.linked_only = this.linkedNumberManager.phoneNumberList.length > 0 ? linkedOnlyCheckbox : false
		} else {
			record.linked_only = this.showLinkOnlyCheckbox ? linkedOnlyCheckbox : false
		}

		return record
	}

	viewBudgetRules() {
		window.open(
			'https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/version.3.0/en/topic/adjusting-hours-based-on-budgets',
			'_blank',
		)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'clients':
				help.header = 'Client / Customer'
				help.message = `You may assign a client to this job for certain reporting and notification purposes.`
				break
			case 'clientPayRate':
				help.header = 'Client Bill Rate'
				help.message =
					'This is the default client bill rate for this job.  It can be overridden by entering a specific client bill rate in the Bill/Pay rate table.'
				break
			case 'details':
				help.header = 'Job Details'
				help.message = `Job Details are displayed in the Date/Details column of the time entries screen and you may optionally expose this information to employees in the employee web portal during check in.`
				break
			case 'flatRate':
				help.header = 'Adjust Hours'
				help.message =
					'When this option is set, the system will adjust the hours worked on any time entries for this job to match the hours budgeted, regardless of how long the employee actually works.'
				break
			case 'jobActive':
				help.header = 'Mark Active'
				help.message =
					'Active jobs are visible throughout the system. Inactive jobs are hidden from certain lists and dropdown menus when it is not necessary to show them.'
				break
			case 'jobCodes':
				help.header = 'Job Codes'
				help.message = `Enter a numeric code for this job which our system will prompt for during check in and check out.`
				break
			case 'employeePayRate':
				help.header = 'Employee Pay Rate'
				help.message =
					'This is the default employee pay rate for this job. It will override any base rate in the employee record and can be overridden by entering a specific employee pay rate in the Bill/Pay rate table.'
				break
			case 'employeeCount':
				help.header = 'Employee Count'
				help.message = `Enter the number of employees that perform this job when you are not scheduling specific individuals.`
				break
			case 'externalID':
				help.header = 'External ID'
				help.message = 'Optional field used to link this job with an external identifier such as a company job ID.'
				break
			case 'anytimeJob':
				help.header = 'Anytime Jobs'
				help.message = `By default, jobs are considered Anytime and do not have a fixed start or end time. This works best for most customers. If you need to specify a specific start time and end time, uncheck this box.`
				break
			case 'outbound_call_interval':
				help.header = 'Dispatcher Checkpoints'
				help.message = `Dispatcher checkpoint calls are a call center feature which automatically checks up on an employee during their shift by setting up a call between the employee and a signed-in call center agent.<br><br>Enter the desired number of minutes between each call. Exact call out times will be randomized with a 10% window so if you choose 60 minutes as your inteval, checkup calls will be made every 54-66 minutes. Leave this field blank to disable checkup calls.`
				break
			case 'breakTime':
				help.header = 'Break Length'
				help.message = `If this job has a set break length associated with it, you may set that value here.`
				break
			case 'breakTimeWorked':
				help.header = 'Auto-apply Break'
				help.message = `Specify how long an employee must work (in hours and minutes) in order for the the Break Length to be applied. For example, to add a fifteen minute break every four and a half hours worked, set the Break Length to 15 and Auto-apply Break to 4:30. Leave blank if you want the Break Length to apply regardless of time worked.`
				break
			case 'checkpoints':
				help.header = 'Checkpoints'
				help.message = `Checkpoints log GPS and time information during a shift and are available using the employe mobile web site. This feature is used for jobs which require employees to make rounds during their shift.`
				break
			case 'trackOverage':
				help.header = 'Time Overage'
				help.message = `When an employee is checked in longer than the time specified, that time will be tracked in a separate column in the Time Entries list. Leave this field blank if you do not wish to track time overage for this job.`
				break
			case 'adjustHoursRule':
				help.header = 'Adjustment Rule'
				help.message = `Do not use this option unless you understand how it will affect your employee's hours. Please visit the link below for a detailed description on each of the available budget rules.`
				break
			case 'ncci_code':
				help.header = 'NCCI Code'
				help.message = `Four-digit numerical codes that are used to classify employees' exposure to risk for workers' compensation insurance. You can search by code or text description.`
				break
			case 'notificationProfile':
				help.header = 'Notification Profile'
				help.message =
					'To enable notifications for this job, select a Notification Profile. Notification Profiles contain all of the specific notification settings for a job such as who should be notified and under what circumstances (late arrival, early check out, etc.). If you have not yet created a Notification Profile that meets the requirements for this Job, you can do so now using the Create button below.'
				break

			case 'qbo_customer':
				help.header = 'QBO Customer'
				help.message = `Set this option to associate any time worked on this job with a QuickBooks customer.\n\n Note: In order to make the time activity billable you must also set a client rate.`
				break
			case 'qbo_vendor':
				help.header = 'QBO Vendor'
				help.message = `If you use vendors in Telephone Timesheets who are not QuickBooks employees, then associating a vendor to any time worked for this job will generate time activity in QuickBooks.\n\nNote: In order to make this time activity billable to a customer you must also set a client rate on this job.`
				break
			case 'qbo_service_item':
				help.header = 'QBO Service Item'
				help.message = `Set this option to associate a QuickBooks service item when any time activity is associated with this job. This will be populated into invoice line items when billing customers`
				break

			case 'supervisorPhone':
				help.header = 'Supervisor Phone'
				help.message = `From the dropdown menu, please select the region for your supervisor and then enter their phone number.`
				break
			case 'multiDayJob':
				help.header = 'Multi-Day Job'
				help.message = `Only used for special circumstances. DO NOT enable this option unless you understand how it will affect scheduling. See the online manual for more information.`
				break
			case 'requirePhotoCheckin':
				help.header = 'Photo Check In'
				help.message = `When enabled, a photo will be required for check in and a check in checkpoint will be automatically created.`
				break
			case 'requirePhotoCheckpoints':
				help.header = 'Photo Checkpoints'
				help.message = `When enabled, a photo will be required to submit a shift checkpoint.`
				break
			case 'requirePhotoCheckout':
				help.header = 'Photo Check Out'
				help.message = `When enabled, an employee will be required to submit a check out checkpoint with photo before checking out.`
				break
			case 'requireQrcCheck':
				help.header = 'Require QR Code'
				help.message = `When enabled, a photo of the QR code posted at the job site will be required.`
				break
			case 'jsLocationDetails':
				help.header = 'Job Site Details'
				help.message = `Job Site Details are displayed in the Date/Details column of the time entries screen and you may optionally expose this information to employees in the employee web portal during check in.`
				break
			case 'jsSupervisor':
				help.header = 'Job Site Supervisor'
				help.message = `From the dropdown menu, please select the supervisor who is responsible for this job site.`
				break
			case 'mobileNumbers':
				help.header = 'Mobile Numbers'
				help.message = `For each employee who will call from their mobile phone at this job site, select them from the dropdown menu. If an employee does not appear in the dropdown you may need look for them in the 'Inactive' employee list and mark them as active.`
				break
			case 'linkLandlineCheckbox':
				help.header = 'Landline Numbers'
				help.message = `As an alternative to Job Codes or Schedule Entries, the system can use Caller ID to determine which job to assign when an employee calls into the system.`
				break
			case 'linked_only':
				help.header = 'Allow List'
				help.message = 'When checked, this job will only be available to phone numbers listed in the Linked Landline phone numbers list.'
				break
			case 'vendors':
				help.header = 'Vendors'
				help.message = `You may assign a vendor to this job for certain reporting and notification purposes.`
				break
			case 'vendorPayRate':
				help.header = 'Vendor Pay Rate'
				help.message =
					'This is the default vendor pay rate for this job.  It can be overridden by entering a specific vendor pay rate in the Bill/Pay rate table.'
				break
			case 'userGroup':
				help.header = 'Supervisor Group'
				help.message = 'Optionally assign a supervisor group which will allow members of this group to manage this job and view related records.'
				break
			case 'work_tax_location':
				help.header = 'Work Tax Location'
				help.message = `If you have business locations or remote workers in different states where you have a state tax ID, you can designate multiple work tax locations and assign them here.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class PinnedReport {
	incident: Incident
	showDeleteConfirm = false

	constructor(incident: Incident) {
		this.incident = incident
	}
}

class JobDetailProfileViewModel {
	ds: DomSanitizer
	empHtml: SafeHtml = ''
	supHtml: SafeHtml = ''

	constructor(ds: DomSanitizer) {
		this.ds = ds
		const profile = new NotificationProfile()
		this.updateViewModel(profile)
	}

	updateViewModel(profile: NotificationProfile) {
		const empHtml = JobTableFormatter.makeNotificationForEmployee(profile)
		this.empHtml = this.ds.bypassSecurityTrustHtml(empHtml)

		const supHtml = JobTableFormatter.makeNotificationForSupervisor(profile)
		this.supHtml = this.ds.bypassSecurityTrustHtml(supHtml)
	}
}
