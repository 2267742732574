import { DateTimeHelper, log } from '@app/helpers'
import { ApprovalState } from 'aws-sdk/clients/codecommit'

import moment from 'moment-timezone'

export type OnboardDefinitionType = 'FILE' | 'OTHER'

export class OnboardUpdateVieFilterOptions {
	iterate: 'EMP_NAME' | 'EMP_LAST_UPDATE' = 'EMP_LAST_UPDATE'
	order: 'asc' | 'desc' = 'asc'
}

export class OnboardUpdateViewManager {
	localStorageKey = 'OnboardUpdateViewManager'
	version = 1

	filterOptions = new OnboardUpdateVieFilterOptions()

	constructor() {
		this.restore()
	}

	private restore() {
		try {
			const record = JSON.parse(localStorage.getItem(this.localStorageKey))
			if (record) {
				for (const attr in record) {
					if (record.hasOwnProperty(attr)) {
						this[attr] = record[attr]
					}
				}
			}
		} catch (err) {
			log(`Error restoring ${this.localStorageKey}`, err)
		}
	}

	public save() {
		const saveData = {
			version: this.version,
			localStorageKey: this.localStorageKey,
			filterOptions: this.filterOptions,
		}
		localStorage.setItem(this.localStorageKey, JSON.stringify(saveData))
	}
}

export class OnboardDefinitionRecord {
	id: number
	company_id: number
	created: string // Date
	updated: string // Date

	enabled: boolean = true
	name: string = 'Deprecated Field' // Required
	description: string = null // Admin notes about this requirement
	emp_instructions: string = null // Instructions for the employee
	expires: boolean = null // Whether or not the requirement expires
	type: OnboardDefinitionType = 'FILE'
	file_count: number = null
	requires_approval: boolean = false

	constructor(record?: OnboardDefinitionRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	get requestType(): string {
		switch (this.type) {
			case 'FILE':
				return 'Document Request'
			default:
				return 'Unknown Request'
		}
	}
}

export class OnboardLogRecord {
	id: number
	company_id: number
	onboard_definition_id: number
	employee_id: number
	created: string // Date
	updated: string // Date
	employee_last_update: string // ISO Date

	enabled: boolean // Joined Property
	expires: boolean // Joined Property
	file_count: number // Joined Property
	type: OnboardDefinitionType // Joined Property
	description: string // Joined Property

	expiration: string // ISO Date
	approval_state: ApprovalState
	approval_state_last_updated: string // ISO Date
	approval_state_last_user_id: number

	employee_notes: string
	admin_notes: string

	skipped = false

	constructor(record?: OnboardLogRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.expiration = DateTimeHelper.stripUtcTag(this.expiration)
			this.approval_state_last_updated = DateTimeHelper.stripUtcTag(this.approval_state_last_updated)
			this.employee_last_update = DateTimeHelper.stripUtcTag(this.employee_last_update)
		}
	}
}
