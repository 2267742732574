import { CheckInOutType, TimeEntryCallerIdMatcher, TransactionLogRecord, TransactionMetaData, TransCheckInOutSource } from '@app/models/transaction'
import { DateTimeHelper } from '@app/helpers/datetime'
import moment from 'moment-timezone'
import { DatabaseService } from '@app/services/backend/database.service'
import { Helper, log } from '@app/helpers'
import { DistanceHelper } from '@app/models/distance'

export class GPSResponseCalc {
	private trans: TransactionLogRecord
	private cidMatcher: TimeEntryCallerIdMatcher

	private delayTimeout: number
	private startDelay: number
	private endDelay: number
	private startDistance: number // In miles
	private endDistance: number // In Miles
	private startTimestamp: string
	private endTimestamp: string
	private hasGpsStartInfo = false
	private hasGpsEndInfo = false
	private showTooltips = false
	private isTravelJob = false
	private isStartWebApi = false
	private isEndWebApi = false
	private inMetaData: TransactionMetaData
	private outMetaData: TransactionMetaData
	public gpsTags = ''

	/*** THIS CLASS IS USED BY REPORTS AND MUST BE SELF CONTAINED  ***/
	constructor(trans: TransactionLogRecord, allowedDelay: string, cidMatcher: TimeEntryCallerIdMatcher, showTooltips: boolean) {
		const delay = allowedDelay
		this.trans = trans
		this.cidMatcher = cidMatcher
		this.delayTimeout = moment.duration(delay).asSeconds()
		this.showTooltips = showTooltips
		this.isTravelJob = trans.travel_job

		const inMetaData: TransactionMetaData = trans['inMetaData'] || new TransactionMetaData('IN', trans)
		this.inMetaData = inMetaData
		const outMetaData: TransactionMetaData = trans['outMetaData'] || new TransactionMetaData('OUT', trans)
		this.outMetaData = outMetaData

		this.isStartWebApi = inMetaData.webAPI
		this.isEndWebApi = outMetaData.webAPI

		this.startDelay = inMetaData.gpsResponseTime
		this.endDelay = outMetaData.gpsResponseTime

		this.startTimestamp = trans.geo_start_ts
		this.endTimestamp = trans.geo_end_ts

		this.startDistance = trans.geo_start_distance
		this.endDistance = trans.geo_end_distance

		if (trans.geo_start_longitude && trans.geo_start_latitude) {
			this.hasGpsStartInfo = true
		}
		if (trans.geo_end_longitude && trans.geo_end_latitude) {
			this.hasGpsEndInfo = true
		}
		this.setupGpsTags(trans)
	}

	getDistanceString(inOut: 'IN' | 'OUT'): string {
		let distInMiles
		if (inOut === 'IN') {
			distInMiles = this.startDistance ? this.startDistance.toFixed(2) : null
		} else {
			distInMiles = this.endDistance ? this.endDistance.toFixed(2) : null
		}
		if (!distInMiles && distInMiles !== 0) {
			return ''
		}
		DistanceHelper.converter.setValue(distInMiles ?? 0, 'MI')
		return DistanceHelper.converter.getSmartDistanceString()
	}

	getDistanceStyleClass(inOut: 'IN' | 'OUT', cidMatches: boolean, showTime: boolean): string {
		const distance = inOut === 'IN' ? this.startDistance : this.endDistance
		const delay = inOut === 'IN' ? this.startDelay : this.endDelay
		const metaData: TransactionMetaData = inOut === 'IN' ? this.trans['inMetaData'] : this.trans['outMetaData']
		const checkInOutSource = inOut === 'IN' ? this.trans.check_in_source : this.trans.check_out_source

		const hasGps = this.hasGpsInfo(inOut)
		const isTravel = this.isTravelJob
		const isStationOrKiosk = checkInOutSource === 'STATION' || checkInOutSource === 'KIOSK'
		const isLandline = checkInOutSource === 'LANDLINE'
		const hasCidMatch = cidMatches && isLandline

		const hasCheckInOut = inOut === 'IN' ? !!this.trans.actual_start : !!this.trans.actual_end
		const hasGpsError = hasCheckInOut && !hasGps && metaData.gpsClientError

		let highlightClass = 'hl-gps-gray'

		// If it's a station or kios and user blocked GPS then mark as orange, otherwise consider valid
		if (isStationOrKiosk) {
			if (metaData.gpsShortError === 'GPS Blocked') {
				return 'hl-invalid'
			}
			if (metaData.gpsShortError === 'GPS Timeout') {
				return 'hl-valid'
			}
		}

		// If we have GPS location then set it green
		if (hasGps || hasCidMatch) {
			highlightClass = 'hl-valid'
		}

		// When dealing with landlines and it's an unknown landline then mark it orange
		if (isLandline && !cidMatches) {
			highlightClass = 'hl-gps-orange'
		}

		// If distance is greater than 1 mile mark it red for out of range
		if (distance && distance > 1) {
			highlightClass = 'hl-invalid'
		}

		// If we aren't splitting icon display into 2 icons
		if (!showTime) {
			// If there's a delay and the delay is greater than the out of range threshold, mark it red
			if (delay && delay > this.delayTimeout) {
				highlightClass = 'hl-invalid'
			}
		}

		// When GPS is blocked or times out, turn it orange regardless of other issues
		if (hasGpsError) {
			highlightClass = 'hl-gps-orange'
		}

		// Travel is always valid so mark it green
		if (isTravel) {
			if (hasGps) {
				highlightClass = 'hl-valid'
			}
		}

		return highlightClass
	}

	getTimeString(inOut: 'IN' | 'OUT'): string {
		const delay = inOut === 'IN' ? this.startDelay : this.endDelay
		if (!delay || delay < 0) {
			return ''
		}
		const responsInMin = Math.ceil(delay / 60)
		return responsInMin + ' min'
	}

	getTimeStyleClass(inOut: 'IN' | 'OUT') {
		const delay = inOut === 'IN' ? this.startDelay : this.endDelay
		const hasGps = this.hasGpsInfo(inOut)
		if (!hasGps) {
			return 'hl-gps-gray'
		}
		if (!delay || delay < 0) {
			return 'hl-gps-gray'
		}
		if (delay < this.delayTimeout) {
			return 'hl-valid'
		} else {
			return 'hl-invalid'
		}
	}

	getTitle(inOut: 'IN' | 'OUT', showTime: boolean, format: 'DIST' | 'TIME' | 'BOTH') {
		const metaData: TransactionMetaData = inOut === 'IN' ? this.trans['inMetaData'] : this.trans['outMetaData']
		const callerId = metaData?.callerIDNumber
		const cidMatchesSite = this.cidMatcher?.siteNumbers.has(callerId) ?? false
		const checkInOutSource = inOut === 'IN' ? this.trans.check_in_source : this.trans.check_out_source

		const isLandline = checkInOutSource === 'LANDLINE'
		const isKnownLandline = isLandline && cidMatchesSite

		const hasGps = this.hasGpsInfo(inOut)
		const distance = this.getDistanceString(inOut)
		const time = this.getTimeString(inOut)
		const gpsErrorTitle = metaData?.gpsShortError
		const hasCheckInOut = inOut === 'IN' ? !!this.trans.actual_start : !!this.trans.actual_end

		const distTitle = gpsErrorTitle ? gpsErrorTitle : distance ? distance : hasGps ? 'On Site' : 'No GPS'
		const timeTitle = time ? time : `No Time`

		let result = ''

		switch (checkInOutSource) {
			case 'LANDLINE':
				result = isKnownLandline ? 'Known Landline' : 'Unknown Landline'
				result += distance ? ` / ${distTitle}` : ''
				break
			case 'MOBILE':
				result = `Mobile / ${distTitle}`
				break
			case 'MOBILESTATION':
				result = `Mobile Station / ${distTitle}`
				break
			case 'KIOSK':
				result = `Kiosk / ${distTitle}`
				break
			case 'STATION':
				result = `Station / ${distTitle}`
				break
			case 'WEB':
				result = `Emp App / ${distTitle}`
				break
			case 'ADMIN':
				result = 'Admin'
				break
			default:
				result = 'No Source Info'
				break
		}

		return showTime ? `${result} / ${timeTitle}` : result
	}

	// When splitting time, 2 separate icons are used and so no time calculations factor into styling map pin
	getDistanceHtml(inOut: 'IN' | 'OUT', showTime: boolean, iconOnly: boolean, iconClass: string): string {
		const metaData: TransactionMetaData = inOut === 'IN' ? this.trans['inMetaData'] : this.trans['outMetaData']
		const callerId = metaData?.callerIDNumber
		const cidMatchesSite = this.cidMatcher?.siteNumbers.has(callerId) ?? false

		const checkInOutSource = inOut === 'IN' ? this.trans.check_in_source : this.trans.check_out_source
		const hasCheckInOut = inOut === 'IN' ? !!this.trans.actual_start : !!this.trans.actual_end

		const isLandline = checkInOutSource === 'LANDLINE' && hasCheckInOut
		const isMobile = checkInOutSource === 'MOBILE' && hasCheckInOut
		const isEmpApp = checkInOutSource === 'WEB' && hasCheckInOut
		const isStation = checkInOutSource === 'STATION' && hasCheckInOut
		const isKiosk = checkInOutSource === 'KIOSK' && hasCheckInOut
		const isMobileStation = checkInOutSource === 'MOBILESTATION' && hasCheckInOut
		const isAdmin = checkInOutSource === 'ADMIN' && hasCheckInOut

		const distance = this.getDistanceString(inOut)
		const distString = iconOnly ? '' : distance
		const iconPad = distString ? 'gps-icon-pad' : ''

		const displayIconClass = isLandline
			? 'far fa-phone'
			: isMobile
				? 'far fa-mobile'
				: isStation || isKiosk || isMobileStation
					? 'fal fa-computer-classic'
					: isEmpApp
						? 'far fa-id-badge'
						: isAdmin
							? 'far fa-user'
							: 'fas fa-ban'

		const tableIconClass = iconOnly ? 'gps-icon' : ''
		const distIcon = `<i class="${displayIconClass} ${tableIconClass} ${iconClass} ${iconPad}" aria-hidden="true"></i>`
		const distStyleClass = this.getDistanceStyleClass(inOut, cidMatchesSite, showTime)

		const distTitle = showTime ? this.getTitle(inOut, showTime, 'DIST') : this.getTitle(inOut, showTime, 'BOTH')

		const showTooltips = this.showTooltips
		const hasTooltip = distString || !showTooltips ? '' : 'gps-icon-no-margin item-tooltip'
		return `<span title="${distTitle}" class="gps-icon-box ${distStyleClass} ${hasTooltip}">${distIcon}${distString}</span>`
	}

	getTimeHtml(inOut: 'IN' | 'OUT', showTIme: boolean, iconOnly: boolean, iconClass: string): string {
		const time = this.getTimeString(inOut)
		const hasGps = this.hasGpsInfo(inOut)
		const timeString = iconOnly ? '' : time
		const iconPad = timeString ? 'gps-icon-pad' : ''
		const tableIconClass = iconOnly ? 'gps-icon' : ''
		const timeIcon = `<i class="fa fa-clock-o ${tableIconClass} ${iconClass} ${iconPad}" aria-hidden="true"></i>`
		const timeStyleClass = this.getTimeStyleClass(inOut)

		const timeTitle = this.getTitle(inOut, showTIme, 'TIME')

		const showTooltips = this.showTooltips
		const hasTooltip = timeString || !showTooltips ? '' : 'gps-icon-no-margin item-tooltip'
		return `<span title="${timeTitle}" class="gps-icon-box ${timeStyleClass} ${hasTooltip}">${timeIcon}${timeString}</span>`
	}

	getImagesHtml(inOut: 'IN' | 'OUT', isDesktop: boolean) {
		const hasImages = this.trans.hasImages(inOut)
		const hasError = inOut === 'IN' ? this.trans.imgErrCheckin : this.trans.imgErrCheckout
		const inOutType = inOut === 'IN' ? 'Check-In' : 'Check-Out'
		const title = hasImages ? `${inOutType} Images Submitted` : `No ${inOutType} Images Submitted`
		const iconClass = hasImages && hasError ? 'hl-gps-red' : hasImages ? 'hl-gps-green' : 'hl-gps-gray'
		const imagesTooltip = isDesktop ? 'item-tooltip' : ''
		const faIcon = `<i class="fa fa-image gps-icon gps-icon-image ${imagesTooltip} ${iconClass}" title="${title}"></i>`
		return `<span class="gps-icon-box">${faIcon}</span>`
	}

	getQRCStatus(inOut: 'IN' | 'OUT', isDesktop: boolean, requireQRC: boolean) {
		const metaData: TransactionMetaData = inOut === 'IN' ? this.trans['inMetaData'] : this.trans['outMetaData']
		const qrcValid = metaData.qrcValid
		const inOutType = inOut === 'IN' ? 'Check-In' : 'Check-Out'
		const title = requireQRC && qrcValid ? `QR ${inOutType} Valid` : requireQRC && qrcValid === false ? `QR ${inOutType} Invalid` : 'No QR Check'
		const iconClass = requireQRC && qrcValid ? 'hl-gps-green' : requireQRC && qrcValid === false ? 'hl-gps-red' : 'hl-gps-gray'
		const imagesTooltip = isDesktop ? 'item-tooltip' : ''
		const faIcon = `<i class="fa fa-qrcode gps-icon gps-icon-qrcode ${imagesTooltip} ${iconClass}" title="${title}"></i>`
		return `<span class="gps-icon-box">${faIcon}</span>`
	}

	getTimestamp(inOut: 'IN' | 'OUT'): string {
		if (inOut === 'IN') {
			return this.startTimestamp
		}
		return this.endTimestamp
	}

	hasGpsInfo(inOut: 'IN' | 'OUT') {
		if (inOut === 'IN') {
			return this.hasGpsStartInfo
		} else {
			return this.hasGpsEndInfo
		}
	}

	setupGpsTags(trans: TransactionLogRecord) {
		const isLandlineOrAdminCheckIn = trans.check_in_source === 'LANDLINE' || trans.check_in_source === 'ADMIN'
		const isLandlineOrAdminCheckOut = trans.check_out_source === 'LANDLINE' || trans.check_out_source === 'ADMIN'

		const tags = []
		if (trans.actual_start && !isLandlineOrAdminCheckIn) {
			if (!this.startDelay) {
				tags.push('#gps:none:in')
			}
			if (this.startDelay > this.delayTimeout) {
				tags.push('#gps:time:in')
			}
			if (this.startDistance > 1.0) {
				tags.push('#gps:dist:in')
			}
		}
		if (trans.actual_end && !isLandlineOrAdminCheckOut) {
			if (!this.endDelay) {
				tags.push('#gps:none:out')
			}
			if (this.endDelay > this.delayTimeout) {
				tags.push('#gps:time:out')
			}
			if (this.endDistance > 1.0) {
				tags.push('#gps:dist:out')
			}
		}
		const tagString = tags.join(' ')
		if (tags.length > 0) {
			this.gpsTags = `<div style="display:none;">${tagString}</div>`
		}
	}
}

export class TransMetaGeoInfo {
	address: string
	distance: string
	time: string

	hasDistance = false
	hasTime = false

	type: TransCheckInOutSource

	get hasData(): boolean {
		return this.hasDistance || this.hasTime
	}

	constructor(inOut: 'IN' | 'OUT', trans: TransactionLogRecord, delay: string) {
		if (!trans) {
			return
		}

		const checkInOutSource = inOut === 'IN' ? trans.check_in_source : trans.check_out_source
		this.type = checkInOutSource

		this.hasDistance = inOut === 'IN' ? !!trans.geo_start_distance : !!trans.geo_end_distance
		this.hasTime = inOut === 'IN' ? !!trans.geo_start_ts : !!trans.geo_end_ts

		const gpsResponse = new GPSResponseCalc(trans, delay, null, false)
		const isTravelJob = trans?.travel_job ?? false

		let address = null
		let isWebApi = false

		if (inOut == 'IN') {
			address = Helper.getStreetInfoFromAddress(trans.geo_start_address)
			const metaData = trans.bulidMetaData('IN')
			isWebApi = metaData.webAPI
		} else {
			address = Helper.getStreetInfoFromAddress(trans.geo_end_address)
			const metaData = trans.bulidMetaData('OUT')
			isWebApi = metaData.webAPI
		}

		const timeHtml = isWebApi
			? `<span class="gps-icon-box hl-valid"> <i class="fa fa-clock-o" aria-hidden="true"></i> 1 min</span>`
			: gpsResponse.getTimeHtml(inOut, true, false, '')

		const distHtml = gpsResponse.getDistanceHtml(inOut, true, false, '')

		this.address = address
		this.distance = distHtml
		this.time = timeHtml
	}
}
