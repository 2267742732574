import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import {
	DialogManager,
	OnboardLogRecord,
	DatabaseTableName,
	EditFormAction,
	EditCompleteEvent,
	HelpDialogMessage,
	SecureFileRecord,
	SecureFileDialogData,
} from '@app/models'
import { CoreService } from '@app/services'

import moment from 'moment-timezone'

@Component({
	selector: 'app-onboard-log-edit',
	templateUrl: './onboard-log-edit.component.html',
	styleUrls: ['./onboard-log-edit.component.scss'],
	standalone: false,
})
export class OnboardLogEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'onboard_log'
	isUpdating = false
	isManagingFiles = false
	form: UntypedFormGroup

	managedByName = null
	lastManagedDate = null

	empInstructions = ''

	highlightIds: Array<number> = []

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: OnboardLogRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<EditCompleteEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	get expirationRequired(): boolean {
		return this.form.get('approval_state').value === 'APPROVED' && this.record.expires && !this.form.get('expiration').value
	}

	public ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
	}

	public ngAfterViewInit(): void {
		setTimeout(() => {
			this.setupDialogManager()
		}, 150)
	}

	public ngAfterContentInit(): void {}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.onboardSrvc.getOnboardLogRecordById(this.recordId)
		const definition = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecordById(record.onboard_definition_id)
		this.empInstructions = definition?.emp_instructions ?? 'Employee Instructions'
		this.record = new OnboardLogRecord(record)

		if (this.action === 'clone') {
			this.record.id = null
		}

		// Setup the managed by name and date
		const userId = record.approval_state_last_user_id
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
		if (user) this.managedByName = user.first_name + ' ' + user.last_name
		this.lastManagedDate = record.approval_state_last_updated
	}

	public setupForm() {
		const record = this.record
		this.form = this.fb.group({
			id: [record?.id],
			approval_state: [record?.approval_state ?? 'PENDING'],
			expiration: [record?.expiration ? moment(record.expiration).startOf('day').toISOString() : null],
			employee_notes: [record?.employee_notes ?? ''],
			admin_notes: [record?.admin_notes ?? ''],
		})
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.form)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)

		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						setTimeout(() => {
							this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						}, 1000)
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						setTimeout(() => {
							this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						}, 1000)
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): OnboardLogRecord {
		const record = new OnboardLogRecord(this.record)

		record.approval_state = this.form.get('approval_state').value
		record.admin_notes = this.form.get('admin_notes').value

		// If definition requires expiration on approval
		const expiration = this.form.get('expiration').value
		record.expiration = expiration ? moment(expiration).format('YYYY-MM-DD') : null
		if (record.expires && record.approval_state === 'APPROVED') {
			if (!record.expiration) {
				this.coreSrvc.notifySrvc.notify('error', 'Expiration Required', 'An expiration date for this request is required.', 4)
				return null
			}
		}

		// If expiration is set, it must be in the future
		const isExpirationInFuture = moment(record.expiration).isAfter(moment(), 'day')
		if (record.expiration && !isExpirationInFuture) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Expiration Required',
				'The expiration date for this request cannot be set to today or in the past.',
				4,
			)
			return null
		}

		// Update any values changed by the form here

		return record
	}

	public startManagingFiles() {
		const empId = this.record?.employee_id
		this.dialogManager.pushState()
		this.dialogManager.headerLabel = 'Manage Files'
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.cancelBtnLabel = 'Done'
		this.dialogManager.cancelBtnAction = () => this.endManagingFiles()
		this.dialogManager.dialogData = new SecureFileDialogData('EMPLOYEE', empId, null)
		this.isManagingFiles = true
	}

	public endManagingFiles() {
		this.dialogManager.popStateAndApply()
		this.isManagingFiles = false
	}

	public toggleCheckbox(prop: string) {
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	private scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'expiration':
				help.header = 'Expiration'
				help.message =
					'Set an expiration date here to have the system automatically prompt the employee to provide the same information when the current request expires.<br><br>For example, you can use the expiration date to request updated copies of certification documents based on when their current certificate expires.'
				break
			case 'employee_notes':
				help.header = 'Employee Notes'
				help.message = 'These are the notes the employee submitted and cannot be edited.'
				break
			case 'admin_notes':
				help.header = 'Admin Notes'
				help.message = 'These are the notes for admins to use to track additional information about this onboard request and employee response.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class AttachedFileWrapper {
	constructor(record: SecureFileRecord) {}
}
