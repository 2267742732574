import { AfterContentInit, Component, Input } from '@angular/core'
import {
	AdpIvrProfileRecord,
	AdpIvrPromptCodeRecord,
	CardViewAction,
	DatabaseTableName,
	DialogAuditAction,
	DialogManager,
	EditCompleteEvent,
	EditFormAction,
	Global,
} from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'

@Component({
	selector: 'app-adp-ivr-prompt-code-list',
	standalone: false,

	templateUrl: './adp-ivr-prompt-code-list.component.html',
	styleUrl: './adp-ivr-prompt-code-list.component.scss',
})
export class AdpIvrPromptCodeListComponent implements AfterContentInit {
	resource: DatabaseTableName = 'adp_ivr_prompt_code'
	searchText = ''

	isLoading = true
	editAction = { recordId: null, action: 'edit' as EditFormAction, isEditing: false }
	auditAction: DialogAuditAction = { resource: 'adp_ivr_profile', recordId: null, isAuditing: false }

	list: Array<PromptCodeRecordWrapper> = []

	@Input() dialogManager = new DialogManager()

	constructor(private coreSrvc: CoreService) {
		// this.loadData() // Used for testing
		this.fetchAndReload()
	}

	get searchResults(): Array<PromptCodeRecordWrapper> {
		return this.list.filter((r) => r.matchesSearch)
	}

	ngAfterContentInit(): void {
		this.dialogManager.submitBtnAction = () => this.newRecord()
	}

	private loadData(): void {
		this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrPromptCodes().map((record) => new PromptCodeRecordWrapper(record))
		if (this.searchText) this.performSearch(this.searchText)
		this.isLoading = false
	}

	private fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			this.coreSrvc.dbSrvc.readTable('adp_ivr_profile').then(() => {
				this.coreSrvc.dbSrvc.readTable(this.resource).then(() => {
					this.loadData()
					resolve(true)
				})
			})
		})
	}

	private resetDialog(): void {
		this.editAction = { recordId: null, action: 'edit', isEditing: false }
		this.auditAction = { resource: this.resource, recordId: null, isAuditing: false }

		this.dialogManager.popStateAndApply()

		setTimeout(() => {
			this.dialogManager.restoreScrollPosition('main')
		})
	}

	public actionIconClicked(event: Event, action: CardViewAction, vm: PromptCodeRecordWrapper) {
		event.stopPropagation()
		switch (action) {
			case 'EDIT':
				this.prepareDialogForEdit(vm, 'edit', 'Edit Record')
				break
			case 'CLONE':
				this.prepareDialogForEdit(vm, 'clone', 'Clone Record')
				break
			case 'DELETE':
				this.list.forEach((r) => (r.isConfirmingDelete = false))
				vm.isConfirmingDelete = true
				break
			case 'AUDIT':
				this.prepareForRecordAudit(vm)
				break
			case 'EXPAND':
				vm.isExpanded = !vm.isExpanded
				break
		}
	}

	private prepareDialogForEdit(vm: PromptCodeRecordWrapper, action: EditFormAction, header: string): void {
		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.headerLabel = header
		this.editAction.recordId = vm.record.id
		this.editAction.action = action
		this.editAction.isEditing = true
	}

	private prepareForRecordAudit(vm: PromptCodeRecordWrapper): void {
		this.auditAction.recordId = vm.record.id
		this.auditAction.resource = this.resource

		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnLabel = 'Done'

		this.auditAction.isAuditing = true
	}

	public newRecord() {
		const vm = new PromptCodeRecordWrapper(new AdpIvrPromptCodeRecord())
		this.prepareDialogForEdit(vm, 'new', 'New Record')
	}

	public recordUpdated(event: EditCompleteEvent) {
		const action = event.action

		if (action === 'new' || action === 'clone') {
			this.fetchAndReload().then(() => {
				this.resetDialog()
			})
		}

		if (action === 'edit') {
			const recordId = event.recordId
			const record = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrPromptCodeForId(recordId)
			const vm = this.list.find((r) => r.record.id === record.id)
			vm.record = record
			vm.updateModel()
			this.resetDialog()
		}
	}

	public confirmDelete(vm: PromptCodeRecordWrapper): void {
		vm.isDeleting = true
		this.coreSrvc.dbSrvc.deleteRecord(this.resource, vm.record.id).then((success) => {
			if (success) {
				vm.isConfirmingDelete = false
				this.fetchAndReload().then(() => {
					vm.isConfirmingDelete = false
				})
			}
		})
	}

	///////////////////////
	// Search Management //
	///////////////////////

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchText = lcText
		this.list.forEach((vm) => {
			vm.peformSearch(lcText)
		})
	}

	///////////////////
	// Miscellaneous //
	///////////////////

	public toggleExpansion(isExpanded: boolean) {
		this.list.forEach((vm) => (vm.isExpanded = isExpanded))
	}
}

class PromptCodeRecordWrapper {
	record: AdpIvrPromptCodeRecord
	assignedProfile: AdpIvrProfileRecord

	// State properties
	isExpanded = false
	isConfirmingDelete = false
	isDeleting = false

	// Display properties
	description = ''
	profileName = ''
	codeValue = ''
	externalId = ''

	// Search properties
	searchText = ''
	matchesSearch = true
	searchTextDescription = ''
	searchTextProfileName = ''
	searchTextExtendedInfo = ''

	constructor(record: AdpIvrPromptCodeRecord) {
		this.record = record
		this.updateModel()
	}

	public updateModel() {
		const record = this.record
		this.assignedProfile = Global.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfileForId(record.ivr_profile) ?? null

		const profileName = this.assignedProfile?.description ?? ''
		const description = record.description ?? 'No Description Set'

		// Setup model properties
		this.profileName = profileName
		this.description = description

		this.codeValue = record.code_value ?? ''
		this.externalId = record.external_id ?? ''

		// Setup search text properties
		this.searchTextProfileName = `${this.profileName}`.toLowerCase()
		this.searchTextDescription = this.description.toLowerCase()
		this.searchTextExtendedInfo = `${this.codeValue}***${this.externalId}`.toLowerCase()
	}

	public peformSearch(searchText: string) {
		this.searchText = searchText
		if (!searchText) {
			this.matchesSearch = true
			return
		}
		this.matchesSearch = false

		const profileNameMatches = this.searchTextProfileName.includes(searchText)
		const descriptionMatches = this.searchTextDescription.includes(searchText)
		const extendedInfoMatches = this.searchTextExtendedInfo.includes(searchText)

		if (profileNameMatches || descriptionMatches) this.matchesSearch = true
		if (extendedInfoMatches) {
			this.matchesSearch = true
			this.isExpanded = true
		}
	}
}
