import { AuditTableNameMapper, DatabaseTableName } from '@app/models/table'
import { DateTimeHelper } from '@app/helpers/datetime'
import { DatabaseService } from '@app/services/backend/database.service'
import { ScheduleOptions } from './schedule'
import { DisplayHelper, GeneralTableHelper, log } from '@app/helpers'

import _ from 'lodash'
import moment from 'moment-timezone'
import { Global } from './global'
import { ComponentBridgeName } from './bridge-table'
import { ImageFile } from './image'

export type AuditLogOperation = 'delete' | 'update' | 'insert'
export type AuditLogDiffSlot = 'PREVIOUS' | 'CURRENT'

// AUTHLINKACCESS - Tracks access to employe app auth links
// AUDIOACCESS - Tracks access to call log audio playing or downloading
// SECUREFILEACCESS - Tracks view/download access to secure files - payload { action: 'VIEW' | 'DOWNLOAD' }

export type AuditLogTrackType = 'CALLCENTER' | 'AUTHLINKACCESS' | 'AUDIOACCESS' | 'SECUREFILEACCESS' | 'STATIONLINKACCESS'

export class AuditLogTrackEvent {
	type: AuditLogTrackType
	payload: any

	constructor(type: AuditLogTrackType, payload: Object) {
		this.type = type
		this.payload = payload ?? {}
	}
}

export interface AuditLogRequestOptions {
	resource: DatabaseTableName
	resource_id: number
	type?: AuditLogTrackType
}

export class AuditLogFormatterData {
	dbSrvc: DatabaseService
	slot: AuditLogDiffSlot
	previous: any
	current: any
	key: string
	value: any
	index: number
	fullView = false

	constructor(
		dbSrvc: DatabaseService,
		slot: AuditLogDiffSlot,
		previous: any,
		current: any,
		key: string,
		value: any,
		index: number,
		fullView: boolean,
	) {
		this.dbSrvc = dbSrvc
		this.slot = slot
		this.previous = previous ?? null
		this.current = current ?? null
		this.key = key
		this.value = value ?? null
		this.index = index
		this.fullView = fullView
	}
}

export class AuditLogResourceRequest {
	resource: DatabaseTableName
	resourceId: number
	constructor(resource: DatabaseTableName, resourceId: number) {
		this.resource = resource
		this.resourceId = resourceId
	}
}

export class AuditLog {
	company_id: number
	created: string
	data: string
	id: number
	operation: string
	resource: DatabaseTableName
	user_id: number
	user_name: string
	resource_id: number
	count: number
	count_index: number
	jobsite_name: string
	job_name: string
	employee_name: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}

	static difference(object: Object, base: Object) {
		function changes(chObject, chBase) {
			return _.transform(chObject, (result, value, key) => {
				if (!_.isEqual(value, chBase[key])) {
					result[key] = _.isObject(value) && _.isObject(chBase[key]) ? changes(value, chBase[key]) : value
				}
			})
		}
		return changes(object, base)
	}
}

// AuditPropertyFormatter methods require two parameters (data: any, value: any)
// data will contain the current resource record and value is the specific value
// of the field in question. If data from outside the record is required it
// cannot be setup as an AuditPropertyFormatter and will need a custom property
// formatter
//
// previous : previous property data
// current : current property data

export class AuditPropertyFormatter {
	static auditDiffDialogBridge: ComponentBridgeName = 'ngBridgeAuditDialogDiff'
	static authLinkRegex = /token=[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/gi

	static durationFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}

		const duration = moment.duration(value)
		return DateTimeHelper.formatDuration('HH:mm', duration) + ' (hr:min)'
	}

	static dateTimeFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}

		const current = data.current
		if (current && value) {
			const tz = current.timezone
			if (tz) {
				const mom = moment.tz(value, tz)
				if (mom.isValid()) {
					const is12Hours = DateTimeHelper.format12Hour
					return is12Hours ? mom.format('ddd MMM Do h:mm a z') : mom.format('ddd MMM Do HH:mm z')
				}
			}
		}
		return value
	}

	static emailListFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}
		return value.split(',').join('<br>')
	}

	static tokenMaskFormatter(data: AuditLogFormatterData) {
		let message = data.value || ''
		const isInternalUser = Global.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		if (isInternalUser) {
			return message
		} else {
			if (message.includes('auth?') && AuditPropertyFormatter.authLinkRegex.test(message)) {
				message = message.replace(AuditPropertyFormatter.authLinkRegex, 'token=[protected]')
			}
			return message
		}
	}

	static trackEventName(data: AuditLogFormatterData) {
		const value = data.value
		return AuditTableNameMapper.trackEventInfoAlertHtml(value)
	}

	static trackEventPayload(data: AuditLogFormatterData) {
		const value = data.current?.payload ?? {}
		const parsed = JSON.parse(value)
		let output = '<code><pre>'
		output += JSON.stringify(parsed, null, 2)
		output += '</pre></code>'

		return output
		// return AuditPropertyFormatter.jsonPropFormatter(value)
	}

	static reportJsonFormatter(data: AuditLogFormatterData) {
		const bridgeName = AuditPropertyFormatter.auditDiffDialogBridge
		const index = data.index
		const viewClass = `class="badge badge-secondary tag-btn"`
		const viewClick = `onclick="${bridgeName}.viewIncidentReport(${index})"`
		const viewLink = `<div ${viewClass} ${viewClick}>View Report</div>`
		return viewLink
	}

	static viewLink(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}
		return `<a href="${value}" target="_blank">View Link</a>`
	}

	static viewOrDownloadWorkorderTemplate(data: AuditLogFormatterData) {
		const bridgeName = AuditPropertyFormatter.auditDiffDialogBridge
		if (data.value) {
			const key = data.key
			const html = data.slot === 'PREVIOUS' ? data.previous?.[key] : data.current?.[key]
			if (html) {
				const index = data.index
				const viewClass = `class="link-text"`
				const viewClick = `onclick="${bridgeName}.viewWorkorderTemplate(${index})"`
				const viewLink = `<span ${viewClass} ${viewClick}>View</span>`
				const downloadClass = 'class="link-text"'
				const downloadClick = `onclick="${bridgeName}.downloadWorkorderTemplate(${index})"`
				const downloadLink = `<span ${downloadClass} ${downloadClick}>Download</span>`
				return `<div>${viewLink} / ${downloadLink}</div>`
			}
		} else {
			return '< not set >'
		}
	}

	static languageFormatter(data: AuditLogFormatterData) {
		const result = JSON.parse(data.value)
		const output = JSON.stringify(result, null, 2)
		return `<pre>${output}</pre>`
	}

	static tagsJsonFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value || value === '{"tags":[]}') return `< not set >`

		const rec = { tags_json: value }
		return GeneralTableHelper.tags(rec, true, true)

		// try {
		// 	const parsed = JSON.parse(value)
		// 	const output = JSON.stringify(parsed, null, 2)
		// 	return `<div class="pre-code">${output}</div>`
		// } catch (error) {
		// 	return `<div class="pre-code">${value}</div>`
		// }
	}

	static imagesJsonFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) return `< not set >`
		try {
			const parsed = JSON.parse(value)
			log('Parsed', parsed)
			let imagesHtml = ''
			for (const image of parsed.files) {
				const url = new ImageFile(image).getUrl()
				imagesHtml += `
					<a href="${url}" target="_blank"><imag src="${url}" />
						<img class="audit-log-image" src="${url}" />
					</a>
				`
			}
			return `<div class="d-flex flex-wrap justify-content-start" style="margin-top:18px">${imagesHtml}</div>`
		} catch (error) {
			return `<div class="pre-code">${value}</div>`
		}
	}

	static jsonArrayFormatter(data: AuditLogFormatterData) {
		// log('DATA', data)
		const key = data.key
		const json = data.slot === 'PREVIOUS' ? data.previous?.[key] : data.current?.[key]
		const parsed = json ? JSON.parse(json) : []

		if (data.fullView) {
			let output = '<code><pre>'
			output += JSON.stringify(parsed, null, 2)
			output += '</pre></code>'

			return output
		}
		return `Item Count: ${parsed.length}`
	}

	static jsonPrettyFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) return `< not set >`
		try {
			const parsed = JSON.parse(value)
			const output = JSON.stringify(parsed, null, 2)
			return `<div class="pre-code">${output}</div>`
		} catch (error) {
			return `<div class="pre-code">${value}</div>`
		}
	}

	static jsonPropFormatter(data: AuditLogFormatterData) {
		const key = data.key
		const slot = data.slot
		let result = {}

		if (data.fullView) {
			const prefs = data.slot === 'PREVIOUS' ? data.previous?.[key] : data.current?.[key]
			const parsedPrefs = prefs ? JSON.parse(prefs) : {}
			if (_.isEmpty(parsedPrefs)) return `< no change >`
			// log('FULL Result', data.slot, parsedPrefs)
			result = parsedPrefs
		} else {
			if (slot === 'CURRENT') {
				// Do Diffs
				const prevPrefs = data.previous?.[key]
				const currentPrefs = data.current?.[key]
				const parsedPrevPrefs = prevPrefs ? JSON.parse(prevPrefs) : {}
				const parsedCurrPrefs = currentPrefs ? JSON.parse(currentPrefs) : {}
				// log('PREV', parsedPrevPrefs)
				// log('CURRENT', parsedCurrPrefs)
				result = AuditLog.difference(parsedCurrPrefs, parsedPrevPrefs)
				// log('RESULT', result)
			} else {
				const prefs = data.previous?.[key]
				const parsedPrefs = prefs ? JSON.parse(prefs) : {}
				if (_.isEmpty(parsedPrefs)) return `< no change >`
				result = parsedPrefs
			}
		}

		// No keys in results which means object may have been pruned
		if (Object.keys(result).length === 0) {
			return '< no data >'
		}

		// const output = JSON.stringify(result, null, 2)
		let output = '<div style="white-space: pre-wrap">'
		for (const key in result) {
			const value = result[key]
			output += `${key}: ${value}\n`
		}
		output += '</div>'
		return output
	}

	static empIdsDaysOfWeekFormatter(data: AuditLogFormatterData) {
		const dbSrvc = data.dbSrvc
		const schedJson = data.slot === 'PREVIOUS' ? data.previous?.emp_ids_days_of_week : data.current?.emp_ids_days_of_week
		const parsedSched = schedJson ? JSON.parse(schedJson) : []
		const renderedOutput = parsedSched
			.map((es) => ({
				empId: es.employee_id,
				emp: dbSrvc.empSrvc.getEmployeeById(es.employee_id),
				dow: es.days_of_week || [1, 2, 3, 4, 5, 6, 7],
			}))
			.map((es) => ({
				empId: es.empId,
				empField: es.emp?.name ?? 'Unknown',
				dowField: ScheduleOptions.convertIsoDayArray(es.dow),
			}))
			.map((es) => `<div>${es.empField} (${es.empId}) - ${es.dowField}</div>`)

		let output = `<div>`
		renderedOutput.forEach((ro) => (output += `${ro}`))
		output += `</div>`
		return output
	}

	static phoneNumberFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}
		return DisplayHelper.displayPhone(data.value)
		// if (value === '< not set >') { return value }
	}

	static jsonFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}

		const parsedValue = value ? JSON.parse(value) : {}
		// log('Parsed Value', parsedValue)
		let output = `<div>`
		for (const attr in parsedValue) {
			if (parsedValue.hasOwnProperty(attr)) {
				output += `<div>${attr}: ${parsedValue[attr]?.toString()}</div>`
			}
		}
		output += `</div>`
		return output
	}

	static adminNotesFormatter(data: AuditLogFormatterData) {
		const value = data.value
		if (!value) {
			return `< not set >`
		}
		const notes = DisplayHelper.formatAdminNotesForPreWrap(data.value)
		return `<div class="trans-note-block hide-scrollbars" style="max-height:unset">${notes}</div>`
		// if (value === '< not set >') { return value }
	}
}

export const AuditLogPruneList = [
	'company_id',
	'created',
	'geo_last_coding',
	'operation',
	'password',
	'phone_number_regex',
	'qbo_id',
	'qbo_customer_id',
	'stats_json',
	'tts_description',
	'table',
	'origNotes',
	'inMetaData',
	'outMetaData',
	'origTranscript',
	'infoHours',
	'isEditing',
	'body_es',
	'body_fr',
	'body_cn',
	'body_ru',
	'updated',
]

export const auditPropertyLookup = {
	adp_ivr_api_log: {
		adp_request_json: {
			name: 'ADP Request Data',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		adp_response_json: {
			name: 'ADP Response Data',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		request_json: {
			name: 'Request Data',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		response_json: {
			name: 'Response Data',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
	},
	track: {
		type: {
			name: 'Event Type',
			formatter: AuditPropertyFormatter.trackEventName,
		},
		payload: {
			name: 'Event Data',
			formatter: AuditPropertyFormatter.trackEventPayload,
		},
	},
	announcements: {
		files_json: {
			name: 'Attached Files',
			formatter: AuditPropertyFormatter.jsonPropFormatter,
		},
	},
	checkpoint: {
		images_json: {
			name: 'Attached Photos',
			formatter: AuditPropertyFormatter.imagesJsonFormatter,
		},
	},
	click_to_call_log: {
		payload: {
			name: 'Event Data',
			formatter: AuditPropertyFormatter.jsonPropFormatter,
		},
		transcript: {
			name: 'Message',
			formatter: AuditPropertyFormatter.tokenMaskFormatter,
		},
	},
	company: {
		address_json: {
			name: 'Address',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		admin_prefs_json: {
			name: 'Portal Prefs',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		analytics_prefs_json: {
			name: 'Data / Analytics Prefs',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		billing_emails: {
			name: 'Billing Emails',
			formatter: AuditPropertyFormatter.emailListFormatter,
		},
		emp_score_config: {
			name: 'Employee Score',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		language_json: {
			name: 'Language',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
		web_prefs_json: {
			name: 'Emp App Prefs',
			formatter: AuditPropertyFormatter.jsonPrettyFormatter,
		},
	},
	dashboard: {
		data_json: {
			name: 'Dashboard Data',
			formatter: () => '< json data >',
		},
	},
	employee: {
		phone_number_e164: {
			name: 'Phone Number',
			formatter: AuditPropertyFormatter.phoneNumberFormatter,
		},
		tags_json: {
			name: 'Tags',
			formatter: AuditPropertyFormatter.tagsJsonFormatter,
		},
		web_prefs_json: {
			name: 'Emp App Prefs',
			formatter: () => AuditPropertyFormatter.jsonPrettyFormatter,
		},
	},
	employee_checklist: {
		checklist_json: {
			name: 'Checklist',
			formatter: AuditPropertyFormatter.jsonArrayFormatter,
		},
	},
	incident_log: {
		report_json: {
			name: 'Report Data',
			formatter: AuditPropertyFormatter.reportJsonFormatter,
		},
		images_json: {
			name: 'Attached Photos',
			formatter: AuditPropertyFormatter.imagesJsonFormatter,
		},
	},
	job: {
		location_id: { name: 'Job Site' },
		supervisor_phone_e164: { name: 'Supervisor Phone' },
		tags_json: {
			name: 'Tags',
			formatter: AuditPropertyFormatter.tagsJsonFormatter,
		},
	},
	location: {
		phone_number_regex_e164: { name: 'Linked Numbers' },
		timezone_id: { name: 'Timezone' },
	},
	login: {
		phone_number: {
			name: 'Phone Number',
			formatter: AuditPropertyFormatter.phoneNumberFormatter,
		},
	},
	reports: {
		filter: { name: 'Filter', formatter: AuditPropertyFormatter.jsonPropFormatter },
		options: { name: 'Options', formatter: AuditPropertyFormatter.jsonPropFormatter },
	},
	schedule_recur: {
		emp_ids_days_of_week: {
			name: 'Multi-Employee Schedules',
			formatter: AuditPropertyFormatter.empIdsDaysOfWeekFormatter,
		},
		employee_id: { name: 'Employee ID / Name' },
		job_id: { name: 'Job ID / Description' },
		tags_json: {
			name: 'Tags',
			formatter: AuditPropertyFormatter.tagsJsonFormatter,
		},
	},
	schedule_view: {
		job_id: { name: 'Job ID / Description' },
		employee_id: { name: 'Employee ID / Name' },
	},
	transaction_log: {
		actual_start: {
			name: 'Actual Start',
			formatter: AuditPropertyFormatter.dateTimeFormatter,
		},
		actual_end: {
			name: 'Actual End',
			formatter: AuditPropertyFormatter.dateTimeFormatter,
		},
		break_time: {
			name: 'Break Time',
			formatter: AuditPropertyFormatter.durationFormatter,
		},
		employee_id: { name: 'Employee ID / Name' },
		geo_start_images_json: {
			name: 'Check-in Images',
			formatter: AuditPropertyFormatter.imagesJsonFormatter,
		},
		geo_start_ts: {
			name: 'GPS Check In Time',
			formatter: AuditPropertyFormatter.dateTimeFormatter,
		},
		geo_end_images_json: {
			name: 'Check-out Images',
			formatter: AuditPropertyFormatter.imagesJsonFormatter,
		},
		geo_end_ts: {
			name: 'GPS Check Out Time',
			formatter: AuditPropertyFormatter.dateTimeFormatter,
		},
		in_meta_json: {
			name: 'Details (IN)',
			formatter: AuditPropertyFormatter.jsonFormatter,
		},
		job_id: { name: 'Job ID / Description' },
		jobsite_id: { name: 'Job Site' },
		notes: { name: 'Notes', formatter: AuditPropertyFormatter.adminNotesFormatter },
		overage_time: {
			name: 'Overage Time',
			formatter: AuditPropertyFormatter.durationFormatter,
		},
		out_meta_json: {
			name: 'Details (OUT)',
			formatter: AuditPropertyFormatter.jsonFormatter,
		},
		time_worked: {
			name: 'Time Worked',
			formatter: AuditPropertyFormatter.durationFormatter,
		},
	},
	tour: {
		waypoints_json: {
			name: 'Waypoints',
			formatter: AuditPropertyFormatter.jsonArrayFormatter,
		},
	},
	users: {
		admin_prefs_json: {
			name: 'Admin Prefs',
			formatter: () => '< json data >', // AuditPropertyFormatter.adminPrefsFormatter
		},
		permissions_json: {
			name: 'Permissions',
			formatter: AuditPropertyFormatter.jsonArrayFormatter,
		},
	},
	wo_config: {
		tac_url: {
			name: 'TAC URL',
			formatter: AuditPropertyFormatter.viewLink,
		},
		html_template: {
			name: 'HTML Template',
			formatter: AuditPropertyFormatter.viewOrDownloadWorkorderTemplate,
		},
	},
}

export class AuditActionEvent {
	recordId: number = null
	resource: DatabaseTableName = 'none'
	header = 'Audit History'
	footer = 'Audit history for record'
	trackType: AuditLogTrackType = null // Used if you need to view a specific track type

	constructor(resource: DatabaseTableName, recordId: number, footer: string) {
		this.recordId = recordId
		this.resource = resource
		this.footer = footer
	}
}
