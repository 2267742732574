<div *ngFor="let item of linkedItemList">
	<!-- Image loads fine -->
	<div *ngIf="!item.hasImageError" [hidden]="!item.isReady" class="card-image-wrap" (click)="showLinkedItem(item)">
		<img [src]="item.url" class="card-img" alt="Profile Picture" (error)="handleImageLoadError(item)" (load)="item.isReady = true" />
		<div class="image-label">{{ item.sfli.record.file_name }}</div>
	</div>
	<!-- Image load encounters error -->
	<div *ngIf="item.hasImageError">
		<div class="card-image-wrap" style="color: gray" (click)="showLinkedItem(item)">
			<div class="image-error">
				<i class="fa-duotone fa-solid fa-image-slash error-icon"></i>
				<div>Non-image {{ item.sfli.extension ? item.sfli.extension : '' }} file</div>
			</div>
			<div class="image-label">{{ item.sfli.record.file_name }}</div>
		</div>
	</div>
</div>
