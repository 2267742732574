<p-select
	appScrollLockSelect
	[id]="tagId"
	appendTo="body"
	[filter]="selector.companiesDropdown.length > 10"
	[options]="selector.companiesDropdown"
	[(ngModel)]="selector.selectedCompanyId"
	[virtualScroll]="selector.companiesDropdown.length > 50"
	[virtualScrollItemSize]="32"
	[scrollHeight]="companyDropdownScrollHeight"
	[resetFilterOnHide]="true"
	(onHide)="changeCompany()"
	filterBy="label,data.externalId,data.accountStatus,data.fullLabel"
>
	<ng-template #selectedItem let-selectedOption>
		<span style="vertical-align: middle">{{ selectedOption.label }}</span>
	</ng-template>
	<ng-template #item let-comp>
		<div class="tts-default-font" style="position: relative">
			<div>
				<span [class]="comp.data.colorClass" style="margin-right: 8px">{{ comp.label }}<i [class]="comp.data.iconClass"></i></span>
			</div>
		</div>
	</ng-template>
</p-select>
