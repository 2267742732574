import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'

import {
	JobRecord,
	JobSiteRecord,
	CrudAction,
	DialogManager,
	JobsViewManager,
	LocalPrefsData,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	TourEditDialogData,
	TourRecord,
	ViewQRCodeDialogData,
	JobQRCodeBUilder,
	ComponentBridgeName,
	BatchActionManager,
	BatchActionType,
} from '@app/models'
import { CoreService, PrefsService } from '@app/services'

import {
	log,
	DisplayHelper,
	DateTimeHelper,
	TableActionFormatter,
	JobTableFormatter,
	SiteTableFormatter,
	GeneralTableHelper,
	DataTablesHelper,
} from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { Subscription } from 'rxjs'

import _ from 'lodash'
import moment from 'moment-timezone'

enum JobTableColumnIndex {
	id = 0,
	description,
	activeStatus,
	tags,
	jobCode,
	qrCode,
	multiDay,
	details,
	siteDetails,
	jobSite,
	supervisor,
	supervisorGroup,
	manager,
	duration,
	breaks,
	profileName,
	tour,
	scheduled,
	// notifyEmp,
	// notifySup,
	adp,
	qbo,
	siteLinkedNumbers,
	siteDistrict,
	siteAddress,
	siteMap,
	siteTimezone,
	employee,
	client,
	vendor,
	workTaxLocation,
	syncLock,
	actions,
	bulkSelect,
}

@Component({
	selector: 'app-job-table',
	templateUrl: './job-table.component.html',
	styleUrls: ['./job-table.component.scss'],
	standalone: false,
})
export class JobTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeJobTable'

	@Input() list: Array<JobRecord> = []
	@Input() filter = null

	accessHelper: AccessHelper
	canAccessAuditLog = false

	isPureSchedule = true
	isJobSiteMerged = false
	isManagerRoleAvailable = false
	isJobQRCodeAvailable = false
	highlightMissingExternalIds = false
	// notificationDefaults: NotificationDefaults

	editAction = { recordId: null, action: 'edit', header: '', footer: '', showEditDialog: false }
	editDialogManager = new DialogManager('jobEditDialog', 'jobEditDialog')
	batchDialogManager = new DialogManager('jobBatchEditDialog')
	tourEditDialogManager = new DialogManager('tourEditDialog')

	siteNumbers = { recordId: null, showDialog: false, linkedOnly: false }

	private jobsTable // : DataTables.DataTable
	private isQBOCustomer = false
	private isAdpCustomer = false
	private defaultSortOrder = [[1, 'asc']]
	private overageCalculationsEnabled = false

	// private showJobCodeColumn = false // DEPRECATED 20240720
	private showMultiDayColumn = false
	private showTaxLocationColumn = false

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')
	public qrCodeManager = new DialogManager('qrCodeManager')

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private subs = new Subscription()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
		private prefsService: PrefsService,
	) {
		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupAvailabilityChecks()
		this.setupLocalPrefsDialog()

		// Setup list count manager
		this.coreSrvc.dbSrvc.jobSrvc.listCountManager.listCount = () => this.list.length
	}

	get batchManager(): BatchActionManager {
		return this.coreSrvc.dbSrvc.jobSrvc.batchManager
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return `${(input?.val() as string) || ''}`
	}

	get hasSyncLockRecords(): boolean {
		return this.list.filter((rec) => rec.sync_lock).length > 0
	}

	get viewManager(): JobsViewManager {
		return this.coreSrvc.dbSrvc.jobSrvc.viewManager
	}
	get hasClients(): boolean {
		return this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('CLIENT').length > 0
	}
	get hasVendors(): boolean {
		return this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('VENDOR').length > 0
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#jobsTable_filter input').attr('placeholder', ' filter')
		$('#jobsTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#jobsTable_filter label')
		})

		// Handle count bubble search highlighting
		$('#jobsTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		// Add row details listener for tooltips
		this.jobsTable.on('responsive-display', () => {
			const itemTooltip: any = $('.item-tooltip')
			itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
		})

		this.enableAllTooltips()
	}

	ngOnDestroy() {
		this.jobsTable['fixedHeader'].disable()
		$('.tooltip').remove() // Remove Bootstrap Tooltips
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		this.subs.add(this.batchManager.updateTable.subscribe(() => this.updateTable()))
		this.subs.add(this.coreSrvc.dbSrvc.npSrvc.listUpdated.subscribe(() => this.profilesUpdated()))

		this.subs.add(
			this.coreSrvc.eventSrvc.showFixedHeader.subscribe((show) => {
				log('Show Table Fixed Header', show)
				if (show) {
					this.jobsTable?.fixedHeader.enable(true)
					setTimeout(() => {
						this.jobsTable.columns.adjust().responsive.recalc()
						this.jobsTable.fixedHeader.adjust()
					}, 100)
				} else {
					this.jobsTable?.fixedHeader.enable(false)
				}
			}),
		)

		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAvailabilityChecks() {
		// Check for merged job site view
		this.isJobSiteMerged = this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite

		log('Jobs Filter', this.filter)
		const isQBDCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBOCustomer()
		const isQBOCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBDCustomer()
		if (isQBOCustomer || isQBDCustomer) {
			if (this.coreSrvc.dbSrvc.qbSrvc.hasCustomerData() || this.coreSrvc.dbSrvc.qbSrvc.hasServiceItemData()) {
				this.isQBOCustomer = true
			}
		}
		if (this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()) {
			this.isAdpCustomer = true
		}

		// Setup optional columns and features
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.overageCalculationsEnabled = company.overage
		// this.showJobCodeColumn = company.useJobCodes // DEPRECATED 2023-04-17 - Stations may now use job codes
		this.showMultiDayColumn = company.useMultiDay
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId
		this.showTaxLocationColumn = company.work_tax_location && this.coreSrvc.prefSrvc.data.jobColTaxLocationVisible
		// Check for managing supervisor role availability
		this.isManagerRoleAvailable = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().userEnableManagerRole
		this.isJobQRCodeAvailable =
			this.coreSrvc.dbSrvc.settingSrvc.getCompany().validate_qr_codes ||
			this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().jobEnableQRCodeColumn
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'job')
		const auditPerms = this.accessHelper.getPermissionsFor('audit')
		this.canAccessAuditLog = auditPerms.access.read
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getJobTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		// If job sites are not merged then hide site specific option
		if (!this.isJobSiteMerged) {
			columnVisibilityGroup.hiddenPrefKeys = [
				'jobColSiteLinkedNumbersVisible',
				'jobColSiteAddressVisible',
				'jobColSiteDistrictVisible',
				'jobColSiteMapVisible',
				'jobColSiteTimezoneVisible',
			]
		}
		if (!this.isJobQRCodeAvailable) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColQRCodeVisible')
		}
		// Only show multi-day if enabled
		if (!this.showMultiDayColumn) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColMultiDayVisible')
		}
		// Don't show job site when sites merged as it's same as job
		if (this.isJobSiteMerged) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColJobSiteVisible')
		}
		// If not an ADP customer than hide ADP option
		if (!this.isAdpCustomer) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColADPVisible')
		}
		// If not a QB customer than hide the QB option
		if (!this.isQBOCustomer) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColQBOVisible')
		}
		// If manager role note available then hide the manager option
		if (!this.isManagerRoleAvailable) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColManagerVisible')
		}
		// If there are no sync locked job records then hide the lock option
		if (!this.coreSrvc.dbSrvc.jobSrvc.hasSyncLockRecords()) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobColSyncLockVisible')
		}

		const miscItems = LocalPrefsData.getJobTableMiscellaneousPrefs()
		const miscGroup = new LocalPrefsGroup('Miscellaneous Prefs', miscItems)

		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup, miscGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_jobsTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		log('Handle Screen Size Changes')
		if (this.jobsTable) {
			this.jobsTable.columns.adjust().responsive.recalc()
			setTimeout(() => {
				this.jobsTable.fixedHeader.adjust()
			}, 250)
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	private profilesUpdated() {
		log('Profiles Updated notification received')
		const profiles = this.coreSrvc.dbSrvc.npSrvc.getProfiles()
		setTimeout(() => {
			if (this.jobsTable) {
				this.jobsTable.clear()
				this.jobsTable.rows.add(this.list)
				this.jobsTable.draw(false)
			}
		})
		// log('Profiles', profiles)
	}

	private filterListItems(job: JobRecord) {
		if (!this.filter) {
			return true
		}
		const currentFilter = this.filter
		if (currentFilter.field === 'location_id') {
			if (job.location_id === currentFilter.id) {
				return true
			} else {
				return false
			}
		}
		return true
	}

	fetchAndReloadData() {
		this.coreSrvc.dbSrvc.readTable('location').then((locSuccess) => {
			this.coreSrvc.dbSrvc.bulkRead(['supervisor_group', 'job']).then((success) => {
				if (success) {
					this.updateTable()
				}
			})
		})
	}

	public loadData() {
		const displayState = this.viewManager.currentView
		const unassignedJob = this.coreSrvc.dbSrvc.jobSrvc.getUnassignedJob()
		const travelJobs = this.coreSrvc.dbSrvc.jobSrvc.getTravelJobs()
		const templateJobs = this.coreSrvc.dbSrvc.jobSrvc.getTemplateJobs()
		const systemJobs = displayState !== 'INACTIVE' ? [unassignedJob, ...templateJobs, ...travelJobs] : []
		const allJobs = this.accessHelper.getJobTableList(displayState)
		const combinedJobs = [...allJobs, ...systemJobs]
		const showSystemJobs = this.coreSrvc.prefSrvc.data.jobTableShowSystemJobs
		const displayJobs = showSystemJobs ? combinedJobs : combinedJobs.filter((job) => !this.isSystemJob(job))

		let list = displayJobs.filter((job) => this.filterListItems(job))

		if (this.batchManager.isPreviewMode) {
			list = list.filter((job) => this.batchManager.selectedRecords.includes(job.id))
		}

		this.list = list
		this.coreSrvc.dbSrvc.jobSrvc.listCountManager.isReady = true
	}

	public reloadTable() {
		$('.item-tooltip').remove() // Remove QBO tooltips
		this.coreSrvc.dbSrvc.readTable('location').then((locSuccess) => {
			this.coreSrvc.dbSrvc.bulkRead(['supervisor_group', 'job']).then((success) => {
				if (success) {
					this.updateTable()
				}
			})
		})
	}

	public redrawTable() {
		this.jobsTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		// this.enableAllTooltips()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns(): void {
		// const hasAssignedClients = this.hasClients // this.list.filter((j) => j.client_id).length > 0
		// const hasAssignedVendors = this.hasVendors // this.list.filter((j) => j.vendor_id).length > 0

		const activeStatus = this.viewManager.currentView === 'ALL'
		const tags = this.coreSrvc.prefSrvc.data.jobColTagsVisible
		const jobCode = this.coreSrvc.prefSrvc.data.jobColJobCodeVisible
		const qrCode = this.isJobQRCodeAvailable && this.coreSrvc.prefSrvc.data.jobColQRCodeVisible
		const multiDay = this.showMultiDayColumn && this.coreSrvc.prefSrvc.data.jobColMultiDayVisible
		const details = this.coreSrvc.prefSrvc.data.jobColDetailsVisible
		const siteDetails = this.coreSrvc.prefSrvc.data.jobColSiteDetailsVisible
		const jobSite = !this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColJobSiteVisible
		const supervisor = this.coreSrvc.prefSrvc.data.jobColSupervisorVisible
		const supervisorGroup = this.coreSrvc.prefSrvc.data.jobColSupervisorGroupVisible
		const manager = this.isManagerRoleAvailable && this.coreSrvc.prefSrvc.data.jobColManagerVisible
		const breaks = this.coreSrvc.prefSrvc.data.jobColBreakVisible
		const profileName = this.coreSrvc.prefSrvc.data.jobColProfileNameVisible
		const tour = this.coreSrvc.prefSrvc.data.jobColTourVisible
		const scheduled = this.coreSrvc.prefSrvc.data.jobColScheduledVisible
		const adp = this.isAdpCustomer && this.coreSrvc.prefSrvc.data.jobColADPVisible
		const qbo = this.isQBOCustomer && this.coreSrvc.prefSrvc.data.jobColQBOVisible
		const siteLinkedNumbers = this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteLinkedNumbersVisible
		const siteAddress = this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteAddressVisible
		const siteDistrict = this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteDistrictVisible
		const siteMap = this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteMapVisible
		const siteTimezone = this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteTimezoneVisible
		const employee = this.coreSrvc.prefSrvc.data.jobColEmployeeVisible
		const client = this.hasClients && this.coreSrvc.prefSrvc.data.jobColClientsVisible
		const vendor = this.hasVendors && this.coreSrvc.prefSrvc.data.jobColVendorsVisible
		const workTaxLocation = this.coreSrvc.prefSrvc.data.jobColTaxLocationVisible
		const syncLock = this.coreSrvc.prefSrvc.data.jobColSyncLockVisible && this.coreSrvc.dbSrvc.jobSrvc.hasSyncLockRecords()

		const bulkSelectVisible = this.batchManager.isActive // Not configurable by preferences

		this.jobsTable.clear()

		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.activeStatus, activeStatus)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.tags, tags)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.jobCode, jobCode)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.qrCode, qrCode)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.multiDay, multiDay)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.details, details)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.siteDetails, siteDetails)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.jobSite, jobSite)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.supervisor, supervisor)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.supervisorGroup, supervisorGroup)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.manager, manager)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.breaks, breaks)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.profileName, profileName)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.tour, tour)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.scheduled, scheduled)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.adp, adp)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.qbo, qbo)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.siteLinkedNumbers, siteLinkedNumbers)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.siteAddress, siteAddress)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.siteDistrict, siteDistrict)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.siteMap, siteMap)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.siteTimezone, siteTimezone)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.employee, employee)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.client, client)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.vendor, vendor)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.workTaxLocation, workTaxLocation)
		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.syncLock, syncLock)

		GeneralTableHelper.updateColumn(this.jobsTable, JobTableColumnIndex.bulkSelect, bulkSelectVisible)

		this.jobsTable.rows.add(this.list)
		this.jobsTable.draw(false)
	}

	// Used for QB column
	enableTooltips() {
		const tooltips: any = $('[data-toggle="tooltip"]')
		tooltips.tooltip()
	}

	// All other tooltips
	enableAllTooltips() {
		// Enable Tooltips
		const tooltips: any = $('.item-tooltip')
		tooltips.tooltip({ show: { effect: 'none', delay: 0 } })
		// Delay long enough to hide tooltips if cursor was hovering during page load
		setTimeout(() => {
			tooltips.tooltip('hide')
		}, 1000)
	}

	saveActionComplete() {
		this.editAction.showEditDialog = false
		this.accessHelper.updateSupervisorIds()
		this.updateTable()
	}

	editActionCancelled() {
		this.editAction.showEditDialog = false
	}

	createRecord() {
		if (!this.accessHelper.canPerformAction(CrudAction.create, null)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.action = 'new'
		this.editDialogManager.headerLabel = 'Add Job'
		this.editDialogManager.isDialogVisible = true
		this.editDialogManager.canSubmit = () => false
	}

	editRecord(id: number) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'job')
		if (!this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)
		if (job) {
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = 'Edit Job'
			this.editDialogManager.isDialogVisible = true
		}
	}

	editTourRecord(id: number) {
		const hasTour = !!this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)?.tour_id

		// const hasTour = this.coreSrvc.dbSrvc.jobSrvc.mockTours[id]
		// log('Tour', tour)
		const action = hasTour ? 'edit' : 'new'
		this.tourEditDialogManager.headerLabel = 'Manage Tour' //action === 'edit' ? 'Edit Checkpoints' : 'Add Checkpoints'
		// this.tourEditDialogManager.isSubmitBtnVisible = action === 'edit'
		this.tourEditDialogManager.isCancelBtnVisble = true
		this.tourEditDialogManager.isSubmitBtnVisible = true
		this.tourEditDialogManager.dialogData = new TourEditDialogData('JOB_TABLE', action, id, null)
		this.tourEditDialogManager.isDialogVisible = true
		log('Edit Tour for ID', id)
	}

	tourRecordUpdated(record: TourRecord) {
		log('Tour Record Updated', event)
		this.tourEditDialogManager.isDialogVisible = false
		this.reloadTable()
	}

	cloneRecord(id: number) {
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)
		const isMyRecord = this.accessHelper.isMyRecord(id, 'job')
		const isSystemJob = this.isSystemJob(job)
		if (isSystemJob) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'You are not allowed to clone a system job.', 3)
			return
		}
		if (!this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		if (job) {
			this.editAction.recordId = id
			this.editAction.action = 'clone'
			this.editDialogManager.headerLabel = 'Copy Job'
			this.editDialogManager.isDialogVisible = true
		}
	}

	showLinkedNumbers(siteId: number, linkedOnly: boolean) {
		log('Show Linked Numbers for Site ID', siteId)
		this.siteNumbers.linkedOnly = linkedOnly
		this.siteNumbers.recordId = siteId
		this.siteNumbers.showDialog = true
	}

	deleteRecord(id: number) {
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)
		const isMyRecord = this.accessHelper.isMyRecord(id, 'job')
		const isSystemJob = this.isSystemJob(job)
		if (isSystemJob) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'You are not allowed to delete a system job.', 3)
			return
		}
		if (job.sync_lock) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'This record is managed by a 3rd party integration and cannot be deleted.')
			return
		}
		if (!this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}

		// const isDefault = job.auto_created
		// if (isDefault) {
		// 	this.coreSrvc.notifySrvc.notify('error', 'Default Job', 'The default job cannot be manually deleted. It will be removed automatically when the job site it belongs to is deleted.', 5)
		// 	return
		// }
		this.coreSrvc.zone.run(() => {
			this.router.navigate([`/admin/jobs/delete/${id}`])
		})
	}

	clearSearch(): boolean {
		this.jobsTable
			.search('')
			.order([[1, 'asc']])
			.draw()
		this.redrawTable()
		return false
	}

	private makeAdpInfo(record: JobRecord) {
		if (!record) {
			return ''
		}

		const deptCodeId = record.adp_department_code_id
		const deptCode = this.coreSrvc.dbSrvc.adpSrvc.getAdpDepartmentCodeForId(deptCodeId)
		const deptCodeBtn = deptCode
			? `<span class="qbo-btn item-tooltip" style="cursor: default; width: 10px;" data-toggle="tooltip" data-placement="top" data-bs-html="true" data-trigger="hover" title="${deptCode.shortName}">D</span>`
			: ``

		const rateCodeId = record.adp_rate_code_id
		const rateCode = this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodeForId(rateCodeId)
		const rateCodeBtn = rateCode
			? `<span class="qbo-btn item-tooltip" style="cursor: default; width: 10px;" data-toggle="tooltip" data-placement="top" data-bs-html="true" data-trigger="hover" title="${rateCode.shortName}">R</span>`
			: ``

		return `${deptCodeBtn}&nbsp;${rateCodeBtn}`
	}

	private makeQBOInfo(record: JobRecord): string {
		if (!record || !this.isQBOCustomer) {
			return ''
		}

		const customerId = record.qbo_customer_id
		const customerInfo = this.coreSrvc.dbSrvc.qbSrvc.getQBOCustomerInfoForId(customerId)
		const customerButton = `<span class="qbo-btn item-tooltip" style="cursor: default; width: 10px;" data-toggle="tooltip" data-placement="top" data-bs-html="true" data-trigger="hover" title="${customerInfo}">C</span>`

		const vendorId = record.qbo_vendor_id
		const vendorInfo = this.coreSrvc.dbSrvc.qbSrvc.getQBOVendorInfoForId(vendorId)
		const vendorButton = `<span class="qbo-btn item-tooltip" style="cursor: default; width: 10px;" data-toggle="tooltip" data-placement="top" data-bs-html="true" data-trigger="hover" title="${vendorInfo}">V</span>`

		const serviceItemId = record.qbo_service_item_id
		const serviceItemInfo = this.coreSrvc.dbSrvc.qbSrvc.getQBOServiceItemInfoForId(serviceItemId)
		const serviceItemButton = `<span class="qbo-btn item-tooltip" style="cursor: default; width: 10px;" data-toggle="tooltip" data-placement="top" data-bs-html="true" data-trigger="hover" title="${serviceItemInfo}">S</span>`

		const qboFlags = []

		if (customerId) qboFlags.push(customerButton)
		if (vendorId) qboFlags.push(vendorButton)
		if (serviceItemId) qboFlags.push(serviceItemButton)
		return qboFlags.join('&nbsp;')
	}

	public gotoAddress(id: number) {
		const record = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id)
		const url = `https://maps.google.com/?q=${record.address_formatted}`
		const target = '_blank' // this.coreSrvc.dbSrvc.devDetect.isDesktop() ? 'sitemap' : '_blank'
		window.open(url, target)
	}

	private isSystemJob(job: JobRecord) {
		return this.coreSrvc.dbSrvc.jobSrvc.isSystemJob(job)
	}

	private isTemplateJob(job: JobRecord) {
		return job.description === 'W2W-TEMPLATE'
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	public setPage(num: number) {
		log('Setting page')
		this.jobsTable?.page(num)
	}

	public showQRCode(id: number) {
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)
		const url = JobQRCodeBUilder.buildCode(id, this.coreSrvc)
		const jobCode = job.job_code ? `${job.job_code}` : ''
		const dialogData = new ViewQRCodeDialogData(url, 'QR Code', job.description, jobCode)

		// log('QRCode Url', url)
		this.qrCodeManager.dialogData = dialogData
		this.qrCodeManager.isDialogVisible = true
	}

	public pendingAddressRefresh() {
		this.coreSrvc.zone.run(() => {
			this.reloadTable()
		})
	}

	//////////////////
	// BULK ACTIONS //
	//////////////////

	toggleBatchActionRecordSelection(id: number) {
		this.batchManager.toggleRecordSelection(id)
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)
		this.invalidateRowForJob(job)
	}
	peformBatchAction(action: BatchActionType) {
		log('Perform batch action', action)
		const currentView = this.viewManager.currentView
		const visibleData: Array<JobRecord> = this.jobsTable?.rows({ filter: 'applied' }).data().toArray() ?? []
		const visibleIds = visibleData.map((emp) => emp.id)

		switch (action) {
			case 'EDIT':
				this.batchDialogManager.headerLabel = 'Edit Batch'
				this.batchDialogManager.isDialogVisible = true
				break
			case 'SELECT_VISIBLE':
				const selectIds = [...this.batchManager.selectedRecords, ...visibleIds]
				this.batchManager.setSelectedRecords(selectIds)
				break

			case 'UNSELECT_VISIBLE':
				const currentIds = this.batchManager.selectedRecords
				const removeIds = visibleData.map((job) => job.id)
				const updateIds = currentIds.filter((id) => !removeIds.includes(id))
				this.batchManager.setSelectedRecords(updateIds)
				break

			case 'TOGGLE_PREVIEW':
				setTimeout(() => {
					this.clearSearch()
				}, 100)
				break

			// case 'SEND_ANNOUNCEMENT':
			// 	const validIds: Array<number> = []
			// 	this.batchManager.selectedRecords.forEach((id) => {
			// 		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
			// 		if (emp && emp.status !== 'RESIGNED' && emp.status !== 'TERMINATED') validIds.push(emp.id)
			// 	})
			// 	const event = new SendAnnouncementEvent(validIds)
			// 	this.coreSrvc.dbSrvc.annSrvc.sendAnnouncementEvent.next(event)
			// 	break
		}
	}

	// End bulk actions

	invalidateRowForJob(job: JobRecord) {
		// log('Invalidated', emp)
		const idx = this.list.indexOf(job)
		this.jobsTable.rows(idx).invalidate().draw()
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.jobsTable = $('#jobsTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{ responsivePriority: 1, targets: [JobTableColumnIndex.description, JobTableColumnIndex.bulkSelect] },
				{ responsivePriority: 2, targets: JobTableColumnIndex.jobCode },
				{ responsivePriority: 3, targets: JobTableColumnIndex.duration },
				{ responsivePriority: 4, targets: JobTableColumnIndex.supervisor },
				{ responsivePriority: 5, targets: JobTableColumnIndex.tour },
				{ responsivePriority: 6, targets: JobTableColumnIndex.details },
				{ responsivePriority: 7, targets: JobTableColumnIndex.siteDetails },
				{ responsivePriority: 8, targets: JobTableColumnIndex.tags },
				{ responsivePriority: 9, targets: JobTableColumnIndex.actions },
				{ responsivePriority: 10, targets: JobTableColumnIndex.profileName },
				{ responsivePriority: 11, targets: JobTableColumnIndex.siteLinkedNumbers },
				{ responsivePriority: 12, targets: JobTableColumnIndex.siteAddress },
				{ responsivePriority: 13, targets: JobTableColumnIndex.siteMap },
				{ responsivePriority: 14, targets: JobTableColumnIndex.siteDistrict },
				{ responsivePriority: 15, targets: JobTableColumnIndex.siteTimezone },
				{
					responsivePriority: 14,
					targets: [JobTableColumnIndex.client, JobTableColumnIndex.vendor],
				},
				{
					// ID
					targets: JobTableColumnIndex.id,
					visible: false,
					searchable: false,
					title: 'ID',
					data: 'id',
				},
				{
					// Description
					targets: JobTableColumnIndex.description,
					visible: true,
					searchable: true,
					title: 'Job Name / ID',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const name = this.coreSrvc.dbSrvc.jobSrvc.formatSystemJobName(job.description || '')
						const flagRow = name.includes('AUTOCREATED') ? true : false
						let jobName = name // DisplayHelper.truncateForWrapDisplay(name)
						const externalId = job.external_id
							? `<div class="table-ext-id-normal">${job.external_id}</div>`
							: this.highlightMissingExternalIds
								? `<div class="table-tag bg-chocolate" style="width:130px">No ID Set</div>`
								: ''
						const systemJob = this.isSystemJob(job) ? `<div class="table-tag bg-sysjob" style="width:130px">System Job</div>` : ''
						if (flagRow) {
							jobName = jobName.replace('AUTOCREATED@', '')
							jobName = `<i title="Auto Created Job" class="table-icon-alert fa fa-exclamation-triangle item-tooltip"></i> ` + jobName
						}
						const sortName = this.isTemplateJob(job) ? `~${name}` : this.isSystemJob(job) ? `~~${name}` : name
						const sortHtml = `<div style="display:none">${sortName}</div>`
						const minWidth = window.innerWidth > 400 ? '200px' : '100px'
						const maxWidth = window.innerWidth > 400 ? '400px' : '200px'
						return `
						<div class="dtr-control-content table-text">
							<div>${sortHtml}</div>
							<div>${systemJob}</div>
							<div>${jobName}</div>
							<div>${externalId}</div>
						</div>`
					},
				},
				{
					// Active Status
					targets: JobTableColumnIndex.activeStatus,
					visible: this.viewManager.currentView === 'ALL' ? true : false, // Visibility managed after load in loadData
					searchable: true,
					title: 'Status',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						return job.active ? '<div class="table-tag bg-green">Active</div>' : `<div class="table-tag bg-chocolate">Inactive</div>`
					},
				},
				{
					// Tags
					targets: JobTableColumnIndex.tags,
					visible: this.prefsService.data.jobColTagsVisible,
					searchable: this.prefsService.data.jobColTagsVisible,
					sortable: true,
					title: 'Tags',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const expandTags = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().enableTagExpansion
						return GeneralTableHelper.tags(job, expandTags, true)
					},
				},
				{
					// Job Code
					targets: JobTableColumnIndex.jobCode,
					visible: this.prefsService.data.jobColJobCodeVisible, // this.showJobCodeColumn,
					searchable: this.prefsService.data.jobColJobCodeVisible, // this.showJobCodeColumn,
					title: 'Job<br>Code',
					data: null,
					render: (d, t, f, m) => {
						return d.job_code
					},
				},
				{
					// QR Code
					targets: JobTableColumnIndex.qrCode,
					visible: this.isJobQRCodeAvailable && this.coreSrvc.prefSrvc.data.jobColQRCodeVisible,
					searchable: false,
					title: 'QR<br>Code',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const isRequired = job.require_photo_checkin || job.require_qrc_checkout
						const style = isRequired ? `style="font-size:1.4rem;color:chocolate;"` : `style="font-size:1.4rem"`
						return `<i class="fa-light fa-qrcode link-text" ${style} onclick="${this.bridgeName}.showQRCode(${job.id})"></i>`
					},
				},
				{
					// Multi-Day
					targets: JobTableColumnIndex.multiDay,
					visible: this.showMultiDayColumn,
					searchable: false,
					title: 'Multi<br>Day',
					data: null,
					render: (rec: JobRecord, t, f, m) => {
						return rec.multi_day
							? `<span style="display: none;">0</span><i class="fa fa-check" style="color: green; margin-left: 14px;"></i>`
							: ``
					},
				},
				{
					// Job Details
					targets: JobTableColumnIndex.details,
					visible: this.prefsService.data.jobColDetailsVisible,
					searchable: this.prefsService.data.jobColDetailsVisible,
					title: 'Job<br>Details',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						return JobTableFormatter.makeJobDetails(job)
					},
				},
				{
					// Site Details
					targets: JobTableColumnIndex.siteDetails,
					visible: this.isJobSiteMerged && this.prefsService.data.jobColSiteDetailsVisible,
					searchable: this.isJobSiteMerged && this.prefsService.data.jobColSiteDetailsVisible,
					title: 'Site<br>Details',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						// const result = d.job_details ? `<span style="margin-right:8px;">${d.job_details}</span>` : ''
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						const siteDetails = site?.location_details
						return siteDetails ? `<div class="table-note-block">${siteDetails}</div>` : ''
					},
				},
				{
					// Job Site
					targets: JobTableColumnIndex.jobSite,
					visible: !this.isJobSiteMerged && this.prefsService.data.jobColJobSiteVisible,
					searchable: !this.isJobSiteMerged && this.prefsService.data.jobColJobSiteVisible,
					title: 'Job Site',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						if (this.isSystemJob(job)) return ''

						const jobSite = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(job.location_id)
						if (!jobSite) {
							return 'Not Found'
						}
						const description = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(job.location_id).description
						return `<div style="white-space:normal;max-width:400px;">${description}</div>`
					},
				},
				{
					// Supervisor
					targets: JobTableColumnIndex.supervisor,
					visible: this.prefsService.data.jobColSupervisorVisible,
					searchable: this.prefsService.data.jobColSupervisorVisible,
					title: 'Supervisor',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						const supId = site ? site.supervisor : null
						const supName = this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(supId) || ''
						const supHtml = `<div class="table-name">${supName}</div>`
						return `${supHtml}`
					},
				},
				{
					// Supervisor Group
					targets: JobTableColumnIndex.supervisorGroup,
					visible: this.prefsService.data.jobColSupervisorGroupVisible,
					searchable: this.prefsService.data.jobColSupervisorGroupVisible,
					title: 'Group',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						const groupId = site?.supervisor_group_id
						const groupName = groupId ? this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(groupId)?.description : ''
						const groupHtml = groupName ? `<div class="table-text">${groupName}</div>` : ''
						return `${groupHtml}`
					},
				},
				{
					// Manager
					targets: JobTableColumnIndex.manager,
					visible: this.isManagerRoleAvailable && this.prefsService.data.jobColManagerVisible,
					searchable: this.isManagerRoleAvailable && this.prefsService.data.jobColManagerVisible,
					title: 'Manager',
					data: null,
					render: (d, t, f, m) => {
						const job: JobRecord = d
						const siteId = job.location_id
						const site: JobSiteRecord = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						const supId = site ? site.supervisor : null
						const sup = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(supId)
						if (sup) {
							const managerId = sup.managed_by
							if (managerId) {
								const manager = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(managerId)
								const managerName = this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(manager?.id)
								return `<div style="white-space: normal">${managerName}</div>`
							}
						}
						return ''
					},
				},
				{
					// Duration
					targets: JobTableColumnIndex.duration,
					visible: this.overageCalculationsEnabled,
					searchable: true,
					title: 'Duration<br>(hr:min)',
					data: null,
					render: (d, t, f, m) => {
						const overageTime = d.overage_time
						if (overageTime) {
							const duration = moment.duration(overageTime)
							return `<span style="margin-left: 10px">${DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', duration)}</span>`
						}
						return ''
					},
				},
				{
					// Break
					targets: JobTableColumnIndex.breaks,
					visible: this.prefsService.data.jobColBreakVisible,
					searchable: this.prefsService.data.jobColBreakVisible,
					title: 'Break',
					data: null,
					render: (d: JobRecord, t, f, m) => {
						const breakDur = d.break_time || ''
						const breakWorked = d.break_time_worked
						const breakTime = moment.duration(breakDur).asMinutes()
						const breakTimeString = breakTime === 0 ? 'n/a' : breakTime + ' min'
						if (breakWorked) {
							const breakWorkedDuration = moment.duration(breakWorked)
							return breakTime + ' / ' + DateTimeHelper.formatDuration('H:mm', breakWorkedDuration)
						}
						return breakTimeString
					},
				},
				{
					// Notification Profile
					targets: JobTableColumnIndex.profileName,
					visible: this.prefsService.data.jobColProfileNameVisible,
					searchable: true,
					orderable: true,
					title: 'Notification<br>Profile',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const profileId = job.notification_profile_id
						const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(profileId)
						if (profile) {
							return `<div class="table-text">${profile.name}</div>`
						}
						return ``
					},
				},
				{
					// Tour
					targets: JobTableColumnIndex.tour,
					visible: this.prefsService.data.jobColTourVisible,
					searchable: true,
					orderable: true,
					title: 'Tour',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const tourId = job.tour_id
						const tour = this.coreSrvc.dbSrvc.tourSrvc.getTourRecordById(tourId)
						if (tour) {
							return `<div class="table-text">${tour.description}</div>`
						}
						return ``
					},
				},
				{
					// Scheduled
					targets: JobTableColumnIndex.scheduled,
					visible: this.prefsService.data.jobColScheduledVisible,
					searchable: false,
					orderable: true,
					title: 'Scheduled',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const hasCheck = this.coreSrvc.dbSrvc.schedulerSrvc.getSchedulesForJobId(job.id).length > 0
						return TableActionFormatter.checkmarkLink(hasCheck, true, 'cell-icon-lg', 34, null)
					},
				},
				{
					// ADP
					targets: JobTableColumnIndex.adp,
					visible: this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated(),
					searchable: false,
					title: 'ADP',
					data: null,
					render: (d, t, f, m) => {
						return this.makeAdpInfo(d)
					},
				},
				{
					// QBO
					targets: JobTableColumnIndex.qbo,
					visible: this.isQBOCustomer ? this.prefsService.data.jobColQBOVisible : false,
					searchable: false,
					title: 'QB',
					data: null,
					render: (d, t, f, m) => {
						return this.makeQBOInfo(d)
					},
				},
				{
					// Site Linked Numbers
					targets: JobTableColumnIndex.siteLinkedNumbers,
					visible: this.isJobSiteMerged && this.prefsService.data.jobColSiteLinkedNumbersVisible,
					searchable: this.isJobSiteMerged && this.prefsService.data.jobColSiteLinkedNumbersVisible,
					title: `Linked #'s`,
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const isSystemJob = this.coreSrvc.dbSrvc.jobSrvc.isSystemJob(job)
						if (isSystemJob) return ''
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						return SiteTableFormatter.makeLinkedNumbers(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, site, job.linked_only)
					},
				},
				{
					// Site District
					targets: JobTableColumnIndex.siteDistrict,
					visible: this.isJobSiteMerged && this.prefsService.data.jobColSiteDistrictVisible,
					searchable: this.isJobSiteMerged && this.prefsService.data.jobColSiteDistrictVisible,
					title: this.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel(),
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						return site?.district ?? ''
					},
				},
				{
					// Site Address
					targets: JobTableColumnIndex.siteAddress,
					visible: this.isJobSiteMerged && this.prefsService.data.jobColSiteAddressVisible,
					searchable: this.isJobSiteMerged && this.prefsService.data.jobColSiteAddressVisible,
					title: 'Address',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						return site ? SiteTableFormatter.makeAddress(site, this.bridgeName) : 'Unknown'
					},
				},
				{
					// Site Map
					targets: JobTableColumnIndex.siteMap,
					visible: this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteMapVisible,
					searchable: this.isJobSiteMerged && this.coreSrvc.prefSrvc.data.jobColSiteMapVisible,
					title: 'Map',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						return SiteTableFormatter.makeMapMarkerIcon(site)
					},
				},
				{
					// Site Timezone
					targets: JobTableColumnIndex.siteTimezone,
					visible: this.isJobSiteMerged && this.prefsService.data.jobColSiteTimezoneVisible,
					searchable: this.isJobSiteMerged && this.prefsService.data.jobColSiteTimezoneVisible,
					title: 'Timezone',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const siteId = job.location_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						return site ? this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForId(site.timezone_id) : 'Unknown'
					},
				},
				{
					// Employee
					targets: JobTableColumnIndex.employee,
					visible: this.prefsService.data.jobColEmployeeVisible,
					searchable: this.prefsService.data.jobColEmployeeVisible,
					title: 'Employee',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						return JobTableFormatter.empPayCellRenderer(job, this.coreSrvc.dbSrvc)
					},
				},
				{
					// Client
					targets: JobTableColumnIndex.client,
					visible: this.hasClients,
					searchable: this.hasClients,
					title: 'Client',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						return JobTableFormatter.clientVendorCellRenderer(job, this.coreSrvc.dbSrvc, 'CLIENT')
					},
				},
				{
					// Vendor
					targets: JobTableColumnIndex.vendor,
					visible: this.hasVendors,
					searchable: this.hasVendors,
					title: 'Vendor',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						return JobTableFormatter.clientVendorCellRenderer(job, this.coreSrvc.dbSrvc, 'VENDOR')
					},
				},
				{
					// Work Tax Location
					targets: JobTableColumnIndex.workTaxLocation,
					visible: this.showTaxLocationColumn,
					searchable: this.showTaxLocationColumn,
					title: 'Tax Location',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						const location = job.work_tax_location
						return location ? `<div class="table-details" style="min-width:200px">${location}</div>` : ''
					},
				},
				{
					// Lock
					targets: JobTableColumnIndex.syncLock,
					visible: this.coreSrvc.prefSrvc.data.jobColSyncLockVisible && this.coreSrvc.dbSrvc.jobSrvc.hasSyncLockRecords(),
					searchable: false,
					title: 'Lock',
					data: null,
					render: (rec: JobRecord, t, f, m) => {
						if (rec.sync_lock) {
							return `<span style="display: none;">0</span><i class="far fa-lock table-ico-sync-lock item-tooltip" title="Integration Lock"></i>`
						} else {
							return ``
						}
					},
				},
				{
					// Row Actions
					targets: JobTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',

					render: (rec: JobRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = this.accessHelper.isMyRecord(recordId, 'job')
						const isSystemJob = this.isSystemJob(rec)
						const isSyncLocked = rec.sync_lock
						const hasAuditLog = rec.updated ? true : false

						const canAccessEdit = this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord) && !isSystemJob && !isSyncLocked
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const canClone = canAccessEdit && !isSystemJob
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canClone)

						const auditLink = TableActionFormatter.auditLinkGlobal('job', recordId, rec.description, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${deleteLink}${auditLink}</span>`
					},
				},
				{
					// Bulk Select
					targets: JobTableColumnIndex.bulkSelect,
					visible: this.batchManager.isActive,
					searchable: false,
					orderable: false,
					title: '',
					data: null,
					render: (job: JobRecord, t, f, m) => {
						return this.batchManager.formatSelectBox(job.id)
					},
				},
			],
			drawCallback: (settings) => {
				// log('drawCallback')
				this.jobsTable?.columns.adjust().responsive.recalc()
				this.jobsTable?.fixedHeader.adjust()
				this.enableTooltips()
				this.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
			createdRow: (row, data: any, dataIndex) => {
				if (this.coreSrvc.dbSrvc.jobSrvc.viewManager.currentView !== 'ACTIVE') {
					if (!data.active) {
						$(row).addClass('job-disabled')
					}
				}
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('jobsTable', this.jobsTable)
	}
}
