import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, log } from '@app/helpers'
import { AdpIvrApiLogViewManager, BaseViewManager, DialogManager, SectionSwitcherConfig } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import { AdpIvrApiLogTableComponent } from './adp-ivr-api-log-table/adp-ivr-api-log-table.component'

import moment from 'moment-timezone'

@Component({
	selector: 'app-adp-ivr-api-log',
	standalone: false,

	templateUrl: './adp-ivr-api-log.component.html',
	styleUrl: './adp-ivr-api-log.component.scss',
})
export class AdpIvrApiLogComponent implements OnInit, AfterViewInit {
	switcherConfig = new SectionSwitcherConfig()
	showMoreInfo = false

	timezoneOptions: Array<SelectItem> = []

	@ViewChild('adpIvrApiLogTable') adpIvrApiLogTable: AdpIvrApiLogTableComponent

	constructor(private coreSrvc: CoreService) {
		if (!this.viewManager.timezone) {
			this.viewManager.timezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
		}

		this.setupTimezoneDropdown()
	}

	get viewManager(): AdpIvrApiLogViewManager {
		return this.coreSrvc.dbSrvc.adpSrvc.adpIvrApiLogViewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.adpSrvc.adpIvrApiLogRecordsLastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'RESOURCE')
	}
	canPerformAction(action) {
		return true
		// return this.accessHelper.canPerformAction(action, false)
	}

	toggleSectionHelp() {
		this.showMoreInfo = !this.showMoreInfo
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		// Update fixed headers for any tables this section has
		setTimeout(() => {
			const table: any = $('#adpIvrApiLogTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}

	public fetchAndReload() {
		this.adpIvrApiLogTable?.fetchAndReload()
	}

	createRecord() {
		this.adpIvrApiLogTable?.createRecord()
	}

	public openPrefsDialog() {
		this.adpIvrApiLogTable?.openPrefsDialog()
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return {
					label: tz.display_name ? tz.display_name : tz.zone_name,
					value: tz.zone_name,
				}
			})
		this.timezoneOptions = timezones
	}

	public setDayViewDate(date: Date) {
		this.viewManager.dayViewDate = date
		this.fetchAndReload()
	}

	// End Tab Switcher Methods

	public toggleDayView() {
		// this.incidentLogTable?.resetPage(false)
		if (this.viewManager.dayViewDate) {
			this.viewManager.dayViewDate = null
		} else {
			this.viewManager.dayViewDate = new Date()
		}
		this.fetchAndReload()
	}
	public previousDay() {
		const currentMom = moment(this.viewManager.dayViewDate).subtract(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public nextDay() {
		const currentMom = moment(this.viewManager.dayViewDate).add(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public dayViewPickerClosed() {
		log('Day view picker closed')
		this.fetchAndReload()
	}
}
