import { AfterContentInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DisplayHelper, log } from '@app/helpers'
import { DataAccessRequest } from '@app/models'
import { CoreService, VisualizationService } from '@app/services'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-viz-health-score-banner',
	templateUrl: './viz-health-score-banner.component.html',
	styleUrl: './viz-health-score-banner.component.scss',
	standalone: false,
})
export class VizHealthScoreBannerComponent implements OnInit, OnDestroy, AfterContentInit {
	isRefreshing = false
	@Input() fetchDataOnInit = false
	@Input() navigateOnClick = false

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
		private router: Router,
	) {
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.handleDataAccessErrorEvent(event)))
	}

	ngOnInit(): void {}

	ngAfterContentInit() {
		// Fetch and load the current company activity log data
		this.fetchData()

		// Refresh the score to perform an update
		setTimeout(() => {
			this.refreshScore(false, false)
		}, 500)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	get healthScore(): number {
		return this.vizSrvc.healthCenterManager.healthScore
	}

	public refreshScore(showAlert: boolean, forceUpdate: boolean) {
		this.isRefreshing = true
		// Don't use standard data access since we are dealing with a separate list than the
		// one used by dashboards which manages the company_activity_log list.
		const options = forceUpdate ? { force_update: true } : null
		const request = new DataAccessRequest('company_activity_log', 'update', options)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.vizSrvc.fetchHealthCenterData().then(() => {
				const healthRecords = this.vizSrvc.getHealthCenterActivityLogRecords()
				this.vizSrvc.healthCenterManager.updateScore(healthRecords)
				this.isRefreshing = false
				if (showAlert) this.coreSrvc.notifySrvc.notify('success', 'Refreshed', 'Your health center score has been refreshed.', 3)
				this.updateBannerStyles()
				this.cd.markForCheck()
			})
		})
	}

	public fetchData() {
		this.isRefreshing = true

		this.vizSrvc.fetchHealthCenterData().then(() => {
			const healthRecords = this.vizSrvc.getHealthCenterActivityLogRecords()
			this.vizSrvc.healthCenterManager.updateScore(healthRecords)
			this.isRefreshing = false
			this.updateBannerStyles()
			this.cd.markForCheck()
		})
	}

	public navigateToHealthCenter() {
		if (!this.navigateOnClick) return
		this.vizSrvc.setCurrentTab('HEALTH_CENTER')
		this.router.navigate(['/admin/analytics'])
	}

	public updateBannerStyles() {
		setTimeout(() => {
			// Get .overall-bar class and update it's background color and text color
			const fillBar = document.querySelector('.score-progress-fill') as HTMLElement
			if (!fillBar) return
			const score = this.healthScore
			const bgColor = DisplayHelper.progressBreakpointColor(score)
			// const bgColor = DisplayHelper.interpolateColor(score, '#6ab46c', '#b46a6a', -25)
			fillBar.style.backgroundColor = bgColor
		}, 100)
	}

	private handleDataAccessErrorEvent(event) {
		log('Got data access exception', event)
		const type = event?.errorType
		if (type === 'com.sst.ivr.lambda.exceptions.CALNoUpdateForAccountStatusException') {
			const errorMsg = event?.errorMessage
			log('Got CALNoUpdateForAccountStatusException Message', errorMsg)
			this.isRefreshing = false
			this.updateBannerStyles()
			this.cd.markForCheck()
		}
	}

	// public updateBannerStyles() {
	// 	setTimeout(() => {
	// 		// Get .score-progress-fill class and update its gradient background
	// 		const fillBar = document.querySelector('.score-progress-fill') as HTMLElement
	// 		if (!fillBar) return

	// 		const score = this.healthScore
	// 		const startColor = '#ba696f' // Define your end color here
	// 		const middleColor = '#bdb050' // 50% waypoint
	// 		const endColor = '#7ea763' // Define your start color here

	// 		// Calculate the gradient position based on the score (0 to 100%)
	// 		const gradientStop = `${score}%`

	// 		// Create a linear gradient based on the score
	// 		const gradient = `linear-gradient(to right, ${startColor} 0%, ${middleColor} 50%, ${endColor} ${gradientStop}, transparent ${gradientStop})`

	// 		fillBar.style.background = gradient
	// 	}, 100)
	// }
}
