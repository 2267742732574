<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Descriptions -->
		<div class="options-block">
			<div class="options-title-inline">Description</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<!-- Phone Number (E164) -->
		<app-form-phone-number id="adpIvrPhoneNumberInput" [manager]="phoneNumberManager" (showHelp)="showHelp($event)"></app-form-phone-number>

		<!-- External ID -->
		<div class="options-block">
			<div class="options-title-inline">External ID</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>

		<!-- Assign to Profile -->
		<div class="options-block">
			<div *ngIf="form.get('ivr_profile').value" class="float-right link-text" (click)="form.get('ivr_profile').setValue(null)">clear</div>
			<div class="options-title-inline">Assign to Profile</div>
			<p-select appScrollLockSelect [options]="profileOptions" formControlName="ivr_profile"></p-select>
		</div>
	</form>
</div>
