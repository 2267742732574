<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Data ...'"></app-activity-banner>

<div *ngIf="!isLoading && !editAction.isEditing">
	<div *ngIf="list.length === 0" class="alert alert-info alert-bg-gray" style="margin-bottom: 20px">
		This section displays employee responses to requests made through the onboarding system.
	</div>

	<div *ngIf="list.length > 0" style="margin-bottom: 16px">
		<div style="margin-bottom: 6px">
			<app-search-filter [debounceTimer]="350" [placeholder]="'filter responses'" (search)="performSearch($event)"></app-search-filter>
		</div>
		<div style="text-align: right">
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span> |
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span>
		</div>
	</div>

	<!-- No Records -->
	<div *ngIf="list.length === 0 || searchResults.length === 0" class="no-data-block">No Records Available</div>

	<!-- Records list -->
	<div *ngFor="let vm of list">
		<div *ngIf="vm.matchesSearch" class="card list-card list-card-no-shadow">
			<div class="card-body list-card-body">
				<div class="list-card-header" (click)="vm.isExpanded = !vm.isExpanded">
					<div class="float-right lc-icon-wrap">
						<i
							class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover lc-act-ico"
							title="Edit Record"
							(click)="actionIconClicked($event, 'EDIT', vm)"
						></i>
						<i
							class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover lc-act-ico"
							title="Delete Record"
							(click)="actionIconClicked($event, 'DELETE', vm)"
						></i>
						<i
							class="far fa-database act-ico act-ico-dark-hover lc-act-ico"
							[class.act-ico-highlight]="false"
							title="Audit Record"
							(click)="actionIconClicked($event, 'AUDIT', vm)"
						></i>
						<i
							class="fas act-ico act-ico-dark-hover lc-act-ico"
							[class.fa-chevron-right]="!vm.isExpanded"
							[class.fa-chevron-down]="vm.isExpanded"
							[class.act-ico-highlight]
							title="Expand Record"
							(click)="actionIconClicked($event, 'EXPAND', vm)"
						></i>
					</div>
					<div>
						<div class="lc-title tts-hl-2">{{ vm.record.description }}</div>
						<div *ngIf="vm.record.skipped" style="margin-top: 10px; font-weight: 500; color: chocolate">Employee skipped this request</div>

						<div class="list-card-status" style="margin-top: 12px">
							<div
								class="float-right approval-state"
								[class.tts-hl-orange]="vm.record.approval_state === 'PENDING'"
								[class.tts-hl-green]="vm.record.approval_state === 'APPROVED'"
								[class.tts-hl-red]="vm.record.approval_state === 'REJECTED'"
								[class.tts-hl-red]="vm.record.approval_state === 'EXPIRED'"
							>
								{{ vm.record.approval_state }}
							</div>
							<div class="lc-label-basic">Approval Status</div>
						</div>
					</div>
				</div>

				<div *ngIf="vm.isExpanded && !vm.isConfirmingDelete">
					<div style="margin-top: 16px">
						<div class="lc-label-basic">Last update from employee on</div>
						<div class="lc-subtitle">{{ vm.record.employee_last_update | dateFormatter: 'ddd MMM, Do, YYYY [at] ' + timeFormatter }}</div>
					</div>

					<div
						class="alert lc-alert-block"
						[class.alert-warning]="vm.record.approval_state === 'PENDING'"
						[class.alert-success]="vm.record.approval_state === 'APPROVED'"
						[class.alert-danger]="vm.record.approval_state === 'EXPIRED'"
						style="margin: 16px 0px 0px 0px"
					>
						<div class="lc-alert-title">Employee Instructions</div>
						<div class="lc-alert-content">{{ vm.empInstructions ?? '- no instructions -' }}</div>
					</div>

					<div *ngIf="vm.empNotes" class="alert alert-bg-gray lc-alert-block">
						<div class="lc-alert-title">Employee Notes</div>
						<div *ngIf="vm.empNotes" class="lc-alert-content">{{ vm.empNotes }}</div>
					</div>

					<div *ngIf="vm.adminNotes" class="alert alert-bg-gray lc-alert-block">
						<div class="lc-alert-title">Admin Notes</div>
						<div *ngIf="vm.adminNotes" class="lc-alert-content">{{ vm.adminNotes }}</div>
					</div>
				</div>

				<div *ngIf="vm.isConfirmingDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary delete-confirm-left-btn" (click)="vm.isConfirmingDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger delete-confirm-right-btn" (click)="confirmDelete(vm)">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<span *ngIf="vm.isDeleting"><i class="fa fa-loader fa-spin"></i></span>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-onboard-log-edit
	*ngIf="editAction.isEditing"
	[recordId]="editAction.recordId"
	[action]="editAction.action"
	[dialogManager]="dialogManager"
	(recordUpdated)="recordUpdated($event)"
>
</app-onboard-log-edit>
