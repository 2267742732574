<div style="font-size: 1.3em; font-weight: 600; margin-top: 25px">
	<div *ngIf="mergeCandidate.isMergeCandidate && mergeCandidate.isMerged" style="color: green">Job sites merged - no issues</div>
	<div *ngIf="mergeCandidate.isMergeCandidate && !mergeCandidate.isMerged" style="color: green">Merged job site candidate</div>
	<div *ngIf="!mergeCandidate.isMergeCandidate" style="color: firebrick">
		<div>Merged job site fixes needed</div>
		<div style="margin-bottom: 20px">
			<span class="badge text-bg-primary np-link" style="cursor: pointer" (click)="gotoJobSites()"> view job sites </span>
			<span class="badge text-bg-primary np-link" style="cursor: pointer; margin-left: 8px" (click)="showIssues = !showIssues">
				{{ showIssues ? 'hide issues' : 'show issues' }}</span
			>
		</div>
	</div>
</div>

<div *ngIf="showIssues">
	<!-- Checklists using job site filter -->
	<div *ngIf="mergeCandidate.checklistsUsingJobSiteFilter.length > 0" class="issue-block">
		<div class="tts-hl-1" style="font-size: 1.3em; font-weight: 600; margin-bottom: 12px">Checklists using job site filter</div>
		<div *ngFor="let checklistEntry of mergeCandidate.checklistsUsingJobSiteFilter" class="card" style="margin-bottom: 12px">
			<div>
				<span class="badge text-bg-primary np-link merge-btn" style="font-size: 0.8rem; cursor: pointer" (click)="fixChecklist(checklistEntry)"
					>Fix</span
				><span class="tts-hl-2" style="font-weight: 600; margin-left: 8px">Checklist:</span>
				{{ checklistEntry.name }}
			</div>
		</div>
	</div>

	<!-- Reports using job site filter -->
	<div *ngIf="mergeCandidate.reportsWithJobSiteFilter.length > 0" class="issue-block">
		<div class="tts-hl-1" style="font-size: 1.3em; font-weight: 600; margin-bottom: 12px">Reports using job site filter</div>
		<div *ngFor="let report of mergeCandidate.reportsWithJobSiteFilter" class="card" style="margin-bottom: 12px">
			<div>
				<span class="badge text-bg-primary np-link merge-btn" style="font-size: 0.8rem; cursor: pointer" (click)="fixReport(report)">Fix</span
				><span class="tts-hl-2" style="font-weight: 600; margin-left: 8px">Report:</span>
				{{ report.name }}
			</div>
		</div>
	</div>

	<!-- Job sites with multiple jobs -->
	<div *ngIf="mergeCandidate.sitesWithMultipleJobs.length > 0" class="issue-block">
		<div class="tts-hl-1" style="font-size: 1.3em; font-weight: 600; margin-bottom: 12px">Job Sites with multiple jobs</div>
		<div *ngFor="let siteEntry of mergeCandidate.sitesWithMultipleJobs" class="card" style="margin-bottom: 12px">
			<div style="margin-bottom: 8px"><span class="tts-hl-2" style="font-weight: 600">Site Name:</span> {{ siteEntry.site.description }}</div>
			<div *ngFor="let jobEntry of siteEntry.jobs">
				<span
					class="badge text-bg-primary np-link merge-btn"
					[class.hide-btn]="jobEntry.description === siteEntry.site.description"
					style="font-size: 0.8rem; cursor: pointer"
					(click)="splitJob(jobEntry)"
					>Fix</span
				><span class="tts-hl-2" style="font-weight: 600; margin-left: 8px">Job Name:</span>
				{{ jobEntry.description }}
			</div>
		</div>
	</div>

	<!-- Job sites with different job names -->
	<div *ngIf="mergeCandidate.sitesWithDifferentJobNames.length > 0" class="issue-block">
		<div class="tts-hl-1" style="font-size: 1.3em; font-weight: 600; margin-bottom: 12px">Job Sites with different job names</div>
		<div>
			<div *ngFor="let entry of mergeCandidate.sitesWithDifferentJobNames" class="card" style="margin-bottom: 12px">
				<div>
					<span class="badge text-bg-primary np-link merge-btn" style="font-size: 0.8rem; cursor: pointer" (click)="mergeName('SITE', entry)"
						>Use</span
					><span class="tts-hl-2" style="font-weight: 600; margin-left: 8px">Site Name:</span>
					{{ entry.site.description }}
				</div>
				<div>
					<span class="badge text-bg-primary np-link merge-btn" style="font-size: 0.8rem; cursor: pointer" (click)="mergeName('JOB', entry)"
						>Use</span
					><span class="tts-hl-2" style="font-weight: 600; margin-left: 8px">Job Name:</span>
					{{ entry.job.description }}
				</div>
			</div>
		</div>
	</div>

	<!-- Job sites without jobs -->
	<div *ngIf="mergeCandidate.sitesWithoutJobs.length > 0" class="issue-block">
		<div class="tts-hl-1" style="font-size: 1.3em; font-weight: 600; margin-bottom: 12px">Job Sites without jobs</div>
		<ul>
			<li *ngFor="let site of mergeCandidate.sitesWithoutJobs">{{ site.description }}</li>
		</ul>
	</div>

	<!-- Jobs without job sites -->
	<div *ngIf="mergeCandidate.jobsWithoutSites.length > 0" class="issue-block">
		<div class="tts-hl-1" style="font-size: 1.3em; font-weight: 600; margin-bottom: 12px">Jobs without job sites</div>
		<ul>
			<li *ngFor="let job of mergeCandidate.jobsWithoutSites">{{ job.description }}</li>
		</ul>
	</div>
</div>

<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="showMigrationDialog"
	[modal]="false"
	[style]="{ width: '250px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
>
	<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: 600">
		<i class="fas fa-loader fa-spin"></i> Processing...
	</div>
</p-dialog>
