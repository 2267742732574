import { IFileUploadsMetadata, RecordTagContainer } from '@app/models'
import { ParsedNumber, isValidNumber, parseNumber } from 'libphonenumber-js'
import { DisplayHelper } from '../display'

interface HasTagJson {
	tags_json: string
}

export class GeneralTableHelper {
	static makeFilesCountBubble(recordId: number, metaData: IFileUploadsMetadata, bridgeTable: string, clickHandler: string) {
		const count = metaData.fileCount
		const hasExpiredFiles = metaData.hasExpiredFiles
		const flagClass = hasExpiredFiles ? 'table-modal-btn-flag' : ''
		const sortString = GeneralTableHelper.leftPadSortString(count, 3)

		return count > 0
			? `${sortString}<div class="btn btn-sm btn-default table-btn-files table-modal-btn-hl ${flagClass}" onclick="${bridgeTable}.${clickHandler}(${recordId})">${count}</div>`
			: `${sortString}<div class="btn btn-sm btn-default table-btn-files table-modal-btn" onclick="${bridgeTable}.${clickHandler}(${recordId})"><i class="fa fa-plus"></i></div>`
	}

	static makeC2CButtons(recordId: number, column: string, bridgeTable: string, clickHandler: string) {
		const textClickHandler = `${bridgeTable}.${clickHandler}(${recordId},'${column}','TEXT')`
		const callClickHandler = `${bridgeTable}.${clickHandler}(${recordId},'${column}','CALL')`
		const logClickHandler = `${bridgeTable}.${clickHandler}(${recordId},'${column}','LOG')`
		return `
			<div>
			<i title="Place Call" class="far fa-phone-office c2c-ico link-text" onclick="event.stopPropagation();${callClickHandler}"></i>
			<i title="Send Text" class="far fa-message-dots c2c-ico link-text" onclick="event.stopPropagation();${textClickHandler}"></i>
			<i title="View Comm Log" class="far fa-bars-staggered c2c-ico link-text" onclick="event.stopPropagation();${logClickHandler}"></i>
			</div>
		`
	}

	static makePHoneNumberWithC2C(id: number, phoneE164: string, column: string, canAccess: boolean, bridgeTableName: string, clickHandler) {
		if (!phoneE164) return ''
		const parsedNumber = parseNumber(phoneE164) as ParsedNumber
		if (parsedNumber.country && isValidNumber(parsedNumber)) {
			const textClickHandler = `${bridgeTableName}.${clickHandler}(${id},'${column}','TEXT')`
			const callClickHandler = `${bridgeTableName}.${clickHandler}(${id},'${column}','CALL')`
			const logClickHandler = `${bridgeTableName}.${clickHandler}(${id},'${column}','LOG')`
			const c2cButtons = canAccess
				? `<div>
						<i title="Place Call" class="far fa-phone-office c2c-ico link-text" onclick="event.stopPropagation();${callClickHandler}"></i>
						<i title="Send Text" class="far fa-message-dots c2c-ico link-text" onclick="event.stopPropagation();${textClickHandler}"></i>
						<i title="View Comm Log" class="far fa-bars-staggered c2c-ico link-text" onclick="event.stopPropagation();${logClickHandler}"></i>
					</div>`
				: ''

			return `
			<div>${DisplayHelper.displayPhone(phoneE164)}</div>
			${c2cButtons}
			`
		} else {
			return '<strong style="color: firebrick">' + phoneE164 + '</strong>'
		}
	}

	static leftPadSortString(value: number, width: number): string {
		const numberString = value.toString()
		const zerosToAdd = Math.max(0, width - numberString.length) // Calculate the number of zeros needed
		const result = '0'.repeat(zerosToAdd) + `${numberString}` // Pad the number with zeros
		return `<div style="display:none">${result}</div>`
	}

	static updateColumn(table: any, col: number, enabled: boolean) {
		const evalEnabled = !!enabled // convert to boolean because it may be conditional expression with undefined as the result
		table?.column(col).visible(evalEnabled, false)

		// Modifying column searchability - must be better way
		// https://stackoverflow.com/questions/33402959/jquery-datatable-enable-disable-column-filtering-on-hide-show-after-initializa
		const colProps = table?.settings()?.[0]?.aoColumns?.[col]
		if (colProps) {
			colProps.bSearchable = evalEnabled
		}
	}

	static booleanIconRenderer(config: BooleanIconRendererConfig) {
		const value: any = config.value
		const leftPad: number = config.leftPad ?? 20
		const falseColor: string = config.falseColor ?? 'firebrick'
		const iconClassFalse: string = config.iconClassFalse ?? 'fa fa-ban'
		const trueColor: string = config.trueColor ?? 'green'
		const iconClassTrue: string = config.iconClassTrue ?? 'fa fa-check'

		const truthy = !!value
		if (truthy) {
			return `<span style="display: none;">0</span><i class="${iconClassTrue}" style="color: ${trueColor}; margin-left: ${leftPad}px;"></i>`
		} else {
			return iconClassFalse
				? `<span style="display: none;">1</span><i class="${iconClassFalse}" style="color: ${falseColor}; margin-left: ${leftPad}px;"></i>`
				: ''
		}
	}

	public static tags(rec: HasTagJson, expanded: boolean, constrainWidth: boolean): string {
		const tagContainer = new RecordTagContainer(rec.tags_json)
		const hasExpiredTag = tagContainer.hasExpiredTag()
		const tagLabels = tagContainer.getTagLabels()
		const expiredTag = hasExpiredTag ? '#expired' : ''
		const searchString = tagLabels
			.map((label) => `#${label}`)
			.join(':')
			.toUpperCase()

		if (expanded) {
			let result = ''
			for (const tag of tagContainer.tags) {
				let tagClass = tag.isExpired
					? 'table-tag table-tag-sm table-tag-danger'
					: tag.expire
						? 'table-tag table-tag-sm table-tag-success'
						: 'table-tag table-tag-sm'
				// const expiration = tag.expire
				// 	? tag.expireTooltip // DateTimeHelper.mediumDateFromDateString(tag.expire) + '<br>@ ' + DateTimeHelper.standardTimeFromDateString(tag.expire)
				// 	: ''
				if (tag.expire) tagClass += ' item-tooltip'
				// const expireLabel = tag.isExpired ? 'Expired' : 'Expires'
				const title = tag.expire ? `title="${tag.expireTooltip}"` : ''
				result += `
				<div class="badge ${tagClass} mb-1 mr-1" ${title} style="padding: 4px 6px"  data-bs-html="true">
					<span class="tag-label">${(tag.label ?? '').toUpperCase()}</span>
				</div>`
			}

			const tagCount = tagContainer.tags.length
			const widthMultiple = tagContainer.getTagLabels().join('').length
			const divisor = tagContainer.tags.length > 0 ? tagContainer.tags.length : 1
			const minWidth = Math.floor((widthMultiple * 42) / divisor)
			const minWidthHtml = '' // constrainWidth ? `width: ${minWidth}px; ` : ''
			const maxWidthHtml = constrainWidth ? `max-width: 320px; ` : ''
			// const minWidth = 100 + tagCount * 20

			return result
				? `
				<div class="d-flex flex-wrap justify-content-start" style="${minWidthHtml}${maxWidthHtml}margin-top: 2px;">${result}</div>
				<div style="display:none;">${searchString}${expiredTag}</div>`
				: ''
		} else {
			const tagIconClass = hasExpiredTag ? 'fa fa-tag table-tag-icon tag-icon-expired' : 'fa fa-tag table-tag-icon'
			const titleString = tagLabels.join('<br>').toUpperCase()
			return tagLabels.length > 0
				? `<i class="${tagIconClass} item-tooltip" data-bs-html="true" title="${titleString}"></i> <div style="display:none;">${searchString}${expiredTag}</div>`
				: ''
		}
	}
}

interface BooleanIconRendererConfig {
	value: any
	leftPad?: number
	falseColor?: string
	iconClassFalse?: string
	trueColor?: string
	iconClassTrue?: string
}
