import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'

import { SelectItem } from 'primeng/api'
import _ from 'lodash'
import { Global } from '@app/models/global'
import { PhoneHelper } from '@app/helpers'

@Component({
	selector: 'app-multi-select-details',
	templateUrl: './multi-select-details.component.html',
	styleUrls: ['./multi-select-details.component.scss'],
	standalone: false,
})
export class MultiSelectDetailsComponent implements OnInit {
	@Input() type: MultiSelectViewModelType = 'EMP'

	@Input() placeholder = 'Select Items'
	@Input() selectedItemsLabel = 'Items Selected: {0}'
	@Input() filterBy = 'label,data.external_id'
	@Input() group = false
	@Input() showToggleAll = true

	@Input() isRequired = false
	@Input() dropdownLabel = ''
	@Input() dropdownLabelStyle: Object = { color: 'inherit' }

	@Input() showFullList = false
	@Input() showInfoTip = false
	@Input() showClearBtn = false

	@Input() options: Array<SelectItem> = []

	@Input() model: Array<any> = []
	@Input() notFoundMsg = 'Record Not Found'

	@Output() modelChange = new EventEmitter<Array<any>>()
	@Output() showHelp = new EventEmitter<true>()

	constructor(public devDetect: DeviceDetectorService) {}

	selectedItems: Array<MultiSelectDetailsViewModel> = []

	ngOnInit(): void {
		this.updateState()
	}

	// This should be used for all options
	removeItem(vm: MultiSelectDetailsViewModel) {
		this.removeByValue(vm.value)
	}

	removeByValue(value: any) {
		const currentList = [...this.model]
		currentList.splice(this.model.indexOf(value), 1)
		this.model = currentList
		this.updateState()
	}

	updateState() {
		const selectedItems = this.model.map((id) => new MultiSelectDetailsViewModel(this.type, id, this.notFoundMsg))
		const notFound = selectedItems.filter((item) => item.label === this.notFoundMsg)
		const found = selectedItems.filter((item) => item.label !== this.notFoundMsg)
		const sortedFound = _.orderBy(found, ['label'], ['asc'])

		this.selectedItems = [...sortedFound, ...notFound]
		this.modelChange.next(this.model)
	}

	public clearBtnClicked() {
		this.model = []
		this.updateState()
	}
}

class MultiSelectDetailsViewModel {
	type: MultiSelectViewModelType

	value: string | number
	label: string
	active = true // Used to show inactive label

	constructor(type: MultiSelectViewModelType, value: number, notFoundMsg: string) {
		this.type = type
		this.value = value
		this.label = `${value}`

		if (type === 'EMP') {
			const emp = Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(value)
			this.value = value
			this.label = emp?.name ?? notFoundMsg
			this.active = emp ? emp.active : true // consider active by default
			return
		}
		if (type === 'SITE') {
			const site = Global.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(value)
			this.value = value
			this.label = site?.description ?? notFoundMsg
			return
		}
		if (type === 'JOB') {
			const job = Global.coreSrvc.dbSrvc.jobSrvc.getJobById(value)
			this.value = value
			this.label = job?.description ?? notFoundMsg
			this.active = job ? job.active : true // consider active by default
			return
		}
		if (type === 'USER') {
			const user = Global.coreSrvc.dbSrvc.settingSrvc.getUserForId(value)
			this.value = value
			if (user) {
				this.label = user?.first_name + ' ' + user?.last_name
			} else {
				this.label = notFoundMsg
			}
			return
		}
		if (type === 'CONTACT') {
			const contact = Global.coreSrvc.dbSrvc.contactSrvc.getContactById(value)
			this.value = value
			if (contact) {
				this.label = contact?.name
			} else {
				this.label = notFoundMsg
			}
			return
		}
		if (type === 'CLIENT' || type === 'VENDOR') {
			const org = Global.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(value)
			this.value = value
			if (org) {
				this.label = org?.name
			} else {
				this.label = notFoundMsg
			}
			return
		}
		if (type === 'PHONENUMBER') {
			const pn = Global.coreSrvc.dbSrvc.settingSrvc.getPhoneNumberRecordById(value)
			this.value = value
			if (pn) {
				this.label = PhoneHelper.formatPhoneFromE164(pn.number_e164) + ' / ' + pn.phoneNumberLabel
			} else {
				this.label = notFoundMsg
			}
			return
		}
	}
}

type MultiSelectViewModelType = 'EMP' | 'SITE' | 'JOB' | 'CONTACT' | 'CLIENT' | 'VENDOR' | 'USER' | 'STRING' | 'TAG' | 'PHONENUMBER'
