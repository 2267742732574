<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Prompt Code Order -->
		<div class="options-block" [class.input-required]="!form.get('prompt_order').value" style="margin-bottom: 40px">
			<div class="float-right" style="width: 80px">
				<p-select appScrollLockSelect [scrollHeight]="'400px'" [options]="promptCodeOrderOptions" formControlName="prompt_order"></p-select>
			</div>
			<div class="options-title" style="padding-top: 4px">Prompt Code Order</div>
		</div>

		<!-- Prompt Code Type -->
		<div class="options-block" [class.input-required]="!form.get('code_type').value">
			<div *ngIf="!form.get('code_type').value" class="float-right">(required)</div>
			<!-- <div *ngIf="form.get('code_type').value" class="float-right link-text" (click)="form.get('code_type').setValue('')">clear</div> -->
			<div class="options-title-inline">Code Type</div>
			<p-select appScrollLockSelect [options]="promptCodeTypeOptions" formControlName="code_type"></p-select>
		</div>

		<!-- Description -->
		<div class="options-block" [class.input-required]="!form.get('description').value">
			<div *ngIf="!form.get('description').value" class="float-right">(required)</div>
			<div *ngIf="form.get('description').value" class="float-right link-text" (click)="form.get('description').setValue(null)">clear</div>
			<div class="options-title-inline">Description</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>
		<!-- Prompt Code -->
		<div class="options-block" [class.input-required]="!form.get('code_value').value">
			<div *ngIf="!form.get('code_value').value" class="float-right">(required)</div>
			<div *ngIf="form.get('code_value').value" class="float-right link-text" (click)="form.get('code_value').setValue(null)">clear</div>
			<div class="options-title-inline">Code Value</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="code_value" />
		</div>

		<!-- External ID -->
		<div class="options-block">
			<div *ngIf="form.get('external_id').value" class="float-right link-text" (click)="form.get('external_id').setValue(null)">clear</div>
			<div class="options-title-inline">External ID</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>

		<!-- Assign to Profile -->
		<div class="options-block">
			<div *ngIf="form.get('ivr_profile').value" class="float-right link-text" (click)="form.get('ivr_profile').setValue(null)">clear</div>
			<div class="options-title-inline">Assign to Profile</div>
			<p-select appScrollLockSelect [options]="profileOptions" formControlName="ivr_profile"></p-select>
		</div>
	</form>
</div>
