import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core'
import {
	AdpIvrProfileRecord,
	CardViewAction,
	DatabaseTableName,
	DialogAuditAction,
	DialogEditAction,
	DialogManager,
	EditCompleteEvent,
	EditFormAction,
} from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'

@Component({
	selector: 'app-adp-ivr-profile-list',
	templateUrl: './adp-ivr-profile-list.component.html',
	styleUrl: './adp-ivr-profile-list.component.scss',
	standalone: false,
})
export class AdpIvrProfileListComponent implements AfterContentInit {
	resource: DatabaseTableName = 'adp_ivr_profile'
	searchText = ''

	isLoading = true

	editAction: DialogEditAction = { recordId: null, action: 'edit' as EditFormAction, isEditing: false }
	auditAction: DialogAuditAction = { resource: 'adp_ivr_profile', recordId: null, isAuditing: false }

	@Input() dialogManager: DialogManager

	list: Array<AdpIvrProfileRecordWrapper> = []

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {
		this.fetchAndReload()
	}

	get searchResults(): Array<AdpIvrProfileRecordWrapper> {
		return this.list.filter((r) => r.matchesSearch)
	}

	ngAfterContentInit() {
		this.dialogManager.submitBtnLabel = 'New'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.submitBtnAction = () => this.newRecord()
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfiles().map((rec) => new AdpIvrProfileRecordWrapper(rec))
		if (this.searchText) this.performSearch(this.searchText)
		this.isLoading = false
	}

	private fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			this.coreSrvc.dbSrvc.readTable('adp_ivr_profile').then(() => {
				this.loadData()
				resolve(true)
			})
		})
	}

	private resetDialog(): void {
		this.editAction = { recordId: null, action: 'edit', isEditing: false }
		this.auditAction = { resource: 'adp_ivr_profile', recordId: null, isAuditing: false }

		this.dialogManager.popStateAndApply()

		setTimeout(() => {
			this.dialogManager.restoreScrollPosition('main')
		}, 175)
	}

	public actionIconClicked(event: Event, action: CardViewAction, vm: AdpIvrProfileRecordWrapper) {
		event.stopPropagation()
		switch (action) {
			case 'EDIT':
				this.prepareDialogForEdit(vm, 'edit', 'Edit Record')
				break
			case 'CLONE':
				this.prepareDialogForEdit(vm, 'clone', 'Clone Record')
				break
			case 'DELETE':
				this.list.forEach((r) => (r.isConfirmingDelete = false))
				vm.isConfirmingDelete = true
				break
			case 'AUDIT':
				this.prepareForRecordAudit(vm)
				break
			case 'EXPAND':
				vm.isExpanded = !vm.isExpanded
				break
		}
	}

	public newRecord() {
		const vm = new AdpIvrProfileRecordWrapper(new AdpIvrProfileRecord())
		this.prepareDialogForEdit(vm, 'new', 'New Record')
	}

	private prepareDialogForEdit(vm: AdpIvrProfileRecordWrapper, action: EditFormAction, header: string): void {
		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.headerLabel = header
		this.editAction.recordId = vm.record.id
		this.editAction.action = action
		this.editAction.isEditing = true
	}

	private prepareForRecordAudit(vm: AdpIvrProfileRecordWrapper): void {
		this.auditAction.recordId = vm.record.id
		this.auditAction.resource = this.resource

		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnLabel = 'Done'

		this.auditAction.isAuditing = true
	}

	public recordUpdated(event: EditCompleteEvent) {
		log('recordUpdated', event)
		const action = event.action

		if (action === 'new' || action === 'clone') {
			this.fetchAndReload().then(() => {
				this.resetDialog()
			})
		}

		if (action === 'edit') {
			const recordId = event.recordId
			const record = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfileForId(recordId)
			const vm = this.list.find((r) => r.record.id === record.id)
			vm.record = record
			vm.updateModel()
			this.resetDialog()
		}
	}

	public deleteRecord(card: AdpIvrProfileRecordWrapper) {
		card.isConfirmingDelete = true
	}

	public confirmDelete(card: AdpIvrProfileRecordWrapper) {
		card.isDeleting = true
		this.coreSrvc.dbSrvc.deleteRecord('adp_ivr_profile', card.record.id).then((success) => {
			this.coreSrvc.dbSrvc.removeLocalRecord('adp_ivr_profile', card.record.id)
			this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfiles().map((rec) => new AdpIvrProfileRecordWrapper(rec))
		})
	}

	///////////////////////
	// Search Management //
	///////////////////////

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchText = lcText
		this.list.forEach((card) => {
			card.peformSearch(lcText)
		})
	}

	///////////////////
	// Miscellaneous //
	///////////////////

	public toggleExpansion(isExpanded: boolean) {
		this.list.forEach((rec) => (rec.isExpanded = isExpanded))
	}
}

class AdpIvrProfileRecordWrapper {
	record: AdpIvrProfileRecord
	isConfirmingDelete = false
	isDeleting = false
	isExpanded = false

	profileName = ''
	externalId = ''

	searchString = ''
	matchesSearch = true

	constructor(record: AdpIvrProfileRecord) {
		this.record = record
		this.updateModel()
	}

	public updateModel() {
		this.profileName = this.record.description
		this.externalId = this.record.external_id
		this.searchString = `${this.profileName}***${this.externalId}`.toLowerCase()
	}

	public peformSearch(searchText: string) {
		if (!searchText) {
			this.matchesSearch = true
			return
		}

		this.matchesSearch = this.searchString.includes(searchText)
	}
}
