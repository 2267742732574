import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
	DialogManager,
	FileUploadManager,
	ScheduledAnnouncementRecord,
	ScheduledAnnouncement,
	SelectorPermissionName,
	DataAccessRequest,
	HelpDialogMessage,
	EmployeeRecord,
	ScheduledAnnouncementEmployeeInfoCard,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { environment } from '@env/environment'

import { DateTimeHelper, FormHelper, RecordBuilder, log } from '@app/helpers'
import { SelectItem } from 'primeng/api/selectitem'
import { AccessHelper } from '@app/helpers/access'

import moment from 'moment-timezone'
import _ from 'lodash'
import { MultiSelectDetailsComponent } from '@app/components/multi-select-details/multi-select-details.component'

@Component({
	selector: 'app-announcements-scheduled-edit',
	templateUrl: './scheduled-edit.component.html',
	styleUrls: ['./scheduled-edit.component.scss'],
	standalone: false,
})
export class AnnouncementsScheduledEditComponent implements OnInit, AfterViewInit, AfterContentInit {
	environment = environment
	accessHelper: AccessHelper

	isNew = true
	isUpdating = false
	isInternalUser = false

	isBuildingPreview = false
	showPreview = false
	previewList: Array<ScheduledAnnouncementEmployeeInfoCard> = []
	previewExcludeIds: Array<number> = []

	is12Hour = DateTimeHelper.format12Hour
	datePickerFormat = this.is12Hour ? 'YYYY-MM-DD [@] h:mm a' : 'YYYY-MM-DD [@] HH:mm'

	targetDropdownOptions: Array<SelectItem> = targetDropdownOptions
	actionDropdownOptions: Array<SelectItem> = actionDropdownOptions
	employeeDropdownOptions: Array<SelectItem> = []
	jobsiteDropdownOptions: Array<SelectItem> = []
	employeeStatusOptions: Array<SelectItem> = []
	departmentDropdownOptions: Array<SelectItem> = []
	employeeTagMultiSelectOptions: Array<SelectItem> = []
	jobDropdownOptions: Array<SelectItem> = []

	fileUploadManager: FileUploadManager

	smsCharLimit = 140

	expandeManageFilters = true
	expandManageAttachments = false

	showSendConfirmation = false

	// When providedTargetEmpIds is populated by another component, hide the Target type dropdown
	// And use the employee IDs provided

	@Input() sendNow = true
	@Input() autoCloseDialog = true
	@Input() providedTargetEmpIds = [] // If this is provided, another component is using this and setting up target list
	@Input() dialogManager = new DialogManager()

	@Input() record: ScheduledAnnouncement
	@Output() recordSaved = new EventEmitter<boolean>()

	@ViewChild('selectedEmployeesMultiselect') selectedEmployeesMultiselect: MultiSelectDetailsComponent

	constructor(public coreSrvc: CoreService) {
		this.setupAccessPermissions()
		this.setupFileUploadManager()

		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		// Set sms character limit
		this.smsCharLimit = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.sms_char_limit ?? 140

		// Setup job dropdown
		this.jobDropdownOptions = this.coreSrvc.dbSrvc.jobSrvc.getDropdownData(true, false)
	}

	get activeCount(): number {
		const empIds = this.record.filter.emp_id ?? []
		return empIds.map((empId) => this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)).filter((emp) => emp.active).length
	}

	get inactiveCount(): number {
		const empIds = this.record.filter.emp_id ?? []
		return empIds.map((empId) => this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)).filter((emp) => !emp.active).length
	}

	get previewActiveCount(): number {
		return this.previewList.filter((card) => card.employee.active).length
	}

	get previewInactiveCount(): number {
		return this.previewList.filter((card) => !card.employee.active).length
	}

	get isAuditMode(): boolean {
		return this.coreSrvc.auditMode
	}

	get attachmentCounter(): number {
		return this.fileUploadManager?.processedFiles.length ?? 0
	}

	get isTargetListProvided(): boolean {
		return this.providedTargetEmpIds.length > 0
	}

	get currentMsgLength(): number {
		const body = this.record?.body_en ?? ''
		return body.length
	}

	get updatingMessage(): string {
		if (this.sendNow) {
			return 'Sending Announcement...'
		} else {
			return this.isNew ? 'Saving Announcement...' : 'Updating Announcement...'
		}
	}

	get isEmployeesDropdownVisible(): boolean {
		const annType = this.record.type
		if (annType === 'SELECTEDEMPLOYEES') {
			return true
		}
		return false
	}

	get isDepartmentDropdownVisible(): boolean {
		return this.departmentDropdownOptions.length > 0 && !this.isEmployeesDropdownVisible
	}

	get isEmployeeTagFilterAvailable(): boolean {
		const filterCount = this.record?.filter?.employee_tags.length ?? 0
		const tagCount = this.employeeTagMultiSelectOptions.length
		return filterCount + tagCount > 0
	}

	get isDepartmentFilterAvailable(): boolean {
		const filterCount = this.record?.filter?.departments.length ?? 0
		const deptCount = this.departmentDropdownOptions.length
		return filterCount + deptCount > 0
	}

	get shouldShowNoGeoCodeAlert(): boolean {
		if (!this.record.filter.job_id) return false
		const jobId = this.record.filter.job_id
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(job?.location_id)
		return !site?.geo_coded || site?.geo_coding_failed
	}

	get isScheduledDatePast(): boolean {
		const schedDate = this.record.sendDate
		if (schedDate) {
			return moment(schedDate).isBefore(moment())
		}
		return false
	}

	get isFormValid(): boolean {
		if (!this.record) return true
		if (!this.sendNow && !this.record.sendDate && !this.record.save_as_template) return false
		if (this.record.filter.job_distance && !this.record.filter.job_id) return false

		return this.validateForm(false) && !this.fileUploadManager.isWorking
	}

	ngOnInit(): void {
		if (this.record) this.isNew = false
		this.record = this.record?.clone() || new ScheduledAnnouncement()

		// Setup default distance units necessary
		if (!this.record.filter.job_distance || !this.record.filter.job_distance_units) {
			const units = this.coreSrvc.dbSrvc.settingSrvc.getCompany().default_units
			const distUnits = units === 'IMPERIAL' ? 'MI' : 'KM'
			this.record.filter.job_distance_units = distUnits
		}

		this.fileUploadManager.processedFiles = this.record.files
		if (this.record.sendDate) {
			this.sendNow = false
		}
		this.setupEmployeeDropdown()

		// Clear resigned and terminated employees from record filter emp_ids
		this.record.filter.emp_id =
			this.record.filter.emp_id?.filter((empId) => {
				const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
				return emp && emp.status !== 'RESIGNED' && emp.status !== 'TERMINATED'
			}) ?? []

		// If target list is provided, use it to setup the target filter
		if (this.isTargetListProvided) {
			this.record.filter.emp_id = [...this.providedTargetEmpIds]
		}

		// Setup departments dropdown
		const includeDepts = this.record?.filter?.departments ?? []
		this.departmentDropdownOptions = this.coreSrvc.dbSrvc.empSrvc.getDepartmentsDropdown(includeDepts)
		this.employeeStatusOptions = [
			{ label: 'Active', value: 'ACTIVE' },
			{ label: 'Inactive', value: 'INACTIVE' },
			{ label: 'Any Status', value: null },
		]

		// Setup employees tag filter
		const includeTags = this.record.filter.employee_tags ?? []
		this.employeeTagMultiSelectOptions = this.coreSrvc.dbSrvc.empSrvc.getTagDropdownData(includeTags)
	}

	ngAfterViewInit(): void {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'announcement')
	}

	setupFileUploadManager() {
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.fileLimit = 3
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'announcements/scheduled')
		fileUploadManager.supportedMimeTypes = ['text/plain', 'text/html', 'application/pdf', 'image/jpeg', 'image/png', 'image/gif']
		this.fileUploadManager = fileUploadManager
	}

	setupEmployeeDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('announcement')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = this.isTargetListProvided ? this.providedTargetEmpIds : this.record.filter.emp_id || []
		const options = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)
		const filteredOptions = options.filter((edd) => edd.data.status !== 'RESIGNED' && edd.data.status !== 'TERMINATED')
		this.employeeDropdownOptions = filteredOptions
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid
		this.dialogManager.submitBtnAction = () => this.saveRecord()
		this.updateSubmitButtonLabel()
	}

	updateSubmitButtonLabel() {
		this.dialogManager.submitBtnLabel = this.sendNow ? 'Send' : 'Save'
	}

	updateSaveAsTemplateOption(value: true | false) {
		this.sendNow = value
		if (value === true) {
			this.record.sendDate = null
		}
		this.updateSubmitButtonLabel()
	}

	updateManageList(value: 'ALLEMPLOYEES' | 'SELECTEDEMPLOYEES') {
		this.record.type = value
		if (value === 'SELECTEDEMPLOYEES') {
			this.record.filter.emp_active_status = null
			this.record.filter.departments = []
			this.record.filter.employee_tags = []
			this.record.filter.job_id = null
			this.record.filter.job_distance = null
			this.record.filter.jobsite_id = []
		}
		if (value === 'ALLEMPLOYEES') {
			this.record.filter.emp_active_status = 'ACTIVE'
		}
	}

	saveRecord() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.record)

		this.isUpdating = true

		const record = this.makeUpdateRecord()

		const recordId = this.record.id
		const action = recordId ? 'update' : 'insert'

		this.postRecord(action, record)
			.then((result) => {
				log('Save Record', action, result)
				this.recordSaved.emit(true)
				if (this.autoCloseDialog) {
					this.dialogManager.backBtnAction()
					this.dialogManager.isDialogVisible = false
				}
			})
			.catch((err) => {
				this.isUpdating = false
				log('Encountered error saving record')
			})
	}

	makeUpdateRecord() {
		this.record.files = this.fileUploadManager.processedFiles
		this.record.subject = this.record.subject || 'Scheduled Announcement'

		// Setup message body
		this.record.body_en = this.record.body_en
		if (this.record.action === 'SEND_EMP_SCHEDULE') {
			this.record.body_en = 'Employee Schedule Link'
		}
		if (this.record.action === 'SEND_EMP_AUTH_LINK') {
			this.record.body_en = 'Employee App Link'
		}

		const updateRecord = this.record.clone().cleanUpFilterData().buildUpdateRecord()

		// If not marked to send now or scheduled, automatically save as a template
		if (!this.sendNow && !this.record.sendDate) {
			updateRecord.save_as_template = true
		}
		return updateRecord
	}

	validateForm(alert: boolean): boolean {
		// if (!this.record.subject) {
		// 	return false
		// }
		// If it's not send now
		// if (!this.sendNow && !this.record.sendDate && !this.record.save_as_template) {
		// 	return false
		// }

		if (this.record.action === 'CUSTOM_MESSAGE' && !this.record.body_en) {
			return false
		}
		if (this.record.action === 'CUSTOM_MESSAGE' && this.record.body_en.length > this.smsCharLimit) {
			return false
		}
		if (this.record.type === 'SELECTEDEMPLOYEES' && this.record.filter.emp_id.length === 0) {
			return false
		}
		// No issues - form is valid
		return true
	}

	private postRecord(action: 'insert' | 'update', record: any): Promise<Array<ScheduledAnnouncement>> {
		const request = new DataAccessRequest('announcements_scheduled', action, null, record)
		return this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Scheduled Announcement Save Result', result)
			const data: Array<any> = result.data
			this.coreSrvc.dbSrvc.annSrvc.addOrUpdateScheduledAnnouncementRecords(data)
			if (this.sendNow) {
				const ann: ScheduledAnnouncementRecord = data[0]
				if (ann) {
					this.sendAnnouncementNow(ann)
				}
			}
			return Promise.resolve(data)
		})
		// .catch(error => { log('ERROR - postRecord:', error); return Promise.resolve([]) })
	}

	private sendAnnouncementNow(announcement: ScheduledAnnouncementRecord) {
		log('Sending now for', announcement.id)
		const announcementId = `${announcement.id}`
		const request = {
			table: 'bogus',
			operation: 'scheduled_announcement_send_now',
			id: announcementId,
			confirm_send: true,
			exclude_emp_ids: this.previewExcludeIds.join(','),
		}
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((lambdaResult) => {
			log('Send Now Result', lambdaResult)
			// this.coreSrvc.notifySrvc.notify('success', 'Sending Announcement', 'Your announcement is now being sent.', 3)
			const targetList = lambdaResult?.data?.targetList
			if (targetList) {
				const targetCount = targetList.length
				if (targetList.length > 0) {
					this.coreSrvc.notifySrvc.notify(
						'success',
						'Sending Announcement',
						`Your announcement is now being sent to ${targetCount} employee${targetCount > 1 ? 's' : ''}.`,
						3,
					)
				} else {
					this.coreSrvc.notifySrvc.notify('success', 'Sending Announcement', 'No employees were targeted for this announcement.', 3)
				}
			}
		})
	}

	public previewTargetList() {
		this.previewExcludeIds = []
		if (this.record.filter.job_distance && !this.record.filter.job_id) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Form', 'You must specify a job if you are filtering by distance.', 3)
			return
		}

		const record = this.makeUpdateRecord()
		const request = new DataAccessRequest('none', 'announcement_preview', null, record)
		this.isBuildingPreview = true
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			const records = RecordBuilder.buildRecords(data)
			const sortedRecords = _.orderBy(records, ['name'], ['asc'])
				.map((rec) => new EmployeeRecord(rec))
				.map((emp) => new ScheduledAnnouncementEmployeeInfoCard(emp))
			this.previewList = sortedRecords

			this.dialogManager.saveScrollPosition('previewTargetList')
			this.dialogManager.pushState()
			this.dialogManager.isBackBtnVisible = true
			// this.dialogManager.isSubmitBtnVisible = false
			this.dialogManager.cancelBtnLabel = 'Back'
			this.dialogManager.backBtnAction = () => this.previewDone()
			this.dialogManager.cancelBtnAction = () => this.previewDone()
			this.showPreview = true
			this.dialogManager.scrollToTop()
			setTimeout(() => {
				this.coreSrvc.displaySrvc.enableAllTooltips()
			}, 150)
			log('Preview Target List', records)
		})
	}

	// Remove a card from the targeted employee preview list
	public removeCard(card: ScheduledAnnouncementEmployeeInfoCard) {
		this.previewExcludeIds.push(card.employee.id)
		this.record.filter.emp_id = this.record.filter.emp_id.filter((empId) => empId !== card.employee.id)
		this.previewList = this.previewList.filter((c) => c.employee.id !== card.employee.id)

		// Remove the employee from the selected employees multiselect
		this.selectedEmployeesMultiselect?.removeByValue(card.employee.id)
	}

	public previewDone() {
		this.showPreview = false
		this.isBuildingPreview = false
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition('previewTargetList')
		this.previewExcludeIds = []
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'sendNow':
				help.header = 'Send Immediately'
				help.message = 'When checked, this announcement will be immediately sent when saved or updated.'
				break
			case 'description':
				help.header = 'Description'
				help.message =
					'Enter a short description of the announcement which will be used to identify this announcement template in the announcements list. The description will only be visible to admins and not employees .'
				break
			case 'message':
				help.header = 'Message'
				help.message =
					'Enter the English language text for the announcement here. When saved, this announcement message will be automatically translated into all supported languages. This message will be sent to employees.'
				break
			case 'emp_active_status':
				help.header = 'Employee Status'
				help.message = `Select an employee status or use 'Any Status' if you wish to target both active and inactive employees. Generally you will want to target only active employees.`
				break
			case 'employee_tags':
				help.header = 'Employee Tags'
				help.message = 'When employee tags are selected, an employee must have all the selected tags to be included in the generated list.'
				break
			case 'department':
				help.header = 'Department'
				help.message = 'Select departments if you wish to narrow your target audience to only the selected departments.'
				break
			case 'job_distance':
				help.header = 'Distance'
				help.message = 'When set, an employee must be no more than this distance from the job site to be included in the generated list.'
				break
			case 'save_as_template':
				help.header = 'Save as Template'
				help.message =
					'When checked, this announcement will be saved as a template which can be sent, rescheduled, or modified for later use.\n\nWhen left unchecked, this announcement will be removed from the scheduled announcement list after it has been sent.'
				break
			case 'targetRules':
				help.header = 'Using Rules'
				help.message = `When targeting employees using rules, each option adds an additional filter, so only employees who meet all the selected criteria will be included.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

const targetDropdownOptions = [
	{ label: 'All Active Employees', value: 'ALLEMPLOYEES' },
	{ label: 'Selected Employees', value: 'SELECTEDEMPLOYEES' },
]

const actionDropdownOptions = [
	{ label: 'Send Custom Message', value: 'CUSTOM_MESSAGE' },
	{ label: 'Send Employee Schedule', value: 'SEND_EMP_SCHEDULE' },
	{ label: 'Send Employee App Link', value: 'SEND_EMP_AUTH_LINK' },
]

const x = {
	emp_id: [],
	jobsite_id: [],
	departments: [],
	employee_tags: [],
	emp_active_status: null,
	job_id: 12224,
	job_distance: 25,
	job_distance_units: 'MI',
}
