import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import { DialogManager, AdpIvrPromptCodeRecord, DatabaseTableName, EditFormAction, EditCompleteEvent, HelpDialogMessage } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
	selector: 'app-adp-ivr-prompt-code-edit',
	standalone: false,

	templateUrl: './adp-ivr-prompt-code-edit.component.html',
	styleUrl: './adp-ivr-prompt-code-edit.component.scss',
})
export class AdpIvrPromptCodeEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'adp_ivr_prompt_code'
	isUpdating = false
	form: UntypedFormGroup

	promptCodeOrderOptions: Array<SelectItem> = promptCodeOrderOptions
	promptCodeTypeOptions: Array<SelectItem> = promptCodeTypeOptions
	profileOptions: Array<SelectItem> = []

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: AdpIvrPromptCodeRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<EditCompleteEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		// Setup profile options
		this.profileOptions = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfiles().map((rec) => {
			return { label: rec.description, value: rec.id }
		})
		this.profileOptions.unshift({ label: 'Select a Profile', value: null })
		log('Profiles', this.profileOptions)

		this.setupComponent()
		this.setupForm()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrPromptCodeForId(this.recordId)
		this.record = new AdpIvrPromptCodeRecord(record)
		log('Record', record)

		if (this.action === 'clone') {
			this.record.id = null
			this.record.description = record.description + ' (copy)'
		}
	}

	public setupForm() {
		const record = this.record

		this.form = this.fb.group({
			id: [record?.id],
			prompt_order: [record?.prompt_order],
			code_type: [record?.code_type, Validators.required],
			description: [record?.description, Validators.required],
			code_value: [record?.code_value, Validators.required],
			external_id: [record?.external_id],
			ivr_profile: [record?.ivr_profile],
		})
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.form)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): AdpIvrPromptCodeRecord {
		const record = new AdpIvrPromptCodeRecord(this.record)

		// Update any values changed by the form here
		record.prompt_order = this.form.get('prompt_order').value
		record.description = this.form.get('description').value
		record.code_value = this.form.get('code_value').value
		record.code_type = this.form.get('code_type').value
		record.external_id = this.form.get('external_id').value
		record.ivr_profile = this.form.get('ivr_profile').value

		return record
	}

	public toggleCheckbox(prop: string) {
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	private scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

const promptCodeTypeOptions = [
	{ value: null, label: 'Select Code Type' },
	{ value: 'BUSINESS_STRUCTURE', label: 'Business Structure' },
	{ value: 'WORK_RULE', label: 'Work Rule' },
	{ value: 'PAY_CODE', label: 'Pay Code' },
]

const promptCodeOrderOptions = [
	{ value: null, label: '--' },
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' },
]
