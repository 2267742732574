import { Component, OnDestroy, Input, AfterViewInit, Renderer2, OnInit, ChangeDetectorRef } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

import {
	EmployeeRecord,
	JobSiteRecord,
	EmployeeStats,
	EmpScoreWeight,
	CrudAction,
	DialogManager,
	SecureFileDialogData,
	AddressHelper,
	LocalPrefsData,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	CompanyRecord,
	ClickToCallRecord,
	ClickToCallGlobalConfig,
	EmployeesViewManager,
	TableRenderCache,
	DataAccessRequest,
	ManageProfileDialogData,
	AuditActionEvent,
	ComponentBridgeName,
	SendAnnouncementEvent,
	OnboardLogRecord,
} from '@app/models'
import { PrefsService, NotificationsService, CoreService } from '@app/services'

import { log, DateTimeHelper, DisplayHelper, TableActionFormatter, LanguageHelper, GeneralTableHelper, DataTablesHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api'
import moment from 'moment-timezone'
import { BatchActionManager, BatchActionType } from '@app/models/batch'

class CloneEmployeeManager {
	showDialog = false
	hasGlobalAccount = false

	isProcessing = false

	allowedCompanies: Array<CompanyRecord> = []
	allowedCompanyOptions: Array<SelectItem> = []

	employeeName = ''
	selectedEmployeeId = null
	selectedCompanyId = null
}
class LinkedJobSite {
	empId: number
	empName = ''
	jobSites: Array<JobSiteRecord> = []
}

enum EmpTableColumnIndex {
	id,
	name,
	firstName,
	lastName,
	activeStatus,
	tags,
	score,
	department,
	adpIvrPin,
	adpIvrOid,
	details,
	supervisor,
	supervisorGroup,
	manager,
	linkedJobSites,
	mobile,
	email,
	district,
	address,
	map,
	useGps,
	voiceprints,
	floater,
	// union,
	adpSync,
	adpCustom1,
	qbSync,
	wiwSync,
	w2wSync,
	language,
	startDate,
	payRate,
	homeTaxLocation,
	created,
	lastActive,
	empStatus,
	onboard,
	profile,
	enrolled,
	files,
	actions,
	bulkSelect,
}

@Component({
	selector: 'app-employee-table',
	templateUrl: './employee-table.component.html',
	styleUrls: ['./employee-table.component.scss'],
	standalone: false,
})
export class EmployeeTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeEmpTable'

	@Input() list: EmployeeRecord[] = []

	company: CompanyRecord
	accessHelper: AccessHelper
	canAccessAuditLog = false
	canConfigurePrefs = false

	currentLinkedJobsite = new LinkedJobSite()
	showLinkedJobSitesModal = false

	selectedEmployee: EmployeeRecord
	currentEmployeeStats = new EmployeeStats(null)
	showEmpScoreModal = false
	empScoreConfig = null
	showInvalidScoreConfigAlert = false
	highlightMissingExternalIds = false

	editAction = { recordId: null, action: 'edit', header: '', footer: '', activeStatus: null }
	editDialogManager = new DialogManager('employeeEditDialog', 'employeeEditDialog')
	batchDialogManager = new DialogManager('employeeBatchEditDialog')

	empDebugAction = {
		recordId: null,
		header: 'Debug Employee App',
		footer: 'Employee Name',
		safeUrl: null,
		showDialog: false,
	}
	empPrefsDialog = { group: 'employee', recordId: null, header: '', footer: '', show: false }

	public onboardLogListAction = { recordId: null }
	public onboardLogListDialogManager = new DialogManager('onboardLogListDialog')
	public secureFilesDialogManager = new DialogManager('secureFilesDialog')

	showJobsite = false
	siteEditAction = { recordId: null, action: 'edit', isViewingSite: false }

	viewAuditAction = {
		recordId: null,
		isAuditTypePickerVisible: false,
	}

	private employeesTable // : DataTables.DataTable
	private dialogListener: () => void

	private defaultSortOrder = [[0, 'asc']]
	private defaultPageLength = 50

	isAdpIvrAdmin = false
	isAdpIntegrated = false
	isQBIntegrated = false
	isWIWIntegrated = false
	isW2WIntegrated = false
	isInternalUser = false
	isPrimaryOrInternal = false
	isManagerRoleAvailable = false
	isOnboardingEnabled = false

	cloneEmployeeeManager = new CloneEmployeeManager()

	private areEmployeeProfilesAvailable = false
	private isTaxLocationAvailable = false
	private isPayRateAvailable = false

	private subs = new Subscription()

	private generalTableHelper: GeneralTableHelper
	private addressHelper: AddressHelper

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')
	public profileDialogManager = new DialogManager('profileDialogManager')

	private clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons

	private renderCache = new TableRenderCache()

	constructor(
		renderer: Renderer2,
		private cd: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
		private router: Router,
		private coreSrvc: CoreService,
		private prefsService: PrefsService,
		private noteSrvc: NotificationsService,
	) {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.company = company

		// Setup helpers
		this.addressHelper = new AddressHelper(coreSrvc.dbSrvc)

		this.empScoreConfig = this.coreSrvc.dbSrvc.settingSrvc.getCompany().emp_score_config
		const isConfigValid = EmpScoreWeight.isConfigValid(this.empScoreConfig)
		if (!isConfigValid) {
			this.showInvalidScoreConfigAlert = true
			this.coreSrvc.dbSrvc.empSrvc.warnings.employeeScoreConfigInvalid = true
		}

		// Check for QB Integration
		this.isQBIntegrated = this.coreSrvc.dbSrvc.settingSrvc.isQBOCustomer()
		this.isAdpIvrAdmin = this.coreSrvc.dbSrvc.settingSrvc.isAdpIvrAdmin()
		this.isAdpIntegrated = this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()
		this.isWIWIntegrated = this.coreSrvc.dbSrvc.wiwSrvc.isWiWIntegrated()
		this.isW2WIntegrated = this.coreSrvc.dbSrvc.w2wSrvc.isW2WIntegrated()
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.isPrimaryOrInternal = this.coreSrvc.dbSrvc.settingSrvc.isPrimaryOrInternalUser()

		// Setup clone record checks
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const globalId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().global_id
		this.cloneEmployeeeManager.hasGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		this.cloneEmployeeeManager.allowedCompanyOptions = this.coreSrvc.dbSrvc.settingSrvc
			.getGlobalCompanyList()
			.filter((c) => c.global_id === globalId)
			.filter((c) => c.id !== companyId)
			.map((ac) => ({ label: ac.name, value: ac.id }))

		// Check for missing ID highlight requirement
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId

		// Check for managing supervisor role availability
		this.isManagerRoleAvailable = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().userEnableManagerRole
		log('Is manager role available', this.isManagerRoleAvailable)

		// Check if onboarding is enabled
		this.isOnboardingEnabled = company.onboard_required

		this.isTaxLocationAvailable = company.work_tax_location
		this.isPayRateAvailable = company.pay_rate
		this.areEmployeeProfilesAvailable = this.coreSrvc.dbSrvc.settingSrvc.areEmployeeProfilesAvailable()

		// Setup list count manager
		this.coreSrvc.dbSrvc.empSrvc.listCountManager.listCount = () => this.list.length

		// Other setup
		this.setupSubscriptions()
		this.setupC2CAvailability()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
		this.loadData()
	}

	get batchManager(): BatchActionManager {
		return this.coreSrvc.dbSrvc.empSrvc.batchManager
	}

	get viewManager(): EmployeesViewManager {
		return this.coreSrvc.dbSrvc.empSrvc.viewManager
	}

	get canAccessC2CBtns(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	ngOnInit() {
		window[this.bridgeName] = this
	}

	ngOnDestroy() {
		this.employeesTable['fixedHeader'].disable()
		// this.dialogListener() // DEPRECATED

		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		this.initTable()
		log('Loading Employees Data')

		// position the clear search button
		$('#employeesTable_filter input').attr('placeholder', ' filter')
		$('#employeesTable_filter input').addClass('search-field-input')
		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#employeesTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.batchManager.updateTable.subscribe(() => this.updateTable()))
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupC2CAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'employee')
		const auditPerms = this.accessHelper.getPermissionsFor('audit')
		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()

		this.canAccessAuditLog = auditPerms.access.read

		// Can configure employee app prefences
		this.canConfigurePrefs = myUserPrefs.employeeEnableEmpAppPrefs
	}

	private handleScreenSizeChanges() {
		if (this.employeesTable) {
			this.employeesTable.columns.adjust().responsive.recalc()
			this.employeesTable.fixedHeader.adjust()
		}
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		let columnVisibilityItems = LocalPrefsData.getEmpTableColumnDisplayPrefs()

		// For ADP IVR Admins remove both empColDepartmentVisible and empColTaxLocationVisible
		if (this.isAdpIvrAdmin) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'empColDepartmentVisible')
		if (this.isAdpIvrAdmin) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'empColTaxLocationVisible')

		if (!this.isAdpIntegrated) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'empColAdpCustom1Visible')
		if (!this.isPayRateAvailable) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'empColPayRateVisible')
		if (!this.areEmployeeProfilesAvailable) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'empColProfileVisible')
		if (!this.isOnboardingEnabled) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'empColOnboardVisible')
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const availableGroups = [columnVisibilityGroup]

		const miscItems = LocalPrefsData.getEmpTableMiscellaneousPrefs()
		const miscGroup = new LocalPrefsGroup('Miscellaneous Prefs', miscItems)
		if (this.areEmployeeProfilesAvailable) availableGroups.push(miscGroup)

		const dialogData = new LocalPrefsDialogData(availableGroups)
		dialogData.localStorageKeyRemovalList = ['DataTables_employeesTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	// Deprecated - was allowing edit in linked sites
	editJobsite(id: number) {
		this.siteEditAction.recordId = id
		this.siteEditAction.action = 'edit'
		this.siteEditAction.isViewingSite = true
	}

	saveSiteActionComplete(event) {
		log('Return from save job site edit')
		this.updateTable()
		this.showLinkedJobSitesModal = false
		this.siteEditAction.isViewingSite = false
	}

	linkedSitesModalChanged(event) {
		log('Linked Sites Modal Changed', event)
		if (event === false) {
			setTimeout(() => {
				this.siteEditAction.isViewingSite = false
			}, 500)
		}
	}

	cancelSiteEditActionComplete() {
		log('Return from site edit by cancel')
		this.siteEditAction.isViewingSite = false
	}

	saveActionComplete(id: number) {
		log('Emp Table Save Action Complete')
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		this.renderCache.invalidateCache()
		this.editDialogManager.isDialogVisible = false

		if (emp) {
			this.updateRecord(id)
		} else {
			this.fetchAndReloadData()
		}
	}

	editActionCancelled() {
		log('Emp Table Cancel Action Complete')
		this.editDialogManager.isDialogVisible = false
	}

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, null)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.action = 'new'
		this.editAction.activeStatus = null // Setting to null will force table refresh because status will differ
		this.editDialogManager.headerLabel = 'Add Employee'
		this.editDialogManager.isDialogVisible = true
	}

	editRecord(id: number) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'employee')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		if (emp) {
			this.editAction.recordId = id
			this.editAction.activeStatus = emp.active
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = 'Edit ' + emp.name
			this.editDialogManager.isDialogVisible = true
		}
	}

	deleteRecord(id: number) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'employee')
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.coreSrvc.zone.run(() => {
			this.router.navigate([`/admin/employees/delete/${id}`])
		})
	}

	editEmpAppPrefs(id: number) {
		const canEditPrefs = this.accessHelper.isMyRecord(id, 'employee') && this.canConfigurePrefs
		if (!this.canPerformAction(CrudAction.update, canEditPrefs)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		this.empPrefsDialog.recordId = id
		this.empPrefsDialog.header = 'Employee App Prefs'
		this.empPrefsDialog.footer = 'Edit settings for ' + emp.first + ' ' + emp.last
		this.empPrefsDialog.group = 'employee'
		this.empPrefsDialog.show = true
	}

	openFilesDialog(id: number) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'employee')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const empName = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id).name
		this.secureFilesDialogManager.dialogData = new SecureFileDialogData('EMPLOYEE', id, null)
		this.secureFilesDialogManager.headerLabel = empName
		this.secureFilesDialogManager.isDialogVisible = true
	}

	empAppPrefsUpdated() {
		this.empPrefsDialog.show = false
		this.updateTable()
	}

	jsBridgeShowScore(id: number) {
		log('Employee', id)
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		if (emp) {
			this.selectedEmployee = emp
			this.currentEmployeeStats = emp.getStats()
			this.currentEmployeeStats.setWeights(this.empScoreConfig)
			this.showEmpScoreModal = true
			// this.currentEmployeeStats.showScoring()
			// const state = this.currentEmployeeStats.weights.getStateString()
			// log('Current State:', state)
		} else {
			alert('Employee stats not available at this time.')
		}
	}

	jsBridgeToggleProperty(id: number, prop: string) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'employee')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		log('Toggling', id, prop)
		this.coreSrvc.workSrvc.blockWorking()
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		emp[prop] = !emp[prop]
		const updateRecord = new EmployeeRecord(emp)
		updateRecord['short_update'] = true
		this.coreSrvc.dbSrvc.updateRecord('employee', updateRecord).then((success) => {
			if (success) {
				this.invalidateRowForEmp(emp)
			} else {
				emp[prop] = !emp[prop]
			}
			this.coreSrvc.workSrvc.unblockWorking()
		})
	}

	cloneRecord(recordId: number) {
		const isMyRecord = this.accessHelper.isMyRecord(recordId, 'employee')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(recordId)
		this.cloneEmployeeeManager.isProcessing = false
		this.cloneEmployeeeManager.selectedCompanyId = null
		this.cloneEmployeeeManager.selectedEmployeeId = emp.id
		this.cloneEmployeeeManager.employeeName = emp.first + ' ' + emp.last
		this.cloneEmployeeeManager.showDialog = true
	}

	cancelEmployeeClone() {
		this.cloneEmployeeeManager.employeeName = ''
		this.cloneEmployeeeManager.selectedCompanyId = null
	}

	performCloneEmployeeOperation() {
		if (!this.cloneEmployeeeManager.selectedCompanyId) {
			this.coreSrvc.notifySrvc.notify('info', 'Select Company', 'Please select a company to clone the employe into.', 3)
			return
		}

		this.cloneEmployeeeManager.isProcessing = true
		const request = new DataAccessRequest('none', 'clone_employee', {
			employee_id: this.cloneEmployeeeManager.selectedEmployeeId,
			target_company_id: this.cloneEmployeeeManager.selectedCompanyId,
		})

		setTimeout(() => {
			this.coreSrvc.dbSrvc.lambdaSrvc
				.dataAccess(request)
				.then((result) => {
					log('Result')
					const companyId = this.cloneEmployeeeManager.selectedCompanyId
					const companyName = this.coreSrvc.dbSrvc.settingSrvc.getAllowedCompanyForId(companyId).name
					this.cloneEmployeeeManager.showDialog = false
					this.cloneEmployeeeManager.isProcessing = true
					const empName = this.cloneEmployeeeManager.employeeName
					this.coreSrvc.notifySrvc.notify('success', 'Employee Cloned', `${empName} has been successfully cloned into ${companyName}.`)
				})
				.catch((error) => {
					this.cloneEmployeeeManager.isProcessing = false
				})
		}, 500)
	}

	invalidateRowForEmp(emp: EmployeeRecord) {
		// log('Invalidated', emp)
		const idx = this.list.indexOf(emp)
		this.employeesTable.rows(idx).invalidate().draw()
	}

	statsEmployee(): string {
		if (this.selectedEmployee) {
			return this.selectedEmployee.name
		} else {
			return ''
		}
	}

	statsUpdated(): string {
		const timestamp = this.currentEmployeeStats.timestamp
		const updateMom = moment(timestamp)
		if (updateMom.isValid()) {
			return 'Updated: ' + updateMom.format('ddd MMM Do @ h:mm a')
		} else {
			return 'Updated: < unavailable >'
		}
	}

	statsHoursWorked(): string {
		const duration = moment.duration(this.currentEmployeeStats.time_worked)
		const hours = DateTimeHelper.formatDurationInHoursAndMinutes('HH:mm', duration)
		if (duration) {
			return hours + ' (hr:min)'
		} else {
			return '< unavailable >'
		}
	}

	resetBtnClicked() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		company.emp_score_config = null
		this.coreSrvc.dbSrvc.updateRecord('company', company).then((result) => {
			this.showInvalidScoreConfigAlert = false
			this.noteSrvc.notify('warn', 'Settings Reset', 'You are now using default settings to compute the employee score.')
		})
	}

	empAppView(id: number) {
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const authUrl = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginAuthUrlByEmpId(this.coreSrvc.dbSrvc, id, companyId)
		const loginRecord = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginRecordByE164Number(emp?.phone_number_e164)
		const empLoginRec = loginRecord?.company_id === this.coreSrvc.dbSrvc.settingSrvc.getCompany().id ? loginRecord : null
		if (empLoginRec) {
			const unsafeUrl = authUrl + '&eab=1'
			log('Employee Auth Url', unsafeUrl)
			this.empDebugAction.header = emp.name
			this.empDebugAction.footer = 'Employee Web App Debugger'
			this.empDebugAction.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl)
			this.empDebugAction.showDialog = true
		} else {
			this.coreSrvc.notifySrvc.notify(
				'warn',
				'Emp App View',
				`${emp.first + ' ' + emp.last} is not currently logged into the employee web app for this company.`,
			)
		}
	}

	customizeBtnClicked() {
		this.router.navigate(['/admin/settings/score/edit'])
	}

	fetchAndReloadData() {
		this.coreSrvc.dbSrvc.readTable('employee_login').then((readEmpLoginSuccess) => {
			this.coreSrvc.dbSrvc.bulkRead(['employee', 'supervisor_group']).then((readEmpSuccess) => {
				if (readEmpSuccess) {
					this.updateTable()
				}
			})
		})
	}

	loadData() {
		const displayState = this.coreSrvc.dbSrvc.empSrvc.viewManager.currentView
		let list = this.accessHelper.getEmployeeTableList(displayState)
		if (this.batchManager.isPreviewMode) {
			list = list.filter((emp) => this.batchManager.selectedRecords.includes(emp.id))
		}
		this.list = list
		this.coreSrvc.dbSrvc.empSrvc.listCountManager.isReady = true
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		performance.mark('render:tableDraw:start')
		// lastActive not currently configurable
		// Floater not currently displayed
		const firstName = this.coreSrvc.prefSrvc.data.empColFirstNameVisible
		const lastName = this.coreSrvc.prefSrvc.data.empColLastNameVisible
		const activeStatus = this.viewManager.currentView === 'ALL'
		const tags = this.coreSrvc.prefSrvc.data.empColTagsVisible
		const score = (this.viewManager.currentView !== 'INACTIVE' ? true : false) && this.prefsService.data.empColScoreVisible
		const department = this.isAdpIvrAdmin || this.coreSrvc.prefSrvc.data.empColDepartmentVisible
		const adpIvrPin = this.isAdpIvrAdmin
		const adpIvrOid = this.isAdpIvrAdmin
		const details = this.coreSrvc.prefSrvc.data.empColDetailsVisible
		const supervisor = this.coreSrvc.prefSrvc.data.empColSupervisorVisible
		const supervisorGroup = this.coreSrvc.prefSrvc.data.empColSupervisorGroupVisible
		const manager = this.isManagerRoleAvailable && this.prefsService.data.empColManagerVisible
		const linkedJobSites = this.coreSrvc.prefSrvc.data.empColJobSitesVisible
		const mobile = this.coreSrvc.prefSrvc.data.empColMobileVisible
		const email = this.coreSrvc.prefSrvc.data.empColEmailVisible
		const district = this.coreSrvc.prefSrvc.data.empColDistrictVisible
		const address = this.coreSrvc.prefSrvc.data.empColAddressVisible
		const map = this.coreSrvc.prefSrvc.data.empColMapVisible
		const useGps = this.coreSrvc.prefSrvc.data.empColDisableSmsVisible // Display inverted from back end
		const voiceprints = this.coreSrvc.prefSrvc.data.empColVoiceprintsVisible
		const floater = false // this.coreSrvc.prefSrvc.data.empColFloaterVisible - not currently used
		// const union = this.coreSrvc.prefSrvc.data.empColUnionVisible
		const adpSync = this.isAdpIntegrated
		const adpCustom1 = this.isAdpIntegrated && this.coreSrvc.prefSrvc.data.empColAdpCustom1Visible
		const qboSync = this.isQBIntegrated
		const wiwSync = this.isWIWIntegrated
		const w2wSYnc = this.isW2WIntegrated
		const language = this.coreSrvc.prefSrvc.data.empColLanguageVisible
		const startDate = this.coreSrvc.prefSrvc.data.empColStartDateVisible
		const payRate = this.isPayRateAvailable && this.coreSrvc.prefSrvc.data.empColPayRateVisible // CHECK OTHER REQS
		const homeTaxLocation = !this.isAdpIvrAdmin && this.isTaxLocationAvailable && this.coreSrvc.prefSrvc.data.empColTaxLocationVisible
		const created = this.coreSrvc.prefSrvc.data.empColCreatedVisible
		const lastActive = this.coreSrvc.prefSrvc.data.empColLastActiveVisible
		const empStatus = this.coreSrvc.prefSrvc.data.empColEmpStatusVisible
		const onboard = this.isOnboardingEnabled && this.coreSrvc.prefSrvc.data.empColOnboardVisible
		const profile = this.areEmployeeProfilesAvailable && this.coreSrvc.prefSrvc.data.empColProfileVisible
		const files = this.coreSrvc.prefSrvc.data.empColFilesVisible

		const bulkSelectVisible = this.batchManager.isActive // Not configurable by preferences

		this.employeesTable.clear()

		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.firstName, firstName)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.lastName, lastName)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.activeStatus, activeStatus)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.tags, tags)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.score, score)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.department, department)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.adpIvrPin, adpIvrPin)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.adpIvrOid, adpIvrOid)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.details, details)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.supervisor, supervisor)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.supervisorGroup, supervisorGroup)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.manager, manager)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.linkedJobSites, linkedJobSites)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.mobile, mobile)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.email, email)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.map, map)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.district, district)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.address, address)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.useGps, useGps)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.voiceprints, voiceprints)
		// GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.floater, floater)
		// GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.union, union)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.adpSync, adpSync)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.adpCustom1, adpCustom1)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.qbSync, qboSync)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.wiwSync, wiwSync)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.language, language)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.startDate, startDate)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.payRate, payRate)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.homeTaxLocation, homeTaxLocation)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.created, created)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.lastActive, lastActive)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.empStatus, empStatus)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.onboard, onboard)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.profile, profile)
		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.files, files)

		GeneralTableHelper.updateColumn(this.employeesTable, EmpTableColumnIndex.bulkSelect, bulkSelectVisible)

		this.employeesTable.rows.add(this.list)
		this.employeesTable.draw(false)
	}

	clearSearch(): boolean {
		this.employeesTable
			.search('')
			.order([[0, 'asc']])
			.draw()
		this.employeesTable.columns.adjust().responsive.recalc()
		return false
	}

	private makeJobSitesButton(number, empId) {
		const sites = this.coreSrvc.dbSrvc.siteSrvc.jobSitesForEmpMobileNumberE164(number)
		const html = `
		<div onclick="${this.bridgeName}.openLinkedNumbersDialog(${empId})" class="table-modal-btn"
		style="display: inline-block; width: 75px; padding: 1px 4px 4px 4px">${sites.length === 1 ? '1 site' : sites.length + ' sites'}</div>`
		return { count: sites.length, html: html }
	}

	openLinkedNumbersDialog(id: number) {
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		if (emp) {
			const linkedJobSite = new LinkedJobSite()
			linkedJobSite.empId = id
			linkedJobSite.empName = emp.name
			linkedJobSite.jobSites = this.coreSrvc.dbSrvc.siteSrvc.jobSitesForEmpMobileNumberE164(emp.phone_number_e164)
			this.currentLinkedJobsite = linkedJobSite
			this.showLinkedJobSitesModal = true
		}
	}

	updateRecord(id: number) {
		log('Update Employee', id)
		if (id) {
			this.coreSrvc.dbSrvc.readRecord('employee', id).then((result) => {
				this.loadData()
				const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
				if (emp && emp.active === this.editAction.activeStatus) {
					const idx = this.list.indexOf(emp)
					this.employeesTable.rows(idx).invalidate().draw(false)
				} else {
					this.updateTable()
				}
			})
		} else {
			this.updateTable()
		}
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	//////////////////
	// BULK ACTIONS //
	//////////////////

	toggleBatchActionRecordSelection(id: number) {
		this.batchManager.toggleRecordSelection(id)
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		this.invalidateRowForEmp(emp)
	}
	peformBatchAction(action: BatchActionType) {
		log('Perform batch action', action)
		const currentView = this.viewManager.currentView
		const visibleData: Array<EmployeeRecord> = this.employeesTable?.rows({ filter: 'applied' }).data().toArray() ?? []
		const visibleIds = visibleData.map((emp) => emp.id)

		switch (action) {
			case 'EDIT':
				this.batchDialogManager.headerLabel = 'Edit Batch'
				this.batchDialogManager.isDialogVisible = true
				break
			case 'SELECT_VISIBLE':
				const selectIds = [...this.batchManager.selectedRecords, ...visibleIds]
				this.batchManager.setSelectedRecords(selectIds)
				break

			case 'UNSELECT_VISIBLE':
				const currentIds = this.batchManager.selectedRecords
				const removeIds = visibleData.map((emp) => emp.id)
				const updateIds = currentIds.filter((id) => !removeIds.includes(id))
				this.batchManager.setSelectedRecords(updateIds)
				break

			case 'TOGGLE_PREVIEW':
				setTimeout(() => {
					this.clearSearch()
				}, 100)
				break

			case 'SEND_ANNOUNCEMENT':
				const validIds: Array<number> = []
				this.batchManager.selectedRecords.forEach((id) => {
					const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
					if (emp && emp.status !== 'RESIGNED' && emp.status !== 'TERMINATED') validIds.push(emp.id)
				})
				const event = new SendAnnouncementEvent(validIds)
				this.coreSrvc.dbSrvc.annSrvc.sendAnnouncementEvent.next(event)
				break
		}
	}

	// End bulk actions

	public handleC2CEvent(id: number, column, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		let name = ''
		let number = ''
		if (column === 'MOBILE') {
			name = record.first + ' ' + record.last || 'Unknown'
			number = record.phone_number_e164
		}
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('EMPLOYEEPHONE', name, null, number, null)
			c2cRecord.destEmpId = id
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
		log('handleC2CEvent', id, column, action, record)
	}

	// BEGIN - Manage Onboard
	manageOnboardingBtnClicked(id: number) {
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		log('Onboard emp', emp)

		this.onboardLogListAction.recordId = id
		this.onboardLogListDialogManager.cancelBtnLabel = 'Close'
		this.onboardLogListDialogManager.isSubmitBtnVisible = false
		this.onboardLogListDialogManager.headerLabel = emp.name
		this.onboardLogListDialogManager.isDialogVisible = true
	}

	// BEGIN - Manage Profile
	public manageProfileBtnClicked(id: number) {
		this.profileDialogManager.dialogData = new ManageProfileDialogData(id)
		this.profileDialogManager.headerLabel = 'Manage Profile'
		this.profileDialogManager.isDialogVisible = true
	}

	public profileEditComplete(id: number) {
		this.profileDialogManager.isDialogVisible = false
		this.updateRecord(id)
	}

	openAuditLogPicker(id: number) {
		log('OPENAUDITLOGPICKER', id)
		this.viewAuditAction.recordId = id
		this.viewAuditAction.isAuditTypePickerVisible = true
	}

	viewAuditLog(type: 'AUTHLINKACCESS') {
		const viewType = type ? 'Auth Link Access' : 'Record Modification'
		this.viewAuditAction.isAuditTypePickerVisible = false
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.viewAuditAction.recordId)
		const footer = emp ? `${emp.first} ${emp.last}` : null
		setTimeout(() => {
			if (type) {
				const auditActionEvent = new AuditActionEvent('employee', this.viewAuditAction.recordId, footer ? footer + ` / ${viewType}` : viewType)
				auditActionEvent.trackType = 'AUTHLINKACCESS'
				this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
			} else {
				const auditActionEvent = new AuditActionEvent('employee', this.viewAuditAction.recordId, footer ? footer + ` / ${viewType}` : viewType)
				this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
			}
		}, 100)
	}

	public setPage(num: number) {
		this.employeesTable?.page(num)
	}

	private initTable() {
		performance.mark('render:tableDraw:start')

		this.coreSrvc.displaySrvc.startSectionLoader()
		this.employeesTable = $('#employeesTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [EmpTableColumnIndex.bulkSelect, EmpTableColumnIndex.name],
				},
				{
					responsivePriority: 2,
					targets: [EmpTableColumnIndex.mobile],
				},
				{
					responsivePriority: 3,
					targets: [EmpTableColumnIndex.score],
				},
				{
					responsivePriority: 4,
					targets: [EmpTableColumnIndex.linkedJobSites],
				},
				{
					responsivePriority: 5,
					targets: [EmpTableColumnIndex.language],
				},
				{
					responsivePriority: 6,
					targets: [EmpTableColumnIndex.district],
				},
				{
					responsivePriority: 7,
					targets: [EmpTableColumnIndex.actions],
				},
				{
					// ID
					targets: EmpTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.id
					},
				},
				{
					// Name
					targets: EmpTableColumnIndex.name,
					visible: true,
					searchable: true,
					title: this.isAdpIvrAdmin ? 'Display Name /<br>Badge Number' : 'Display Name / ID',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(emp.id, 'name')
						if (cachedData) return cachedData

						const name = emp.name || ''
						const externalId = emp.external_id ? `<div class="table-ext-id-normal">${emp.external_id}</div>` : ''
						const noExternalId =
							this.highlightMissingExternalIds && !emp.external_id ? `<div class="table-tag bg-chocolate">No ID Set</div>` : ''
						const flagRow = name.toLowerCase().includes('autocreated') ? true : false
						let nameString = name.replace('_', ' ')
						if (flagRow) {
							nameString = `<i title="Auto Created Employee" class="table-icon-alert fa fa-exclamation-triangle"></i> ` + nameString
						}
						const nameHtml = `<div>${nameString}</div>`
						let pfpHtml = ''
						const areInlinePfpsEnabled = this.coreSrvc.prefSrvc.data.empTableIncludeInlinePfp
						if (this.areEmployeeProfilesAvailable && areInlinePfpsEnabled) {
							const clickHandler = ''
							const pfpImageFile = emp.getPfpImageFile()
							if (pfpImageFile) {
								const src = pfpImageFile.getPfpImageUrl()
								pfpHtml = `<div ${clickHandler}><img class="table-pfp-img-sm" src="${src}" /></div>`
							} else {
								pfpHtml = `<div ${clickHandler}><img class="table-pfp-img-sm" src="/assets/img/profile-placeholder.jpg" /></div>`
							}
						}

						const renderData = `
						<div class="dtr-control-content">
							<div class="table-name">${nameHtml}${externalId}${noExternalId}</div>
							${pfpHtml}
						</div>`
						this.renderCache.setRenderData(emp.id, 'name', renderData)
						return renderData
					},
				},
				{
					// First Name
					targets: EmpTableColumnIndex.firstName,
					visible: this.prefsService.data.empColFirstNameVisible,
					searchable: this.prefsService.data.empColFirstNameVisible,
					title: 'First Name',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.first
					},
				},
				{
					// Last Name
					targets: EmpTableColumnIndex.lastName,
					visible: this.prefsService.data.empColLastNameVisible,
					searchable: this.prefsService.data.empColLastNameVisible,
					title: 'Last Name',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.last
					},
				},
				{
					// Status
					targets: EmpTableColumnIndex.activeStatus,
					visible: this.viewManager.currentView === 'ALL' ? true : false, // Visibility managed after load in loadData
					searchable: true,
					title: 'Status',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.active ? '<div class="table-tag bg-green">Active</div>' : `<div class="table-tag bg-chocolate">Inactive</div>`
					},
				},
				{
					// Tags
					targets: EmpTableColumnIndex.tags,
					visible: this.prefsService.data.empColTagsVisible,
					searchable: this.prefsService.data.empColTagsVisible,
					sortable: true,
					title: 'Tags',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const expandTags = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().enableTagExpansion
						return GeneralTableHelper.tags(emp, expandTags, true)
					},
				},
				{
					// Score
					targets: EmpTableColumnIndex.score,
					visible: (this.viewManager.currentView !== 'INACTIVE' ? true : false) && this.prefsService.data.empColScoreVisible, // Visibility managed after load in loadData
					title: 'Score',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(emp.id, 'score')
						if (cachedData) return cachedData
						if (!emp.active) return ''

						const stats: EmployeeStats = emp.getStats()
						stats.setWeights(this.empScoreConfig)
						const score = stats.getScore()
						const backgroundColor = stats.getStyleBackgroundColor()
						const textColor = stats.getStyleTextColor()

						const renderData = `<div class="emp-score-box" style="background-color: ${backgroundColor}; color: ${textColor}" onclick="${this.bridgeName}.jsBridgeShowScore(${emp.id})">${score}</div>`
						this.renderCache.setRenderData(emp.id, 'score', renderData)
						return renderData
					},
				},
				{
					// Department
					targets: EmpTableColumnIndex.department,
					visible: this.prefsService.data.empColDepartmentVisible,
					searchable: this.prefsService.data.empColDepartmentVisible,
					title: this.isAdpIvrAdmin ? 'ADP IVR<br>Profile Name' : 'Dept',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.department
					},
				},
				{
					// IVR Pin Number
					targets: EmpTableColumnIndex.adpIvrPin,
					visible: this.isAdpIvrAdmin,
					searchable: this.isAdpIvrAdmin,
					title: 'ADP IVR<br>Pin Number',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const location = emp.home_tax_location
						return location ? `<div class="table-details" style="min-width:200px">${location}</div>` : ''
					},
				},
				{
					// IVR OID
					targets: EmpTableColumnIndex.adpIvrOid,
					visible: this.isAdpIvrAdmin,
					searchable: this.isAdpIvrAdmin,
					title: 'ADP OID',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const adpId = emp.adp_id
						return adpId ? `<div class="table-details" style="min-width:200px">${adpId}</div>` : ''
					},
				},
				{
					// Details
					targets: EmpTableColumnIndex.details,
					visible: this.prefsService.data.empColDetailsVisible,
					searchable: this.prefsService.data.empColDetailsVisible,
					title: 'Details',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.employee_details
							? `<div class="trans-note-block hide-scrollbars" style="min-width: 175px;max-width: 450px">${emp.employee_details}</div>`
							: ''
					},
				},
				{
					// Supervisor
					targets: EmpTableColumnIndex.supervisor,
					visible: this.prefsService.data.empColSupervisorVisible,
					searchable: true,
					title: 'Supervisor',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						if (emp.supervisor === 0) return '<div>Unassigned Supervisor</div>'
						const supName = this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(emp.supervisor) || ''
						const supHtml = `<div>${supName}</div>`
						return `${supHtml}`
					},
				},
				{
					// Supervisor Group
					targets: EmpTableColumnIndex.supervisorGroup,
					visible: this.prefsService.data.empColSupervisorGroupVisible,
					searchable: true,
					title: 'Group',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const groupId = emp.supervisor_group_id
						const groupName = groupId ? this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(groupId)?.description : ''
						const groupHtml = `<div>${groupName}</div>`
						return `${groupHtml}`
					},
				},
				{
					// Manager
					targets: EmpTableColumnIndex.manager,
					visible: this.isManagerRoleAvailable && this.prefsService.data.empColManagerVisible,
					searchable: this.isManagerRoleAvailable && this.prefsService.data.empColManagerVisible,
					title: 'Manager',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const supId = emp.supervisor
						const sup = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(supId)
						if (sup) {
							const managerId = sup.managed_by
							if (managerId) {
								const manager = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(managerId)
								if (manager) {
									return manager.first_name + ' ' + manager.last_name
								}
							}
						}
						return ''
					},
				},
				{
					// Linked Job Sites
					targets: EmpTableColumnIndex.linkedJobSites,
					visible: this.prefsService.data.empColJobSitesVisible,
					searchable: false,
					title: 'Linked',
					data: null,
					render: (d, t, f, m) => {
						const button = this.makeJobSitesButton(d.phone_number_e164, d.id)
						return button.count > 0 ? button.html : ''
					},
				},
				{
					// Mobile
					targets: EmpTableColumnIndex.mobile,
					visible: this.prefsService.data.empColMobileVisible,
					searchable: this.prefsService.data.empColMobileVisible,
					title: 'Mobile',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return GeneralTableHelper.makePHoneNumberWithC2C(
							emp.id,
							emp.phone_number_e164,
							'MOBILE',
							this.canAccessC2CBtns,
							this.bridgeName,
							'handleC2CEvent',
						)
					},
				},
				{
					// Email
					targets: EmpTableColumnIndex.email,
					visible: this.prefsService.data.empColEmailVisible,
					searchable: this.prefsService.data.empColEmailVisible,
					title: 'Email',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						if (emp.email) {
							return `<div style="white-space: nowrap">${emp.email}</div>`
						}
						return ``
					},
				},
				{
					// District
					targets: EmpTableColumnIndex.district,
					visible: this.prefsService.data.empColDistrictVisible,
					searchable: this.prefsService.data.empColDistrictVisible,
					title: this.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel(),
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.district ?? ''
					},
				},
				{
					// Address
					targets: EmpTableColumnIndex.address,
					visible: this.prefsService.data.empColAddressVisible,
					searchable: this.prefsService.data.empColAddressVisible,
					title: 'Address',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return this.addressHelper.addressFormatter(emp)
					},
				},
				{
					// Map
					targets: EmpTableColumnIndex.map,
					visible: this.prefsService.data.empColMapVisible,
					searchable: this.prefsService.data.empColMapVisible,
					title: 'Map',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return this.addressHelper.mapFormatter(emp)
					},
				},
				{
					// Use GPS
					targets: EmpTableColumnIndex.useGps,
					visible: this.prefsService.data.empColDisableSmsVisible,
					searchable: false,
					title: 'IVR GPS',
					data: null,
					render: (d, t, f, m) => {
						let result = ''
						if (d.disable_sms) {
							result = `<span style="color: gray;"><i class="fa fa-square-o cell-icon-lg" aria-hidden="true"></i></span>`
						} else {
							result = `<span style="color: green;"><span hidden="true">~</span><i class="fa fa-check-square cell-icon-lg" aria-hidden="true"></i></span>`
						}
						return `<span style="padding-left: 14px;" class="prop-toggle" onclick="${this.bridgeName}.jsBridgeToggleProperty(${d.id},'disable_sms')">${result}</span>`
					},
				},
				{
					// Voiceprints
					targets: EmpTableColumnIndex.voiceprints,
					visible: this.prefsService.data.empColVoiceprintsVisible,
					searchable: false,
					title: 'Voiceprint',
					data: null,
					render: (d, t, f, m) => {
						let result = ''
						if (d.voice_fingerprint) {
							result = `<span style="padding-left: 30px; color: green;"><span hidden="true">~</span><i class="fa fa-check-square cell-icon-lg" aria-hidden="true"></i></span>`
						} else {
							result = `<span style="padding-left: 30px; color: gray;"><i class="fa fa-square-o cell-icon-lg" aria-hidden="true"></i></span>`
						}
						return `<span class="prop-toggle" onclick="${this.bridgeName}.jsBridgeToggleProperty(${d.id},'voice_fingerprint')">${result}</span>`
					},
				},
				{
					// Floater
					targets: EmpTableColumnIndex.floater,
					visible: false,
					searchable: false,
					title: 'Floater',
					data: null,
					render: (d, t, f, m) => {
						let result = ''
						if (d.floater) {
							result = `<span style="padding-left: 30px; color: green;"><span hidden="true">~</span><i class="fa fa-check-square cell-icon-lg" aria-hidden="true"></i></span>`
						} else {
							result = `<span style="padding-left: 30px; color: gray;"><i class="fa fa-square-o cell-icon-lg" aria-hidden="true"></i></span>`
						}
						return `<span class="prop-toggle" onclick="${this.bridgeName}.jsBridgeToggleProperty(${d.id},'floater')">${result}</span>`
					},
				},
				// {
				// 	// Union
				// 	targets: EmpTableColumnIndex.union,
				// 	visible: this.isQBIntegrated && this.prefsService.data.empColUnionVisible,
				// 	searchable: false,
				// 	title: 'Union',
				// 	data: null,
				// 	render: (d, t, f, m) => {
				// 		let result = ''
				// 		if (d.union) {
				// 			result = `<i class="fa fa-check-square cell-icon-lg" style="color: green;"></i>`
				// 		} else {
				// 			result = `<i class="fa fa-square-o cell-icon-lg" style="color: gray;"></i>`
				// 		}
				// 		return `<div class="emp-union-cb prop-toggle" onclick="${this.bridgeTableName}.jsBridgeToggleProperty(${d.id},'union')">${result}</div>`
				// 	},
				// },
				{
					// ADP Sync
					targets: EmpTableColumnIndex.adpSync,
					visible: this.isAdpIntegrated,
					searchable: false,
					title: 'ADP Sync',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const hasCheck = !!rec.adp_id
						return TableActionFormatter.checkmarkLink(hasCheck, true, 'cell-icon-lg', 34, null)
					},
				},
				{
					// ADP Custom 1
					targets: EmpTableColumnIndex.adpCustom1,
					visible: this.isAdpIntegrated && this.coreSrvc.prefSrvc.data.empColAdpCustom1Visible,
					searchable: true,
					title: 'ADP (1)',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return emp.adp_custom_1 ?? ''
					},
				},
				{
					// QB Sync
					targets: EmpTableColumnIndex.qbSync,
					visible: this.isQBIntegrated,
					searchable: false,
					title: 'QB Sync',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const hasCheck = !!rec.qbo_id
						return TableActionFormatter.checkmarkLink(hasCheck, true, 'cell-icon-lg', 34, null)
					},
				},
				{
					// WIW Sync
					targets: EmpTableColumnIndex.wiwSync,
					visible: this.isWIWIntegrated,
					searchable: false,
					title: 'WIW Sync',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const hasCheck = !!rec.wiw_id
						return TableActionFormatter.checkmarkLink(hasCheck, true, 'cell-icon-lg', 34, null)
					},
				},
				{
					// W2W Sync
					targets: EmpTableColumnIndex.w2wSync,
					visible: this.isW2WIntegrated,
					searchable: false,
					title: 'W2W Sync',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const hasCheck = !!rec.w2w_id
						return TableActionFormatter.checkmarkLink(hasCheck, true, 'cell-icon-lg', 34, null)
					},
				},
				{
					// Language
					targets: EmpTableColumnIndex.language,
					visible: this.prefsService.data.empColLanguageVisible,
					searchable: this.prefsService.data.empColLanguageVisible,
					title: 'Language',
					data: 'language',
					render: function (d, t, f, m) {
						return LanguageHelper.getLanguageForCode(d)
					},
				},
				{
					// Start Date
					targets: EmpTableColumnIndex.startDate,
					visible: this.prefsService.data.empColStartDateVisible,
					searchable: this.prefsService.data.empColStartDateVisible,
					title: 'Start Date',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const empStartDate = emp.start_date
						const startDate = empStartDate ? moment(empStartDate, 'YYYY-MM-DD').format('ddd. MMM Do, YYYY ') : ''
						const startDateHtml = `<div><span style="font-size:.9em;font-weight:bold;color:#135d94;">${startDate}</span></div>`
						const notSetHtml = `<div><span style="font-size:.9em;font-weight:bold;color:chocolate;">&lt; not set &gt;</span></div>`
						return empStartDate ? startDateHtml : notSetHtml
					},
				},
				{
					// Pay Rate
					targets: EmpTableColumnIndex.payRate,
					visible: this.isPayRateAvailable && this.coreSrvc.prefSrvc.data.empColPayRateVisible,
					searchable: false,
					title: 'Pay Rate',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return TableActionFormatter.checkmarkLink(!!emp.pay_rate, false, 'cell-icon-lg', 35, null)
					},
				},
				{
					// Tax Location
					targets: EmpTableColumnIndex.homeTaxLocation,
					visible: !this.isAdpIvrAdmin && this.isTaxLocationAvailable && this.coreSrvc.prefSrvc.data.empColTaxLocationVisible,
					searchable: this.isTaxLocationAvailable,
					title: 'Tax Location',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						const location = emp.home_tax_location
						return location ? `<div class="table-details" style="min-width:200px">${location}</div>` : ''
					},
				},
				{
					// Created
					targets: EmpTableColumnIndex.created,
					visible: this.coreSrvc.prefSrvc.data.empColCreatedVisible,
					searchable: true,
					title: 'Created',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const ts = moment(rec.created).unix()
						const createdDate = DateTimeHelper.mediumDateFromDateString(rec.created)
						const createdHtml = `<div style="display:none;">${ts}</div><div><span style="font-size:.9em;font-weight:bold;color:#135d94;">${createdDate}</span></div>`
						return createdHtml
					},
				},
				{
					// Last Active
					targets: EmpTableColumnIndex.lastActive,
					visible: this.coreSrvc.prefSrvc.data.empColLastActiveVisible,
					searchable: true,
					title: 'Last Active',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						// Render
						const noLoginHtml = `<div><span style="font-size:.9em;font-weight:bold;color:chocolate;">&lt; no activity &gt;</span></div>`
						if (!rec.last_active) {
							return noLoginHtml
						}
						const ts = moment(rec.last_active).unix()
						const lastActiveDate = DateTimeHelper.mediumDateFromDateString(rec.last_active)
						const lastActiveHtml = `<div style="display:none;">${ts}</div><div><span style="font-size:.9em;font-weight:bold;color:#135d94;">${lastActiveDate}</span></div>`
						return lastActiveHtml
					},
				},
				{
					// Status
					targets: EmpTableColumnIndex.empStatus,
					visible: this.coreSrvc.prefSrvc.data.empColEmpStatusVisible,
					searchable: true,
					title: 'Emp Status',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const statusHtml = `<div style="margin-bottom: 6px">${rec.getStatus() ?? ''}</div>`
						const notesHtml = rec.employment_status_notes ? `<div class="table-note-block">${rec.employment_status_notes}</div>` : ''
						return statusHtml + notesHtml
					},
				},
				{
					// Onboard
					targets: EmpTableColumnIndex.onboard,
					visible: this.isOnboardingEnabled && this.coreSrvc.prefSrvc.data.empColOnboardVisible,
					searchable: false,
					title: 'Onboard',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const hasApproved = rec.onboard_updates?.approved > 0
						const hasPending = rec.onboard_updates?.pending > 0
						const hasExpired = rec.onboard_updates?.expired > 0

						let colorStyle = 'color:gray'
						let sortHtml = 'A'

						if (hasApproved) {
							colorStyle = 'color:green'
							sortHtml = 'B'
						}
						if (hasPending) {
							colorStyle = 'color:chocolate'
							sortHtml = 'C'
						}
						if (hasExpired) {
							colorStyle = 'color:firebrick'
							sortHtml = 'D'
						}

						const clickHandler = `onclick="${this.bridgeName}.manageOnboardingBtnClicked(${rec.id})"`
						const onboardHtml = `<div style="display:none">${sortHtml}</div><div class="emp-onboard-icon" ${clickHandler} style="${colorStyle}"><i class="far fa-circle-user"></i></div>`
						return onboardHtml
					},
				},
				{
					// Profile
					targets: EmpTableColumnIndex.profile,
					visible: this.areEmployeeProfilesAvailable && this.coreSrvc.prefSrvc.data.empColProfileVisible,
					searchable: false,
					title: 'Profile',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						const isApproved = rec.pfp_approved
						const pfpImageFile = rec.getPfpImageFile()
						const sortHtml = !pfpImageFile ? 'C' : pfpImageFile && !isApproved ? 'A' : 'B'
						const colorStyle = pfpImageFile && isApproved ? 'color:green' : pfpImageFile && !isApproved ? 'color:firebrick' : 'color:gray'
						const clickHandler = `onclick="${this.bridgeName}.manageProfileBtnClicked(${rec.id})"`
						const profileHtml = `<div style="display:none">${sortHtml}</div><div class="emp-profile-icon" ${clickHandler} style="${colorStyle}"><i class="far fa-circle-user"></i></div>`
						return profileHtml
					},
				},
				{
					// Enrolled
					targets: EmpTableColumnIndex.enrolled,
					visible: this.isInternalUser,
					searchable: false,
					title: 'Enrolled',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						return TableActionFormatter.checkmarkLink(rec.enrolled, true, 'emp-enroll-icon', null, null)
					},
				},
				{
					// Files
					targets: EmpTableColumnIndex.files,
					visible: this.coreSrvc.prefSrvc.data.empColFilesVisible,
					searchable: false,
					title: 'Files',
					data: null,
					render: (rec: EmployeeRecord, t, f, m) => {
						return GeneralTableHelper.makeFilesCountBubble(rec.id, rec.file_uploads_metadata, this.bridgeName, 'openFilesDialog')
					},
				},
				{
					// Actions
					targets: EmpTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (rec: EmployeeRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = this.accessHelper.isMyRecord(recordId, 'employee')

						const canAccessEdit = this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						// Only show icon when company has globabl account / use edit permissions to style icon
						const cloneLink = this.cloneEmployeeeManager.hasGlobalAccount
							? TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canAccessEdit)
							: ''

						const canAccessDelete = this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const hasPrefs = !!rec.web_prefs_json
						const prefsLink = this.canConfigurePrefs
							? TableActionFormatter.empPrefsLink(this.bridgeName, 'editEmpAppPrefs', recordId, true, hasPrefs)
							: ''

						const empLoginRecord = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginRecordByE164Number(rec.phone_number_e164)
						const canAccessEmpApp = !this.isAdpIvrAdmin && (!this.coreSrvc.auditMode || this.isInternalUser)
						const hadEmpAppSession = empLoginRecord?.company_id === this.company?.id ? true : false
						const empAppViewLink = canAccessEmpApp
							? TableActionFormatter.empAppViewLink(this.bridgeName, 'empAppView', recordId, canAccessEmpApp, hadEmpAppSession)
							: ''

						const auditLink = TableActionFormatter.auditLink(this.bridgeName, 'openAuditLogPicker', recordId, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${deleteLink}${auditLink}${prefsLink}${empAppViewLink}</span>`
					},
				},
				{
					// Bulk Select
					targets: EmpTableColumnIndex.bulkSelect,
					visible: this.batchManager.isActive,
					searchable: false,
					orderable: false,
					title: '',
					data: null,
					render: (emp: EmployeeRecord, t, f, m) => {
						return this.batchManager.formatSelectBox(emp.id)
					},
				},
			],
			createdRow: (row, data: any, dataIndex) => {
				if (this.viewManager.currentView !== 'ACTIVE') {
					if (!data.active) {
						$(row).addClass('emp-disabled')
					}
				}
			},
			drawCallback: (settings) => {
				this.employeesTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
				this.renderCache.invalidateCache()

				// performance.mark('render:tableDraw:end')
				// const measure = performance.measure('table-draw', 'render:tableDraw:start', 'render:tableDraw:end')
				// log('performance', measure)
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('employeesTable', this.employeesTable)

		$('#employeesTable').on('page', () => {
			log('Recalculating table layout')
			this.employeesTable.columns.adjust().responsive.recalc()
			this.employeesTable['fixedHeader'].adjust()
		})

		this.employeesTable.on('responsive-display', (e, datatable, row, showHide, update) => {
			const itemTooltip: any = $('.item-tooltip')
			itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
		})
	}
}
