import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core'
import {
	CardViewAction,
	ComponentBridgeName,
	DatabaseTableName,
	DialogManager,
	EditCompleteEvent,
	EditFormAction,
	Global,
	OnboardLogRecord,
} from '@app/models'
import { CoreService } from '@app/services'

import { DateTimeHelper, log } from '@app/helpers'

@Component({
	selector: 'app-onboard-log-list',
	standalone: false,

	templateUrl: './onboard-log-list.component.html',
	styleUrl: './onboard-log-list.component.scss',
})
export class OnboardLogListComponent implements AfterContentInit {
	resource: DatabaseTableName = 'onboard_log'
	timeFormatter = DateTimeHelper.getTimeFormat()

	isLoading = true
	editAction = { recordId: null, action: 'edit' as EditFormAction, isEditing: false }

	list: Array<OnboardLogRecordWrapper> = []

	@Input() empId: number
	@Input() dialogManager = new DialogManager()

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {}

	ngAfterContentInit(): void {
		this.dialogManager.submitBtnAction = () => this.newRecord()
		this.fetchAndReload()
	}

	get searchResults(): Array<OnboardLogRecordWrapper> {
		return this.list.filter((r) => r.matchesSearch)
	}

	private loadData(): void {
		this.list = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardLogRecords().map((record) => new OnboardLogRecordWrapper(record))
		this.isLoading = false
	}

	private fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			const options = { employee_id: this.empId }
			this.coreSrvc.dbSrvc.readTable(this.resource, options).then(() => {
				this.loadData()
				resolve(true)
			})
		})
	}

	private resetDialog(): void {
		this.editAction = { recordId: null, action: 'edit', isEditing: false }
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition('main')
		this.cd.markForCheck()
	}

	public actionIconClicked(event: Event, action: CardViewAction, vm: OnboardLogRecordWrapper) {
		event.stopPropagation()
		switch (action) {
			case 'EDIT':
				this.prepareDialogForEdit(vm, 'edit', 'Manage Approval')
				break
			case 'CLONE':
				this.prepareDialogForEdit(vm, 'clone', 'Clone Record')
				break
			case 'DELETE':
				this.list.forEach((r) => (r.isConfirmingDelete = false))
				vm.isConfirmingDelete = true
				break
			case 'AUDIT':
				this.showAuditLogDialog(vm)
				break
			case 'EXPAND':
				vm.isExpanded = !vm.isExpanded
				break
		}
	}

	private prepareDialogForEdit(vm: OnboardLogRecordWrapper, action: EditFormAction, header: string): void {
		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.headerLabel = header
		this.editAction.recordId = vm.record.id
		this.editAction.action = action
		this.editAction.isEditing = true
	}

	public newRecord() {
		const vm = new OnboardLogRecordWrapper(new OnboardLogRecord())
		this.prepareDialogForEdit(vm, 'new', 'New Record')
	}

	public recordUpdated(event: EditCompleteEvent) {
		const action = event.action

		if (action === 'new' || action === 'clone') {
			this.fetchAndReload().then(() => {
				this.resetDialog()
			})
		}

		if (action === 'edit') {
			const recordId = event.recordId
			const record = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardLogRecordById(recordId)
			const vm = this.list.find((r) => r.record.id === record.id)
			vm.record = record
			vm.updateModel()
			this.resetDialog()
		}

		this.coreSrvc.dbSrvc.onboardSrvc.onboardUpdateListNeedsRefresh.next(true)
	}

	public confirmDelete(vm: OnboardLogRecordWrapper): void {
		vm.isDeleting = true
		this.coreSrvc.dbSrvc.deleteRecord(this.resource, vm.record.id).then((success) => {
			if (success) {
				this.fetchAndReload()
				this.coreSrvc.dbSrvc.onboardSrvc.onboardUpdateListNeedsRefresh.next(true)
			}
		})
	}

	private showAuditLogDialog(vm: OnboardLogRecordWrapper): void {
		setTimeout(() => {
			const recordId = vm.record.id
			const record = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardLogRecordById(recordId)
			const header = 'Audit Record'

			const globalBridge: ComponentBridgeName = 'ngBridgeGlobal'
			const bridge = window[globalBridge]
			bridge.showAuditLog('onboard_log', recordId, header)
		}, 250)
		this.dialogManager.isDialogVisible = false
	}

	///////////////////////
	// Search Management //
	///////////////////////

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.list.forEach((vm) => {
			vm.peformSearch(lcText)
		})
	}
	public toggleExpansion(isExpanded: boolean) {
		this.list.forEach((vm) => (vm.isExpanded = isExpanded))
	}
}

class OnboardLogRecordWrapper {
	record: OnboardLogRecord
	isExpanded = false
	isConfirmingDelete = false
	isDeleting = false

	empInstructions = ''
	empNotes = ''
	adminNotes = ''

	// Search properties
	searchText = ''
	matchesSearch = true
	searchTextDescription = ''
	searchTextEmpInstructions = ''
	searchTextEmpNotes = ''
	searchTextAdminNotes = ''

	constructor(record: OnboardLogRecord) {
		this.record = record
		this.updateModel()
	}

	public updateModel() {
		const record = this.record

		// Get parent definition record for data referencing
		const definition = Global.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecordById(record.onboard_definition_id)

		// Setup model properties
		this.empInstructions = definition?.emp_instructions ?? ''
		this.empNotes = record.employee_notes ?? ''
		this.adminNotes = this.record.admin_notes ?? ''

		// Setup search text properties
		this.searchTextEmpInstructions = this.empInstructions.toLowerCase()
		this.searchTextDescription = this.empNotes.toLowerCase()
		this.searchTextEmpNotes = this.empNotes.toLowerCase()
		this.searchTextAdminNotes = this.adminNotes.toLowerCase()
	}

	public peformSearch(searchText: string) {
		this.searchText = searchText
		if (!searchText) {
			this.matchesSearch = true
			return
		}
		this.matchesSearch = false

		const descriptionMatches = this.searchTextDescription.includes(searchText)
		const empInstructMatches = this.searchTextEmpInstructions.includes(searchText)
		const empNotesMatches = this.searchTextEmpNotes.includes(searchText)
		const adminNotesMatches = this.searchTextAdminNotes.includes(searchText)

		if (descriptionMatches) this.matchesSearch = true

		if (empInstructMatches || empNotesMatches || adminNotesMatches) {
			this.matchesSearch = true
			this.isExpanded = true
		}
	}
}
