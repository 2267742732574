<div class="container section-container" style="max-width: 800px; margin: 40px auto 0px auto">
	<div class="card card-body">
		<div class="row">
			<div class="col-12 record-item">
				<div class="user-name tts-hl-2">{{ userName }}</div>
				<strong class="settings-card-title"> <i class="far fa-cog" aria-hidden="true"></i> Settings</strong>
				<hr />
			</div>
		</div>

		<div>
			<div class="row">
				<!-- ADP Company -->
				<div [appAdpIvrVisible]="true" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureAdpCompanyEdit"
						(click)="adpCompanyEditBtnClicked()"
					>
						<i class="fal fa-plug config-icon"></i>ADP IVR Company
					</div>
				</div>

				<!-- ADP Profiles -->
				<div [appAdpIvrVisible]="true" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureAdpProfiles"
						(click)="adpProfilesBtnClicked()"
					>
						<i class="fal fa-plug config-icon"></i>ADP IVR Profiles
					</div>
				</div>

				<!-- ADP Pay Codes -->
				<div [appAdpIvrVisible]="true" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureAdpPromptCodes"
						(click)="adpPromptCodesBtnClicked()"
					>
						<i class="fal fa-plug config-icon"></i>ADP IVR Prompt Codes
					</div>
				</div>

				<!-- ADP Phone Numbers -->
				<div [appAdpIvrVisible]="true" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureAdpPhoneNumbers"
						(click)="adpPhoneNumbersBtnClicked()"
					>
						<i class="fal fa-plug config-icon"></i>ADP IVR Phone Numbers
					</div>
				</div>

				<!-- General Settings -->
				<div class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureAccountDefaults"
						(click)="editCompanyDefaultsBtnClicked('GENERAL')"
					>
						<i class="fal fa-cog config-icon"></i>General Settings
					</div>
				</div>

				<!-- Company Profile -->
				<div class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureAccountDefaults"
						(click)="editCompanyDefaultsBtnClicked('PROFILE')"
					>
						<i class="fal fa-address-card config-icon"></i>Company Profile
					</div>
				</div>

				<!-- Portal Preferences -->
				<div *ngIf="!isAuditMode || isInternalUser" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configurePortalPrefs"
						(click)="configureeAdminPrefsBtnClicked()"
					>
						<i class="fal fa-browser config-icon"></i>Portal Preferences
					</div>
				</div>

				<!-- Employee Application -->
				<div *ngIf="!isAuditMode || isInternalUser" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureEmpApp"
						(click)="configureEmpAppBtnClicked()"
					>
						<i class="fal fa-id-badge config-icon"></i>Employee Application
					</div>
				</div>

				<!-- Onboarding -->
				<div *ngIf="!isAuditMode && isOnboardingEnabled" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureOnboarding"
						(click)="employeeOnboardingBtnClicked()"
					>
						<i class="fal fa-screen-users config-icon"></i>Employee Onboarding
					</div>
				</div>

				<!-- Employee Scoring -->
				<div *ngIf="!isAuditMode || isInternalUser" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureEmpScore"
						(click)="employeeScoreBtnClicked()"
					>
						<i class="fal fa-chart-pie config-icon"></i>Employee Scoring
					</div>
				</div>

				<!-- Employee Surveys -->
				<div *ngIf="!isAuditMode || isInternalUser" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						id="customShiftReportsBtn"
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureChecklist"
						(click)="configureChecklistBtnClicked()"
					>
						<i class="fal fa-list-check config-icon"></i>Employee Surveys
					</div>
				</div>

				<!-- Inbound Call Blocking -->
				<div class="col-12 col-sm-6 record-item" [hidden]="isAdpIvrAdmin">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureCallBlocking"
						(click)="configureCallBlockingBtnClicked()"
					>
						<i class="fal fa-ban config-icon"></i>Inbound Call Blocking
					</div>
				</div>

				<!-- Archived Files -->
				<div class="col-12 col-sm-6 record-item" [hidden]="isAdpIvrAdmin">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureSecureFiles"
						(click)="configureArchivedFilesBtnClicked()"
					>
						<i class="fal fa-file config-icon"></i>Archived Files
					</div>
				</div>

				<!-- Call Center -->
				<div *ngIf="showCallCenterConfigButton" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configurePortalPrefs"
						(click)="configureeCallCenterBtnClicked()"
					>
						<i class="fal fa-phone-office config-icon"></i>Call Center
					</div>
				</div>

				<!-- Work Orders -->
				<div *ngIf="showWorkorderConfigButton" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureWorkorder"
						(click)="configureWorkordersBtnClicked()"
					>
						<i class="fal fa-file-invoice config-icon"></i>Work Orders
					</div>
				</div>

				<!-- Data / Analytics -->
				<div *ngIf="isInternalUser || isAnalyticsEnabled" [hidden]="isAdpIvrAdmin" class="col-12 col-sm-6 record-item">
					<div
						class="btn btn-block btn-secondary config-btn"
						[class.disabled]="!permissions.configureDataAnalytics"
						(click)="configureDataAnalyticsBtnClicked()"
					>
						<i class="fal fa-chart-pie config-icon"></i>Data / Analytics
					</div>
				</div>
			</div>
		</div>

		<!-- <app-decode-emp-app-errors *ngIf="isInternalUser"></app-decode-emp-app-errors> -->
		<app-job-site-merge-migration *ngIf="isInternalUser" #jobSiteMergeMigrationComponent [appAdpIvrVisible]="false"></app-job-site-merge-migration>
		<div [appAdpIvrVisible]="false">
			<hr />
			<app-company-logo-uploader *ngIf="permissions.configureCompanyLogo"></app-company-logo-uploader>
		</div>

		<div *ngIf="false" class="alert alert-info">
			{{ company | json }}
		</div>
	</div>
</div>

<!-- General Setting / Company Profile -->
<app-basic-modal
	#companyEditModal
	[(visible)]="companyEditDialog.show"
	modalId="companyEditModal"
	[preventBackgroundClose]="true"
	[modalHeader]="companyEditDialog.header"
	[modalFooter]="companyEditDialog.footer"
	[modalWidth]="450"
	[saveTable]="'company'"
>
	<div *ngIf="companyEditDialog.show">
		<app-company-edit
			[section]="companyEditDialog.section"
			(recordSaved)="companyRecordUpdated(); companyEditModal.showBackBtn = false"
			(actionCancelled)="companyEditDialog.show = false"
		></app-company-edit>
	</div>
</app-basic-modal>

<!-- Employee Score -->
<app-basic-modal
	[(visible)]="employeeScoreDialog.show"
	modalId="employeeScoreModal"
	[preventBackgroundClose]="true"
	[modalHeader]="employeeScoreDialog.header"
	[modalFooter]="employeeScoreDialog.footer"
	[modalWidth]="450"
	[saveTable]="'empScore'"
>
	<div *ngIf="employeeScoreDialog.show">
		<app-score-edit (scoreSaved)="employeeScoreDialog.show = false" (actionCancelled)="employeeScoreDialog.show = false"></app-score-edit>
	</div>
</app-basic-modal>

<!-- Employee Surveys -->
<app-modal-dialog *ngIf="employeeChecklistDialogManager.isDialogVisible" [dialogManager]="employeeChecklistDialogManager">
	<app-employee-checklist-list [dialogManager]="employeeChecklistDialogManager" (recordUpdated)="updateUI()"></app-employee-checklist-list>
</app-modal-dialog>

<!-- Employee Application -->
<app-basic-modal
	[(visible)]="empPrefsDialog.show"
	modalId="empPrefsModal"
	[preventBackgroundClose]="true"
	[modalHeader]="empPrefsDialog.header"
	[modalFooter]="empPrefsDialog.footer"
	[modalWidth]="450"
	[saveTable]="'empAppPrefs'"
>
	<div *ngIf="empPrefsDialog.show">
		<app-emp-prefs [group]="empPrefsDialog.group" (saveComplete)="empPrefsDialog.show = false"></app-emp-prefs>
	</div>
</app-basic-modal>

<!-- Portal Preferences -->
<app-basic-modal
	[(visible)]="adminPrefsDialog.show"
	modalId="adminPrefsModal"
	[preventBackgroundClose]="true"
	[modalHeader]="adminPrefsDialog.header"
	[modalFooter]="adminPrefsDialog.footer"
	[modalWidth]="450"
	[saveTable]="'adminPrefs'"
>
	<div *ngIf="adminPrefsDialog.show">
		<app-admin-prefs [group]="adminPrefsDialog.group" (saveComplete)="adminPrefsUpdated()"></app-admin-prefs>
	</div>
</app-basic-modal>

<!-- ADP Company -->
<app-modal-dialog *ngIf="adpCompanyEditDialogManager.isDialogVisible" [dialogManager]="adpCompanyEditDialogManager">
	<app-adp-ivr-company-edit [dialogManager]="adpCompanyEditDialogManager"></app-adp-ivr-company-edit>
</app-modal-dialog>

<!-- ADP Profiles -->
<app-modal-dialog *ngIf="adpProfilesDialogManager.isDialogVisible" [dialogManager]="adpProfilesDialogManager">
	<app-adp-ivr-profile-list [dialogManager]="adpProfilesDialogManager"></app-adp-ivr-profile-list>
</app-modal-dialog>

<!-- ADP Prompt Codes -->
<app-modal-dialog *ngIf="adpPayCodesDialogManager.isDialogVisible" [dialogManager]="adpPayCodesDialogManager">
	<app-adp-ivr-prompt-code-list [dialogManager]="adpPayCodesDialogManager"></app-adp-ivr-prompt-code-list>
</app-modal-dialog>

<!-- ADP Phone Numbers -->
<app-modal-dialog *ngIf="adpPhoneNumbersDialogManager.isDialogVisible" [dialogManager]="adpPhoneNumbersDialogManager">
	<app-adp-ivr-phone-number-list [dialogManager]="adpPhoneNumbersDialogManager"></app-adp-ivr-phone-number-list>
</app-modal-dialog>

<!-- Employee Onboarding -->
<app-modal-dialog *ngIf="employeeOnboardingDialogManager.isDialogVisible" [dialogManager]="employeeOnboardingDialogManager">
	<app-onboard-definition-list [dialogManager]="employeeOnboardingDialogManager"></app-onboard-definition-list>
</app-modal-dialog>

<!-- Inbound Call Blocking -->
<app-modal-dialog *ngIf="callBlockingDialogManager.isDialogVisible" [dialogManager]="callBlockingDialogManager">
	<app-call-blocking [dialogManager]="callBlockingDialogManager"></app-call-blocking>
</app-modal-dialog>

<!-- Archived Files (Secure Files) -->
<app-modal-dialog *ngIf="secureFilesDialogManager.isDialogVisible" [dialogManager]="secureFilesDialogManager">
	<app-secure-files-list [dialogManager]="secureFilesDialogManager"></app-secure-files-list>
</app-modal-dialog>

<!-- Call Center -->
<app-modal-dialog *ngIf="callCenterDialogManager.isDialogVisible" [dialogManager]="callCenterDialogManager">
	<app-call-center-settings [dialogManager]="callCenterDialogManager"></app-call-center-settings>
</app-modal-dialog>

<!-- Work Orders -->
<app-modal-dialog *ngIf="workorderDialogManager.isDialogVisible" [dialogManager]="workorderDialogManager">
	<app-workorder-edit [dialogManager]="workorderDialogManager"></app-workorder-edit>
</app-modal-dialog>

<!-- Health Center -->
<app-modal-dialog *ngIf="healthCenterDialogManager.isDialogVisible" [dialogManager]="healthCenterDialogManager">
	<app-visualization-prefs [dialogManager]="healthCenterDialogManager"></app-visualization-prefs>
</app-modal-dialog>
