<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div style="margin-bottom: 15px">
		<app-form-input-switch
			[(model)]="form.enabled"
			[wrapStyle]="{ 'margin-bottom': '8px' }"
			[enabledLabel]="'Shift is enabled'"
			[disabledLabel]="'Shift is disabled'"
		></app-form-input-switch>
		<app-form-input-switch
			*ngIf="form.enabled"
			[(model)]="form.enable_notifications"
			[enabledLabel]="'Notifications are enabled'"
			[disabledLabel]="'Notifications are disabled'"
		></app-form-input-switch>
	</div>

	<!-- <div class="options-block options-switch has-pointer" (mouseenter)="showShiftEnableHint = true" (mouseleave)="showShiftEnableHint = false">
		<div class="float-right">
			<p-toggleswitch [(ngModel)]="form.enabled" binary="true"></p-toggleswitch>
		</div>
		<div *ngIf="form.enabled" (click)="form.enabled = !form.enabled">
			<span class="options-title-inline png-clr-green">Shift is enabled</span>
		</div>
		<div *ngIf="!form.enabled" (click)="form.enabled = !form.enabled">
			<span class="options-title-inline png-clr-firebrick">Shift is disabled</span>
		</div>
		<div class="options-switch-hint">
			<div *ngIf="showShiftEnableHint">{{ form.enabled ? 'Click to disable' : 'Click to enable' }}</div>
		</div>
	</div> -->

	<!-- <div
		*ngIf="form.enabled"
		class="options-block options-switch has-pointer"
		(mouseenter)="showNotificationsEnableHint = true"
		(mouseleave)="showNotificationsEnableHint = false"
	>
		<div class="float-right">
			<p-toggleswitch [(ngModel)]="form.enable_notifications" binary="true"></p-toggleswitch>
		</div>
		<div *ngIf="form.enable_notifications" (click)="form.enable_notifications = !form.enable_notifications">
			<span class="options-title-inline png-clr-green">Notifications are enabled</span>
		</div>
		<div *ngIf="!form.enable_notifications" (click)="form.enable_notifications = !form.enable_notifications">
			<span class="options-title-inline png-clr-firebrick">Notifications are disabled</span>
		</div>
		<div class="options-switch-hint">
			<div *ngIf="showNotificationsEnableHint">{{ form.enable_notifications ? 'Click to disable' : 'Click to enable' }}</div>
		</div>
	</div> -->

	<div class="options-block">
		<div>
			<div class="sched-data job-label-info float-right">
				{{ schedLog.job_date }}<span *ngIf="origSchedInfo.tzAbrev"> / {{ origSchedInfo.tzAbrev }}</span>
			</div>
			<div class="options-title">Job</div>
		</div>
		<div class="input-disabled">{{ origSchedInfo.jobName }}</div>
	</div>

	<div class="options-block">
		<div class="options-title" [class.tts-hl-1]="hasScheduledEmployeeBeenModified">
			Scheduled Employee
			<div *ngIf="hasScheduledEmployeeBeenModified" class="undo-icon link-text" (click)="undo('employee_id')">
				<i class="far fa-undo"></i>
			</div>
		</div>
		<p-select
			appScrollLockSelect
			placeholder="Assign Employee"
			selectedItemsLabel="Assign Shift To: {0}"
			[autofocusFilter]="devDetect.isDesktop()"
			[options]="employeeOptions"
			[filter]="true"
			filterBy="label"
			[(ngModel)]="selectedEmployee"
			(onChange)="handleAssignmentChange()"
		>
		</p-select>
	</div>

	<div *ngIf="selectedEmployee === 0" class="form-group options-block" style="margin-top: 20px; margin-bottom: 20px">
		<div class="form-row align-items-center" [class.tts-hl-1]="hasEmployeeCountBeenModified">
			<div class="form-label-bold">
				Schedule
				<input type="tel" class="form-control form-inline emp-count-input" [(ngModel)]="form.employee_count" (keyup)="validateNumericInput()" />
				employee(s)
				<div *ngIf="hasEmployeeCountBeenModified" class="undo-icon link-text" (click)="undo('employee_count')">
					<i class="far fa-undo"></i>
				</div>
			</div>
		</div>
	</div>

	<!-- Job Date -->
	<div class="options-block" [hidden]="hasShiftStarted">
		<div class="options-title">Job Date</div>
		<app-date-time-picker
			[currentDate]="form.job_date"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Job Date'"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="form.job_date = $event"
		>
		</app-date-time-picker>
		<div *ngIf="hasJobDateChanged" class="alert alert-warning" style="margin-top: 18px">
			When changing the job date for a shift, a new shift will be created for the new date and the original shift will be disabled.
		</div>
	</div>

	<div *ngIf="!schedLog.anytime" style="margin-top: 25px; margin-bottom: 25px">
		<div class="row">
			<div class="col-6">
				<div class="options-title" [class.tts-hl-1]="hasStartBeenModified">
					Start Time
					<div *ngIf="hasStartBeenModified" class="undo-icon link-text" (click)="undo('start_time')">
						<i class="far fa-undo"></i>
					</div>
				</div>
				<app-date-time-picker
					id="START_TIME"
					[currentDate]="form.job_start"
					[pickerType]="'TIME'"
					[pickerMode]="'dialog'"
					[pickerLabel]="'Start Time'"
					[format]="is12HourFormat ? 'h:mm a' : 'HH:mm'"
					[hour12Timer]="is12HourFormat"
					(pickerDidClose)="setStartEndPickerTime('start', $event)"
				>
				</app-date-time-picker>
			</div>
			<div class="col-6">
				<div class="options-title" [class.tts-hl-1]="hasEndBeenModified">
					End Time
					<div *ngIf="hasEndBeenModified" class="undo-icon link-text" (click)="undo('end_time')">
						<i class="far fa-undo"></i>
					</div>
				</div>
				<app-date-time-picker
					id="END_TIME"
					[currentDate]="form.job_end"
					[pickerType]="'TIME'"
					[pickerMode]="'dialog'"
					[pickerLabel]="'End Time'"
					[format]="is12HourFormat ? 'h:mm a' : 'HH:mm'"
					[hour12Timer]="is12HourFormat"
					(pickerDidClose)="setStartEndPickerTime('end', $event)"
				>
				</app-date-time-picker>
			</div>
		</div>
		<div style="margin-top: 20px">
			<div *ngIf="form.start_time !== form.end_time">
				<div class="shift-length-data">{{ calculateShiftLength('FORM') }}</div>
				<div class="sched-label" [class.mod-flag]="calculateShiftLength('ORIG') !== calculateShiftLength('FORM')">
					Current shift length (hr:min)
				</div>
			</div>
			<div *ngIf="form.start_time === form.end_time" class="sched-label mod-flag">Cannot specific same start and end time</div>
		</div>
	</div>

	<!-- <div>
		<code>
			<pre>
			<div>{{ form | json }}</div>
			<div>{{ schedLog | json }}</div>
		</pre>
		</code>
	</div> -->

	<div style="margin-top: 25px">
		<hr />
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="form.open_shift" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('open_shift')" class="has-pointer">Mark as open shift</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('open_shift')"></i>
			</div>
		</div>
	</div>

	<!-- Admin Notes -->
	<div [hidden]="hasShiftStarted && !form.admin_note">
		<hr />
		<div class="options-block" style="margin: 25px 0px">
			<div *ngIf="form.admin_note && !hasShiftStarted" class="float-right link-text" (click)="form.admin_note = null">clear</div>
			<div class="options-title-inline">Pre-Shift Admin Notes</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="showHelp('admin_note')"></i>
			<div *ngIf="!hasShiftStarted">
				<textarea
					appTextAreaResizer
					class="form-control options-textarea-resizer"
					[(ngModel)]="form.admin_note"
					placeholder="Notes entered here will be copied to the time entry when it is created."
				></textarea>
			</div>
			<div *ngIf="hasShiftStarted" class="textarea-disabled">{{ form.admin_note || 'No notes entered' }}</div>
		</div>
	</div>

	<div class="sched-block">
		<div *ngIf="hasBeenModified">
			<div class="sched-label tts-hl-2" style="margin-bottom: 16px">Original Schedule</div>
			<div>
				<div *ngIf="schedLog.enabled" class="sched-data-med float-right" style="color: green">Enabled</div>
				<div *ngIf="!schedLog.enabled" class="sched-data-med float-right" style="color: gray">Disabled</div>
				<div class="sched-label" [class.mod-flag]="hasEnabledStatusBeenModified">Shift Status</div>
			</div>
			<div>
				<div class="sched-data float-right">{{ origSchedInfo.empName }}</div>
				<div class="sched-label" [class.mod-flag]="hasScheduledEmployeeBeenModified">Employee</div>
			</div>
			<div *ngIf="schedLog.employee_id === 0">
				<div class="sched-data float-right">{{ origSchedInfo.empCount }}</div>
				<div class="sched-label" [class.mod-flag]="hasEmployeeCountBeenModified">Employee Count</div>
			</div>
			<div *ngIf="origSchedInfo.start !== origSchedInfo.end">
				<div>
					<div class="sched-data float-right">{{ origSchedInfo.start }}</div>
					<div class="sched-label" [class.mod-flag]="hasStartBeenModified">Start Time</div>
				</div>
				<div>
					<div class="sched-data float-right">{{ origSchedInfo.end }}</div>
					<div class="sched-label" [class.mod-flag]="hasEndBeenModified">End Time</div>
				</div>
				<div style="margin-top: 20px">
					<div class="shift-length-data">{{ calculateShiftLength('ORIG') }}</div>
					<div class="sched-label" [class.mod-flag]="calculateShiftLength('ORIG') !== calculateShiftLength('FORM')">
						Original shift length (hr:min)
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="!hasBeenModified" style="text-align: center">
			<div class="sched-label tts-hl-2">Shift matches schedule</div>
		</div>
	</div>

	<div *ngIf="notesForScheduler" style="margin-top: 20px">
		<span class="sched-label">Notes for Scheduler: </span>
		<span>{{ notesForScheduler }}</span>
	</div>
</div>

<p-dialog
	[header]="overrideProtection.headerLabel"
	[(visible)]="overrideProtection.showDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	<div class="tts-info-msg">
		<span
			>Click <strong>Cancel</strong> to modify this schedule entry and deal with the issue. Click <strong>Submit</strong> to bypass
			{{ overrideProtection.typeLabel }} protection and record this schedule entry as is.</span
		>
	</div>
	<div style="margin-top: 18px">
		<button class="btn btn-sm btn-success float-right" (click)="submitOverrideClicked()">Submit</button>
		<button class="btn btn-sm btn-secondary" (click)="overrideProtection.showDialog = false">Cancel</button>
	</div>
</p-dialog>
