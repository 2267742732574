import { DateTimeHelper, log } from '@app/helpers'

export type BaseViewState = 'ACTIVE' | 'INACTIVE' | 'ALL'

export class BaseViewManager {
	localStorageKey = 'BaseViewManager'
	version = 1

	currentView: BaseViewState
	dayViewDate: Date
	timezone: string
	isProcessing = false

	constructor() {
		this.restore()
	}

	private restore() {
		try {
			const record = JSON.parse(localStorage.getItem(this.localStorageKey))
			if (record) {
				for (const attr in record) {
					if (record.hasOwnProperty(attr)) {
						this[attr] = record[attr]
					}
				}
				const dayViewDateStr = record['dayViewDate']
				this.dayViewDate = dayViewDateStr ? new Date(dayViewDateStr) : null
			}
		} catch (err) {
			log(`Error restoring ${this.localStorageKey}`, err)
		}
	}

	public save() {
		const saveData = {
			version: this.version,
			localStorageKey: this.localStorageKey,
			currentView: this.currentView,
			dayViewDate: this.dayViewDate,
			timezone: this.timezone,
		}
		localStorage.setItem(this.localStorageKey, JSON.stringify(saveData))
	}
}

export class BaseRecord {
	id: number
	description: string
	created: string
	updated: string

	constructor(record?: BaseRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}
