import { log } from '@app/helpers/logger'
import { Helper } from '@app/helpers/functions'
import { DateTimeHelper } from '@app/helpers/datetime'
import {
	TransactionLogRecord,
	TransactionMetaData,
	CheckInOutThreshold,
	ShiftThreshold,
	TimeEntryCallerIdMatcher,
	TransInfoHours,
	TransTableViewConfiguration,
} from '@app/models/transaction'
import { GPSResponseCalc } from '@app/models/gps-response-calc'
import { DatabaseService } from '@app/services'

import { ScheduleEntry, ScheduleOptions } from '@app/models/schedule'
import { JobRecord } from '@app/models/job'
import { RRuleSet, rrulestr } from 'rrule'
import { String } from 'aws-sdk/clients/batch'

import moment from 'moment-timezone'
import { ComponentBridgeName, RecordTagContainer } from '@app/models'

export interface IShiftTimeResult {
	start: string
	end: string
	abrv: string
	isAnytime: boolean
}

export interface IJobScheduleInput {
	start: string
	end: string
	recurrence: string
	empId: number
	jobId: number
	schedId: number
	timezone: string
}

export interface IJobScheduleShiftInfo {
	start: string
	end: string
	abrv: string
	isAnytime: boolean
}

export interface IJobScheduleDropdown {
	label: string
	value: string
	data: {
		type: 'sched' | 'job' | 'trans'
		empName: string
		description: string
		shift: string
		days: string
		count: number
		timezone: string
	}
}

export interface IJobScheduleScheduleInfo {
	label: string
	value: string
	data: {
		input: IJobScheduleInput
		type: 'sched' | 'job' | 'trans'
		empName: string
		recurName: string
		empExternalId: String
		description: string
		jobExternalId: string
		shift: string
		shiftInfo: IJobScheduleShiftInfo
		days: string
		freq: number
		freqString: string
		oneTime: boolean
		showDays: boolean
		count: number
		showCount: boolean
		timezone: string
		tzAbrv: string
		isValid: boolean
		isEnabled: boolean
		isJobActive: boolean
		isJobMultiday: boolean
		searchText: string
	}
}

class TransScheduleCount {
	key: string
	order = 1
	count = 1

	constructor(trans: TransactionLogRecord) {
		const schedCountString = trans.sched_count
		// log('Schedule Count', schedCountString)
		if (trans.sched_count) {
			const components = trans.sched_count.split(':')
			if (components.length === 3) {
				this.key = components[0]
				this.order = parseInt(components[1], 10)
				this.count = parseInt(components[2], 10)
			}
		}
	}

	get isOverLimit(): boolean {
		return this.order > this.count
	}
}

export class TransTableFormatter {
	static transTableBridge: ComponentBridgeName = 'ngBridgeTransTable'

	static activityInfo(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const emp = dbSrvc.empSrvc.getEmployeeById(trans.employee_id)
		if (emp) {
			if (emp.id === 0) {
				return `<div class="table-tag bg-firebrick item-tooltip" title="The Any Employee placeholder does not track activity">None</div>`
			}
			const lastActiveDate = emp.last_active ? DateTimeHelper.mediumDateTimeFromDateString(emp.last_active) : ''
			const lastActiveDateString = lastActiveDate ? 'Last Active: ' + lastActiveDate : 'No activity recorded'
			return emp?.active
				? `<div class="table-tag bg-green item-tooltip" title="${lastActiveDateString}">Active</div>`
				: `<div class="table-tag bg-chocolate item-tooltip" title="${lastActiveDateString}">Inactive</div>`
		} else {
			return `<div class="table-tag bg-firebrick item-tooltip" title="Employee may have been deleted or not yet synchronized">None</div>`
		}
	}

	static tagInfo(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const jobId = trans.job_id
		const transId = trans.id
		const schedId = trans.schedule_recur_id
		const isUnassignedJob = trans.job_id === dbSrvc.jobSrvc.getUnassignedJob().id

		const tags = []

		tags.push(`#jobId:${jobId}`)
		tags.push(`#transId:${transId}`)
		if (schedId) tags.push(`#schedId:${schedId}`)
		if (isUnassignedJob) tags.push(`#jobUnassigned`)
		if (trans.dup_images) tags.push('#imgErr')
		if (trans.imgErrCheckin) tags.push('#imgErrIn')
		if (trans.imgErrCheckout) tags.push('#imgErrOut')
		if (trans.hasMissingChecklist) tags.push('#clErr')

		const tagHtml = tags.join(' ')

		return `
			<div style="display:none;">
				${tagHtml}
			</div>
		`
	}

	static isEarlyOrLate(inOut: 'IN' | 'OUT', checkFor: 'EARLY' | 'LATE', threshold: number, trans: TransactionLogRecord): boolean {
		if (!threshold) {
			return false
		}
		const actualStart = DateTimeHelper.stripUtcTag(trans.actual_start)
		const actualEnd = DateTimeHelper.stripUtcTag(trans.actual_end)
		const transStartTime = trans.start_time
		const transEndTime = trans.end_time

		// Setup the time variables to look at for check in or checkout
		const actualTime = inOut === 'IN' ? actualStart : actualEnd
		const transTime = inOut === 'IN' ? transStartTime : transEndTime

		// The actual start or end time needs to exist, the transaction needs to have
		// scheduled times set and it can't be an anytime job
		if (actualTime && transTime && transStartTime !== transEndTime) {
			const jobDate = trans.job_date
			const actualMom = moment.tz(actualTime, trans.timezone)
			const schedMom = moment.tz(jobDate + 'T' + transTime, trans.timezone)
			if (inOut === 'IN') {
				// We're looking at a check in
				const diff = moment.duration(actualMom.diff(schedMom)).asMinutes()
				if (checkFor === 'EARLY') {
					if (diff < 0 && Math.abs(diff) > threshold) {
						return true
					}
				} else {
					if (diff > 0 && diff > threshold) {
						return true
					}
				}
			} else {
				// We're looking at a check out
				// Figure out if schedule goes over day boundary
				const jobDateStr = trans.job_date
				const schedStartMom = moment.tz(jobDateStr + transStartTime, 'YYYY-MM-DDHH:mm:ss', trans.timezone)
				const schedEndMom = moment.tz(jobDateStr + transEndTime, 'YYYY-MM-DDHH:mm:ss', trans.timezone)
				if (schedEndMom.isBefore(schedStartMom, 'seconds')) {
					schedMom.add(1, 'day')
				}

				const diff = moment.duration(actualMom.diff(schedMom)).asMinutes()
				if (checkFor === 'EARLY') {
					if (diff < 0 && Math.abs(diff) > threshold) {
						return true
					}
				} else {
					if (diff > 0 && diff > threshold) {
						return true
					}
				}
			}
		}
		return false
	}

	static actualShift(
		dbSrvc: DatabaseService,
		trans: TransactionLogRecord,
		isMobile: boolean,
		thresholds: CheckInOutThreshold,
		showDates: boolean,
	): string {
		const isOpenTransaction = trans.isOpen() || trans.isNoShow()
		const isScheduledJob = !!trans.schedule_recur_id
		const earlyCheckinThreshold = isScheduledJob ? thresholds.earlyCheckin : null
		const lateCheckinThreshold = isScheduledJob ? thresholds.lateCheckin : null
		const earlyCheckoutThreshold = isScheduledJob ? thresholds.earlyCheckout : null
		const lateCheckoutThreshold = isScheduledJob ? thresholds.lateCheckout : null

		const start = trans.actual_start
		const end = trans.actual_end
		let tz = trans.timezone

		// let results = ''
		if (tz === 'No Job Site') {
			log('MISSING TIMEZONE USE DEFAULT')
			const company = dbSrvc.settingSrvc.getCompany()
			log('Company Info', company)
			tz = company.timezone
		}

		const is12Hours = DateTimeHelper.format12Hour
		const startMoment = moment.tz(start, 'YYYY-MM-DD HH:mm:ss', 'UTC')
		const endMoment = moment.tz(end, 'YYYY-MM-DD HH:mm:ss', 'UTC')
		const isNoShow = !startMoment.isValid()
		const isStartValid = startMoment.isValid()
		const isEndValid = endMoment.isValid()

		const abbr = end ? endMoment.tz(tz).zoneAbbr() : startMoment.tz(tz).zoneAbbr()
		const startTimeString = is12Hours ? startMoment.tz(tz).format('h:mm a') : startMoment.tz(tz).format('HH:mm')
		const endTimeString = is12Hours ? endMoment.tz(tz).format('h:mm a') : endMoment.tz(tz).format('HH:mm')

		const actualStartDate = startMoment.tz(tz).format('MM/DD')
		const actualEndDate = endMoment.tz(tz).format('MM/DD')

		const highlightKeys = []
		const isEarlyCheckin = earlyCheckinThreshold ? TransTableFormatter.isEarlyOrLate('IN', 'EARLY', earlyCheckinThreshold, trans) : false
		if (isEarlyCheckin) highlightKeys.push('#chkhl:in:erly')
		const isLateCheckin = lateCheckinThreshold ? TransTableFormatter.isEarlyOrLate('IN', 'LATE', lateCheckinThreshold, trans) : false
		if (isLateCheckin) highlightKeys.push('#chkhl:in:lt')
		const earlyOrLateCheckinClass = isEarlyCheckin || isLateCheckin ? `class="trans-highlight-flag"` : ''

		const isEarlyCheckout = earlyCheckoutThreshold ? TransTableFormatter.isEarlyOrLate('OUT', 'EARLY', earlyCheckoutThreshold, trans) : false
		if (isEarlyCheckout) highlightKeys.push('#chkhl:out:erly')
		const isLateCheckout = lateCheckoutThreshold ? TransTableFormatter.isEarlyOrLate('OUT', 'LATE', lateCheckoutThreshold, trans) : false
		if (isEarlyCheckout) highlightKeys.push('#chkhl:out:lt')
		const earlyOrLateCheckoutClass = isEarlyCheckout || isLateCheckout ? 'class="trans-highlight-flag"' : ''

		const highlightKeysHtml = highlightKeys.length > 0 ? `<div style="display:none">${highlightKeys.join(' ')}</div>` : ''

		// If there's a start time use it's information / if a no show, then use job date for sorting
		const sortKeyString = startMoment.isValid() ? `${startMoment.format('YYYY-MM-DDTHH:mm:ss')}` : `${trans.job_date + 'T24:00:00'}`
		const sortKeyHtml = `<div style="display:none;">${sortKeyString}</div>`
		const filterKeysHtml = isOpenTransaction ? `<span style="display:none;">#optr</span>` : ``

		const actualStartDateHtml = showDates
			? `<small style="color: ${isEarlyCheckin || isLateCheckin ? 'white' : 'chocolate'}"><strong>${actualStartDate}</strong></small>&nbsp;`
			: ``
		const actualEndDateHtml = showDates
			? `<small style="color: ${isEarlyCheckout || isLateCheckout ? 'white' : 'chocolate'}"><strong>${actualEndDate}</strong></small>&nbsp;`
			: ``

		const checkinTzAbrevHtml = `<small style="color: ${
			isEarlyCheckin || isLateCheckin ? 'white' : 'chocolate'
		}">&nbsp;<strong>${abbr}</strong></small>`
		const checkoutTzAbrevHtml = `<small style="color: ${
			isEarlyCheckout || isLateCheckout ? 'white' : 'chocolate'
		}">&nbsp;<strong>${abbr}</strong></small>`

		let outputResult = ''

		const openEntryText = 'Unspecified' // trans.travel_job ? 'Open Travel' : 'Open Shift'

		if (isNoShow) {
			outputResult = `<span style="color: firebrick"><strong>No Show</strong></span>`
		} else {
			if (isEndValid) {
				// If we have both start and end
				outputResult = `<span ${earlyOrLateCheckinClass}>${actualStartDateHtml}${startTimeString}</span> - <br><span ${earlyOrLateCheckoutClass}>${actualEndDateHtml}${endTimeString}${checkoutTzAbrevHtml}</span>`
			} else {
				// If we only have a start
				let checkoutNote = ''
				const metaData = trans['outMetaData']
				if (metaData) {
					const checkoutTime = DateTimeHelper.stripUtcTag(metaData.checklistTimerCheckoutTime)
					if (checkoutTime) {
						const tz = trans.timezone
						const format = DateTimeHelper.getTimeFormat()
						const time = moment.tz(checkoutTime, tz).format('ddd Do @ ' + format)
						checkoutNote = `<i title="Checkout time recorded as<br>${time}<br>Waiting for checklist" data-bs-html="true" class="fas fa-clock item-tooltip" style="margin-left: 4px; color:chocolate"></i>`
					}
				}
				outputResult = `<span ${earlyOrLateCheckinClass}>${actualStartDateHtml}${startTimeString}${checkinTzAbrevHtml}</span>  - <br><span style="color:#777;"><strong>${openEntryText}</strong></span>${checkoutNote}`
			}
		}

		return `${sortKeyHtml}${filterKeysHtml}${highlightKeysHtml}<div>${outputResult}</div>`
	}

	static breaks(dbSrvc: DatabaseService, trans: TransactionLogRecord, shortBreakThreshold: number, longBreakThreshold: number) {
		const bridgeName: ComponentBridgeName = TransTableFormatter.transTableBridge
		const unpaidBreakTime = trans.break_time || 0
		const unpaidDur = moment.duration(unpaidBreakTime).asMinutes()
		const paidBreakTime = trans.break_time_paid || 0
		const paidDur = moment.duration(paidBreakTime).asMinutes()
		const totalDur = unpaidDur + paidDur
		const shortBreakFlag = shortBreakThreshold && totalDur < shortBreakThreshold
		const longBreakFlag = longBreakThreshold && totalDur > longBreakThreshold
		const breakClass = shortBreakFlag || longBreakFlag ? `table-modal-btn-flag` : ''
		return totalDur && totalDur !== 0
			? `<a class="btn btn-sm btn-default table-modal-btn-hl ${breakClass}"  href="#" onclick="${bridgeName}.editBreakTime(${trans.id}); return false">${totalDur}</a>`
			: `<a class="btn btn-sm btn-default table-modal-btn" href="#" onclick="${bridgeName}.editBreakTime(${trans.id}); return false"><i class="fa fa-plus"></i></a>`
	}

	static breaksDenied(trans: TransactionLogRecord) {
		const outMetaData: TransactionMetaData = trans['outMetaData']
		return outMetaData?.confirmedBreaks === false
			? `<div class="table-tag bg-firebrick item-tooltip" style="display: inline-block;margin-top: 2px;margin-left: 3px;width: 30px;" title="Employee indicates their break was denied">BD</div>`
			: ''
	}

	static duplicateImages(trans: TransactionLogRecord, bridgeTable: string) {
		const clickHandler = `onclick="${bridgeTable}.openDuplicateImagesDialog(${trans.id})"`
		return trans.dup_images
			? `<div ${clickHandler} class="table-tag bg-firebrick" style="display: inline-block;cursor: pointer;margin-top: 2px;margin-left: 3px;width:65px"><i class="far fa-image"></i> Dups</div>`
			: ''
	}

	static missingChecklist(trans: TransactionLogRecord) {
		return trans.hasMissingChecklist
			? `<div title="Missing a required<br>survey report" class="table-tag bg-firebrick item-tooltip" data-bs-html="true" style="display: inline-block;margin-top: 2px;margin-left: 3px;width:45px"><i class="far fa-square-list"></i> CL</div>`
			: ''
	}

	static detailsTags(trans: TransactionLogRecord) {
		const inMetaData: TransactionMetaData = trans['inMetaData']
		const outMetaData: TransactionMetaData = trans['outMetaData']

		const hasCheckedIn = trans.actual_start
		const hasCheckedOut = trans.actual_end

		const inFromLandline = trans.check_in_source === 'LANDLINE'
		const outFromLandline = trans.check_out_source === 'LANDLINE'
		const inFromMobile = trans.check_in_source === 'MOBILE'
		const outFromMobile = trans.check_out_source === 'MOBILE'
		const inFromKiosk = trans.check_in_source === 'KIOSK'
		const outFromKiosk = trans.check_out_source === 'KIOSK'
		const inFromMobileStation = trans.check_in_source === 'MOBILESTATION'
		const outFromMobileStation = trans.check_out_source === 'MOBILESTATION'
		const inFromStation = trans.check_in_source === 'STATION'
		const outFromStation = trans.check_out_source === 'STATION'
		const inFromEmpApp = trans.check_in_source === 'WEB'
		const outFromEmpApp = trans.check_out_source === 'WEB'
		const inFromAdmin = trans.check_in_source === 'ADMIN'
		const outFromAdmin = trans.check_out_source === 'ADMIN'

		// DEPRECATED - 2025-01-02 - use transaction based source
		// const inByPhone = inMetaData.isLandline
		// const outByPhone = outMetaData.isLandline
		// const inFromCell = inMetaData.isCellphone
		// const outFromCell = outMetaData.isCellphone
		// const inFromStation = inMetaData.isStation
		// const outFromStation = outMetaData.isStation
		// const inFromWeb = inMetaData.isWebApi
		// const outFromWeb = outMetaData.isWebApi
		// const inByAdmin = hasCheckedIn && inMetaData.isAdmin
		// const outByAdmin = hasCheckedOut && outMetaData.isAdmin

		const gpsBlockedIn = inMetaData.gpsClientError ? '#gps:blocked:in' : ''
		const gpsBlockedOut = outMetaData.gpsClientError ? '#gps:blocked:out' : ''

		// Order of precedence for Tag Filters is important
		// Const Name (tag) Naming Convention / Display Name

		// FromLandline (rsb:llp) Landline Phone / Landline
		// FromMobile (rsb:ecp) Employee Cell Phone / Mobile
		// FromKiosk (rsb:ksk) Kiosk / Kiosk
		// FromStation (rsb:sta) Station / Staion
		// FromMobileStation (rsb:mst) Mobile Station / Mobile Station
		// FromEmpApp (rsb:ewa) Employee Web App / Emp App
		// ByAdmin (rsb:sau) System Admin User / Admin

		const filterTagIn = inFromLandline
			? '#rsb:llp:in'
			: inFromMobile
				? '#rsb:ecp:in'
				: inFromKiosk
					? '#rsb:ksk:in'
					: inFromMobileStation
						? '#rsb:mst:in'
						: inFromStation
							? '#rsb:sta:in'
							: inFromEmpApp
								? '#rsb:ewa:in'
								: inFromAdmin && hasCheckedIn
									? '#rsb:sau:in'
									: ''

		const filterTagOut = outFromLandline
			? '#rsb:llp:out'
			: outFromMobile
				? '#rsb:ecp:out'
				: outFromKiosk
					? '#rsb:ksk:out'
					: outFromMobileStation
						? '#rsb:mst:out'
						: outFromStation
							? '#rsb:sta:out'
							: outFromEmpApp
								? '#rsb:ewa:out'
								: outFromAdmin && hasCheckedOut
									? '#rsb:sau:out'
									: ''

		// const tagBreaksDenied = `<span style="display:none;">#brks:denied</span>`
		const breaksDeniedTag = outMetaData.confirmedBreaks === false ? '#brks:denied' : ``

		return `<span style="display:none;">${breaksDeniedTag} ${filterTagIn} ${filterTagOut} ${gpsBlockedIn} ${gpsBlockedOut}</span>`
	}

	static gpsIcon(
		inOut: 'IN' | 'OUT',
		dbSrvc: DatabaseService,
		trans: TransactionLogRecord,
		cidMatcher: TimeEntryCallerIdMatcher,
		showTime: boolean,
		isDesktop: boolean,
		requireQRC: boolean, // Require QR code validation
	) {
		const bridgeName: ComponentBridgeName = TransTableFormatter.transTableBridge
		const delay = dbSrvc.settingSrvc.getCompany().gps_allowed_delay
		const gpsResponse = new GPSResponseCalc(trans, delay, cidMatcher, isDesktop)

		const distHtml = gpsResponse.getDistanceHtml(inOut, showTime, true, 'gps-icon-dist')
		// const timeHtml = showTime ? gpsResponse.getTimeHtml(inOut, showTime, true, 'gps-icon-time') : ''
		const imagesHtml = gpsResponse.getImagesHtml(inOut, isDesktop)
		const qrcHtml = requireQRC ? gpsResponse.getQRCStatus(inOut, isDesktop, requireQRC) : ''
		const tagsHtml = gpsResponse.gpsTags

		const notes = inOut === 'IN' ? (trans.geo_start_emp_note ?? '') : (trans.geo_end_emp_note ?? '')
		const notesTooltip = isDesktop ? 'item-tooltip' : ''
		const notesIcon = notes
			? `<i class="far fa-memo gps-icon gps-icon-notes ${notesTooltip}" title="${notes}" style="color: green"></i>`
			: `<i class="far fa-memo gps-icon gps-icon-notes ${notesTooltip} hl-gps-gray" title="No ${
					inOut === 'IN' ? 'Check-In' : 'Check-Out'
				} Notes"></i>`
		const notesHtml = `<span class="gps-icon-box">${notesIcon}</span>`

		const resultHtml = distHtml + qrcHtml + notesHtml + imagesHtml + tagsHtml

		return `<div class="gps-icon-block" onclick="${bridgeName}.jsBridgeOpenGpsDialog(${trans.id}, '${inOut}')">${resultHtml}</div>`
	}

	static hours(
		dbSrvc: DatabaseService,
		trans: TransactionLogRecord,
		viewConfig: TransTableViewConfiguration,
		breakThreshold: number,
		shiftThreshold: ShiftThreshold,
	) {
		// noShowThreshold is number of days for recent no shows
		const shortShift = shiftThreshold.shortShift
		const longShift = shiftThreshold.longShift
		const inMetaData: TransactionMetaData = trans['inMetaData']
		const info = trans['infoHours'] as TransInfoHours

		const showOnBreak = trans.break_start && !trans.actual_end && !info.hasMissingCheckout
		if (showOnBreak) {
			info.onGoing = true
			const is12Hours = DateTimeHelper.format12Hour
			const breakMom = moment(trans.break_start).tz(trans.timezone)
			if (breakMom.isValid()) {
				const breakTimeString = is12Hours ? breakMom.format('h:mm a') : breakMom.format('HH:mm')
				const onBreakHtml = `<div>On Break</div>`
				const breakTimeHtml = `<div>@ ${breakTimeString}</div>`
				const breakExceeded = moment().diff(breakMom) / 1000 / 60
				const flagColor = breakThreshold && breakExceeded > breakThreshold ? 'bg-flag-red' : ''
				return `<div class="trans-break-flag ${flagColor}">${onBreakHtml}${breakTimeHtml}</div><span style="display:none">#shft:ongoing #shft:onbreak</span>`
			}
		}

		if (info.hasNoShow) {
			let noShowTag = '#shft:noshow'
			const noShowWindowDays = viewConfig.noShowFilterDays
			const noShowWindowHours = noShowWindowDays ? noShowWindowDays * 24 : null
			const noShowLimitStartMom = moment().subtract(noShowWindowHours, 'hours')

			if (noShowWindowDays) {
				const createdMom = moment(trans.created)
				if (createdMom.isAfter(noShowLimitStartMom, 'second')) {
					noShowTag = '#shft:noshow:rcnt'
				}
			}

			return `<strong style="color: firebrick">No Show</strong><span style="display:none">${noShowTag}</span>`
		}
		if (info.hasMissingCheckout) {
			return `<strong style="color: firebrick">Missing</strong><span style="display:none">#shft:missing</span>`
		}
		if (info.hoursNotSet) {
			return '<strong style="color: firebrick">Missing</strong><span style="display:none">#shft:missing</span>'
		}

		if (info.isPending) {
			return `<strong style="color: green">Pending</strong><span style="display:none">#shft:ongoing</span>`
		}
		if (info.onGoing) {
			return '<strong style="color: green">Ongoing</strong><span style="display:none">#shft:ongoing</span>'
		}
		if (info.hasInvalid) {
			return '<div style="min-width:60px;display:inline-block;"><strong style="color: firebrick;">Invalid</strong><span style="display:none">#shft:invalid</span></div>'
		}

		const duration = moment.duration(info.timeWorked)
		const durAsHours = duration.asHours()
		const durAsMinutes = duration.asMinutes()
		const formattedDuration = DateTimeHelper.formatDuration('HH:mm', duration)

		// If it's a travel job then don't apply any duration flags
		if (trans.travel_job) {
			return `<div style="min-width: 60px; display: inline-block;">${formattedDuration}</div>`
		}

		const shortShiftHtml = `<div class="trans-highlight-flag">${formattedDuration}</div>`
		const longShiftHtml = `<div class="trans-highlight-flag">${formattedDuration}</div>`

		let shiftHtml = formattedDuration
		if (shortShift && durAsMinutes < shortShift) {
			shiftHtml = shortShiftHtml
		}
		if (longShift && durAsHours > longShift) {
			shiftHtml = longShiftHtml
		}

		const job = dbSrvc.jobSrvc.getJobById(trans.job_id)
		let hourGlassIcon = ''
		if (job?.adjust_hours_rule) {
			let adjustLabel = ''
			const adjustment = job.adjust_hours_rule
			switch (adjustment) {
				case 'FLAT_RATE':
					adjustLabel = '(Match Always)'
					break
				case 'FLAT_RATE_ALLOW_OVER':
					adjustLabel = '(Match Unless Over)'
					break
				case 'ADJUST_FOR_TARDY':
					adjustLabel = '(Match Unless Over/Tardy)'
					break
			}
			const iconClass = 'fa-solid fa-hourglass-empty tts-hl-orange item-tooltip'
			hourGlassIcon = `<i class="${iconClass}" style="margin-left: 4px" title="Hours worked adjusted to hours budgeted ${adjustLabel}"></i>`
		}

		return `<div style="min-width: 60px; display: inline-block;">${shiftHtml}${hourGlassIcon}</div>`
	}

	static alertActionJobCodeTip(dbSrvc: DatabaseService, trans: TransactionLogRecord, isSystemJob: boolean, forceWhite: boolean) {
		const isLandlineOrMobileCheckIn = trans.check_in_source === 'LANDLINE' || trans.check_in_source === 'MOBILE'
		const inMetaData: TransactionMetaData = trans['inMetaData']
		const useJobCodes = dbSrvc.settingSrvc.getCompany().useJobCodes

		const alertIcon = forceWhite
			? `<i title="Show Alert" class="fa fa-exclamation-triangle action-icon-alert" style="color:white"></i>`
			: `<i title="Show Alert" class="fa fa-exclamation-triangle action-icon-alert"></i>`

		const alertAction = trans.exception ? `<span>${alertIcon}</span>` : ''

		let jobCodeTip = ''
		if (useJobCodes) {
			const isCallIn = isLandlineOrMobileCheckIn
			const jobCode1 = inMetaData.jobCodeEntered1
			const jobCode2 = inMetaData.jobCodeEntered2
			const code1Text = jobCode1 === 0 ? '#' : jobCode1 ? jobCode1 : 'NONE'
			const code2Text = jobCode2 === 0 ? '#' : jobCode2 ? jobCode2 : 'NONE'
			const title = `${code1Text} / ${code2Text}`
			jobCodeTip = `<i class="fa fa-exclamation-circle item-tooltip action-icon-job-code" style="color:white" title="${title}"></i>`
			if (isSystemJob) {
				return `${alertAction ? alertAction : isCallIn ? jobCodeTip : ''}`
			}
		}
		if (isSystemJob) {
			return `${alertAction ? alertAction : ''}`
		}
		return ``
	}

	static externalIds(dbSrvc: DatabaseService, trans: TransactionLogRecord): string {
		const empId = trans.employee_id
		const emp = dbSrvc.empSrvc.getEmployeeById(empId)
		const empExtId = emp ? emp.external_id : ''
		const empIdClass = empExtId ? 'bg-green item-tooltip' : 'bg-chocolate item-tooltip'
		const empTooltip = empExtId ? empExtId : 'No External ID'
		const empIdHtml = `<span class="table-tag ${empIdClass}" title="${empTooltip}">Emp</span>`
		const empExtIdTag = empExtId ? `#extId:${empExtId}:emp` : ''

		const jobId = trans.job_id
		const job = dbSrvc.jobSrvc.getJobById(jobId)
		const jobExtId = job ? job.external_id : ''
		const jobIdClass = jobExtId ? 'bg-green item-tooltip' : 'bg-chocolate item-tooltip'
		const jobTooltip = jobExtId ? jobExtId : 'No External ID'
		const jobIdHtml = `<span class="table-tag ${jobIdClass}" title="${jobTooltip}">Job</span>`
		const jobExtIdTag = jobExtId ? `#extId:${jobExtId}:job` : ''

		return `<span>${empIdHtml} ${jobIdHtml}</span><div style="display:none;">${empExtIdTag} ${jobExtIdTag}</div>`
	}

	static tags(dbSrvc: DatabaseService, trans: TransactionLogRecord, filterText: string): string {
		const emp = dbSrvc.empSrvc.getEmployeeById(trans.employee_id)
		const empTags = new RecordTagContainer(emp?.tags_json).getTagLabels()
		const empTagText = empTags.length > 0 ? empTags.join('<br>') : ''
		const empHlClr = empTags.length > 0 ? 'tts-hl-green' : 'tts-hl-gray'
		// if (filterText) {
		// 	const filterComps = filterText.split(' ')
		// 	filterComps.forEach((term) => {
		// 		if (empTagText.includes(term)) empHlClr = 'tts-hl-orange'
		// 	})
		// }
		const empClass = `class="fas fa-user trans-tag-icon ${empHlClr} item-tooltip"`

		const job = dbSrvc.jobSrvc.getJobById(trans.job_id)
		const jobTags = new RecordTagContainer(job?.tags_json).getTagLabels()
		const jobTagText = jobTags.length > 0 ? jobTags.join('<br>') : ''
		const jobHlClr = jobTags.length > 0 ? 'tts-hl-green' : 'tts-hl-gray'
		// if (filterText) {
		// 	const filterComps = filterText.split(' ')
		// 	filterComps.forEach((term) => {
		// 		if (jobTagText.includes(term)) jobHlClr = 'tts-hl-orange'
		// 	})
		// }
		const jobClass = `class="far fa-tasks-alt trans-tag-icon ${jobHlClr} item-tooltip"`

		const searchHtml = `<div style="display:none">${empTagText}<br>${jobTagText}`

		return `<i title="${empTagText.toUpperCase()}" data-bs-html="true" ${empClass}></i> / <i title="${jobTagText.toUpperCase()}" data-bs-html="true" ${jobClass}></i>${searchHtml}`
	}

	static jobDate(dbSrvc: DatabaseService, trans: TransactionLogRecord): string {
		const dateString = trans.job_date
		let tz = trans.timezone
		if (!dateString) {
			return null
		}
		if (!tz) {
			return dateString
		}
		if (tz === 'No Job Site') {
			const company = dbSrvc.settingSrvc.getCompany()
			tz = company.timezone
		}
		const dayOfWeek = moment.tz(dateString, 'YYYY-MM-DD', tz).format('ddd')
		return `<div>${dateString} <small style="color: chocolate"><strong>${dayOfWeek}</strong></small></div>` // dateString + ' <small style="color: chocolate"><strong>' + dayOfWeek + '</strong></small>'
	}

	static notified(trans: TransactionLogRecord, bridgeTable: string, isMobile: boolean): string {
		const empInNote = trans.warned_flag
		const empOutNote = trans.warned_flag_out
		const empChkptNote = trans.emp_checkpoint
		const hasEmpNote = empInNote || empOutNote || empChkptNote

		const empNoteOptions = ['Employee notified for:']
		if (empInNote) {
			empNoteOptions.push('Late check in')
		}
		if (empOutNote) {
			empNoteOptions.push('Late check out')
		}
		if (empChkptNote) {
			empNoteOptions.push(empChkptNote + ' Late checkpoint(s)')
		}

		const empTitleInfo = empNoteOptions.join('<br>')

		const empResult = hasEmpNote
			? `<span data-bs-html="true" title="${empTitleInfo}" class="action-icon-box warn-highlight item-tooltip"><i class="fas fa-exclamation-circle act-ico act-ico-clr-red"></i></span>`
			: `<i title="No Notifications" class="fa fa-ban act-ico act-ico-disabled item-tooltip"></i>`

		const supInNote = trans.supervisor_flag
		const supOutNote = trans.supervisor_flag_out
		const supChkptNote = trans.sup_checkpoint
		const hasSupNote = supInNote || supOutNote || supChkptNote

		const supNoteOptions = ['Supervisor notified for:']
		if (supInNote) {
			supNoteOptions.push('Late check in')
		}
		if (supOutNote) {
			supNoteOptions.push('Late check out')
		}
		if (supChkptNote) {
			supNoteOptions.push(supChkptNote + ' Late checkpoint(s)')
		}

		const supTitleInfo = supNoteOptions.join('<br>')

		const supResult = hasSupNote
			? `<span data-bs-html="true" title="${supTitleInfo}" class="action-icon-box warn-highlight item-tooltip"><i class="fas fa-exclamation-circle act-ico act-ico-clr-red"></i></span>`
			: `<i title="No Notifications" class="fa fa-ban act-ico act-ico-disabled item-tooltip"></i>`

		const isEnabled = !!trans.enable_notifications
		const pauseFormat = DateTimeHelper.format12Hour ? 'ddd MMM Do [@] h:mm a' : 'ddd MMM Do [@] HH:mm'
		const notifyToggleHandler = `onclick="${bridgeTable}.manageNotifications(${trans.id})"`
		const notifyIconColor = trans.notify_paused_until ? 'color:chocolate;' : 'color:green'
		const pausedDateFormatted = trans.notify_paused_until
			? `Notifications Paused Until ${moment(trans.notify_paused_until).format(pauseFormat)}`
			: ''
		const notifyPauseTitle = trans.notify_paused_until ? pausedDateFormatted : 'Notifications Enabled'
		const notifyIcon = !isEnabled ? 'fa fa-bell-slash' : !!trans.notify_paused_until ? 'fa fa-bell' : 'far fa-bell'
		const pauseIcon = isEnabled
			? `<i ${notifyToggleHandler} title="${notifyPauseTitle}" class="${notifyIcon} act-ico act-ico-disabled item-tooltip" style="cursor: pointer; ${notifyIconColor}"></i>`
			: `<i ${notifyToggleHandler} title="Notifications Disabled" class="${notifyIcon} act-ico act-ico-disabled item-tooltip" style="color:firebrick; cursor:pointer"></i><span style="display:none">#paused</span>`

		return `<span style="font-size: 1.3rem">${empResult}/${supResult}/${pauseIcon}</span>`
	}

	static scheduledShift(trans: TransactionLogRecord, isDesktop: boolean): string {
		const start = trans.start_time
		const end = trans.end_time
		const timezone = trans.timezone
		const date = trans.job_date

		const shiftInfo = TransTableFormatter.makeShiftTime(start, end, timezone, date)
		if (shiftInfo.isAnytime) {
			return 'Anytime'
		}
		return `<div>${shiftInfo.start} - ${isDesktop ? '<br>' : ''}${shiftInfo.end}</div>`
	}

	static voicePrints(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const bridgeName: ComponentBridgeName = TransTableFormatter.transTableBridge
		const inMetaData: TransactionMetaData = trans['inMetaData']
		const outMetaData: TransactionMetaData = trans['outMetaData']
		const empId = trans.employee_id
		const emp = dbSrvc.empSrvc.getEmployeeById(empId)
		const inFileName = trans.in_voice_fingerprint
		const outFileName = trans.out_voice_fingerprint
		const inFromCell = inMetaData.calledFromCell
		const outFromCell = outMetaData.calledFromCell
		const inByPhone = inMetaData.calledTo && !inFromCell
		const outByPhone = outMetaData.calledTo && !outFromCell
		const inRequireVoiceprint = emp && emp.voice_fingerprint && inByPhone ? true : false
		const outRequireVoiceprint = emp && emp.voice_fingerprint && outByPhone ? true : false

		const inVoice = inFileName
			? `<i title="Check-In Voiceprint" onclick="${bridgeName}.jsBridgePlayAudio('${inFileName}')" class="fa fa-volume-up act-ico act-ico-clr-green"></i>`
			: inRequireVoiceprint
				? '<i title="Missing Check-In Voiceprint" class="fas fa-exclamation-circle act-ico act-ico-clr-red item-tooltip" aria-hidden="true"></i>'
				: '<i title="No Check-In Voiceprint" class="fa fa-ban act-ico act-ico-clr-gray item-tooltip"></i>'

		const outVoice = outFileName
			? `<i title="Check-Out Voiceprint" onclick="${bridgeName}.jsBridgePlayAudio('${outFileName}')" class="fa fa-volume-up act-ico act-ico-clr-green"></i>`
			: outRequireVoiceprint
				? '<i title="Missing Check-Out Voiceprint" class="fas fa-exclamation-circle act-ico act-ico-red item-tooltip" aria-hidden="true"></i>'
				: '<i title="No Check-Out Voiceprint" class="fa fa-ban act-ico act-ico-clr-gray item-tooltip"></i>'

		const result = `${inVoice}/${outVoice}`
		return `<span class="act-ico-wrap">${result}</span>`
	}

	// Schedules

	static makeJobScheduleDropdownScheduleItem(dbSrvc: DatabaseService, sched: ScheduleEntry, date: string): IJobScheduleScheduleInfo {
		const schedId = sched.id
		const empId = sched.employee_id
		const emp = dbSrvc.empSrvc.getEmployeeById(empId)
		const empName = emp ? emp.name.trim() : 'Employee Missing'
		const empExternalId = emp ? emp.external_id : null
		const description = sched.description || ''
		const jobId = sched.job_id
		const job = dbSrvc.jobSrvc.getJobById(jobId)
		const jobExternalId = job.external_id
		const jobName = dbSrvc.jobSrvc.jobNameForId(jobId)
		const jobCode = job?.job_code || ''
		const jobSite = dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
		const tzId = jobSite.timezone_id
		const timezone = dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)
		const count = sched.employee_count
		const startTime = sched.start_time
		const endTime = sched.end_time

		const shiftInfo = TransTableFormatter.makeShiftTime(startTime, endTime, timezone, date)
		const scheduled = shiftInfo.start === shiftInfo.end ? `Anytime Job` : `${shiftInfo.start} - ${shiftInfo.end} ${shiftInfo.abrv}`
		const days = TransTableFormatter.makeScheduledDaysForScheduleEntry(dbSrvc, sched)
		const freq = sched.getFrequency()
		const freqString = sched?.getScheduledOnString() // 'Scheduled ' + TransTableFormatter.getFrequencyNameForValue(freq)
		const oneTime = sched?.schedule_one_time

		const input: IJobScheduleInput = {
			start: sched.start_time,
			end: sched.end_time,
			recurrence: sched.recurrence,
			empId: sched.employee_id,
			jobId: sched.job_id,
			schedId: sched.id,
			timezone: timezone,
		}

		// log('EMPLOYEE NAME', '*' + empName + '*')
		return {
			label: jobName,
			value: 'sched-' + schedId,
			data: {
				input: input,
				type: 'sched',
				empName: `${empName}`,
				recurName: `${empName}`,
				empExternalId: `${empExternalId}`,
				description: `${description}`,
				jobExternalId: `${jobExternalId}`,
				shift: `${scheduled}`,
				shiftInfo: shiftInfo,
				days: `${days}`,
				freq: freq,
				freqString: freqString,
				oneTime: oneTime,
				showDays: days ? true : false,
				count: count ? count : 1,
				showCount: empName === 'Any Employee',
				timezone: timezone,
				tzAbrv: shiftInfo.abrv,
				isValid: true,
				isEnabled: sched.enabled,
				isJobActive: job ? job.active : true,
				isJobMultiday: job ? job.multi_day : false,
				searchText: `${jobCode} ${freqString} ${empName} ${freqString} ${jobName} ${empExternalId} ${jobExternalId} ${scheduled}`,
			},
		}
	}

	// Used on time entry table for job / schedule details. Doesn't need all the same things the dropdown item needs
	static makeJobSchedulePseudoSchedule(dbSrvc: DatabaseService, trans: TransactionLogRecord): IJobScheduleScheduleInfo {
		const timezone = trans.timezone
		const startTime = trans.start_time
		const endTime = trans.end_time
		const empName = trans.employee_name || 'Name Unavailable'
		const recurName = trans.recur_emp_name
		const description = trans.recur_description
		const jobId = trans.job_id
		const job = dbSrvc.jobSrvc.getJobById(jobId)
		const jobExternalId = job?.external_id || ''
		const jobName = Helper.getJobName(trans.job_description)
		const schedId = trans.schedule_recur_id
		const count = trans.employee_count
		const sched = dbSrvc.schedulerSrvc.getScheduleForId(schedId)
		const isValid = !!sched
		const isEnabled = sched ? sched.enabled : true
		const isJobActive = job ? job.active : true
		const isJobMultiday = job ? job.multi_day : false

		const shiftInfo = TransTableFormatter.makeShiftTimeForTransaction(trans)
		const scheduled = shiftInfo.start === shiftInfo.end ? `Anytime Job` : `${shiftInfo.start} - ${shiftInfo.end} ${shiftInfo.abrv}`

		const ruleStr = trans.recurrence ?? 'RRULE:FREQ=WEEKLY' // 20240310 - Added back the weekly default because of errors being thrown

		// const rule = RRule.fromString(ruleStr)
		const ruleSet = rrulestr(ruleStr, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		const daysScheduled = rule.options.byweekday || []
		const freq = rule.options.freq
		const oneTime = sched?.schedule_one_time
		const freqString = sched?.getScheduledOnString() // 'Scheduled ' + TransTableFormatter.getFrequencyNameForValue(freq)

		const result = []
		const dayOptions = dbSrvc.settingSrvc.getWkstRelativeDayOptions()
		for (const day of dayOptions) {
			if (daysScheduled.includes(day.value)) {
				result.push(day.code)
			}
		}
		const days = result.join(', ')

		const input: IJobScheduleInput = {
			start: trans.start_time,
			end: trans.end_time,
			recurrence: trans.recurrence,
			empId: trans.employee_id,
			jobId: trans.job_id,
			schedId: trans.schedule_recur_id,
			timezone: trans.timezone,
		}

		return {
			label: jobName,
			value: 'sched-' + schedId,
			data: {
				input: input,
				type: 'sched',
				empName: `${empName}`,
				recurName: `${recurName}`,
				empExternalId: null,
				description: description || '',
				jobExternalId: null,
				shift: `${scheduled}`,
				shiftInfo: shiftInfo,
				days: `${days}`,
				freq: freq,
				freqString: freqString,
				oneTime: oneTime,
				showDays: days ? true : false,
				count: count ? count : 1,
				showCount: empName === 'Any Employee',
				timezone: timezone,
				tzAbrv: shiftInfo.abrv,
				isValid: isValid,
				isEnabled: isEnabled,
				isJobActive: isJobActive,
				isJobMultiday: isJobMultiday,
				searchText: '', // `${freqString} ${empName} ${freqString} ${jobName} ${jobExternalId} ${scheduled}`
			},
		}
	}

	// Jobs

	static makeJobScheduleDropdownJobItem(dbSrvc: DatabaseService, job: JobRecord, date: string) {
		const jobId = job.id
		const jobExternalId = job?.external_id || ''
		const jobName = dbSrvc.jobSrvc.jobNameForId(jobId)
		const jobCode = job?.job_code || ''
		const jobSite = dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
		const tzId = jobSite.timezone_id
		const timezone = dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)
		const abvr = moment.tz(timezone).zoneAbbr()
		const isJobActive = job ? job.active : true

		const scheduled = `Anytime Job ` + abvr

		return {
			label: jobName,
			value: 'job-' + jobId,
			data: {
				type: 'job',
				empName: null,
				jobExternalId: jobExternalId,
				shift: `Anytime Job`,
				days: '',
				count: 1,
				timezone: timezone,
				isValid: true,
				isJobActive: isJobActive,
				searchText: `${jobCode} ${jobName} ${jobExternalId} ${scheduled}`,
			},
		}
	}

	// Check to see if this is in use anymore
	static makeJobSchedulePseudoJob(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const jobId = trans.job_id
		const job = dbSrvc.jobSrvc.getJobById(jobId)
		const jobName = Helper.getJobName(trans.job_description)
		const jobCode = job?.job_code || ''
		const jobExternalId = job?.external_id || ''
		const timezone = trans.timezone
		const count = trans.employee_count
		const startTime = trans.start_time
		const endTime = trans.end_time
		const date = trans.job_date

		const shiftInfo = TransTableFormatter.makeShiftTime(startTime, endTime, timezone, date)
		const scheduled = shiftInfo.start === shiftInfo.end ? `Anytime Job` : `${shiftInfo.start} - ${shiftInfo.end} ${shiftInfo.abrv}`

		return {
			label: jobName,
			value: 'job-' + jobId,
			data: {
				type: 'job',
				empName: null,
				shift: `${scheduled}`,
				days: '',
				count: count ? count : 1,
				timezone: timezone,
				isValid: false,
				searchText: '', // `${jobCode} ${jobName} ${jobExternalId} ${scheduled}`
			},
		}
	}

	// Transactions table Job / Schedule column formatter

	static jobScheduleInfo(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const bridgeName: ComponentBridgeName = TransTableFormatter.transTableBridge
		const showQualifier = dbSrvc.settingSrvc.getMyUserAdminPrefs().transShowJSQualifier
		const isShiftWithRecurRule = trans.schedule_log_id && trans.recurrence
		const type = trans.schedule_recur_id || isShiftWithRecurRule ? 'SCHED' : 'JOB'
		const jobId = trans.job_id
		const job = dbSrvc.jobSrvc.getJobById(trans.job_id)
		const transId = trans.id

		// Style of main schedule block
		// const schedStyle = `min-width: 190px; max-width: 300px; font-size: .9em; white-space: normal; line-height: 1.1em; color:#1d6399`

		const isUnassignedJob = job?.company_default
		if (isUnassignedJob) {
			const transAlertIcon = TransTableFormatter.alertActionJobCodeTip(dbSrvc, trans, isUnassignedJob, true)
			const transId = trans.id
			return `<div class="tts-default-font trans-job-sched-wrap" onclick="${bridgeName}.jobSchedClicked(${transId})">
				<div class="trans-sched-flag" style="background-color:firebrick;">UNASSIGNED JOB${transAlertIcon}</div>
			</div>`
		}

		const isTravelJob = trans.travel_job
		if (isTravelJob) {
			const transAlertIcon = TransTableFormatter.alertActionJobCodeTip(dbSrvc, trans, isTravelJob, true)
			const transId = trans.id
			return `<div class="tts-default-font trans-job-sched-wrap" onclick="${bridgeName}.jobSchedClicked(${transId})">
				<div class="trans-sched-flag" style="background-color:#7b22b2;">TRAVEL${transAlertIcon}</div>
			</div>`
		}

		if (type === 'SCHED') {
			// const sched = dbSrvc.schedulerSrvc.getScheduleForId(trans.schedule_recur_id)

			const info = TransTableFormatter.makeJobSchedulePseudoSchedule(dbSrvc, trans)
			const inMetaData: TransactionMetaData = trans['inMetaData']

			const jobName = info.label
			const data = info.data
			const count = data.count
			const recurName = data.recurName
			const showCount = recurName === 'Any Employee' // info.data.showCount
			const countString = showCount ? ` (${count})` : ''

			const hasEmpOverride = data.recurName !== data.empName && data.empName !== 'Any Employee' && data.empName !== 'Multi Employee'
			const empName = hasEmpOverride
				? `<div style="color:chocolate">Filling in for:</div><div>${recurName} ${countString}</div>`
				: `${data.empName} ${countString}`

			const empNameWrap = hasEmpOverride ? (trans.recur_emp_name === 'Multi Employee' ? 'Multi-Employee Schedule' : `${empName}`) : `${empName}`

			// const shiftName = data.recurName
			const days = data.days
			// const shift = data.shift
			const shiftInfo = data.shiftInfo
			const start = shiftInfo.start
			const end = shiftInfo.end
			const scheduled = start === end ? `Anytime Job` : `${shiftInfo.start} - ${shiftInfo.end}`

			// const isValid = info.data.isValid
			const isYearlyOrMonthly = data.freq === 0 || data.freq === 1
			// const freqString = sched?.getScheduledOnString() || `${TransTableFormatter.getFrequencyNameForValue(data.freq)}`
			const freqString = trans.job_date || `${TransTableFormatter.getFrequencyNameForValue(data.freq)}`
			const tzAbrev = shiftInfo.abrv
			// const isEnabled = data.isEnabled
			const isJobMultiday = data.isJobMultiday

			const summary = isYearlyOrMonthly ? freqString : days

			const infoHours = trans['infoHours'] as TransInfoHours
			let checkInRatio = ''

			if (infoHours.hasNoShow) {
				const checkedInCount = inMetaData.checkedInCount
				const requiredCount = inMetaData.requiredCount
				if (checkedInCount) {
					checkInRatio = `<div class="trans-checkin-count"><span style="font-variant: normal">${checkedInCount}/${requiredCount}</span> CHECKED IN</div>`
				}
			}

			const multidayClass = isJobMultiday ? 'tts-hl-multiday' : ''

			const dowBuilder = new ScheduledDaysHtmlString(trans)
			const dowSummary = dowBuilder.getHtmlString()

			// const schedColor = '#1d6399' // isValid && isEnabled ? '#1d6399' : '#888f95'
			return `
			<div class="tts-default-font trans-job-sched-wrap" onclick="${bridgeName}.jobSchedClicked(${transId})">
				${checkInRatio}
				<div><b>${jobName}</b></div>
				<div><b>${empNameWrap}</b></div>
				<div style="whit-space:nowrap"><b>${scheduled} ${tzAbrev}</b></div>
				<div class="${multidayClass}"><b>${dowSummary ? dowSummary : summary}</b></div>
			</div>
			`
			// <div><b>${description}</b></div>
		} else {
			const jobName = Helper.getJobName(trans.job_description)
			const job = dbSrvc.jobSrvc.getJobById(jobId)
			const isValid = job ? true : false
			const jobNote = showQualifier ? `<div class="trans-sched-flag">UNSCHEDULED</div>` : ``

			return `
			<div  class="tts-default-font trans-job-sched-wrap" onclick="${bridgeName}.jobSchedClicked(${transId})">
				${jobNote}
				<div><b>${jobName}</b></div>
			</div>
			`
		}
	}

	static getFrequencyNameForValue(freq: number) {
		if (freq === 2) {
			return 'Weekly'
		}
		if (freq === 1) {
			return 'Monthly'
		}
		if (freq === 0) {
			return 'Yearly'
		}
		return 'unknown'
	}

	static jobScheduleDetails(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const bridgeName: ComponentBridgeName = TransTableFormatter.transTableBridge
		const empId = trans.employee_id
		const emp = dbSrvc.empSrvc.getEmployeeById(empId)
		const empDetails = emp?.employee_details
		// const empDepartment = emp?.department
		// const empDetailsString = `${empDetails || ''}${empDetails && empDepartment ? ' / ' : ''}${empDepartment || ''}`
		const siteId = trans.jobsite_id
		const site = dbSrvc.siteSrvc.getJobSiteById(siteId)
		const siteDetails = site ? site.location_details : null
		const jobId = trans.job_id
		const job = dbSrvc.jobSrvc.getJobById(jobId)
		const jobDetails = job ? job.job_details : null
		const schedId = trans.schedule_recur_id
		const sched = dbSrvc.schedulerSrvc.getScheduleForId(schedId)
		const schedDetails = sched ? sched.description : null
		const showPayRate = dbSrvc.settingSrvc.getCompany()?.pay_rate
		const empPayRate = showPayRate ? trans.pay_rate : false
		const clientPayRate = showPayRate ? trans.client_pay_rate : false
		const vendorPayRate = showPayRate ? trans.vendor_pay_rate : false
		const empPayRateDetails = empPayRate ? 'Employee Pay rate from ' + trans.getPayRateSourceLabel('EMPLOYEE') : ''
		const clientPayRateDetails = clientPayRate ? 'Client Bill rate from ' + trans.getPayRateSourceLabel('CLIENT') : ''
		const vendorPayRateDetails = vendorPayRate ? 'Vendor Pay rate from ' + trans.getPayRateSourceLabel('VENDOR') : ''

		let finalPayRateDetails = []
		if (empPayRateDetails) finalPayRateDetails.push(empPayRateDetails)
		if (clientPayRateDetails) finalPayRateDetails.push(clientPayRateDetails)
		if (vendorPayRateDetails) finalPayRateDetails.push(vendorPayRateDetails)

		const finalPayRateCopy = finalPayRateDetails.join(' / ')

		const payRateHtml =
			showPayRate && finalPayRateDetails.length > 0
				? `<i title="${finalPayRateCopy}" class="fas fa-usd-circle details-icon-pay tts-hl-green item-tooltip"></i>`
				: showPayRate
					? `<i title="No Pay Rate Details" class="fas fa-usd-circle details-icon-pay details-icon-none item-tooltip"></i>`
					: ''
		const empHtml = empDetails
			? `<i title="${empDetails}" class="fas fa-user details-icon-site tts-hl-green item-tooltip"></i>`
			: `<i title="No Employee Details" class="fas fa-user details-icon-site details-icon-none item-tooltip"></i>`
		const siteHtml = siteDetails
			? `<i title="${siteDetails}" class="fas fa-map-marker-alt details-icon-site tts-hl-green item-tooltip"></i>`
			: `<i title="No Site Details" class="fas fa-map-marker-alt details-icon-site details-icon-none item-tooltip"></i>`
		const jobHtml = jobDetails
			? `<i title="${jobDetails}" class="far fa-tasks-alt details-icon-job tts-hl-green item-tooltip"></i>`
			: `<i title="No Job Details" class="far fa-tasks-alt details-icon-job details-icon-none item-tooltip"></i>`
		const schedHtml = schedDetails
			? `<i title="${schedDetails}" class="far fa-calendar details-icon-sched tts-hl-green item-tooltip"></i>`
			: `<i title="No Schedule Details" class="far fa-calendar details-icon-sched details-icon-none item-tooltip"></i>`

		const tsCount = new TransScheduleCount(trans)
		const tsColorClass = tsCount.isOverLimit ? 'tts-hl-red' : 'tts-hl-gray'
		const tsCountTitle = tsCount.isOverLimit ? 'Scheduled employee count exceeded' : 'Scheduled employee count overage detected'
		const countHtml = tsCount.key
			? `<i title="${tsCountTitle}" class="fa fa-users details-icon-count has-pointer item-tooltip ${tsColorClass}" onclick="${bridgeName}.filterCountGroup(${trans.id})"></i>`
			: ``
		const overCountTag = tsCount.isOverLimit ? `#ecover` : ``
		const countFilter = tsCount.key ? `<div style="display: none">${tsCount.key} ${overCountTag}</div>` : ``

		return sched
			? `${countFilter}<div class="details-icon-block">${payRateHtml}${empHtml}${siteHtml}${jobHtml}${schedHtml}${countHtml}</div>`
			: `<div class="details-icon-block">${payRateHtml}${empHtml}${siteHtml}${jobHtml}</div>`
	}

	/**
	 * Returns a formatted shift time based on basic parameters
	 * @param start: Start time in HH:mm:ss
	 * @param end: End time in HH:mm:ss
	 * @param timezone: Timezone string
	 * @param date?: Date for TZ abbrv in YYYY-MM-DD
	 */

	static makeShiftTime(start: string, end: string, timezone: string, dateStr: string): IShiftTimeResult {
		const is12Hours = DateTimeHelper.format12Hour

		const input = { start: start, end: end, timezone: timezone, dateStr: dateStr }
		const jobDate = dateStr ? dateStr : moment().format('YYYY-MM-DD')
		const abrv = moment(jobDate).tz(timezone).zoneAbbr()
		const isAnytime = start === end

		const startMom = start ? moment('2019-01-01T' + start) : null
		const endMom = end ? moment('2019-01-01T' + end) : null
		let startResult = 'n/a'
		let endResult = 'n/a'
		if (startMom && startMom.isValid()) {
			startResult = is12Hours ? startMom.format('h:mma') : startMom.format('HH:mm')
		}
		if (endMom && endMom.isValid()) {
			endResult = is12Hours ? endMom.format('h:mma') : endMom.format('HH:mm')
		}
		return { start: startResult, end: endResult, abrv: abrv, isAnytime: isAnytime }
	}

	// Returns an object containing shift time elements given a transaction

	static makeShiftTimeForTransaction(trans: TransactionLogRecord): IShiftTimeResult {
		return TransTableFormatter.makeShiftTime(trans.start_time, trans.end_time, trans.timezone, trans.job_date)
	}

	static makeScheduledDaysForScheduleEntry(dbSrvc: DatabaseService, sched: ScheduleEntry) {
		const result = []
		const dayOptions = dbSrvc.settingSrvc.getWkstRelativeDayOptions()
		const daysScheduled = sched.getScheduledDays()
		for (const day of dayOptions) {
			if (daysScheduled.includes(day.value)) {
				result.push(day.code)
			}
		}
		return result.join(', ')
	}
}

export class ScheduledDaysHtmlString {
	isValid = false

	selectedDay: number
	scheduledDays: Array<number> = []

	constructor(trans: TransactionLogRecord) {
		const recurrence = trans.recurrence ?? ''
		const isWeekly = recurrence.includes('FREQ=WEEKLY')
		if (isWeekly) {
			const jobDate = trans.job_date
			const scheduledDays = trans.getScheduledDays()
			if (scheduledDays.length === 0) return
			const momDayOfWeek = moment(jobDate).isoWeekday()
			if (!momDayOfWeek) return
			const selectedDay = ScheduleOptions.dayOptions.find((opt) => opt.isoValue === momDayOfWeek).value
			this.selectedDay = selectedDay
			this.scheduledDays = scheduledDays
			this.isValid = true
		}
	}

	getHtmlString(): string {
		if (!this.isValid) return null
		const wrongDay = !this.scheduledDays.includes(this.selectedDay)
		const wrongDayClass = '' // wrongDay ? 'class="trans-dow-wrong-day"' : ''
		const result = this.isValid ? `${this.scheduledDays.map((day) => this.buildDays(day)).join(', ')}` : null
		return result ? `<span ${wrongDayClass} style="font-weight: 600">${result}</span>` : null
	}

	buildDays(day: number): string {
		const hasHighlight = day === this.selectedDay
		const dayString = ScheduleOptions.dayOptions.find((opt) => opt.value === day).code
		return hasHighlight ? `<span style="color:chocolate; font-weight: 700">${dayString}</span>` : `<span>${dayString}</span>`
	}
}
