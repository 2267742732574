<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Data ...'"></app-activity-banner>

<div [hidden]="isLoading || editAction.isEditing || auditAction.isAuditing">
	<div *ngIf="list.length === 0" class="alert alert-info alert-bg-gray" style="margin-bottom: 20px">
		This section allows you to manage your prompt codes and assign them to profiles.
	</div>

	<div *ngIf="list.length > 0" style="margin-bottom: 16px">
		<div style="margin-bottom: 6px">
			<app-search-filter [debounceTimer]="350" [placeholder]="'filter records'" (search)="performSearch($event)"></app-search-filter>
		</div>
		<div style="text-align: right">
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span> |
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span>
		</div>
	</div>

	<!-- No Records -->
	<div *ngIf="list.length === 0 || searchResults.length === 0" class="no-data-block">No Records Available</div>

	<div *ngFor="let vm of list; let idx = index">
		<div *ngIf="vm.matchesSearch" class="card list-card list-card-no-shadow">
			<div class="card-body list-card-body">
				<div class="list-card-header" (click)="vm.isExpanded = !vm.isExpanded">
					<div class="float-right lc-icon-wrap">
						<i
							class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover lc-act-ico"
							title="Edit Record"
							(click)="actionIconClicked($event, 'EDIT', vm)"
						></i>
						<!-- <i
							class="far fa-copy act-ico act-ico-dark-hover lc-act-ico"
							title="Clone Record"
							(click)="actionIconClicked($event, 'CLONE', vm)"
						></i> -->
						<i
							class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover lc-act-ico"
							title="Delete Record"
							(click)="actionIconClicked($event, 'DELETE', vm)"
						></i>
						<i
							class="far fa-database act-ico act-ico-dark-hover lc-act-ico"
							[class.act-ico-highlight]="vm.record.updated"
							title="Audit Record"
							(click)="actionIconClicked($event, 'AUDIT', vm)"
						></i>
						<i
							class="fas act-ico act-ico-dark-hover lc-act-ico"
							[class.fa-chevron-right]="!vm.isExpanded"
							[class.fa-chevron-down]="vm.isExpanded"
							[class.act-ico-highlight]
							title="Expand Record"
							(click)="actionIconClicked($event, 'EXPAND', vm)"
						></i>
					</div>
					<div class="lc-title tts-hl-2">{{ vm.description }}</div>
					<div class="lc-subtitle">{{ vm.record.codeTypeLable }}</div>
					<div class="lc-description">
						<span *ngIf="!vm.assignedProfile" class="tts-hl-1">No Assigned Profile</span>
						<span *ngIf="vm.assignedProfile"><b>Assigned To:</b> {{ vm.profileName }}</span>
					</div>
				</div>
				<div *ngIf="vm.isExpanded && !vm.isConfirmingDelete">
					<div class="alert alert-bg-gray lc-alert-block">
						<div class="lc-alert-title">Code Value</div>
						<div class="lc-alert-content">{{ vm.codeValue || 'No Value Set' }}</div>
						<div class="lc-alert-title mt-12">External ID</div>
						<div class="lc-alert-content">{{ vm.externalId || 'No Value Set' }}</div>
					</div>
					<!-- <div class="pre-code">{{ vm | json }}</div> -->
				</div>
				<div *ngIf="vm.isConfirmingDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="vm.isConfirmingDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(vm)" style="width: 64px">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<i class="fa fa-loader fa-spin" *ngIf="vm.isDeleting"></i>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Edit View -->
<app-adp-ivr-prompt-code-edit
	*ngIf="editAction.isEditing"
	[recordId]="editAction.recordId"
	[action]="editAction.action"
	[dialogManager]="dialogManager"
	(recordUpdated)="recordUpdated($event)"
>
</app-adp-ivr-prompt-code-edit>

<!-- Audit View -->
<app-audit-log-record-view
	*ngIf="auditAction.isAuditing"
	[resource]="auditAction.resource"
	[recordId]="auditAction.recordId"
></app-audit-log-record-view>
