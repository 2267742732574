<div *ngIf="showInvalidScoreConfigAlert" class="alert alert-warning score-alert">
	<div><strong>Please update your settings</strong></div>
	<div class="score-alert-info">
		Recent changes to our scoring model require that you update or reset your settings. Choose 'Reset' to use our recommended settings or
		'Customize' to configure them yourself.
	</div>
	<br />
	<div>
		<button class="btn btn-sm btn-outline-secondary" style="margin-right: 15px" (click)="resetBtnClicked()">Reset</button>
		<button class="btn btn-sm btn-outline-success" (click)="customizeBtnClicked()">Customize</button>
	</div>
</div>

<!-- Batch Action Banner -->
<app-batch-action-banner [manager]="batchManager" [resource]="'employee'" (performAction)="peformBatchAction($event)"></app-batch-action-banner>

<!-- Main Table Block -->
<div class="section-table">
	<table id="employeesTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
	<app-table-footer-buttons
		[filename]="'employee_records'"
		[newBtnLabel]="'Add Employee'"
		[exportBtnLabel]="'Export Records'"
		[table]="'employeesTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
</div>

<!-- Linked Job Sites Dialog -->
<app-basic-modal
	[(visible)]="showLinkedJobSitesModal"
	modalId="linkedJobSitesModal"
	modalHeader="Linked Job Sites"
	modalFooter="{{ currentLinkedJobsite.empName }}"
	[modalWidth]="450"
	[modalHeight]="800"
	(visibleChange)="linkedSitesModalChanged($event)"
>
	<div *ngIf="!siteEditAction.isViewingSite">
		<ul>
			<li *ngFor="let site of currentLinkedJobsite.jobSites">
				<strong>{{ site.description }}</strong>
			</li>
		</ul>
	</div>
	<div *ngIf="siteEditAction.isViewingSite">
		<app-job-site-edit
			[action]="siteEditAction.action"
			[jobSiteId]="siteEditAction.recordId"
			(saveActionComplete)="saveSiteActionComplete($event)"
			(editActionCancelled)="cancelSiteEditActionComplete()"
		></app-job-site-edit>
	</div>
</app-basic-modal>

<!-- Edit Record Dialog -->
<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-employee-edit
		[action]="editAction.action"
		[employeeId]="editAction.recordId"
		[dialogManager]="editDialogManager"
		(saveActionComplete)="saveActionComplete($event)"
		(editActionCancelled)="editActionCancelled()"
	></app-employee-edit>
</app-modal-dialog>

<!-- Employee Batch Edit Dialog -->
<app-modal-dialog *ngIf="batchDialogManager.isDialogVisible" [dialogManager]="batchDialogManager">
	<app-employee-edit-batch [dialogManager]="batchDialogManager" (actionComplete)="fetchAndReloadData()"></app-employee-edit-batch>
</app-modal-dialog>

<!-- Employee App Debugger -->
<app-basic-modal
	[(visible)]="empDebugAction.showDialog"
	modalId="empDebugModal"
	[preventBackgroundClose]="true"
	[removePadding]="true"
	[modalHeader]="empDebugAction.header"
	[modalFooter]="empDebugAction.footer"
	[modalWidth]="450"
	[modalHeight]="1150"
>
	<div *ngIf="empDebugAction.showDialog" style="width: 100%; height: 100%">
		<iframe
			id="empAppDebugFrame"
			width="100%"
			height="100%"
			[src]="empDebugAction.safeUrl"
			style="flex-grow: 1; border: none; margin: 0; padding: 0"
		></iframe>
	</div>
</app-basic-modal>

<!-- Employee Score Dialog -->
<app-basic-modal
	[(visible)]="showEmpScoreModal"
	modalId="empScoreModal"
	[modalHeader]="statsEmployee()"
	[modalFooter]="statsUpdated()"
	[modalWidth]="450"
>
	<div *ngIf="showEmpScoreModal" class="score-dialog" style="line-height: 1em">
		<div class="score-data" *ngIf="currentEmployeeStats.count">
			<div style="color: chocolate">Late Notifications</div>
			<div>
				Employee notified: {{ currentEmployeeStats.warned_flag || 0 }} of
				{{ currentEmployeeStats.warned_flag_possible || 0 }}
			</div>
			<div>
				Supervisor notified: {{ currentEmployeeStats.supervisor_flag || 0 }} of
				{{ currentEmployeeStats.supervisor_flag_possible || 0 }}
			</div>
			<br />

			<div style="color: chocolate">GPS Tracking</div>
			<div>
				Location provided: {{ currentEmployeeStats.gps_completed || 0 }} of
				{{ currentEmployeeStats.gps_requests || 0 }}
			</div>
			<div>Delayed response: {{ currentEmployeeStats.gps_late || 0 }} of {{ currentEmployeeStats.gps_completed || 0 }}</div>
			<div>
				Excessive distance: {{ currentEmployeeStats.gps_distance || 0 }} of
				{{ currentEmployeeStats.gps_completed || 0 }}
			</div>

			<br />

			<span style="font-size: 1.4em">Total Score</span>
			<div
				class="emp-score-box"
				style="display: inline-block; position: absolute; margin-top: -2px; margin-left: 15px"
				[style.background-color]="currentEmployeeStats.getStyleBackgroundColor()"
				[style.color]="currentEmployeeStats.getStyleTextColor()"
			>
				{{ currentEmployeeStats.getScore() }}
			</div>
			<br />

			<br />
			<div>Total Events: {{ currentEmployeeStats.count || 0 }}</div>
			<div>Alerts Generated: {{ currentEmployeeStats.exceptions || 0 }}</div>
			<br />
			<div style="color: green">Total Hours Worked:<br />{{ statsHoursWorked() }}</div>
		</div>
		<div class="no-score-data" *ngIf="!currentEmployeeStats.count">
			<strong>Statistics for this employee have not yet been generated.</strong>
		</div>
		<div class="score-notes">
			<small
				>This is an experimental feature for tracking statistics related to employee compliance with proper check-in and check-out procedures. We
				use a rolling 30-day window to compile statistics each night. If an employee is inactivated, their statistics start fresh when they become
				active.<br /><br />Each employee starts with a score of 100 and will lose points for missing check-ins and check-outs or failure to
				provide GPS information when requested.</small
			>
		</div>
		<br />
	</div>
</app-basic-modal>

<!-- Employee App Preferences Dialog -->
<app-basic-modal
	[(visible)]="empPrefsDialog.show"
	modalId="empPrefsModal"
	[modalHeader]="empPrefsDialog.header"
	[modalFooter]="empPrefsDialog.footer"
	[modalWidth]="450"
	[saveTable]="'empAppPrefs'"
>
	<div *ngIf="empPrefsDialog.show">
		<app-emp-prefs [group]="empPrefsDialog.group" [empId]="empPrefsDialog.recordId" (saveComplete)="empAppPrefsUpdated()"></app-emp-prefs>
	</div>
</app-basic-modal>

<!-- Secure Files Dialog -->
<app-modal-dialog *ngIf="secureFilesDialogManager.isDialogVisible" [dialogManager]="secureFilesDialogManager">
	<app-secure-files-list [dialogManager]="secureFilesDialogManager" (recordUpdated)="updateRecord($event)"></app-secure-files-list>
</app-modal-dialog>

<!-- Preferences Dialog -->
<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<!-- Manage Profile -->
<app-modal-dialog *ngIf="profileDialogManager.isDialogVisible" [dialogManager]="profileDialogManager">
	<app-manage-profile [dialogManager]="profileDialogManager" (editActionComplete)="profileEditComplete($event)"></app-manage-profile>
</app-modal-dialog>

<!-- Profile Manager Dialog - DEPRECATED - 2024-10-23 - Uses profileDialogManager and app-manage-profile component -->
<!-- <p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Click To Call"
	[(visible)]="profileManager.showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<img *ngIf="!profileManager.isUpdating" [src]="profileManager.imageUrl" class="pfp-image" />
		<div *ngIf="profileManager.isUpdating" class="pfp-update-wrap"><i class="fas fa-loader fa-spin"></i></div>
	</div>
	<div style="margin-top: 12px">
		<div *ngIf="profileManager.hasImage" class="d-flex justify-content-between">
			<button class="btn btn-sm btn-danger" (click)="profileRejectBtnClicked()">{{ profileManager.isApproved ? 'Remove' : 'Reject' }}</button>
			<button [disabled]="profileManager.isApproved" class="btn btn-sm btn-success" (click)="profileApproveBtnClicked()">Approve</button>
		</div>
		<div *ngIf="!profileManager.hasImage" style="text-align: center; font-weight: 600">- No Profile Image -</div>
	</div>
</p-dialog> -->

<!-- Clone Employee Into New Company Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Clone Employee"
	[(visible)]="cloneEmployeeeManager.showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div style="width: 300px; height: 300px">
		<!-- Processing -->
		<div *ngIf="!cloneEmployeeeManager.isProcessing">
			<p-select
				appScrollLockSelect
				id="companies1"
				[filter]="cloneEmployeeeManager.allowedCompanies.length > 10"
				[options]="cloneEmployeeeManager.allowedCompanyOptions"
				[(ngModel)]="cloneEmployeeeManager.selectedCompanyId"
				placeholder="Select a company"
				[virtualScroll]="cloneEmployeeeManager.allowedCompanyOptions.length > 50"
				[virtualScrollItemSize]="32"
				[resetFilterOnHide]="true"
			>
			</p-select>
			<div class="clone-note">
				Copy <span style="color: chocolate">{{ cloneEmployeeeManager.employeeName }}</span> into the company selected above. This includes their
				address, phone #, email, pay rate, files, and other sensitive information.
			</div>
			<div class="d-flex justify-content-between">
				<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-right: 4px" (click)="cloneEmployeeeManager.showDialog = false">
					Cancel
				</button>
				<button class="btn btn-sm btn-block btn-outline-success" style="margin-left: 4px" (click)="performCloneEmployeeOperation()">Clone</button>
			</div>
		</div>
		<!-- Not processing -->
		<div *ngIf="cloneEmployeeeManager.isProcessing">
			<div class="clone-progress"><i class="fas fa-loader fa-spin"></i> Processing ...</div>
		</div>
	</div>
</p-dialog>

<!-- Audit Actions Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Audit Options"
	[(visible)]="viewAuditAction.isAuditTypePickerVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div>
			<button class="btn-menu btn-menu-primary" style="margin-top: 6px; margin-bottom: 10px" (click)="viewAuditLog(null)">
				Record Modification
			</button>
			<button class="btn-menu btn-menu-primary" style="margin-bottom: 6px" (click)="viewAuditLog('AUTHLINKACCESS')">Auth Link Access</button>
		</div>
	</div>
</p-dialog>

<!-- Onboard Log List Dialog -->
<app-modal-dialog *ngIf="onboardLogListDialogManager.isDialogVisible" [dialogManager]="onboardLogListDialogManager">
	<app-onboard-log-list [dialogManager]="onboardLogListDialogManager" [empId]="onboardLogListAction.recordId" [records]></app-onboard-log-list>
</app-modal-dialog>
