<div *ngIf="showDetails">
	<div class="float-right">
		<img class="map-icon-marker" [src]="mapIconSrc" (click)="showOnMap()" />
	</div>
	<div *ngIf="employeeName" class="meta-label">{{ employeeName }}</div>
	<div *ngIf="trans.actual_start && inOut === 'IN'" class="meta-label">
		Checked in via <span class="meta-source">{{ checkInOutSource }}</span> <br />on
		{{ trans.actual_start | dateTzFormatter: trans.timezone : dateFormat }} <br />for <span class="meta-job-desc">{{ trans.job_description }}</span>
	</div>
	<div *ngIf="trans.actual_end && inOut === 'OUT'" class="meta-label">
		Checked out via <span class="meta-source">{{ checkInOutSource }}</span> <br />on
		{{ trans.actual_end | dateTzFormatter: trans.timezone : dateFormat }} <br />for <span class="meta-job-desc">{{ trans.job_description }}</span>
	</div>

	<div *ngIf="metaData.hasData">
		<div *ngIf="metaData.callerIDNumber" class="meta-label meta-label-tm">
			<span>Called from: {{ metaData.callerIDNumber | displayPhone }}</span>
		</div>
		<div *ngIf="metaData.callerIDNumber" class="meta-label">
			Caller ID Name: {{ metaData.callerIDName ? metaData.callerIDName : '&lt; unavailable &gt;' }}
		</div>
		<div *ngIf="showUnknownLandlineNote" class="meta-label meta-label-tm hl-issues">Called from an unknown landline</div>
		<div *ngIf="metaData.employeeUnion" class="meta-label">Employee is in a union</div>
		<div *ngIf="showJobCodeBlock" class="meta-label meta-label-tm">Job Codes Entered: {{ jobCodesEnteredFormatter() }}</div>

		<div *ngIf="geoInfo.hasDistance || geoInfo.hasTime" class="meta-label-tm">
			<div *ngIf="geoInfo.hasDistance" class="d-flex justify-content-between meta-geo-block">
				<div>
					<div class="meta-label meta-label-inline">GPS Distance:</div>
					<div class="meta-geo-info" [innerHtml]="geoInfo.distance | sanitizer"></div>
				</div>
				<div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('gpsDistance')"></i>
				</div>
			</div>
			<div
				*ngIf="geoInfo.hasTime && (geoInfo.type === 'LANDLINE' || geoInfo.type === 'MOBILE')"
				class="d-flex justify-content-between meta-geo-block"
			>
				<div>
					<div class="meta-label meta-label-inline">GPS Response:</div>
					<div class="meta-geo-info" [innerHtml]="geoInfo.time | sanitizer"></div>
				</div>
				<div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('gpsTime')"></i>
				</div>
			</div>
		</div>

		<!-- <div *ngIf="geoInfo.address">
			<div class="meta-label meta-label-inline">Street Address:</div>
			<div>{{ geoInfo.address }}</div>
		</div> -->

		<!-- Unknown landline note -->

		<div *ngIf="metaData.gpsClientError && (!metaData.stationId || !!metaData.mobileStationE164)" class="meta-label meta-label-tm hl-issues">
			{{ metaData.gpsClientError }}
		</div>

		<!-- Notes -->
		<div *ngIf="trans.geo_start_emp_note || trans.geo_end_emp_note" class="meta-label-tm">
			<!-- <hr class="msg-divider" /> -->
			<div *ngIf="inOut === 'IN'"><span class="meta-label tts-hl-1">Employee Notes: </span>{{ trans.geo_start_emp_note }}</div>
			<div *ngIf="inOut === 'OUT'"><span class="meta-label tts-hl-1">Employee Notes: </span>{{ trans.geo_end_emp_note }}</div>
		</div>

		<div *ngIf="qrcRequired">
			<div *ngIf="metaData.qrcValid === true" class="meta-label hl-notify-green">QR code validation succeeded</div>
			<div *ngIf="metaData.qrcValid === false" class="meta-label hl-notify-red">QR code validation failed</div>
			<div *ngIf="metaData.qrcValid !== true && metaData.qrcValid !== false" class="meta-label hl-notify-gray">
				QR code validation not performed
			</div>
		</div>

		<div *ngIf="inOut === 'OUT' && (metaData.confirmedBreaks === true || metaData.confirmedBreaks === false) && !trans?.travel_job">
			<!-- <hr class="msg-divider" /> -->
			<div *ngIf="metaData.confirmedBreaks === true" class="meta-label-tm meta-block-msg hl-notify-green">
				Employee was given the opportunity to take their allowed breaks
			</div>
			<div *ngIf="metaData.confirmedBreaks === false" class="meta-label-tm meta-block-msg hl-notify-red">
				Employee was NOT given the opportunity to take their allowed breaks
			</div>
		</div>

		<!-- Trans Alert Message -->
		<div *ngIf="transAlertMessage">
			<hr class="msg-divider" />
			<div class="meta-alert-msg">{{ transAlertMessage }}</div>
		</div>
	</div>

	<!-- Images -->
	<app-image-files-view [images]="images" [timezone]="trans.timezone" [refTs]="refTs"></app-image-files-view>

	<div *ngIf="false && rawDataVisible" class="raw-data-block">
		<hr />
		<pre><code>{{ metaData | json }}</code></pre>
	</div>
</div>

<div *ngIf="!showDetails" class="no-info-block">
	<div class="no-info-label">No Information Available</div>
</div>
