import { EnrollmentNeedsUpdateStatus, IEnrollmentVersionable } from './enrollment'
// Merge checkpoint v1.9.02b07
export class AppVersion {
	static version = '1.9.02b08'

	static vTos = '20230619'
	static vPrivacy = '20230619'

	static getEnrollmentNeedsUpdateStatus(record: IEnrollmentVersionable): EnrollmentNeedsUpdateStatus {
		if (!record) return new EnrollmentNeedsUpdateStatus(true, true)

		const appTos = +AppVersion.vTos
		const appPrivacy = +AppVersion.vPrivacy
		const recordTos = +record.v_tos
		const recordPrivacy = +record.v_privacy

		const tosNeedsUpdate = !recordTos || recordTos < appTos
		const privacyNeedsUpdate = !recordPrivacy || recordPrivacy < appPrivacy

		return new EnrollmentNeedsUpdateStatus(tosNeedsUpdate, privacyNeedsUpdate)
	}
}
