<div class="approval-wrap">
	<div class="form-group form-group-lg">
		<div class="input-label">
			<strong class="float-right tts-hl-2" style="font-variant: small-caps" *ngIf="selectedState === 'PENDING'">pending</strong>
			<strong
				class="float-right tts-hl-2"
				style="color: green; font-variant: small-caps"
				*ngIf="selectedState === 'DIRECT' || selectedState === 'APPROVED'"
				>approved</strong
			>
			<strong class="float-right" style="color: firebrick; font-variant: small-caps" *ngIf="selectedState === 'REJECTED'">denied</strong>
			<strong>Approval Status</strong>
		</div>
		<div style="height: 40px">
			<p-select
				*ngIf="selectedState !== 'PENDING'"
				appScrollLockSelect
				id="jobSite"
				[options]="approvalStateOptions"
				[(ngModel)]="selectedState"
				[required]="true"
				(onChange)="handleDropdownStateChange($event)"
			></p-select>
			<div *ngIf="selectedState === 'PENDING'" class="d-flex justify-content-evenly" style="height: 36px">
				<button class="btn btn-sm btn-outline-danger btn-block" style="margin-right: 4px" (click)="setApprovalState('REJECTED')">Deny</button>
				<button class="btn btn-sm btn-outline-success btn-block" style="margin-left: 4px" (click)="setApprovalState('APPROVED')">Approve</button>
			</div>
		</div>
	</div>

	<div>
		<div>
			<div *ngIf="managedByName" class="managed-value float-right" style="color: green">{{ managedByName }}</div>
			<div *ngIf="!managedByName" class="managed-value float-right" style="color: chocolate">Unassigned</div>
			<div class="options-title-inline">Managed By</div>
		</div>
		<div>
			<div *ngIf="lastManagedDate" class="managed-value float-right">{{ lastManagedDate | dateFormatter: managedDateFormatter }}</div>
			<div *ngIf="!lastManagedDate" class="managed-value float-right">Not Set</div>
			<div class="options-title-inline">Last Modified</div>
		</div>
	</div>
</div>
