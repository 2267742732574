import { Component } from '@angular/core'
import { log } from '@app/helpers'
import { CompanySelectorState, DataAccessRequest, Global } from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-company-selector',
	standalone: false,

	templateUrl: './company-selector.component.html',
	styleUrl: './company-selector.component.scss',
})
export class CompanySelectorComponent {
	tagId = Global.createUuid()

	constructor(private coreSrvc: CoreService) {}

	get selector(): CompanySelectorState {
		return this.coreSrvc.dbSrvc.companySrvc.selector
	}

	get companyDropdownScrollHeight(): string {
		if (window.innerHeight > 1000) {
			return '636px'
		}
		if (window.innerHeight > 500) {
			return '400px'
		}
		return '200px'
	}

	public changeCompany() {
		log('Changing Company to', this.selector.selectedCompanyId)
		if (this.selector.currentCompanyId !== this.selector.selectedCompanyId) {
			log('Selected Company', this.selector.selectedCompanyId)
			const request = new DataAccessRequest('company_switch_list', 'switch_company', { id: this.selector.selectedCompanyId })
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				this.coreSrvc.dbSrvc.settingSrvc.clearSettingsForCompanySwitch()
				this.reloadApplication()
			})
		}
	}

	private reloadApplication() {
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
	}
}
