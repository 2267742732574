<app-activity-banner [isProcessing]="isLoading" [message]="'Loading List ...'"></app-activity-banner>

<div *ngIf="!isLoading" [hidden]="viewAuditAction.isViewingAuditLog">
	<!-- List View -->
	<div *ngIf="currentView === 'LIST'">
		<app-secure-files-upload
			(uploadProcessStarted)="uploadProcessStarted()"
			(uploadComplete)="uploadComplete($event)"
			(uploadProcessComplete)="uploadProcessComplete()"
		></app-secure-files-upload>

		<div *ngIf="list.length > 0 && deletionNote" class="alert alert-danger delete-note">{{ deletionNote }}</div>

		<!-- List Options -->
		<div *ngIf="list.length > 1">
			<div style="text-align: center; margin-bottom: 8px">
				<div class="list-option link-text" [class.disabled]="areAllItemsCollapsed" (click)="setAllItemsExpansion(false)">collapse all</div>
				/
				<div class="list-option link-text" [class.disabled]="areAllItemsExpanded" (click)="setAllItemsExpansion(true)">expand all</div>
			</div>
		</div>

		<!-- No file records available -->
		<div *ngIf="list.length === 0" class="dialog-no-records">No files available</div>
	</div>

	<!-- Has file records -->
	<div *ngIf="list.length > 0" [hidden]="currentView === 'EDIT'">
		<div *ngFor="let item of list">
			<app-secure-files-list-item
				[item]="item"
				[highlightCard]="highlightIds.includes(item.record.id)"
				(viewItem)="viewItem($event)"
				(downloadItem)="downloadItem($event)"
				(editItem)="editItem($event)"
				(unlinkItem)="unlinkItem($event)"
				(deleteItem)="deleteItem($event)"
				(auditRecord)="pickAuditType($event)"
			></app-secure-files-list-item>
		</div>
	</div>

	<!-- Edit View -->
	<div *ngIf="currentView === 'EDIT'">
		<app-secure-files-edit [dialogManager]="dialogManager" (cancelEdit)="cancelEdit()" (fileSaved)="fileSaved()"></app-secure-files-edit>
	</div>
</div>

<div *ngIf="viewAuditAction.isViewingAuditLog">
	<div *ngIf="viewAuditAction.isDataLoaded">
		<app-audit-log-diff [records]="viewAuditAction.records" [entry]="viewAuditAction.auditEntry"></app-audit-log-diff>
	</div>
	<div *ngIf="!viewAuditAction.isDataLoaded" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data...</div>
</div>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Report Options"
	[(visible)]="viewAuditAction.isAuditTypePickerVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<!-- This hidden button is to fix a rendering bug which highlights first button -->
	<div>
		<div>
			<button class="btn-menu btn-menu-primary" style="margin-top: 6px; margin-bottom: 10px" (click)="viewAuditLog(null)">
				Record Modification
			</button>
			<button class="btn-menu btn-menu-primary" style="margin-bottom: 6px" (click)="viewAuditLog('SECUREFILEACCESS')">File Access</button>
		</div>
	</div>
</p-dialog>
