<hr class="noprint" style="margin-top: 35px" />

<div *ngIf="isReady" class="footer">
	<!-- Logo -->
	<div *ngIf="logoUrl" style="margin-top: 35px; margin-bottom: 20px">
		<img [src]="logoUrl" style="max-width: 250px" />
	</div>

	<div class="noprint" style="font-size: 1rem; font-weight: 600; margin-bottom: 20px">
		<!-- Company Name / Identifier -->
		<div>
			<div id="footerCompanyName">{{ companyName }} {{ companyId }}</div>
			<div style="color: chocolate">{{ adpIvrInfo }}</div>
		</div>

		<!-- Call-In / IVR Numbers -->
		<div *ngIf="callInNumbers.length > 0" style="margin-top: 30px">
			<div *ngFor="let item of callInNumbers" class="call-in-block">
				<div class="call-in-label tts-hl-2">{{ item.label ? item.label : 'IVR Call-in Number' }}</div>
				<div>{{ item.number }}</div>
			</div>
		</div>

		<div style="margin-top: 30px; font-size: 0.9rem">
			<!-- Copyright Information -->
			<div>Copyright &copy; {{ copyrightYear }} Telephone Timesheets</div>
			<div>
				<span class="link-text" (click)="showSupportInfo = true">Contact Support</span> /
				<span class="link-text" (click)="openManual()">View Manual</span>
				<span *ngIf="chatbotAvailable"> / <span class="link-text" (click)="gotoChatbot()">Chatbot</span></span>
			</div>

			<!-- Support Information -->
			<div *ngIf="showSupportInfo" style="margin-top: 30px">
				<div>Customer Support Contacts</div>
				<a href="mailto:support@telephonetimesheets.com">support&#64;telephonetimesheets.com</a>
				<div *ngIf="companyName">
					<a *ngIf="countryCode === 'GB' || countryCode === 'JE'" href="tel:+448081645656">Call: 0808 164 5656</a>
					<a *ngIf="countryCode === 'US'" href="tel:+18882508463">Call: 1-888-250-8463</a>
				</div>
				<div class="link-text" style="margin-top: 10px" (click)="resetApplication()">Reset Application</div>
			</div>
		</div>
	</div>

	<!-- App Version / Update Button -->
	<div *ngIf="currentAppVersion" class="noprint" style="margin-bottom: 70px">
		<button class="btn btn-sm btn-outline-secondary btn-update" (click)="reloadApplication()">Check For Updates - v{{ currentAppVersion }}</button>
	</div>
</div>
