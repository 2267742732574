import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Location } from '@angular/common'

import { CompanyRecord, Timezone, DialogControlEvent, MailingAddress, GenericEmailListManager, ScheduleOptions, HelpDialogMessage } from '@app/models'
import { CoreService, PrefsService } from '@app/services'

import { log, Helper, DateTimeHelper, FormHelper } from '@app/helpers'
import { parseNumber, ParsedNumber, formatNumber, CountryCode, isValidNumber, isValidNumberForRegion } from 'libphonenumber-js'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'
import _ from 'lodash'
import { SelectItem } from 'primeng/api'
import { DropdownChangeEvent } from 'primeng/dropdown'

@Component({
	selector: 'app-company-edit',
	templateUrl: './company-edit.component.html',
	styleUrls: ['./company-edit.component.scss'],
	standalone: false,
})
export class CompanyEditComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() section: string = 'GENERAL'
	@Output() recordSaved = new EventEmitter<boolean>()

	title: string
	compForm: UntypedFormGroup

	isUpdating = false
	isInternalUser = false
	// hasAddress = false
	isIvrExternalIdOptionAvailable = false

	countryCodeData = Helper.countryIsoData.filter((code) => code.regionSupport)

	timezoneData: Array<Timezone> = []

	showNotifications = false

	showTextOptionsDialog = false

	supPhoneValid = false

	company: CompanyRecord
	// originalWebPrefs // DEPRECATED 2023-03-29

	languages

	error = {
		empDelay: false,
		empDelayOut: false,
		empDelayChkpt: false,
		empChkptFreq: false,
		supDelay: false,
		supDelayOut: false,
		supDelayChkpt: false,
	}

	vacAlertEmailListManager: GenericEmailListManager

	private subs = new Subscription()

	// Internal Options

	showCallCenterOption = false
	showInternalOptions = false
	accountStatusOptions = []
	industryOptions = []
	supervisorOptions = []
	hoursRoundingOptions = []

	defaultUnitsOptions: Array<SelectItem> = defaultUnitsOptions

	otDayOfWeekOptions: Array<SelectItem> = ScheduleOptions.dayOptions.map((day) => ({ label: day.name, value: day.isoValue }))

	constructor(
		private fb: UntypedFormBuilder,
		private location: Location,
		private coreSrvc: CoreService,
		private prefsService: PrefsService,
	) {
		// Create Vacation Email Alert Form Manager
		this.vacAlertEmailListManager = new GenericEmailListManager(coreSrvc)
		log('CompanyDetailConstructor')
		this.title = 'Account Defaults'
		this.company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.languages = this.company.getLanguageJson()
		log('Current Language', this.languages)
		this.timezoneData = this.coreSrvc.dbSrvc.settingSrvc.getSupportedTimezones()
		// this.callInNumbers = this.coreSrvc.dbSrvc.settingSrvc.getCallInNumbers()
		// this.callInNumbersE164 = this.coreSrvc.dbSrvc.settingSrvc.getCallInNumbersE164()
		// log('ADDITIONAL TIMEZONES:', this.additionalTimezones)

		// if (this.company && this.company.billing_emails != null) {
		// 	this.billingEmails = this.company.billing_emails.split(/[;|,|\s]+/)
		// }

		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.checkForIvrExternalIdAvailability() // Call before setting up form

		// Copy web prefs to check for use job code changes
		// this.originalWebPrefs = this.company.getCompanyEmpAppPrefs() // DEPRECATED 2023-03-29
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isActualUserInternal()

		this.showCallCenterOption = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallCenterConfigurable()
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'company' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.onSubmit(this.compForm)
			}
			if (eventName === 'backBtnClicked') {
				log('edit got back clicked')
				this.toggleNotifications()
			}
		}
	}

	isFormValid(): boolean {
		const isValid = this.compForm.valid && this.supPhoneValid // && this.isBillingEmailValid()
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('employeeEdit', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	ngOnInit() {
		this.setupForm()
		this.setupVacAlertEmailListManager()

		// Setup Internal Options
		this.setupAccountStatusOptions()
		this.setupIndustryOptions()
		this.setupSupervisorOptions()
		this.setupHoursRounding()
	}

	ngOnDestroy() {
		this.coreSrvc.dbSrvc.settingSrvc.isCompanyRefreshAllowed = true
		this.subs.unsubscribe()
		this.coreSrvc.notifySrvc.clear()
	}

	ngAfterViewInit() {
		// scrollTo(0, 0)
	}

	setupForm() {
		log('CompanyDetail: ngOnInit', this.company)
		const trialExpires = this.company.trial_expires ? moment(this.company.trial_expires).toDate() : null
		// this.hasAddress = !!this.company.address_json
		const address = MailingAddress.buildFromJsonSting(this.company.address_json)
		log('Address', address)
		this.coreSrvc.dbSrvc.settingSrvc.isCompanyRefreshAllowed = false
		this.compForm = this.fb.group({
			address: this.fb.group({
				addr_1: address.addr_1,
				addr_2: address.addr_2,
				addr_3: address.addr_3,
				city: address.city,
				state: address.state,
				post_code: address.post_code,
				country: address.country,
			}),
			supervisor_phone_e164: [this.company ? this.company.supervisor_phone_e164 : null, Validators.required],
			supervisor_email: [this.company ? this.company.supervisor_email : null, Validators.required],
			timezone: [this.company ? this.company.timezone : null, Validators.required],
			sms_responder_schedule: [this.company ? this.company.sms_responder_schedule : null],
			sms_responder_time: [this.company ? this.company.sms_responder_time : null],
			create_no_show: [this.company ? this.makeNotificationDelay(this.company.create_no_show, true) : null],
			b2b_shift_auto: [this.company ? this.company.b2b_shift_auto : null],
			dialingCode: [this.company ? Helper.getCountryCodeFromE164(this.company.supervisor_phone_e164) : null],
			autoLogoutTimer: this.prefsService.data.autoLogoutTimer / 60 / 60,
			name: [this.company ? this.company.name : '', Validators.required],
			confirm_breaks: [this.company ? this.company.confirm_breaks : false],
			travel: [this.company ? this.company.travel : false],
			travel_target: [this.company ? this.company.travel_target : 'ALL'],
			overage: [this.company ? this.company.overage : false],
			allowAutoCreate: [this.company ? this.company.allowAutoCreate : null],
			useJobCodes: [this.company ? this.company.useJobCodes : null],
			allow_invalid_job_code: [this.company ? this.company.allow_invalid_job_code : null],
			allow_unscheduled_job: [this.company ? this.company.allow_unscheduled_job : null],
			ivr_external_id: [this.company ? this.company.ivr_external_id : null],
			ivr_sms_breaks: [this.company ? this.company.ivr_sms_breaks : null],
			// webPrefs: [this.company ? this.company.getCompanyEmpAppPrefs() : null], // DEPRECATED 2023-03-29
			// adminPrefs: [this.company ? this.company.getCompanyAdminPrefs() : null], // DEPRECATED 2023-03-29
			useWhitelist: [this.company ? this.company.useWhitelist : false],
			emp_schedule_notify: [this.company ? this.company.emp_schedule_notify : false],
			schedule_change_notifications: [this.company ? this.company.schedule_change_notifications : false],
			notify_sms_using_schedule: [this.company ? this.company.notify_sms_using_schedule : false],

			schedule_overlap: [this.company ? this.company.schedule_overlap : true],
			blocked_numbers: [this.company ? this.company.blocked_numbers : null],
			default_supervisor_id: [this.company ? this.company.default_supervisor_id : null],
			trial_expires: [this.company ? trialExpires : null],

			// Internal Options
			industry: [this.company.industry ? this.company.industry : 'DEFAULT'],
			default_units: [this.company.default_units ? this.company.default_units : 'IMPERIAL'],
			account_status: [this.company.account_status ? this.company.account_status : 'TRIAL'],
			cost_user_month: [this.company ? this.company.cost_user_month.toFixed(2) : null],
			prepaid_balance: [this.company ? this.company.prepaid_balance.toFixed(2) : null],
			invoice_due_days: [this.company ? this.company.invoice_due_days : 7],

			auto_logout: [this.company ? this.company.auto_logout : null],
			// agent_logout_timer: [this.company ? this.company.agent_logout_timer : null], // DEPRECATED 2025-01-12 Moved to Call center settings
			checklist_timer: [this.company ? this.makeNotificationDelay(this.company.checklist_timer, false) : null],
			gps_allowed_delay: [this.company ? this.makeNotificationDelay(this.company.gps_allowed_delay, false) : null],

			fuzzy_match_distance: [this.company ? this.company.fuzzy_match_distance : null],
			require_key_delay: [FormHelper.formatIsoDuration_Hmm(this.company?.require_key_delay)],

			min_notification_interval: [FormHelper.formatIsoDuration_Hmm(this.company?.min_notification_interval)],
			max_notification_duration: [FormHelper.formatIsoDuration_Hmm(this.company?.max_notification_duration)],

			break_reminder: [FormHelper.formatIsoDuration_Hmm(this.company?.break_reminder)],
			break_start_reminder: [FormHelper.formatIsoDuration_Hmm(this.company?.break_start_reminder)],
			break_early_warning: [FormHelper.formatIsoDuration_Hmm(this.company?.break_early_warning)],
			break_type_prompt: [this.company ? this.company.break_type_prompt : false],

			hours_rounding: [this.company.hours_rounding ? this.company.hours_rounding : 'ROUNDING_NONE'],
			round_down_timer: [FormHelper.formatIsoDuration_Hmm(this.company?.round_down_timer)],
			rounddown_note_timer: [FormHelper.formatIsoDuration_Hmm(this.company?.rounddown_note_timer)],
			round_up_timer: [FormHelper.formatIsoDuration_Hmm(this.company?.round_up_timer)],
			roundup_note_timer: [FormHelper.formatIsoDuration_Hmm(this.company?.roundup_note_timer)],
			check_in_out_window: [FormHelper.formatIsoDuration_Hmm(this.company?.check_in_out_window)],
			auto_close_open_trans: [FormHelper.formatIsoDuration_Hmm(this.company?.auto_close_open_trans)],
			job_length_max: [this.company ? this.company.job_length_max : null],
			image_dup_check: [this.company ? this.company.image_dup_check : null],
			validate_qr_codes: [this.company ? this.company.validate_qr_codes : false],

			c2c_enable: [this.company ? this.company.c2c_enable : false],
			cc_enable: [this.company ? this.company.cc_enable : false],

			onboard_required: [this.company ? this.company.onboard_required : false],
			pureMobile: [this.company ? this.company.pureMobile : false],
			useIncidents: [this.company ? this.company.useIncidents : false],
			gps_prompt_extra: [this.company ? this.company.gps_prompt_extra : false],
			// gps_auto_location: [this.company ? this.company.gps_auto_location : false], // DEPRECATED 2024-08-20
			useCheckpoints: [this.company ? this.company.useCheckpoints : false],
			useCheckpointsLL: [this.company ? this.company.useCheckpointsLL : false],
			useMultiDay: [this.company ? this.company.useMultiDay : false],
			force_job_codes: [this.company ? this.company.force_job_codes : false],
			ivr_require_key_check_in: [this.company ? this.company.ivr_require_key_check_in : false],
			ncci_codes: [this.company ? this.company.ncci_codes : false],
			prevent_auto_inactive: [this.company ? this.company.prevent_auto_inactive : false],
			reject_inactive_employee: [this.company ? this.company.reject_inactive_employee : false],
			pay_rate: [this.company ? this.company.pay_rate : false],
			work_tax_location: [this.company ? this.company.work_tax_location : false],
			paid_holiday: [this.company ? this.company.paid_holiday : false],
			additional_details_notify: [this.company ? this.company.additional_details_notify : false],
			sup_notify_ongoing: [this.company ? this.company.sup_notify_ongoing : false],
			use_emp_photos: [this.company ? this.company.use_emp_photos : false],
			ssr_public_admin_notes: [this.company ? this.company.ssr_public_admin_notes : false],
			useQBOCustLocations: [this.company ? this.company.useQBOCustLocations : false],
			useQBOCustOrg: [this.company ? this.company.useQBOCustOrg : true],

			wkst: [this.company ? this.company.wkst : null],
			sms_char_limit: [this.company ? this.company.sms_char_limit : 140],

			// Reporting
			midnight_splits: [this.company ? this.company.midnight_splits : false],
			emp_cost_multiplier: [this.company ? this.company.emp_cost_multiplier : null],

			// Overtime
			ot_enable: [this.company ? this.company.ot_enable : false],
			ot_dow_start: [this.company ? this.company.ot_dow_start : null], // ISO Day of week
			ot_week_count: [this.company ? this.company.ot_week_count : null], // Number of weeks in period
			ot_threshold_week: [FormHelper.formatIsoDuration_Hmm(this.company?.ot_threshold_week)], // Durationn hr:min
			ot_threshold_day: [FormHelper.formatIsoDuration_Hmm(this.company?.ot_threshold_day)], // Duration hr:min
			max_daily_hours: [FormHelper.formatIsoDuration_Hmm(this.company?.max_daily_hours)], // Duration hr:min
		})
		// this.compForm.controls['name'].disable()
		if (!this.isIvrExternalIdOptionAvailable) {
			this.compForm.controls['ivr_external_id'].disable()
		}
		this.formatSupPhone()
		const autoLogoutTimer = this.prefsService.data.autoLogoutTimer / 60 / 60
		// log('Current Timer:', autoLogoutTimer)
	}

	setupVacAlertEmailListManager() {
		this.vacAlertEmailListManager.initWithString(this.company.emp_vacation_alert)
		this.vacAlertEmailListManager.invalidEmailAlertMsg = 'The Employee Vacation Alert email is not a valid address.'
	}

	private setupAccountStatusOptions() {
		const statusOptions = [
			{ label: 'Testing', value: 'TESTING' },
			{ label: 'Trial', value: 'TRIAL' },
			{ label: 'Prepaid', value: 'PREPAID' },
			{ label: 'Invoice', value: 'INVOICE' },
			{ label: 'ADP Invoice', value: 'ADP_INVOICE' },
			{ label: 'Billing OK', value: 'BILLING_OK' },
			{ label: 'Billing Payment Failed', value: 'BILLING_PAYMENT_FAILED' },
			{ label: 'Billing Portal Locked', value: 'BILLING_PORTAL_LOCKED' },
			{ label: 'Billing Account Locked', value: 'BILLING_ACCOUNT_LOCKED' },
			{ label: 'Billing Needs Info', value: 'BILLING_NEED_INFO' },
			{ label: 'Cancel Pending', value: 'CANCEL_PENDING' },
			{ label: 'Locked / Suspended', value: 'LOCKED_SUSPENDED' },
			{ label: 'Deleted', value: 'DELETED' },
		]
		this.accountStatusOptions = statusOptions
	}

	private setupIndustryOptions() {
		this.industryOptions = this.coreSrvc.dbSrvc.settingSrvc.getIndustryDropdown()
	}

	private setupSupervisorOptions() {
		this.supervisorOptions = this.coreSrvc.dbSrvc.settingSrvc.getUsersDropdownData()
	}

	private setupHoursRounding() {
		this.hoursRoundingOptions = this.coreSrvc.dbSrvc.settingSrvc.getHoursRoundingDropdown()
	}

	formatSupPhone() {
		const countryCode: CountryCode = this.compForm.get('dialingCode').value
		const inputNumber = this.compForm.get('supervisor_phone_e164').value

		if (inputNumber && inputNumber.length > 3 && isValidNumberForRegion(inputNumber, countryCode)) {
			const parsedNumber = parseNumber(inputNumber, countryCode) as ParsedNumber
			const formattedNumber = formatNumber(parsedNumber, 'NATIONAL')
			this.supPhoneValid = true
			this.compForm.patchValue({ supervisor_phone_e164: formattedNumber })
		} else {
			this.supPhoneValid = false
		}
	}

	clearError(field: string) {
		this.error[field] = false
	}

	makeNotificationDelay(delay: string, allowNull: boolean): number {
		if (allowNull) {
			if (!delay) {
				return null
			}
		}
		return this.formatDurationInMin(delay)
	}

	onAccountStatusChange(event: DropdownChangeEvent) {
		const status = this.compForm.get('account_status').value
		if (status === 'TESTING') {
			this.compForm.get('cost_user_month').setValue('0.00')
			this.coreSrvc.notifySrvc.notify(
				'info',
				'User Cost Updated',
				'The cost per user/month has been set to $0.00 in order to accomodate Stripe billing flow for testing accounts.',
			)
		}
	}

	onSubmit({ value, valid }: { value: CompanyRecord; valid: boolean }): boolean {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.compForm)

		value.supervisor_email = Helper.trimWhitespace(value.supervisor_email)
		const supEmailValid = Helper.isValidEmail(value.supervisor_email)
		if (!supEmailValid) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Supervisor Email', 'Please enter a valid default supervisor email address.')
			return false
		}

		const vacAlertEmailsValid = this.vacAlertEmailListManager.prepareForSubmission()
		log('isVacAlertEmailListValid', vacAlertEmailsValid)
		if (!vacAlertEmailsValid) {
			return false
		}

		// Make the update record and update the database

		const record = this.makeUpdateRecord()
		if (!record) {
			return
		}

		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('company', record).then((success) => {
			if (success) {
				this.recordSaved.emit(true)
			} else {
				this.isUpdating = false
			}
		})
		return false
	}

	onCancel(): boolean {
		this.location.back()
		return false
	}

	toggleCheckbox(prop: string) {
		this.compForm.get(prop).setValue(!this.compForm.get(prop).value)
		switch (prop) {
			case 'useJobCodes':
				this.handleJobCodesChange()
		}
	}

	handleJobCodesChange() {
		localStorage.removeItem('DataTables_jobsTable_/')
		const isUsingJobCodes = this.compForm.get('useJobCodes').value
		if (isUsingJobCodes) {
			this.compForm.get('force_job_codes').setValue(true)
			this.compForm.get('pureMobile').setValue(true)
		} else {
			this.compForm.get('force_job_codes').setValue(false)
		}
	}

	toggleLanguageCheckbox(code: string) {
		log('Toggling language', code)
		this.languages[code] = !this.languages[code]
	}

	formatLanguageName(code: string) {
		switch (code) {
			case 'en':
				return 'English Prompts'
			case 'es':
				return 'Spanish Prompts'
			case 'fr':
				return 'French Prompts'
			case 'cn':
				return 'Chinese Prompts'
			case 'ru':
				return 'Russian Prompts'
			default:
				return 'Unknown Language'
		}
	}

	getLangCodeIndex(code: string): number {
		const langList = this.languages.order as Array<string>
		return langList.indexOf(code)
	}

	canMoveLangUp(code: string): boolean {
		const index = this.getLangCodeIndex(code)
		return index > 0
	}

	canMoveLangDown(code: string): boolean {
		const langList = this.languages.order as Array<string>
		const index = this.getLangCodeIndex(code)
		return index < langList.length - 1
	}

	swapLang(array, idxA, idxB) {
		const temp = array[idxA]
		array[idxA] = array[idxB]
		array[idxB] = temp
	}

	adjustLangOrder(code: string, dir: string) {
		if (dir === 'UP') {
			if (!this.canMoveLangUp(code)) {
				return
			}
		}
		if (dir === 'DOWN') {
			if (!this.canMoveLangDown(code)) {
				return
			}
		}
		log('Moving', code, dir)
		const list = this.languages.order as Array<string>
		const newList = [...list]
		const currentIndex = this.getLangCodeIndex(code)
		const newIndex = dir === 'UP' ? currentIndex - 1 : currentIndex + 1
		const temp = newList[currentIndex]
		newList[currentIndex] = newList[newIndex]
		newList[newIndex] = temp
		this.languages.order = newList
		log('New Json', JSON.stringify(this.languages))
	}

	isLanguageSettingValid(): boolean {
		const list = this.languages.order as Array<string>
		const setList = list.map((code) => this.languages[code])
		let hasLangSet = false
		setList.forEach((value) => {
			if (value) {
				hasLangSet = true
			}
		})
		return hasLangSet ? true : false
	}

	setAutoLogout(event) {
		const value = event.target.value
		log('Change Timeout To', value)
		const msTimer = value * 60 * 60
		this.prefsService.data.autoLogoutTimer = msTimer
		this.prefsService.save()
	}

	formatDurationInMin(duration: string): number {
		if (!duration || duration === '') {
			return 0
		}
		return moment.duration(duration).asMinutes()
	}

	formatDuration(input: string, includeSeconds = false): string {
		if (!input) {
			return null
		}
		const intValue = parseInt(input, 10)
		if (!intValue) {
			return null
		}

		const dur = moment.duration(intValue, 'minutes')
		if (dur) {
			const result = DateTimeHelper.formatDuration('HH:mm:ss', dur)
			return result
		}
		return null
	}

	validateDurationHrsAndMinutes(prop: string) {
		const value = this.compForm.get(prop).value
		let adjustedValue = value
		if (!`${adjustedValue}`.includes(':')) {
			adjustedValue = '0:' + adjustedValue
		}
		const dur = moment.duration(adjustedValue)
		if (!dur.isValid()) {
			this.compForm.get(prop).setValue(null)
		}
	}

	validateNumericInput(input) {
		const value = this.compForm.get(input).value
		const intValue = parseInt(value, 10)
		if (intValue) {
			this.compForm.get(input).setValue(intValue)
		} else {
			this.compForm.get(input).setValue(null)
		}
	}

	validateFloat(prop: string) {
		const value = this.compForm.get(prop).value
		const validated = parseFloat(value)
		if (!validated) {
			this.compForm.get(prop).setValue('0.00')
		} else {
			this.compForm.get(prop).setValue(validated.toFixed(2))
		}
	}

	isValidDiscount(): boolean {
		// If this is not an internal user, just say the cost_user_month is valid since they can't modify it
		if (!this.isInternalUser) return true

		const baseRate = 5
		const inputValue = parseFloat(`${this.compForm.get('cost_user_month').value}`)

		// Setup rates that should not be checked. Currently 0.00 and 0.99
		if (inputValue === 0) return true

		// Calculate the discount percentage from the input value
		const discountPercentage = parseFloat(((1 - inputValue / baseRate) * 100).toFixed(2))

		// Check if the discount percentage is an integer and within the range of 0 to 100
		if (discountPercentage % 1 === 0 && discountPercentage >= 0 && discountPercentage <= 100) {
			return true
		} else {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Discount', 'The cost per user/month entered is not valid for Stripe discount rules.')
			return false
		}
	}

	formatFloat(prop: string): string {
		const value = this.compForm.get(prop).value
		if (!value) return '0.00'
		return parseFloat(value).toFixed(2)
	}

	private makeJobLengthMaxUpdateValue(): number {
		const value = this.compForm.get('job_length_max').value
		const intValue = parseInt(value, 10)
		return intValue ? intValue : 16
	}

	makeUpdateRecord() {
		// Check to see if language settings are valid
		if (!this.isLanguageSettingValid()) {
			this.coreSrvc.notifySrvc.notify('error', 'Call-In Prompts', 'You must have at least one language selected for Call-In Prompts.')
			return null
		}

		if (!this.isValidDiscount()) {
			return null
		}

		const form = this.compForm.value
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany().getUpdateRecord()
		const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		// Validate Supervisor Phone
		const supPhone = this.compForm.get('supervisor_phone_e164').value
		const countryCode: CountryCode = this.compForm.get('dialingCode').value
		const parsedNumber = parseNumber(supPhone, countryCode) as ParsedNumber
		if (isValidNumber(parsedNumber)) {
			company.supervisor_phone_e164 = formatNumber(parsedNumber, 'E.164')
		} else {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Invalid Phone Number',
				'Supervisor mobile phone is not a valid number for your selected dialing prefix.',
			)
			return null
		}

		if (!this.isValidDiscount()) return null

		const hasAddress = !!this.compForm.get('address.addr_1').value
		const formAddress = this.compForm.get('address').value
		company.address_json = hasAddress ? JSON.stringify(formAddress) : null

		// company.admin_prefs_json = JSON.stringify(this.compForm.get('adminPrefs').value) || null // DEPRECATED 2023-03-29
		company.allow_cell_calls = this.company.allow_cell_calls
		company.blocked_numbers = this.compForm.get('blocked_numbers').value
		company.emp_score_config = this.company.emp_score_config
		company.emp_schedule_notify = this.compForm.get('emp_schedule_notify').value
		company.schedule_change_notifications = this.compForm.get('schedule_change_notifications').value
		company.notify_sms_using_schedule = this.compForm.get('notify_sms_using_schedule').value
		company.emp_vacation_alert = this.vacAlertEmailListManager.emailListString
		company.create_no_show = this.formatDuration(form.create_no_show)
		// b2b_shift_auto is tied to create_no_show. If create_no_show is not set, b2b_shift_auto must be false
		company.b2b_shift_auto = company.create_no_show ? this.compForm.get('b2b_shift_auto').value : false
		company.language_json = JSON.stringify(this.languages)
		company.name = this.compForm.get('name').value
		company.travel = this.compForm.get('travel').value
		company.travel_target = this.compForm.get('travel_target').value
		company.overage = form.overage
		company.supervisor_email = form.supervisor_email
		company.sms_responder_schedule = form.sms_responder_schedule
		company.sms_responder_time = form.sms_responder_time
		company.timezone = form.timezone
		company.allowAutoCreate = this.compForm.get('allowAutoCreate').value
		company.ivr_sms_breaks = this.compForm.get('ivr_sms_breaks').value
		company.ivr_external_id = this.compForm.get('ivr_external_id').value
		company.confirm_breaks = this.compForm.get('confirm_breaks').value
		company.useJobCodes = this.compForm.get('useJobCodes').value
		company.allow_invalid_job_code = this.compForm.get('allow_invalid_job_code').value
		company.allow_unscheduled_job = this.compForm.get('allow_unscheduled_job').value
		company.useWhitelist = this.compForm.get('useWhitelist').value
		company.schedule_overlap = this.compForm.get('schedule_overlap').value
		company.default_supervisor_id = this.compForm.get('default_supervisor_id').value

		// Manage Internal Options
		company.industry = this.compForm.get('industry').value
		company.default_units = this.compForm.get('default_units').value
		company.account_status = this.compForm.get('account_status').value

		// Trial Expiration
		company.trial_expires = moment(form.trial_expires).format('YYYY-MM-DD')

		company.auto_logout = parseInt(form.auto_logout, 10) ?? null
		// company.agent_logout_timer = parseInt(form.agent_logout_timer, 10) ?? null // DEPRECATED 2025-01-12 - Moved to call center settings
		company.checklist_timer = this.formatDuration(form.checklist_timer)
		company.gps_allowed_delay = this.formatDuration(form.gps_allowed_delay)

		company.cost_user_month = this.compForm.get('cost_user_month').value
		company.prepaid_balance = this.compForm.get('prepaid_balance').value ?? 0
		company.invoice_due_days = this.compForm.get('invoice_due_days').value ?? 7

		company.fuzzy_match_distance = this.compForm.get('fuzzy_match_distance').value

		const requireKeyDelay = this.compForm.get('require_key_delay').value
		company.require_key_delay = FormHelper.formDurationToIso(requireKeyDelay)

		const minNoteInterval = this.compForm.get('min_notification_interval').value
		company.min_notification_interval = FormHelper.formDurationToIso(minNoteInterval)
		const maxNoteDuration = this.compForm.get('max_notification_duration').value
		company.max_notification_duration = FormHelper.formDurationToIso(maxNoteDuration)

		const breakReminder = this.compForm.get('break_reminder').value
		company.break_reminder = FormHelper.formDurationToIso(breakReminder)
		const breakStartReminder = this.compForm.get('break_start_reminder').value
		company.break_start_reminder = FormHelper.formDurationToIso(breakStartReminder)
		const breakEarlyWarning = this.compForm.get('break_early_warning').value
		company.break_early_warning = FormHelper.formDurationToIso(breakEarlyWarning)
		company.break_type_prompt = this.compForm.get('break_type_prompt').value

		company.hours_rounding = this.compForm.get('hours_rounding').value

		const roundupTimer = this.compForm.get('round_up_timer').value
		company.round_up_timer = FormHelper.formDurationToIso(roundupTimer)
		const roundupNoteTimer = this.compForm.get('roundup_note_timer').value
		company.roundup_note_timer = FormHelper.formDurationToIso(roundupNoteTimer)

		// Check for rundupNote timer <= rundupTimer
		if (company.round_up_timer && company.roundup_note_timer) {
			const rutSec = moment.duration(company.round_up_timer)?.asSeconds()
			const runtSec = moment.duration(company.roundup_note_timer)?.asSeconds()
			if (runtSec > rutSec) {
				this.coreSrvc.notifySrvc.notify('error', 'Timer Invalid', 'Round up note timer must be less than or equal to round up timer')
				return null
			}
		}

		const rounddownTimer = this.compForm.get('round_down_timer').value
		company.round_down_timer = FormHelper.formDurationToIso(rounddownTimer)
		const rounddownNoteTimer = this.compForm.get('rounddown_note_timer').value
		company.rounddown_note_timer = FormHelper.formDurationToIso(rounddownNoteTimer)

		// Check for rounddown_note_timer <= round_down_timer
		if (company.round_down_timer && company.rounddown_note_timer) {
			const rutSec = moment.duration(company.round_down_timer)?.asSeconds()
			const runtSec = moment.duration(company.rounddown_note_timer)?.asSeconds()
			if (runtSec > rutSec) {
				this.coreSrvc.notifySrvc.notify('error', 'Timer Invalid', 'Round down note timer must be less than or equal to round down timer')
				return null
			}
		}

		const checkInOutWindow = this.compForm.get('check_in_out_window').value
		company.check_in_out_window = FormHelper.formDurationToIso(checkInOutWindow)
		const autoCloseOpenTrans = this.compForm.get('auto_close_open_trans').value
		company.auto_close_open_trans = FormHelper.formDurationToIso(autoCloseOpenTrans)
		company.job_length_max = this.makeJobLengthMaxUpdateValue()
		company.image_dup_check = this.compForm.get('image_dup_check').value
		company.validate_qr_codes = this.compForm.get('validate_qr_codes').value

		// Overtime Options
		company.ot_enable = this.compForm.get('ot_enable').value
		company.ot_dow_start = this.compForm.get('ot_dow_start').value
		company.ot_week_count = this.compForm.get('ot_week_count').value
		const otThresholdWeek = this.compForm.get('ot_threshold_week').value
		company.ot_threshold_week = FormHelper.formInputToIsoDuration(otThresholdWeek, 'hours')
		const otThresholdDay = this.compForm.get('ot_threshold_day').value
		company.ot_threshold_day = FormHelper.formInputToIsoDuration(otThresholdDay, 'hours')
		const maxDailyHours = this.compForm.get('max_daily_hours').value
		company.max_daily_hours = FormHelper.formInputToIsoDuration(maxDailyHours, 'hours')

		// Click-to-Call / Call Center
		if (isInternalUser) {
			company.c2c_enable = this.compForm.get('c2c_enable').value
			if (!company.c2c_enable) {
				company.c2c_record_enable = false
				company.c2c_preamble_message = null
				company.c2c_email = null
			}
			company.cc_enable = this.compForm.get('cc_enable').value
			if (!company.cc_enable) {
				company.cc_record_enable = false
				company.cc_preamble_message = null
				company.cc_email = null
				company.cc_vm_enable = false
				company.cc_vm_message = null
				company.cc_vm_email = null
			}
		}

		// Miscellaneous
		company.onboard_required = this.compForm.get('onboard_required').value
		company.pureMobile = this.compForm.get('pureMobile').value
		company.useIncidents = this.compForm.get('useIncidents').value
		company.ivr_require_key_check_in = this.compForm.get('ivr_require_key_check_in').value
		company.gps_prompt_extra = this.compForm.get('gps_prompt_extra').value
		// company.gps_auto_location = this.compForm.get('gps_auto_location').value // DEPRECATED 2024-08-20
		company.useCheckpoints = this.compForm.get('useCheckpoints').value
		company.useCheckpointsLL = this.compForm.get('useCheckpointsLL').value
		company.useMultiDay = this.compForm.get('useMultiDay').value
		company.force_job_codes = this.compForm.get('force_job_codes').value
		company.ncci_codes = this.compForm.get('ncci_codes').value
		company.prevent_auto_inactive = this.compForm.get('prevent_auto_inactive').value
		company.reject_inactive_employee = this.compForm.get('reject_inactive_employee').value
		company.pay_rate = this.compForm.get('pay_rate').value
		company.work_tax_location = this.compForm.get('work_tax_location').value
		company.paid_holiday = this.compForm.get('paid_holiday').value
		company.additional_details_notify = this.compForm.get('additional_details_notify').value
		company.sup_notify_ongoing = this.compForm.get('sup_notify_ongoing').value
		company.use_emp_photos = this.compForm.get('use_emp_photos').value
		company.ssr_public_admin_notes = this.compForm.get('ssr_public_admin_notes').value
		company.useQBOCustLocations = this.compForm.get('useQBOCustLocations').value
		company.useQBOCustOrg = this.compForm.get('useQBOCustOrg').value

		// Hidden in internal but not restricted to internal
		company.confirm_breaks = this.compForm.get('confirm_breaks').value
		company.gps_prompt_extra = this.compForm.get('gps_prompt_extra').value

		// Not currently available for modification because extra steps needed
		company.wkst = this.compForm.get('wkst').value

		// SMS character limit
		company.sms_char_limit = this.compForm.get('sms_char_limit').value

		// Reporting
		company.emp_cost_multiplier = this.compForm.get('emp_cost_multiplier').value
		company.midnight_splits = this.compForm.get('midnight_splits').value

		return company
	}

	toggleNotifications() {
		this.showNotifications = !this.showNotifications
		$('.modal-body').scrollTop(0)
		const backBtnState = this.showNotifications ? 'showBackBtn' : 'hideBackBtn'
		const event = new DialogControlEvent('company', 'toDialog', backBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	private checkForIvrExternalIdAvailability() {
		const empWithoutExtIds = this.coreSrvc.dbSrvc.empSrvc.getAllEmployees().filter((emp) => !emp.external_id).length
		if (empWithoutExtIds > 0) {
			this.isIvrExternalIdOptionAvailable = false
		} else {
			this.isIvrExternalIdOptionAvailable = true
		}
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		switch (trigger) {
			case 'additional_details_notify':
				help.header = 'Notification Details'
				help.message = 'Enable adding additional schedule details to notifications.'
				break
			case 'auto_logout':
				help.header = 'Inactivity Timer'
				help.message =
					'This option specifies how long users can be inactive before being automatically logged out of the admin portal. Leave blank to disable automatic logout due to inactivity.'
				break
			// DEPRECATED 2025-01-12 - Moved to call center settins
			// case 'agent_logout_timer':
			// 	help.header = 'Activity Timer'
			// 	help.message =
			// 		'This option specifies the maximum time (in hours) that an agent may be signed in before being automatically signed out from agent status. Leave blank to disable automatic signout for agents.'
			// 	break
			case 'autocreatedSupId':
				help.header = 'Autocreated Supervisor'
				help.message = 'Select the supervisor who will be assigned to employees when they are autocreated by the system.'
				break
			case 'b2b_shift_auto':
				help.header = 'Auto Shifts'
				help.message =
					'When checked, if the employee has back-to-back shifts for different jobs, they wll be checked out of the first shift and and checked in to next shift automatically.'
				break
			case 'companyName':
				help.header = 'Company Name'
				help.message = 'Please email customer support if you would like to change your company name.'
				break
			case 'systemPrompts':
				help.header = 'System Prompts'
				help.message = `Until the system can determine who is calling, prompts will be played in the languages selected and the order shown. After the caller has been identified, prompts will be played in the language set for that employee.`
				break
			case 'autoCreateEmployees':
				help.header = 'Auto Create Employees'
				help.message =
					'Enable this option to have the system create a new employee record when an unknown mobile phone number is discovered during check in.'
				break
			case 'checklist_timer':
				help.header = 'Checklist Timer'
				help.message =
					'When IVR needs to checkout a time entry with a required checklist, setting this option will timestamp the open transaction and re-direct back to employee app for completion.\n\nWhen completed via the employee app, the timestamp from the IVR will be used for the checkout. If employee fails to checkout within the time indicated here, their time entry will automatically be closed using the timestamp logged by the IVR.\n\nSet to 0 to disable this feature.'
				break
			case 'confirm_breaks':
				help.header = 'Break Confirmation'
				help.message =
					'When this feature is enabled, employees will be asked to confirm that they were given the opportunity to take all allowed breaks for their shift.'
				break
			case 'ivrSmsBreaks':
				help.header = 'Enable IVR/SMS Breaks'
				help.message = `Check this to allow your employees to start and end unpaid breaks by calling into the system or by texting ‘break’ to start or end a break.`
				break
			case 'defaultTimezone':
				help.header = 'Default Timezone'
				help.message =
					'To edit the default timezone, choose one from the Default Timezone pull-down menu. This value can be overridden within each job that you create in the system. If the system creates a job automatically for you, the default timezone is always used.'
				break
			case 'default_units':
				help.header = 'Default Units'
				help.message =
					'Used to set the default units when distance data is required. Use imperial for miles/feet and metric for kilometers/meters.'
				break
			case 'timeWorkedOverage':
				help.header = 'Track Overage'
				help.message =
					'Enable this option to allow setting a budgeted job length for a job or a schedule entry. When an employee is checked in longer than the budgeted time, the overage will be visible as a separate column in the Time Entries list.'
				break
			case 'supervisorPhone':
				help.header = 'Supervisor Phone'
				help.message =
					'To edit the Default Supervisor Phone Number, first select the appropriate country from the pull-down menu, then enter a valid phone number. This value can be overridden within each job that you create in the system. If the system creates a job automatically for you, the default Supervisor Phone Number is always used.'
				break
			case 'supervisorEmail':
				help.header = 'Supervisor Email'
				help.message =
					'The default supervisor email is used to notify a supervisor via email for late check in/out. This option can be overridden for each job.'
				break
			case 'empVacAlertEmail':
				help.header = 'Vacation Clock-In Alerts'
				help.message =
					'An optional list of email addresses which will be notified if an employee attempts to clock-in while on vacation. An employee is considered to be on vacation when they have been added to the scheduled time-off list.'
				break
			case 'gpsResponseTime':
				help.header = 'GPS Response Time'
				help.message =
					'This value sets the window for valid responses to GPS requests from the system. It is used to format data in the GPS Start/End columns of the Transactions list.'
				break
			case 'createNoShow':
				help.header = 'Automatic No Shows'
				help.message = `The 'Timeout Window' determines when a scheduled employee will be flagged as a 'No Show' in the Time Entries list. This setting applies when there is no notification profile assigned to a given job that would trigger a 'No Show' entry. Leave blank to disable.`
				break
			case 'billingEmail':
				help.header = 'Billing Emails'
				help.message =
					'By default, the monthly billing statement will be sent to the email address that was entered during sign up. That value can be changed here. If you wish to delete the default email address, you must first enter at least one new address.'
				break
			case 'useJobCodes':
				help.header = 'Use Job Codes'
				help.message =
					'Enable this option to allow the assignment of numeric codes to jobs. The system will then prompt your employees to enter this code when checking in or out.'
				break
			case 'allowInvalidJobCode':
				help.header = 'Allow Default Job'
				help.message = `Enable this option to allow check in to the unassigned job if an invalid job code is entered. In the employee web app the unassigned job is shown as the Default Job.`
				break
			case 'allowUnscheduledJob':
				help.header = 'Allow Unscheduled Job'
				help.message = `Enable this option to allow employees to check in to an unschedule job. If you disable this option, employees will not be able to check into a job unless it is scheduled.`
				break
			case 'emp_cost_multiplier':
				help.header = 'Cost Multiplier'
				help.message =
					'Employee cost multiplier takes a decimal number which is used to calculate and display Employee Loaded cost column in job profitability reports. Employee Loaded column will not be displayed if this option is blank.'
				break
			case 'midnight_splits':
				help.header = 'Midnight Splits'
				help.message =
					'When checked, all reporting results will be processed on 24-hour boundaries. Any time entry that spans multiple days will be split into chunks for each date included in the entry.'
				break
			case 'employeeCounts':
				help.header = 'Employee Counts'
				help.message =
					'Enable this option to specify the number of employees required to check in for a job when you assign a schedule to the Any Employee user.'
				break
			case 'allowExternalId':
				help.header = 'External ID Access'
				help.message =
					'This option is only available if all employees have an External ID set. It allows them to use either their External ID or their phone number when accessing the system by phone.'
				break
			case 'empScheduleNotify':
				help.header = 'Change Notifications'
				help.message = 'Enable this option to notify employees with updated schedule information when their schedule changes.'
				break
			case 'empSchedOverlap':
				help.header = 'Schedule Overlap'
				help.message = 'Enable this option to allow the creation of employee schedules which overlap existing schedules.'
				break
			case 'travel':
				help.header = 'Travel Tracking'
				help.message =
					'Enable this option to allow tracking employee travel using the employee web app. Travel entries will be logged in the time entries section.\n\nWhen tracking selected employees, you will manage tracking for individual employees in their employee record.'
				break

			// Internal Options
			case 'industry':
				help.header = 'Industry'
				help.message = 'Setting an industry other than the default will make certain industry specific reports available in the employee web app.'
				break

			case 'fuzzy_match_distance':
				help.header = 'Fuzzy Match Distance'
				help.message = 'The number of digit changes allowed to perform a match against existing employee records.'
				break

			case 'require_key_delay':
				help.header = 'Require Key Delay'
				help.message = `If not null, the system will require the employee to press a key within this interval to perform check out operations (to prevent butt dials after calling for check in). If an employee calls back too soon after they clock in, it usually means a butt dial.  So if they don’t press a key we play “You must press a key to acknowledge check out operation. Your transaction has not been recorded.  Please call back and try again."`
				break

			// Notification Options
			case 'min_notification_interval':
				help.header = 'Minimum Interval'
				help.message = 'The smallest interval that can be configured for repeat notifications.'
				break
			case 'max_notification_duration':
				help.header = 'Maximum Duration'
				help.message = 'The longest we will continue to send out notifications for a time entry.'
				break

			// Break Options
			case 'break_reminder':
				help.header = 'Break Reminder'
				help.message = 'Send reminder text to employee when their break extends beyond this duration. Leave blank to disable.'
				break
			case 'break_start_reminder':
				help.header = 'Break Start Reminder'
				help.message =
					'Send reminder text to employee to take their break. Set the duration after the start of their shift to send the reminder. Leave blank to disable.'
				break
			case 'break_early_warning':
				help.header = 'Short Break Warning'
				help.message =
					'Set the minimum lenght of an employee break. If they attempt to end their break before the elapsed time, they will be warned they are ending their break early. Leave blank to disable.'
				break
			case 'break_type_prompt':
				help.header = 'Break Type Prompt'
				help.message = 'Prompt the employee to select a break type when they start a break. Current options are Paid Break and Unpaid Break.'
				break

			// Roundup Options
			case 'round_up_timer':
				help.header = 'Clock In Adjustment'
				help.message = 'Round up time entry start time to the scheduled start if within this window before the schduled start.'
				break
			case 'roundup_note_timer':
				help.header = 'Adjustment Note'
				help.message = 'If a time entry was rounded up to the start time greater than this window, then apply a note to the time entry.'
				break

			// Rounddown Options
			case 'round_down_timer':
				help.header = 'Clock Out Adjustment'
				help.message = 'Round down time entry end time to the scheduled end if within this window after the schduled end.'
				break
			case 'rounddown_note_timer':
				help.header = 'Adjustment Note'
				help.message = 'If a time entry was rounded down to the end time greater than this window, then apply a note to the time entry.'
				break

			// Time Entry Options
			case 'check_in_out_window':
				help.header = 'Clock In/Out Window'
				help.message = 'The period of time before and after a shift starts or ends to allow clocking in and out.'
				break
			case 'auto_close_open_trans':
				help.header = 'Auto-close Entry'
				help.message =
					'Whether to auto-close open transactions within this interval, null disables feature, default it null and not enabled for a company.'
				break
			case 'job_length_max':
				help.header = 'Maximum Job Length'
				help.message =
					'The length in hours that is used as a window to determine whether to complete a check-in that exists.  IE, value is 16, if you check in, and 17 hours later you call, it exceeds 16 thus a new record (another check-in).'
				break
			case 'image_dup_check':
				help.header = 'Duplicate Images'
				help.message =
					'The number of days to check back for duplicate images on time entries. Disabled if left blank. Should default to 7 days if enabled.'
				break
			case 'validate_qr_codes':
				help.header = 'Validate QR Codes'
				help.message =
					'When enabled, options will be exposed on jobs to require QR codes and status icons will be available on time entries to show validation status.'
				break

			case 'onboard_required':
				help.header = 'Enable Onboarding'
				help.message =
					'Enables company onboarding feature for new employees in the admin portal and employee app.<br><br>See the <a href="https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/version.3.0/en/topic/onboarding" target="_blank">manual</a> for more information or contact support if you have any questions.'
				break
			case 'pureMobile':
				help.header = 'Pure Mobile'
				help.message = 'Company uses all mobile phones and no landlines. Do not warn supervisors or employees for scheduled stuff.'
				break
			case 'useIncidents':
				help.header = 'Shift Reports'
				help.message =
					'Enable the IVR prompt for submitting shift reports by phone. Shift reports are always available using the employee web app.'
				break
			case 'ivr_require_key_check_in':
				help.header = 'IVR Key Press'
				help.message = `When enabled, IVR users must press a key to confirm check-in (to prevent butt dials).`
				break
			case 'gps_prompt_extra':
				help.header = 'GPS Prompt Extra'
				help.message = 'When enabled, add a prompt to IVR reminding employees to click their GPS sharing request link.'
				break
			case 'gps_auto_location':
				help.header = 'GPS Auto Location'
				help.message = 'Enable GPS auto location for a company.'
				break
			case 'useCheckpoints':
				help.header = 'Enable Checkpoints'
				help.message = 'When enabled, the IVR asks checked in employees to press 0 to send checkpoint link (only for mobile callers).'
				break
			case 'useCheckpointsLL':
				help.header = 'Use Checkpoints (landline)'
				help.message =
					'When enabled, the IVR prompts (after check in) to press 0 to send checkpoint GPS link and applies only to landline callers.'
				break
			case 'useMultiDay':
				help.header = 'Enable Multi-Day'
				help.message = 'When enabled, multi-day jobs may be configured in the admin portal.'
				break
			case 'force_job_codes':
				help.header = 'Force Job Codes'
				help.message = `When enabled, always prompt for job code regardless of employee's schedule. Forced job codes will automatically be enabled or disabled based on the current Use Job Codes setting.`
				break
			case 'ncci_codes':
				help.header = 'NCCI Codes'
				help.message = `When enabled, expose option to configure NCCI code information for jobs and reports.`
				break
			case 'prevent_auto_inactive':
				help.header = 'Prevent Inactive'
				help.message = `When enabled, the system will be prevented from switching employees to the inactive state.`
				break
			case 'reject_inactive_employee':
				help.header = 'Reject Inactive Emp'
				help.message = `When enabled, reject inactive employees from accessing IVR or the employee web app.`
				break
			case 'pay_rate':
				help.header = 'Pay Rate'
				help.message = `When enabled, expose functionality for working with pay rates in admin portal.`
				break
			case 'sup_notify_ongoing':
				help.header = 'Multiple Shifts'
				help.message = `Notify supervisor for no show even when scheduled employee is checked into an another shift.`
				break
			case 'use_emp_photos':
				help.header = 'Employee Photos'
				help.message = `When enabled, employee profile photos will be included with public reports and UI to manage approvals will be available.`
				break

			case 'ssr_public_admin_notes':
				help.header = 'Admin Notes'
				help.message = 'When enabled, admin notes will be displayed in the public shift summary report. This is the default.'
				break

			case 'useQBOCustLocations':
				help.header = 'QBO Jobs Sync'
				help.message = 'When enabled, the system will automatically create job records from QBO data.'
				break
			case 'useQBOCustOrg':
				help.header = 'QBO Org Sync'
				help.message = 'When enabled, the system will automatically create organization records from QBO data.'
				break

			case 'work_tax_location':
				help.header = 'Work Tax Location'
				help.message = `When enabled, expose functionality for configuring work tax location.`
				break
			case 'paid_holiday': // Deprecated
				help.header = 'Paid Holiday'
				help.message = `When enabled, tag schedules as paid holiday in schedule view.`
				break
			case 'sms_char_limit':
				help.header = 'Max SMS length'
				help.message =
					'Set the maximum length of text messages sent using the admin portal Click-to-Call text message feature. Use multiples of 140 when adjusting.'
				break
			case 'ot_week_count':
				help.header = 'OT Period'
				help.message = `How many weeks in an overtime period.`
				break
			case 'ot_threshold_week':
				help.header = 'OT Weekly'
				help.message = `Maximum duration per week before triggering overtime.`
				break
			case 'ot_threshold_day':
				help.header = 'OT Daily'
				help.message = `Maximum duration per day before triggering overtime.`
				break
			case 'max_daily_hours':
				help.header = 'Notification'
				help.message = `Maximum duration an employee can work each day (midnight to midnight) before supervisors are notifiied about overage.`
				break
			case 'schedule_change_notifications':
				help.header = 'Notify Emails'
				help.message = 'Enable option to add email addresses to receive notification of schedule changes.'
				break
			case 'notify_sms_using_schedule':
				help.header = 'Notify w/Schedule'
				help.message = 'If a number matches both a supervisor and an employee, only notify if the employee is checked in.'
				break

			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

// {"cn": false, "en": true, "es": true, "fr": false, "order": ["en", "es", "fr", "cn"]}

const defaultUnitsOptions = [
	{ label: 'Imperial', value: 'IMPERIAL' },
	{ label: 'Metric', value: 'METRIC' },
]
