import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'

import {
	ReportRecord,
	ReportOptions,
	Timezone,
	FilterData,
	DialogControlEvent,
	ScheduleOptions,
	ReportSaveCompleteEvent,
	HelpDialogMessage,
	ReportsViewManager,
} from '@app/models'
import { ReportsService, SettingsService, CoreService } from '@app/services'

import { log, Helper, DateTimeHelper } from '@app/helpers'
import { SelectItem } from 'primeng/api'

import { Subscription } from 'rxjs'

import _ from 'lodash'
import moment from 'moment-timezone'

// For new report types, make sure to populate decimal checkbox if necessary

@Component({
	selector: 'app-insight-report-edit',
	templateUrl: './insight-report-edit.component.html',
	styleUrls: ['./insight-report-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class InsightReportEditComponent implements OnInit, OnDestroy {
	title: string
	isNew: boolean
	isSavingReport = false
	is12Hours = DateTimeHelper.format12Hour
	isJobSiteMerged = true

	repForm: UntypedFormGroup

	isAdvancedOptionsExpanded = false
	isOwnershipSharingExpanded = false

	showSupervisors = false
	showUserGroups = false
	supervisorOptions: Array<SelectItem> = []
	userGroupOptions: Array<SelectItem> = []

	reportTypeDropdown: Array<SelectItem> = []
	frequencyDropdown: Array<SelectItem> = []
	dayOfWeekDropdown: Array<SelectItem> = []
	startDateWeekRangeDropdown: Array<SelectItem> = []
	reportFrequency: string
	reportType: string

	showSupFilterOptions = true // Manages visiblity supervisors filter options dropdown

	// showTooltips = false

	monthlyStartDay = '1'

	supervisorsMultiSelect: SelectItem[] = []
	employeesMultiSelect: SelectItem[] = []
	employeeTagMultiSelect: SelectItem[] = []
	jobTagMultiSelect: SelectItem[] = []
	employeeDepartmentMultiSelect: SelectItem[] = []
	jobSitesMultiSelect: SelectItem[] = []
	jobsMultiSelect: SelectItem[] = []
	clientsMultiSelect: SelectItem[] = []
	vendorsMultiSelect: SelectItem[] = []

	districtplaceholder = 'Select States'
	districtSelectedLabel = 'Selected States: {0}'
	districtMultiSelect: SelectItem[] = []

	reportEmails = []
	newEmailInput: string

	timezoneData: Array<Timezone> = []

	is12HourFormat = DateTimeHelper.format12Hour
	isReportRunDelayAvailable = false

	weekdays = ['Monday-Sunday', 'Tuesday-Monday', 'Wednesday-Tuesday', 'Thursday-Wednesday', 'Friday-Thursday', 'Saturday-Friday', 'Sunday-Saturday']

	useStartDateWeekStartFeature = true // Can remove and adjust code when fully tested
	startDateWeekStartIso = 1 // Default to Monday (1)

	dayOptions = ScheduleOptions.dayOptions
	selectedDays = [1, 2, 3, 4, 5, 6, 7]

	@Input() viewOnly = false
	@Input() action: string
	@Input() reportId: number
	private report: ReportRecord
	private subs = new Subscription()

	get viewManager(): ReportsViewManager {
		return this.coreSrvc.dbSrvc.reportSrvc.viewManager
	}

	get startDateLabel(): string {
		const period = this.repForm.get('period').value
		switch (period) {
			// case 'EVERY_2_WEEKS':
			// 	return 'Start of 2-week period'
			default:
				return 'Beginning of any 2-week period'
		}
	}

	get initialTwoWeekEndDate(): Date {
		const periodStart = this.repForm.get('period_start').value
		const periodStartMom = moment(periodStart)
		return periodStartMom.add(13, 'days').toDate()
	}

	get currentTwoWeekStartDate(): Date {
		return this.getCurrentPeriodRange(this.repForm.get('period_start').value, 14).start
	}

	get currentTwoWeekEndDate(): Date {
		return this.getCurrentPeriodRange(this.repForm.get('period_start').value, 14).end
	}

	get startDateWeekStartName(): string {
		const wkstId = this.startDateWeekStartIso || 1
		return this.weekdays[wkstId - 1]
	}

	get dowWeekStartName(): string {
		const options = this.repForm.get('options').value as ReportOptions
		const wkstId = options?.dow_ot_start_iso_id
		if (wkstId) {
			return this.weekdays[wkstId - 1]
		}
	}

	get filterActiveEmployees(): boolean {
		return this.isCustomDate() ? false : true
	}

	get shouldShowFileUploadDaysInput(): boolean {
		const javaClass = this.repForm.get('java_class').value ?? ''
		return javaClass.includes('FileUploads')
	}

	get shouldShowShareWithSupervisors(): boolean {
		return true
	}

	constructor(
		route: ActivatedRoute,
		private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder,
		private location: Location,
		private coreSrvc: CoreService,
		private reportSrvc: ReportsService,
		private settingSrvc: SettingsService,
	) {
		log('ReportsDetailConstructor')
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()

		// Setup availability for report run delay input
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.isReportRunDelayAvailable = myUserPrefs.reportShowDelayRunInput

		this.isJobSiteMerged = company.merge_jobsite

		// Style district labels
		if (company.country_iso === 'CA') {
			this.districtplaceholder = 'Select Province'
			this.districtSelectedLabel = 'Selected Provinces: {0}'
		}
	}

	getCurrentPeriodRange(startDate: Date, periodDays: number): { start: Date; end: Date } {
		const today = new Date()
		let periodStart = new Date(startDate)
		let periodEnd = new Date(periodStart)
		periodEnd.setDate(periodEnd.getDate() + periodDays - 1)

		// Progressively move the range forward until today falls within it
		while (today > periodEnd) {
			periodStart.setDate(periodStart.getDate() + periodDays)
			periodEnd.setDate(periodEnd.getDate() + periodDays)
		}

		return { start: periodStart, end: periodEnd }
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'reports' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.submit(this.repForm)
			}
		}
	}

	isFormValid(): boolean {
		const isValid = !this.reportFormInvalid()
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('employeeEdit', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	ngOnInit() {
		log('ReportsDetail: ngOnInit', this.action, this.reportId)
		this.setupComponent()
		this.setupForm()
		this.setupAdvancedOptions()
		this.setupOwnershipSharingOptions()
		this.setupDropdowns()

		// Setup startDateWeekStart Day
		if (this.useStartDateWeekStartFeature) {
			// Default wkst to the company defaut
			this.startDateWeekStartIso = this.coreSrvc.dbSrvc.settingSrvc.getCompany().getOtDowStartIso()
			log('WEEKSTART', this.startDateWeekStartIso)

			if (this.isNew) {
				const startDate = this.getClosestPastDate(this.startDateWeekStartIso)
				this.repForm.get('period_start').setValue(startDate)
			} else {
				const startDateStr = this.report.period_start
				if (startDateStr) {
					log('Not a new report', startDateStr)
					const startDateMom = moment(this.report.period_start, 'YYYY-MM-DD')
					const startDateIso = startDateMom.isoWeekday()
					log('ISO WEEKDAY', startDateIso)
					this.startDateWeekStartIso = startDateIso
				}
			}
		}
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupComponent() {
		this.timezoneData = this.coreSrvc.dbSrvc.settingSrvc.getSupportedTimezones()

		// this.action = route.snapshot.params['action']
		// this.reportId = parseInt(route.snapshot.params['reportId'], 10) || null

		const companyId = this.settingSrvc.getCompany().id

		this.reportTypeDropdown = this.coreSrvc.dbSrvc.reportSrvc.getReportTypeDropdownData()
		this.frequencyDropdown = this.coreSrvc.dbSrvc.reportSrvc.getReportsPeriodDropdownData(companyId)

		if (this.action === 'new') {
			this.title = 'Add Report'
			this.isNew = true
		}

		if (this.action === 'edit' || this.action === 'clone') {
			this.report = this.reportSrvc.getReportById(this.reportId)
			this.reportFrequency = this.reportSrvc.getReportFrequencyName(this.report.period)
			this.reportType = this.reportSrvc.getReportTypeName(this.report.java_class)
			this.title = this.action === 'edit' ? 'Edit Report' : 'Copy Report'
			this.isNew = this.action === 'edit' ? false : true
			if (this.report && this.report.email_addresses != null) {
				this.reportEmails = this.report.email_addresses.split(/[;|,|\s]+/)
			}
			this.setupMonthlyStartDay()
		}

		// Show or hide Sup Filter Options UI - Should show always.
		const users = this.settingSrvc.getUsers()
		if (users && users.length > 1) {
			this.showSupFilterOptions = true
		} else {
			this.showSupFilterOptions = false
		}
	}

	setupDropdowns() {
		// this.setupDistrictDropdown()
		this.setupSupervisorsDropdown()
		this.setupUserGroupDropdown()
		this.setupSupervisorsMultiSelect()
		this.setupEmployeesMultiSelect()
		this.setupJobSitesMultiSelect()
		this.setupJobsMultiSelect()
		this.setupDistrictMultiSelect()
		this.setupClientsMultiSelect()
		this.setupVendorsMultiSelect()
		this.setupDayOfWeekDropdown()
		this.setupStartDateWeekRangeDropdown()
		this.cd.detectChanges()
	}

	setupForm() {
		// Setup Filter Options
		const defaultTz = this.settingSrvc.getCompany().timezone
		const filterData: FilterData = this.report && this.report.filter ? JSON.parse(this.report.filter) : {}
		log('Filter Data', filterData)

		// Setup Filter Data
		const hasEmpSupervisorIds = filterData && filterData.emp_supervisor_id ? filterData.emp_supervisor_id : null
		const hasEmployeeTags = filterData && filterData.employee_tags ? filterData.employee_tags : null
		const hasEmployeeDepts = filterData && filterData.employee_departments ? filterData.employee_departments : null
		const hasJobSiteSupervisorIds = filterData && filterData.jobsite_supervisor_id ? filterData.jobsite_supervisor_id : null
		const hasEmpIds = filterData && filterData.emp_id ? filterData.emp_id : null
		const hasJobSiteIds = filterData && filterData.jobsite_id ? filterData.jobsite_id : null
		const hasJobIds = filterData && filterData.job_id ? filterData.job_id : null
		const hasJobTags = filterData && filterData.job_tags ? filterData.job_tags : null
		const hasJobSiteDistricts = !this.isJobSiteMerged && filterData && filterData.jobsite_districts ? filterData.jobsite_districts : null
		const hasJobDistricts = this.isJobSiteMerged && filterData && filterData.jobsite_districts ? filterData.jobsite_districts : null
		const hasClientIds = filterData && filterData.client_id ? filterData.client_id : null
		const hasVendorIds = filterData && filterData.vendor_id ? filterData.vendor_id : null
		// const hasAdminAreaL1Codes = filterData && filterData.aal1_code ? filterData.aal1_code : null
		this.selectedDays = filterData?.dow_iso_id || [1, 2, 3, 4, 5, 6, 7]

		log('hasSiteDistricts/hasJobDistricts', hasJobSiteDistricts, hasJobDistricts)

		const options = this.report && this.report.options ? new ReportOptions(this.report.options) : new ReportOptions()
		if (this.isNew) {
			options.dow_ot_start_iso_id = this.coreSrvc.dbSrvc.settingSrvc.getCompany().getOtDowStartIso()
		}
		this.modifyFormPropertyOptionsDecimalEnabled(options)
		log('OPTIONS', options)

		this.repForm = this.fb.group({
			id: [this.report ? this.report.id : ''],
			company_id: [this.report ? this.report.company_id : ''],
			name: [this.report ? this.report.name : '', Validators.required],
			java_class: [this.report ? this.report.java_class : ''],
			// display_decimal: [this.report ? this.setDecimalFormat(this.report.java_class) : false],
			custom_start: [this.report ? this.makeDate(this.report.custom_start) : null],
			custom_end: [this.report ? this.makeDate(this.report.custom_end) : null],
			delay_days: [this.report ? this.report.delay_days : null],
			manual: [this.report ? this.makeFormPropertyManual(this.report.manual) : 'AUTO'],
			period: [this.report ? this.report.period : ''],
			period_start: [this.report ? this.makeDate(this.report.period_start) : null],
			next_run: [this.report ? this.report.next_run : null],
			last_run: [this.report ? this.report.last_run : null],
			timezone: [this.makeTimezone(this.report)],
			run_time: [this.makeRunTime(this.report)],
			send_sms: [this.report ? this.report.send_sms : false],
			send_email: [this.report ? this.report.send_email : false],
			supervisor: [this.report ? this.report.supervisor : 0],
			supervisor_group_id: [this.report ? this.report.supervisor_group_id : null],
			shared_supervisor_ids: [this.report?.shared_supervisor_ids ?? []],

			filterOptions: this.fb.group({
				employee_sort: options.employee_sort ?? 'last',
				emp_supervisor_id: hasEmpSupervisorIds ? true : false,
				jobsite_supervisor_id: hasJobSiteSupervisorIds ? true : false,
				emp_id: hasEmpIds || hasEmployeeTags ? true : false,
				jobsite_id: hasJobSiteIds || hasJobSiteDistricts ? true : false,
				job_id: hasJobIds || hasJobDistricts || hasJobTags ? true : false,
				client_id: hasClientIds ? true : false,
				vendor_id: hasVendorIds ? true : false,
				// aal1_code: hasAdminAreaL1Codes ? true : false,
			}),
			filterData: this.fb.group({
				emp_supervisor_id: null,
				employee_tags: null,
				employee_departments: null,
				jobsite_supervisor_id: null,
				emp_id: null,
				jobsite_id: null,
				job_id: null,
				job_tags: null,
				jobsite_districts: null,
				client_id: null,
				vendor_id: null,
				// aal1_code: null,
			}),
			options: this.fb.group(options),
			created: [this.report ? this.report.created : ''],
		})
		this.updateFormFilterData(filterData)

		if (this.action === 'clone') {
			const repName = this.report.name
			this.repForm.get('id').setValue(null)
			this.repForm.get('name').setValue(`${repName} (Copy)`)
		}

		// If it's a new report then auto-assign myself as owner if not an INTERNAL user
		if (this.action === 'new' && !this.viewManager.showAllReports) {
			const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
			if (myUser.role !== 'INTERNAL') {
				const myUserId = myUser.id
				this.repForm.get('supervisor').setValue(myUserId)
			}
		}
	}

	modifyFormPropertyOptionsDecimalEnabled(options: ReportOptions) {
		options.decimal_enabled = options.decimal_enabled ? 'DECIMAL' : ('HOURMIN' as any)
	}

	makeFormPropertyManual(value: boolean): string {
		if (value) {
			return 'MANUAL'
		} else {
			return 'AUTO'
		}
	}

	private setupOwnershipSharingOptions() {
		const hasSupervisor = !!this.repForm.get('supervisor').value
		const hasSupervisorGroup = !!this.repForm.get('supervisor_group_id').value

		const conditions = {
			hasSupervisor: hasSupervisor,
			hasSupervisorGroup: hasSupervisorGroup,
		}

		log('Ownership / Sharing Conditions', conditions)

		this.isOwnershipSharingExpanded = false
		for (const key in conditions) {
			if (conditions.hasOwnProperty(key) && conditions[key] === true) {
				this.isOwnershipSharingExpanded = true
				break
			}
		}
	}

	isFilterDataPopulatedForKey(prop: string) {
		const value = this.repForm.get('filterData.' + prop).value
		if (_.isEmpty(value)) return false
		return true
	}

	private setupAdvancedOptions() {
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const showAdvancedOptions = myUserPrefs.globalExpandAdvancedOptions

		const hasOptionFileUploadDays = !!this.repForm.get('options.file_uploads_days').value
		const hasOptionPaginationEnabled = !!this.repForm.get('options.pagination_enabled').value
		const hasOptionSuspectEnabled = !!this.repForm.get('options.suspect_enabled').value
		const hasOptionEmpRateEnabled = !!this.repForm.get('options.employee_rate_enabled').value
		const hasOptionVendorRateEnabled = !!this.repForm.get('options.vendor_rate_enabled').value
		const hasOptionClientRateEnabled = !!this.repForm.get('options.client_rate_enabled').value
		const hasSendTextWhenRun = !!this.repForm.get('send_sms').value
		const hasSendEmailWhenRun = !!this.repForm.get('send_email').value

		const hasFilterEmpSup = this.isFilterDataPopulatedForKey('emp_supervisor_id')
		const hasFilterSiteSup = this.isFilterDataPopulatedForKey('jobsite_supervisor_id')
		const hasFilterEmp = this.isFilterDataPopulatedForKey('emp_id')
		const hasFilterEmpTag = this.isFilterDataPopulatedForKey('employee_tags')
		const hasFilterEmpDepartments = this.isFilterDataPopulatedForKey('employee_departments')
		const hasFilterSites = this.isFilterDataPopulatedForKey('jobsite_id')
		const hasFilterJobs = this.isFilterDataPopulatedForKey('job_id')
		const hasFilterJobTags = this.isFilterDataPopulatedForKey('job_tags')
		const hasFilterDistricts = this.isFilterDataPopulatedForKey('jobsite_districts')
		const hasFilterClients = this.isFilterDataPopulatedForKey('client_id')
		const hasFilterVendors = this.isFilterDataPopulatedForKey('vendor_id')

		const isViewOnly = this.viewOnly

		let isDaySelectionCustom = false
		for (const day of this.dayOptions) {
			if (this.isWeekdaySelected(day)) {
				continue
			} else {
				isDaySelectionCustom = true
			}
		}

		const conditions = {
			isViewOnly: isViewOnly,
			showAdvancedOptions: showAdvancedOptions,

			hasOptionFileUploadDays: hasOptionFileUploadDays,
			hasOptionPaginationEnabled: hasOptionPaginationEnabled,
			hasOptionSuspectEnabled: hasOptionSuspectEnabled,
			hasOptionEmpRateEnabled: hasOptionEmpRateEnabled,
			hasOptionVendorRateEnabled: hasOptionVendorRateEnabled,
			hasOptionClientRateEnabled: hasOptionClientRateEnabled,
			hasSendTextWhenRun: hasSendTextWhenRun,
			hasSendEmailWhenRun: hasSendEmailWhenRun,
			hasFilterEmpSup: hasFilterEmpSup,
			hasFilterSiteSup: hasFilterSiteSup,
			hasFilterEmp: hasFilterEmp,
			hasFilterEmpTag: hasFilterEmpTag,
			hasFilterEmpDepartments: hasFilterEmpDepartments,
			hasFilterSites: hasFilterSites,
			hasFilterJobs: hasFilterJobs,
			hasFilterJobTags: hasFilterJobTags,
			hasFilterDistricts: hasFilterDistricts,
			hasFilterClients: hasFilterClients,
			hasFilterVendors: hasFilterVendors,

			isDaySelectionCustom: isDaySelectionCustom,
		}

		log('Advanced Options Conditions', conditions)

		this.isAdvancedOptionsExpanded = false
		for (const key in conditions) {
			if (conditions.hasOwnProperty(key) && conditions[key] === true) {
				this.isAdvancedOptionsExpanded = true
				break
			}
		}
	}

	updateFormFilterData(filterData: FilterData) {
		// Employee Supervisors
		const empSupFilterData = filterData.emp_supervisor_id ? filterData.emp_supervisor_id : []
		const empSupervisors = this.makeNumberFilterList(empSupFilterData)
		this.repForm.get('filterData.emp_supervisor_id').setValue(empSupervisors)

		// Job Site Supervisors
		const siteSupFilterData = filterData.jobsite_supervisor_id ? filterData.jobsite_supervisor_id : []
		const siteSupervisors = this.makeNumberFilterList(siteSupFilterData)
		this.repForm.get('filterData.jobsite_supervisor_id').setValue(siteSupervisors)

		// Employees
		const empFilterData = filterData.emp_id ? filterData.emp_id : []
		const employees = this.makeNumberFilterList(empFilterData)
		this.repForm.get('filterData.emp_id').setValue(employees)

		// Employee Tags
		const employeeTagData = filterData.employee_tags ? filterData.employee_tags : []
		const employeeTags = this.makeStringFilterList(employeeTagData)
		this.repForm.get('filterData.employee_tags').setValue(employeeTags)

		// Employee Departments
		const employeeDeptData = filterData.employee_departments ? filterData.employee_departments : []
		const employeeDepartments = this.makeStringFilterList(employeeDeptData)
		this.repForm.get('filterData.employee_departments').setValue(employeeDepartments)

		// Job Sites
		const jobSiteFilterData = filterData.jobsite_id ? filterData.jobsite_id : []
		const jobSites = this.makeNumberFilterList(jobSiteFilterData)
		this.repForm.get('filterData.jobsite_id').setValue(jobSites)

		// Jobs
		const jobFilterData = filterData.job_id ? filterData.job_id : []
		const jobs = this.makeNumberFilterList(jobFilterData)
		this.repForm.get('filterData.job_id').setValue(jobs)

		const jobTagData = filterData.job_tags ? filterData.job_tags : []
		const jobTags = this.makeStringFilterList(jobTagData)
		this.repForm.get('filterData.job_tags').setValue(jobTags)

		// Jobsite Districts
		const jobSiteDistrictTagData = filterData.jobsite_districts ? filterData.jobsite_districts : []
		const jobSiteDistricts = this.makeStringFilterList(jobSiteDistrictTagData)
		this.repForm.get('filterData.jobsite_districts').setValue(jobSiteDistricts)

		// Clients
		const clientFilterData = filterData.client_id ? filterData.client_id : []
		const clients = this.makeNumberFilterList(clientFilterData)
		this.repForm.get('filterData.client_id').setValue(clients)

		// Vendors
		const vendorFilterData = filterData.vendor_id ? filterData.vendor_id : []
		const vendors = this.makeNumberFilterList(vendorFilterData)
		this.repForm.get('filterData.vendor_id').setValue(vendors)

		// Admin Area L1
		// const adminAreaL1Data = filterData.aal1_code ? filterData.vendor_id : []
		// const adminAreaL1 = this.makeStringFilterDataListForFilterData(adminAreaL1Data)
		// this.repForm.get('filterData.aal1_code').setValue(vendors)
	}

	// Methods for managing multi-select lists

	makeNumberFilterList(data: any): Array<number> {
		if (!data) {
			return null
		}
		const isArray = Array.isArray(data)

		if (isArray) {
			return data
		} else {
			return [parseInt(data, 10)]
		}
	}

	makeStringFilterList(data: any): Array<string> {
		if (!data) {
			return null
		}
		const isArray = Array.isArray(data)
		if (isArray) {
			return data
		} else {
			return [data]
		}
	}

	setupSupervisorsDropdown() {
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()

		// Filter users if menu permissions restricted.
		const dropdown = this.coreSrvc.dbSrvc.settingSrvc.getUsersDropdownData() // isRestricted ? this.coreSrvc.dbSrvc.settingSrvc.getManagedUsers() : this.coreSrvc.dbSrvc.settingSrvc.getUsers()

		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Any Supervisor', value: 0 })
		this.supervisorOptions = sortedDropdown

		// Autofill supervisor when only one available
		// if (dropdown.length === 1) {
		// 	this.repForm.get('supervisor').setValue(myUserId)
		// 	this.showSupervisors = false
		// } else {
		// 	this.showSupervisors = true
		// }
	}

	setupUserGroupDropdown() {
		this.userGroupOptions = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupsDropdownData(true)
		if (this.userGroupOptions.length > 1) {
			this.showUserGroups = true
		}
		log('userGroupOptions', this.userGroupOptions)
	}

	private setupSupervisorsMultiSelect() {
		const dropdown = this.settingSrvc.getUsers().map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		this.supervisorsMultiSelect = sortedDropdown
	}

	private setupEmployeesMultiSelect() {
		const addEmpIds = this.repForm.get('filterData.emp_id').value ?? []
		log('Additional IDs', addEmpIds)
		const employees = this.coreSrvc.dbSrvc.empSrvc.getAllEmployees().map((emp) => {
			return {
				label: `${emp.active ? '' : '[INACTIVE] '}${emp.name}`,
				value: emp.id,
				data: emp,
			}
		})
		const filteredDropdown = employees.filter((item) => item.data.active || addEmpIds.includes(item.value))
		this.employeesMultiSelect = this.filterActiveEmployees ? _.sortBy(filteredDropdown, 'label') : _.sortBy(employees, 'label')

		// Setup employees tag filter
		const includeEmpTags = this.repForm.get('filterData.employee_tags').value ?? []
		this.employeeTagMultiSelect = this.coreSrvc.dbSrvc.empSrvc.getTagDropdownData(includeEmpTags)

		// Setup employees department filter
		const includeDepartments = this.repForm.get('filterData.employee_departments').value ?? []
		this.employeeDepartmentMultiSelect = this.coreSrvc.dbSrvc.empSrvc.getDepartmentsDropdown(includeDepartments)
	}
	private setupJobSitesMultiSelect() {
		const jobSites = this.coreSrvc.dbSrvc.siteSrvc.getJobSites().map((site) => {
			return {
				label: site.description,
				value: site.id,
			}
		})
		this.jobSitesMultiSelect = jobSites
	}

	private setupJobsMultiSelect() {
		this.jobsMultiSelect = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, true, false, false, [])

		// Setup job tag filter
		const includeJobTags = this.repForm.get('filterData.job_tags').value ?? []
		this.jobTagMultiSelect = this.coreSrvc.dbSrvc.jobSrvc.getTagDropdownData(includeJobTags)
	}

	private setupDistrictMultiSelect() {
		const included = this.repForm.get('filterData.jobsite_districts').value ?? []
		this.districtMultiSelect = this.coreSrvc.dbSrvc.siteSrvc.getDistrictDropdown(included)
		log('District multi-select', this.districtMultiSelect)
	}

	private setupClientsMultiSelect() {
		const clients = this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('CLIENT').map((client) => {
			return {
				label: client.name,
				value: client.id,
				data: client,
			}
		})
		this.clientsMultiSelect = clients
		log('Clients Multiselect', clients)
	}

	private setupVendorsMultiSelect() {
		const vendors = this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('VENDOR').map((vendor) => {
			return {
				label: vendor.name,
				value: vendor.id,
				data: vendor,
			}
		})
		this.vendorsMultiSelect = vendors
		log('Vendors Multiselect', vendors)
	}

	private setupDayOfWeekDropdown() {
		const labels = this.weekdays
		let dayOfWeekNum = 1
		const dropdown: Array<SelectItem> = []
		for (const label of labels) {
			const item: SelectItem = { label: label, value: dayOfWeekNum++ }
			dropdown.push(item)
		}
		this.dayOfWeekDropdown = dropdown
	}

	private setupStartDateWeekRangeDropdown() {
		const labels = this.weekdays
		let dayOfWeekNum = 1
		const dropdown: Array<SelectItem> = []
		for (const label of labels) {
			const item: SelectItem = { label: label, value: dayOfWeekNum++ }
			dropdown.push(item)
		}
		this.dayOfWeekDropdown = dropdown
	}

	setupMonthlyStartDay() {
		const startDate = moment(this.report.period_start, 'YYYY-MM-DD')
		if (startDate.isValid()) {
			this.monthlyStartDay = startDate.date().toString()
		}
	}

	// toggleTooltips(): boolean {
	// 	this.showTooltips = !this.showTooltips
	// 	return false
	// }

	toggleWeekday(day) {
		const isoId = day.value + 1
		const selected = this.selectedDays
		if (selected.includes(isoId)) {
			this.selectedDays = this.selectedDays.filter((d) => d !== isoId)
		} else {
			selected.push(isoId)
			this.selectedDays = _.sortBy(this.selectedDays)
		}
	}

	isWeekdaySelected(day): boolean {
		if (!this.selectedDays) {
			return true
		}
		const isoId = day.value + 1
		return this.selectedDays.includes(isoId)
	}

	displayDayOfWeekFilter(): boolean {
		const period = this.repForm.get('period').value
		if (!period) {
			return false
		}
		if (period === 'DAILY') {
			return false
		}
		return true
	}

	// Called from template. Check to see if it's a report type of Transaction Dump and
	// if so then set the frequency dropdown to only show custom option.

	reportTypeSelected(event) {
		log('event', event)
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const selectedType = this.repForm.get('java_class').value
		log('Report Type', selectedType)
		const dropDownData = this.reportSrvc.getReportsPeriodDropdownData(companyId)
		if (selectedType === 'CSVTransactions') {
			this.frequencyDropdown = dropDownData.filter((item) => item.value === 'CUSTOM' || item.value === null)
			this.repForm.get('period').setValue('CUSTOM')
		} else {
			this.frequencyDropdown = dropDownData
			this.repForm.get('period').setValue(null)
		}
	}

	frequencyChanged() {
		log('Frequency Changed')
		const filterData = this.repForm.get('filterData').value as FilterData
		this.updateFormFilterData(filterData)
		this.setupEmployeesMultiSelect()
	}

	setDecimalFormat(value: string): boolean {
		if (!value) {
			return false
		}
		if (value === 'EmployeeDecimal') {
			return true
		}
		if (value === 'JobDecimal') {
			return true
		}
		if (value === 'SuspectDecimal') {
			return true
		}
		if (value === 'PayrollDecimal') {
			return true
		}
		if (value === 'LocationDecimal') {
			return true
		}
		if (value === 'DailyDecimal') {
			return true
		}
		if (value === 'PayrollHolidayDecimal') {
			return true
		}
		if (value === 'PayrollOvertimeDecimal') {
			return true
		}
		return false
	}

	// showReportTypeFormatOption(): boolean {
	// 	const reportType = this.repForm.value.java_class;
	// 	if (reportType === 'Payroll' || reportType === 'PayrollDecimal') { return true; }
	// 	return false;
	// }

	makeTimezone(rep: ReportRecord) {
		if (rep) {
			const timezone = rep.timezone
			if (timezone) {
				return timezone
			}
		}
		return this.settingSrvc.getCompany().timezone
	}

	makeRunTime(rep: ReportRecord) {
		if (rep) {
			const time = rep.run_time
			const timeMom = moment(time, 'HH:mm:ss')
			if (timeMom.isValid()) {
				return timeMom.toDate()
			}
		}
		return moment().set({ hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }).toDate()
	}

	makeDate(str: string): Date {
		if (!str) {
			return null
		}
		return moment(str, 'YYYY-MM-DD').toDate()
	}

	isCustomDate(): boolean {
		const period = this.repForm.get('period').value
		if (period === 'CUSTOM') {
			return true
		}
		return false
	}

	isPeriodDate(): boolean {
		const period = this.repForm.get('period').value
		if (period === 'WEEKLY') {
			return true
		}
		if (period === 'EVERY_2_WEEKS') {
			return true
		}
		return false
	}

	isDaily(): boolean {
		const period = this.repForm.get('period').value
		if (period === 'DAILY') {
			return true
		}
		return false
	}

	isMonthly(): boolean {
		const period = this.repForm.get('period').value
		if (period === 'MONTHLY') {
			return true
		}
		return false
	}

	// validateMonthlyInput() {
	// 	const startDay = this.monthlyStartDay
	// 	if ( )
	// }

	displayDecimalCheckbox(): boolean {
		const reportType = this.repForm.get('java_class').value
		if (reportType === 'QBDesktop') {
			return false
		}
		if (reportType === 'CSVTransactions') {
			return false
		}
		return true
	}

	// displayRunManuallyDialog(): boolean {
	// 	this.showRunManuallyDialog = true
	// 	return false
	// }

	displayAddEmailInput(): boolean {
		const form = this.repForm.value
		if (form.send_email) {
			return true
		}
		return false
	}

	displayWeekStart(): boolean {
		const period = this.repForm.get('period').value
		const repClass = this.repForm.get('java_class').value || ''
		// if (!repClass.includes('Payroll')) { return false }
		if (!(repClass.includes('Overtime') || repClass.includes('Holiday'))) {
			return false
		}
		if (!period || period === 'DAILY' || period === 'WEEKLY' || period === 'EVERY_2_WEEKS') {
			return false
		}
		return true
	}

	displayStartDateWeekStart(): boolean {
		if (!this.useStartDateWeekStartFeature) return false
		const period = this.repForm.get('period').value
		const repClass = this.repForm.get('java_class').value || ''
		if (period === 'WEEKLY') {
			return true
		}
		return false
	}

	public startDateWeekStartChanged(): void {
		const dowStartNumber = this.startDateWeekStartIso
		const date = this.getClosestPastDate(dowStartNumber)
		this.repForm.get('period_start').setValue(date)
	}

	private getClosestPastDate(dayOfWeek: number): Date {
		if (dayOfWeek < 1 || dayOfWeek > 7) {
			throw new Error('Invalid day of the week. Please provide a value between 1 (Monday) and 7 (Sunday).')
		}

		const today = moment()
		const targetDay = dayOfWeek % 7 // Adjusting Sunday from 7 to 0 for moment.js

		let closestPastDate = today.clone().day(targetDay - 7)

		if (today.day() === targetDay) {
			closestPastDate = today.clone().subtract(7, 'days')
		} else if (today.day() < targetDay) {
			closestPastDate = today.clone().day(targetDay - 7)
		} else {
			closestPastDate = today.clone().day(targetDay)
		}

		return closestPastDate.toDate()
	}

	isEmailInputValid(): boolean {
		const currentEmail = this.newEmailInput
		const isCurrentEmailValid = Helper.isValidEmail(currentEmail)
		if (isCurrentEmailValid) {
			return true
		}
		return false
	}

	isReportEmailValid(): boolean {
		if (this.isEmailInputValid() || this.reportEmails.length > 0) {
			return true
		}
		return false
	}

	submit({ value, valid }: { value: ReportRecord; valid: boolean }): boolean {
		// Guard against double submission
		if (this.isSavingReport) {
			return
		}

		if (this.isMonthly()) {
			const startDay = parseInt(this.monthlyStartDay, 10)
			log('Start Day', startDay)
			if (!startDay || startDay < 1 || startDay > 28) {
				alert('Day of Month must be between 1 and 28.')
				return false
			}
		}

		if (this.dateRangeInvalid()) {
			alert('The end date must be equal to or later than the start date')
			return false
		}

		// If it's a custom date we want to switch to VIEW after save and flash the row
		// Here we just set row flash. We'll check for that after reportSaveComplete in
		// the reports component and dispatch if it's set.

		if (this.isCustomDate()) {
			this.coreSrvc.dbSrvc.reportSrvc.viewManager.pulseFirstRow = true
		} else {
			this.coreSrvc.dbSrvc.reportSrvc.viewManager.pulseFirstRow = false
		}

		const record = this.makeUpdateRecord()
		// log('Update Record', record)
		// return

		if (record) {
			this.isSavingReport = true
			this.cd.detectChanges()
			const event = new ReportSaveCompleteEvent(this.action, record)
			if (this.isNew) {
				this.reportSrvc.createReport(record).then((createSuccess) => {
					this.coreSrvc.dbSrvc.reportSrvc.reportSaveComplete.emit(event)
				})
			} else {
				this.reportSrvc.updateReport(record).then((updateSuccess) => {
					this.coreSrvc.dbSrvc.reportSrvc.reportSaveComplete.emit(event)
				})
			}
		}
		return false
	}

	onCancel(): boolean {
		this.location.back()
		return false
	}

	toggleCheckbox(prop: string) {
		this.repForm.get(prop).setValue(!this.repForm.get(prop).value)
	}

	toggleRadio(prop: string, value: string) {
		this.repForm.get(prop).setValue(value)
		setTimeout(() => {
			this.cd.detectChanges()
		}, 100)
	}

	addReportEmail(showAlert: boolean): boolean {
		const email = this.newEmailInput
		if (!Helper.isValidEmail(email)) {
			if (showAlert) {
				alert('The email address you entered is not valid.')
			}
			return
		} else {
			this.reportEmails.push(email)
			this.newEmailInput = ''
		}
	}

	onRemoveEmail(idx): boolean {
		log('Removing Email')
		this.reportEmails.splice(idx, 1)
		return false
	}

	formatDate(date: Date): string {
		if (!date) {
			return null
		}
		return moment(date).format('YYYY-MM-DD')
	}

	makeJsonStringOptionsFromForm(options: ReportOptions): string {
		const newOptions = new ReportOptions()
		for (const attr in options) {
			if (options.hasOwnProperty(attr)) {
				newOptions[attr] = options[attr]
			}
		}

		// Revert options.decimal_enabled from form string enum to boolean
		const isDecimalEnabled = (options.decimal_enabled as any) === 'DECIMAL' ? true : false
		newOptions.decimal_enabled = isDecimalEnabled
		return JSON.stringify(newOptions)
	}

	makeUpdateRecord(): ReportRecord {
		const form: any = this.repForm.value
		const record = new ReportRecord(form)

		// Revert manual from string enum back to boolean
		const manualProperty = form.manual === 'MANUAL' ? true : false
		record.manual = manualProperty

		// for (let attr in rec) report[attr] = rec[attr];
		// for (const attr in form) {
		// 	if (form.hasOwnProperty(attr)) {
		// 		record[attr] = form[attr]
		// 	}
		// }

		log('Current Form Object', form)

		if (this.isCustomDate()) {
			log('This is custom Date')
			record.custom_start = this.formatDate(form.custom_start)
			record.custom_end = this.formatDate(form.custom_end)
			record.manual = null
		} else {
			record.custom_start = null
			record.custom_end = null
		}

		if (this.isPeriodDate()) {
			log('This is period date')
			record.period_start = this.formatDate(form.period_start)
		} else {
			record.period_start = null
		}

		if (this.isMonthly()) {
			let startDay = parseInt(this.monthlyStartDay, 10) || 1
			if (startDay < 1 || startDay > 28) {
				startDay = 1
			}
			const date = new Date()
			date.setDate(startDay)
			record.period_start = this.formatDate(date)
		}

		if (this.isDaily()) {
			record.delay_days = null
		}

		const runMom = moment(form.run_time)
		if (runMom.isValid()) {
			record.run_time = runMom.format('HH:mm:ss')
		}

		record.timezone = form.timezone

		const currentSupEmail = this.newEmailInput
		if (Helper.isValidEmail(currentSupEmail)) {
			this.addReportEmail(false)
		} else {
			if (currentSupEmail) {
				alert('The email address you entered is not valid.')
				return null
			}
		}

		// Copy in report email addresses
		if (this.reportEmails.length > 0) {
			record.email_addresses = this.reportEmails.join(',')
		} else {
			record.email_addresses = null
		}

		record.options = this.makeJsonStringOptionsFromForm(form.options)

		// Setup filter options
		if (this.hasFilter()) {
			const selectedDays = this.selectedDays.length === 7 ? null : this.selectedDays
			const filter = {
				emp_supervisor_id: null,
				jobsite_supervisor_id: null,
				emp_id: null,
				employee_tags: null,
				employee_departments: null,
				jobsite_id: null,
				job_id: null,
				job_tags: null,
				jobsite_districts: null,
				client_id: null,
				vendor_id: null,
				dow_iso_id: selectedDays,
			}

			if (form.filterOptions.emp_supervisor_id) {
				const empSupList = form.filterData.emp_supervisor_id
				filter.emp_supervisor_id = empSupList && empSupList.length > 0 ? empSupList : null
			}
			if (form.filterOptions.jobsite_supervisor_id) {
				const jobSiteSupList = form.filterData.jobsite_supervisor_id
				filter.jobsite_supervisor_id = jobSiteSupList && jobSiteSupList.length > 0 ? jobSiteSupList : null
			}
			if (form.filterOptions.emp_id) {
				const empList = form.filterData.emp_id
				filter.emp_id = empList && empList.length > 0 ? empList : null

				const empTagList = form.filterData.employee_tags
				filter.employee_tags = empTagList && empTagList.length > 0 ? empTagList : null

				const deptList = form.filterData.employee_departments
				filter.employee_departments = deptList && deptList.length > 0 ? deptList : null
			}

			if (form.filterOptions.jobsite_id) {
				const jobSiteList = form.filterData.jobsite_id
				filter.jobsite_id = jobSiteList && jobSiteList.length > 0 ? jobSiteList : null
			}
			if (!this.isJobSiteMerged && form.filterOptions.jobsite_id) {
				const districtList = form.filterData.jobsite_districts
				filter.jobsite_districts = districtList && districtList.length > 0 ? districtList : null
			}
			if (form.filterOptions.job_id) {
				const jobList = form.filterData.job_id
				filter.job_id = jobList && jobList.length > 0 ? jobList : null

				const jobTagList = form.filterData.job_tags
				filter.job_tags = jobTagList && jobTagList.length > 0 ? jobTagList : null
			}
			if (this.isJobSiteMerged && form.filterOptions.job_id) {
				const districtList = form.filterData.jobsite_districts
				filter.jobsite_districts = districtList && districtList.length > 0 ? districtList : null
			}
			if (form.filterOptions.client_id) {
				const clientList = form.filterData.client_id
				filter.client_id = clientList && clientList.length > 0 ? clientList : null
			}
			if (form.filterOptions.vendor_id) {
				const vendorList = form.filterData.vendor_id
				filter.vendor_id = vendorList && vendorList.length > 0 ? vendorList : null
			}
			record.filter = JSON.stringify(filter)
		} else {
			record.filter = null
		}

		log('Record to submit', record)
		return record
	}

	// Checks to see if any filter options are currently set

	hasFilter(): boolean {
		// filterOptions represents the checkbox used to display the multi-select dropdowns
		// filterOptions may be set for multiple filterData arrays: see emp_id and employee_tags
		const filterOptions = this.repForm.value.filterOptions
		const filterData = this.repForm.value.filterData
		if (this.selectedDays.length !== 7) {
			return true
		}
		if (filterOptions.emp_supervisor_id && filterData.emp_supervisor_id && filterData.emp_supervisor_id.length > 0) {
			return true
		}
		if (filterOptions.jobsite_supervisor_id && filterData.jobsite_supervisor_id && filterData.jobsite_supervisor_id.length > 0) {
			return true
		}
		if (filterOptions.emp_id && filterData.emp_id && filterData.emp_id.length > 0) {
			return true
		}
		if (filterOptions.emp_id && filterData.employee_tags && filterData.employee_tags.length > 0) {
			return true
		}
		if (filterOptions.emp_id && filterData.employee_departments && filterData.employee_departments.length > 0) {
			return true
		}
		if (filterOptions.jobsite_id && filterData.jobsite_id && filterData.jobsite_id.length > 0) {
			return true
		}
		if (!this.isJobSiteMerged && filterOptions.jobsite_id && filterData.jobsite_districts && filterData.jobsite_districts.length > 0) {
			return true
		}
		if (filterOptions.job_id && filterData.job_id && filterData.job_id.length > 0) {
			return true
		}
		if (filterOptions.job_id && filterData.job_tags && filterData.job_tags.length > 0) {
			return true
		}
		if (this.isJobSiteMerged && filterOptions.job_id && filterData.jobsite_districts && filterData.jobsite_districts.length > 0) {
			return true
		}
		if (filterOptions.client_id && filterData.client_id && filterData.client_id.length > 0) {
			return true
		}
		if (filterOptions.vendor_id && filterData.vendor_id && filterData.vendor_id.length > 0) {
			return true
		}
		log('Did I get here')
		return false
	}

	dateRangeInvalid(): boolean {
		const form = this.repForm.value
		const period = form.period
		const start = form.custom_start
		const end = form.custom_end

		if (start && end && period === 'CUSTOM') {
			if (start > end) {
				return true
			}
		}
		return false
	}

	reportFormInvalid(): boolean {
		const formValue = this.repForm.value

		if (this.repForm.invalid) {
			return true
		}
		if (this.selectedDays.length === 0) {
			return true
		}
		if (formValue.send_email && !this.isReportEmailValid()) {
			return true
		}
		if (this.isCustomDate() && (!formValue.custom_start || !formValue.custom_end)) {
			return true
		}
		if (this.isPeriodDate() && !formValue.period_start) {
			return true
		}

		return false
	}

	validateNumericInput(input) {
		const value = this.repForm.get(input).value
		const intValue = parseInt(value, 10)

		switch (input) {
			case 'delay_days':
				if (intValue && intValue > 6) {
					this.coreSrvc.notifySrvc.notify('warn', 'Invalid Value', `The maximum value for this field is 6 days.`, 3)
					this.repForm.get(input).setValue(6)
				}
				if (intValue < 1) {
					this.repForm.get(input).setValue(null)
				}
				return
		}

		if (intValue) {
			this.repForm.get(input).setValue(intValue)
		} else {
			this.repForm.get(input).setValue(null)
		}
	}

	frequencyNote() {
		const period = this.repForm.get('period').value
		return period ? this.reportSrvc.getFrequencyNote(period) : ''
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		const maxJobLength = this.coreSrvc.dbSrvc.settingSrvc.getCompany().job_length_max
		switch (trigger) {
			case 'employee_sort':
				help.header = 'Sort By'
				help.message = `Use this option to select the default sort order when displaying employee lists.`
				break
			case 'delayDays':
				help.header = 'Delay Report'
				help.message = `You may automatically delay the running of this report for up to 6 days from it's normal scheduled run time in order to verify your transaction data for the given reporting period. Leave blank to not use a delay.`
				break
			case 'dayOfWeek':
				help.header = 'Filter by Day'
				help.message = `Select which days to include in the report. Tap a day to toggle inclusion. Blue highlight indicates a day will be included in the report.`
				break
			case 'periodEvery2Weeks':
				help.header = 'Begin Date'
				help.message = `Selecting the beginning of any 2-week period establishes the starting point for a recurring 2-week reporting cycle.<br /><br />You can choose any past or future date that aligns with the beginning of a 2-week period you wish to track.<br /><br />If you are trying to create a report for a specific 2-week period, use the <b>Custom Date Range</b> option in the <b>Frequency</b> dropdown.`
				break
			case 'file_uploads_days':
				help.header = 'Expiration Window'
				help.message =
					'When this value is provided, reports will only include files expiring within the number of days indicated. When left blank, all files will be included.'
				break
			case 'reportOwner':
				help.header = 'Report Owner'
				help.message = `Select the supervisor who owns this report or select 'Any Supervisor' to make the report available to everyone.`
				break
			case 'enablePagination':
				help.header = 'Pagination'
				help.message = `Enable pagination if you wish to print your reports with only one employee section per page.`
				break
			case 'suspectEntries':
				help.header = 'Time Entry Issues'
				help.message = `Enable this option to include a summary of time entry issues (at the top of the report) which may require manual adjustment and should be verified by a supervisor.<br><br>This includes time entries missing a check-out, entries that are less than 5 minutes, or entries that are longer than the company defined max job length of ${maxJobLength} hours.`
				break
			case 'userGroup':
				help.header = 'Share with Group'
				help.message =
					'Optionally assign a supervisor group which will allow members of this group to manage this report and view related records.'
				break
			case 'shared_supervisor_ids':
				help.header = 'Share with Supervisors'
				help.message =
					'Optionally share this report with individual supervisors which will allow them to manage this report and view related records.'
				break
			case 'show_admin_notes':
				help.header = 'Admin Notes'
				help.message = 'When checked, include admin notes in any sections which show time entry details.'
				break
			case 'show_comm_log':
				help.header = 'Comm Log'
				help.message = 'When checked, include any comm log records linked to included time entries.'
				break
			case 'show_emp_tags':
				help.header = 'Employee Tags'
				help.message = 'When checked, include employee tag information in any sections that show employee details.'
				break
			case 'show_job_tags':
				help.header = 'Job Tags'
				help.message = 'When checked, include job tag information in any sections that show job details.'
				break
			case 'show_emp_in_out_notes':
				help.header = 'Employee Notes'
				help.message = 'When checked, include employee notes in any sections which show time entry details.'
				break
			case 'weekStart':
				help.header = 'Week Start'
				help.message = `The system calculates overtime as any hours over 40 during a 7-day work week. Please specify the start and end of your 7-day work week. By default, it runs from Monday to Sunday.`
				break
			case 'startDateWeekStart':
				help.header = 'Week Start'
				help.message = `Please specify the start and end of your 7-day work week. By default, it runs from Monday to Sunday.`
				break

			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
