import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { CoreService } from '@app/services'

import {
	JobSiteRecord,
	Timezone,
	DialogControlEvent,
	LocationAddress,
	HelpDialogMessage,
	EditFormJobSiteLocationManager,
	EditFormJobSiteLinkedNumbersManager,
	EditFormJobSiteLinkedNumbersManagerConfig,
	EditFormJobSiteLocationManagerConfig,
} from '@app/models'

import { FormHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { SelectItem } from 'primeng/api'
import { CountryCode } from 'libphonenumber-js'
import { Subscription } from 'rxjs'

import _ from 'lodash'

@Component({
	selector: 'app-job-site-edit',
	templateUrl: './job-site-edit.component.html',
	styleUrls: ['./job-site-edit.component.scss'],
	standalone: false,
})
export class JobSiteEditComponent implements OnInit, OnDestroy, AfterViewInit {
	// useLocationComponent = true // Added for migration - remove after cleanup
	// useLinkedNumbersComponent = true // Added for migration - remove after cleanup

	accessHelper: AccessHelper

	linkedNumberManager: EditFormJobSiteLinkedNumbersManager
	locationManager: EditFormJobSiteLocationManager

	title: string
	isNew: boolean
	isUpdating: boolean
	isInternalUser = false

	jobSiteForm: UntypedFormGroup
	isQBDCustomer = false

	showSupervisors = false
	showUserGroups = false
	supervisorOptions: Array<SelectItem> = []
	userGroupOptions: Array<SelectItem> = []

	showSupervisorDialog = false
	showAdvancedOptions = false
	clientDropdown: Array<SelectItem> = []
	timezoneOptions: Array<SelectItem> = []

	defaultTimezoneId: number
	timezoneData: Array<Timezone> = []

	allowCallsFromEmployeeMobilePhones = true

	// addrText = {
	// 	// migrated
	// 	addr1: 'Street Address',
	// 	addr2: 'City',
	// 	addr3: 'State/Province',
	// 	addr4: 'Zipcode',
	// }

	selectedLocationType: string = null
	latitude: number
	longitude: number

	@Input() action: string
	@Input() jobSiteId: number

	@Output() editActionCancelled = new EventEmitter<boolean>()
	@Output() saveActionComplete = new EventEmitter<number>()

	// @ViewChild('newNumber') newNumber: ElementRef

	jobSite: JobSiteRecord
	// private skipNumberCheck = false

	private subs = new Subscription()

	constructor(
		private route: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private location: Location,
		private coreSrvc: CoreService,
	) {
		// Manage dialog based save
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.setupAccessPermissions()
		this.locationManager = new EditFormJobSiteLocationManager(this.coreSrvc.dbSrvc)
		this.linkedNumberManager = new EditFormJobSiteLinkedNumbersManager(this.coreSrvc)

		// Check if internal user
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.role === 'INTERNAL'
	}

	get isSyncLocked(): boolean {
		return this.jobSite?.sync_lock
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'site')
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'location' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.onSubmit(this.jobSiteForm)
			}
		}
	}

	isFormValid(): boolean {
		const isValid = this.jobSiteForm.valid
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('location', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	ngOnInit() {
		this.setupComponent()
		this.setupForm()
		this.setupAdvancedOptions()
		this.setupLocationManager()
		this.setupLinkedNumberManager()
		this.setupTimezoneDropdown()
		this.setupSupervisorsDropdown()
		this.setupUserGroupDropdown()
	}

	ngAfterViewInit() {
		// scrollTo(0, 0)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	gpsUpdated(position: any) {
		this.latitude = position.latitude
		this.longitude = position.longitude
		log('Updated Job Site lat/lon', this.latitude, this.longitude)
	}

	setupComponent() {
		this.timezoneData = this.coreSrvc.dbSrvc.settingSrvc.getSupportedTimezones()

		// if (this.coreSrvc.dbSrvc.siteSrvc.count() === 0) { this.showTooltips = false } // Deprecated

		// Check for QBDesktop integration
		this.isQBDCustomer = this.coreSrvc.dbSrvc.qbSrvc.isQBDCustomer()

		if (this.action === 'new') {
			this.title = 'Add Job Site'
			this.isNew = true
			this.defaultTimezoneId = this.coreSrvc.dbSrvc.settingSrvc.getCompanyDefaultTimezoneId()
			this.selectedLocationType = 'none'
		} else if (this.action === 'edit') {
			this.title = 'Edit Job Site'
			this.isNew = false
			this.jobSite = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(this.jobSiteId)
		}

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.allowCallsFromEmployeeMobilePhones = company.allow_cell_calls
	}

	private setupForm() {
		// log('IS NEW', this.isNew)
		let address: LocationAddress
		if (this.isNew) {
			address = new LocationAddress()
		} else {
			address = LocationAddress.buildFromSite(this.jobSite)
		}
		// log('Address Object', address)

		this.jobSiteForm = this.fb.group({
			id: [this.jobSite ? this.jobSite.id : ''],
			chkpts_require_photo: [this.jobSite ? this.jobSite.chkpts_require_photo : false],
			description: [this.jobSite ? this.jobSite.description : '', Validators.required],
			supervisor: [this.jobSite ? this.jobSite.supervisor : null, Validators.required],
			timezone_id: [this.jobSite ? this.jobSite.timezone_id : this.defaultTimezoneId, Validators.required],
			location_details: [this.jobSite ? this.jobSite.location_details : null],
			phone_number_regex: [this.jobSite ? this.jobSite.phone_number_regex : ''],
			phone_number_regex_e164: [this.jobSite ? this.jobSite.phone_number_regex_e164 : ''],
			addMobileNumberDropdown: [null],
			client_id: [this.jobSite ? this.jobSite.client_id : null],
			dialingCode: [this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso],
			union_zone: [this.jobSite ? this.jobSite.union_zone : null],
			supervisor_group_id: [this.jobSite ? this.jobSite.supervisor_group_id : null],
		})

		// Disable editing name if sync locked
		if (this.jobSite?.sync_lock) {
			this.jobSiteForm.get('description').disable()
		}
	}

	private setupAdvancedOptions() {
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const showAdvancedOptions = myUserPrefs.globalExpandAdvancedOptions
		this.showAdvancedOptions = showAdvancedOptions
	}

	private setupLocationManager() {
		const config = new EditFormJobSiteLocationManagerConfig()
		const myAdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		config.enableAddressByMapPin = myAdminPrefs.enableAddressByMapPin

		const jobSite = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(this.jobSiteId)
		// Also check for new action in case cloning is added
		if (jobSite) {
			config.locationAddress = this.action === 'new' ? new LocationAddress() : LocationAddress.buildFromSite(jobSite)
			config.geoCodeReverse = this.action === 'new' ? false : jobSite.geo_code_reverse
			config.geoCoded = this.action === 'new' ? null : jobSite.geo_coded
			config.geoLatitude = this.action === 'new' ? null : jobSite.geo_latitude
			config.geoLongitude = this.action === 'new' ? null : jobSite.geo_longitude
		} else {
			config.locationAddress = new LocationAddress()
		}
		// log('CONFIG ADDR', config)
		this.locationManager.setupManager(config)
	}

	private setupLinkedNumberManager() {
		const phoneNumbers = this.jobSite ? this.jobSite.phone_number_regex_e164.split(',').filter((num) => num !== '+18888888888') : []
		const config = new EditFormJobSiteLinkedNumbersManagerConfig()
		config.allowCallsFromEmployeeMobilePhones = this.coreSrvc.dbSrvc.settingSrvc.getCompany().allow_cell_calls
		config.numbers = phoneNumbers
		config.countryCode = this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso as CountryCode
		this.linkedNumberManager.setupManager(config)
		log('Linked Number Manager', this.linkedNumberManager)
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_id }
			})
		// timezones.unshift({ label: 'Select a Timezone', value: null })
		this.timezoneOptions = timezones
	}

	private setupSupervisorsDropdown() {
		const userPermissions = this.accessHelper.getPermissionsFor('site')
		const access = userPermissions.access
		const owner = userPermissions.owner
		const supervisors = this.coreSrvc.dbSrvc.settingSrvc.getUsers()
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		const primaryUserId = this.coreSrvc.dbSrvc.settingSrvc.getPrimaryUser()?.id
		// if (!primaryUserId) { alert('Error encountered. This account does not have a primary user set.') }
		const isSupRestricted = this.isNew ? owner.create && !access.create : owner.update && !access.update
		const isUserAManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const selectedSupId = isSupRestricted ? myUserId : primaryUserId

		const users =
			isUserAManager && isSupRestricted ? this.coreSrvc.dbSrvc.settingSrvc.getManagedUsers() : this.coreSrvc.dbSrvc.settingSrvc.getUsers()
		const dropdown = users.map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Supervisor', value: null })
		this.supervisorOptions = sortedDropdown

		// Autofill supervisor when only one option
		// if ((supervisors.length === 1 || isSupRestricted) && !isUserAManager) {
		if (supervisors.length === 1) {
			this.jobSiteForm.get('supervisor').setValue(myUserId)
			this.showSupervisors = false
		} else {
			this.showSupervisors = true
		}
	}

	setupUserGroupDropdown() {
		this.userGroupOptions = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupsDropdownData(true)
		if (this.userGroupOptions.length > 1) {
			this.showUserGroups = true
		}
	}

	public onSubmit({ value, valid }: { value: JobSiteRecord; valid: boolean }): boolean {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.jobSiteForm)

		// Check for number in landline input and add if entered
		// if (this.useLinkedNumbersComponent) {
		// Use linked numbers component
		const submitMsg = this.linkedNumberManager.prepareForSubmission()
		if (submitMsg) {
			alert(submitMsg)
			return
		}

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		// return
		if (record) {
			this.isUpdating = true
			if (this.isNew) {
				this.coreSrvc.dbSrvc.insertRecord('location', record).then((success) => {
					this.coreSrvc.dbSrvc.bulkRead(['location', 'job']).then((result) => {
						this.saveActionComplete.emit(null)
					})
				})
			} else {
				this.coreSrvc.dbSrvc.updateRecord('location', record).then((success) => {
					this.saveActionComplete.emit(record.id)
				})
			}
		}
		return false
	}

	onCancel(): boolean {
		this.editActionCancelled.emit(true)
		return false
	}

	toggleCheckbox(prop: string, type: string) {
		if (type === 'FORM') {
			const currentValue = this.jobSiteForm.get(prop).value
			this.jobSiteForm.get(prop).setValue(!currentValue)
		} else {
			this[prop] = !this[prop]
		}
	}

	private makeUpdateRecord(): JobSiteRecord {
		const record = new JobSiteRecord(this.jobSite)

		// Setup Geocoding
		const locResult = this.locationManager.result
		const locationAddress = locResult.locationAddress

		record.description = this.jobSiteForm.get('description').value
		record.supervisor = this.jobSiteForm.get('supervisor').value
		record.timezone_id = this.jobSiteForm.get('timezone_id').value
		record.location_details = this.jobSiteForm.get('location_details').value
		record.phone_number_regex = this.jobSiteForm.get('phone_number_regex').value
		record.chkpts_require_photo = this.jobSiteForm.get('chkpts_require_photo').value
		record.client_id = this.jobSiteForm.get('client_id').value
		record.union_zone = this.jobSiteForm.get('union_zone').value
		record.supervisor_group_id = this.jobSiteForm.get('supervisor_group_id').value

		// record.address = locResult.address
		record.address_1 = locationAddress.address_1
		record.address_2 = locationAddress.address_2
		record.city = locationAddress.city
		record.district = locationAddress.district
		record.postal_code = locationAddress.postal_code
		record.geo_code_reverse = locResult.geoCodeReverse
		record.geo_latitude = locResult.latitude
		record.geo_longitude = locResult.longitude

		record.phone_number_regex_e164 = this.linkedNumberManager.phoneNumberString
		delete record.phone_number_regex

		record.address = null

		return record
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'client':
				help.header = 'Client / Customer'
				help.message = `You may assign a client to this job site for certain reporting and notification purposes.`
				break
			case 'chkptsRequirePhoto':
				help.header = 'Checkpoint Photos'
				help.message = `Require all checkpoints for this job site to include a photo.`
				break
			case 'linkMobileCheckbox':
				help.header = 'Link Mobile Numbers'
				help.message = 'Check this box to to link employee mobile phones to this job site.'
				break
			case 'mobileNumbers':
				help.header = 'Mobile Numbers'
				help.message = `For each employee who will call from their mobile phone at this job site, select them from the dropdown menu. If an employee does not appear in the dropdown you may need look for them in the 'Inactive' employee list and mark them as active.`
				break
			case 'linkLandlineCheckbox':
				help.header = 'Link Landline Numbers'
				help.message =
					'As an alternative to Job Codes or Schedule Entries, the system can use Caller ID to determine which job to assign when an employee calls into the system.'
				break
			case 'landlineNumbers':
				help.header = 'Landline Numbers'
				help.message = `For each landline telephone your employees will call from at this job site, enter a valid phone number.`
				break
			case 'details':
				help.header = 'Job Site Details'
				help.message = `Job Site Details are displayed in the Date/Details column of the time entries screen and you may optionally expose this information to employees in the employee web portal during check in.`
				break
			case 'supervisor':
				help.header = 'Job Site Supervisor'
				help.message = `From the dropdown menu, please select the supervisor who is responsible for this job site.`
				break
			case 'userGroup':
				help.header = 'Supervisor Group'
				help.message =
					'Optionally assign a supervisor group which will allow members of this group to manage this job site and view related records.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
