<div [hidden]="isSendingAnnouncement">
	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<div [hidden]="isUpdating || showFilterResults">
		<form class="edit-form-block" novalidate [formGroup]="offerForm" autocomplete="off">
			<!-- Can open this one up if we allow for multiple offers -->
			<!-- <div class="options-block">
				<div class="options-title-inline">Description</div>
				<div *ngIf="!offerForm.get('description').value" class="input-optional float-right">(optional)</div>
				<input type="text" class="form-control" autocomplete="off" formControlName="description" />
			</div> -->

			<div class="options-block">
				<div class="options-title-inline">Offer Notice</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employee_details')"></i>
				<div *ngIf="!offerForm.get('employee_details').value" class="input-optional float-right">(optional)</div>
				<textarea class="form-control options-textarea" formControlName="employee_details"></textarea>
			</div>

			<div class="options-block">
				<div class="options-title-inline">Admin Notes</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('details')"></i>
				<div *ngIf="!offerForm.get('details').value" class="input-optional float-right">(optional)</div>
				<textarea class="form-control options-textarea" formControlName="details"></textarea>
			</div>

			<div class="options-block" [class.input-required]="offerForm.get('employee_ids').value.length === 0">
				<div class="options-title-inline">Target Employees</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employee_ids')"></i>
				<div *ngIf="offerForm.get('employee_ids').value.length === 0" class="float-right">(required)</div>
				<div *ngIf="offerForm.get('employee_ids').value.length > 0" class="float-right">
					<i class="fa fa-bullhorn tts-info-btn" (click)="sendAnnouncement()"></i>
				</div>

				<app-multi-select-details
					#selectedEmployeesMultiselect
					[type]="'EMP'"
					placeholder="Select Employees"
					selectedItemsLabel="Employees Selected: {0}"
					[options]="employeeDropdownOptions"
					[model]="offerForm.get('employee_ids').value"
					(modelChange)="offerForm.get('employee_ids').setValue($event)"
				>
				</app-multi-select-details>
			</div>

			<div *ngIf="showOpenShiftCheckbox" class="checkbox-item d-flex justify-content-between" style="margin: 32px 0px 0px 0px">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="open_shift" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('open_shift')" class="has-pointer">Mark shift as open</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('open_shift')"></i>
				</div>
			</div>

			<div *ngIf="!hasAnyoneAccepted" class="checkbox-item d-flex justify-content-between" style="margin: 12px 0px">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="auto_fill" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('auto_fill')" class="has-pointer"
						>Assign this shift to the first employee who accepts the offer</strong
					>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('auto_fill')"></i>
				</div>
			</div>

			<div *ngIf="!isNew">
				<div class="status-summary">
					<div class="table-tag status-tag">{{ statusSummary.unread }} Unread</div>
					<!-- <div class="table-tag status-tag bg-read">{{ statusSummary.read }} Read</div> -->
					<div class="table-tag status-tag bg-replied">{{ statusSummary.replied }} Replied</div>
					<div class="table-tag status-tag bg-declined">{{ statusSummary.declined }} Declined</div>
					<div class="table-tag status-tag bg-accepted">{{ statusSummary.accepted }} Accepted</div>
				</div>
				<app-form-expander [label]="'Offer Status'" [centerOnExpansion]="true">
					<div *ngFor="let entryVm of offerEntryList">
						<div class="card response-card">
							<div>
								<div
									class="entry-status float-right"
									[class.es-read]="entryVm.derivedStatus === 'READ'"
									[class.es-declined]="entryVm.derivedStatus === 'DECLINED'"
									[class.es-accepted]="entryVm.derivedStatus === 'ACCEPTED'"
									[class.es-scheduled]="entryVm.derivedStatus === 'SCHEDULED'"
								>
									{{ entryVm.derivedStatus }}
								</div>
								<div>
									<div class="emp-name tts-hl-2">{{ entryVm.employeeName }}</div>
									<div>{{ entryVm.updated }}</div>
								</div>
							</div>
							<!-- <div *ngIf="entryVm.entry.audit_json.length > 0"> -->

							<div *ngIf="entryVm.hasAuditEntries">
								<div *ngFor="let auditInfo of entryVm.entry.audit_json">
									<div *ngIf="auditInfo.notes" class="note-block">
										<div class="note-date">{{ auditInfo.formattedDate }}</div>
										<div class="note-content">{{ auditInfo.notes }}</div>
									</div>
								</div>
							</div>
							<button
								class="btn btn-sm btn-block btn-assign"
								[class.btn-outline-secondary]="entryVm.derivedStatus === 'READ' || entryVm.derivedStatus === 'UNREAD'"
								[class.btn-outline-danger]="entryVm.derivedStatus === 'DECLINED'"
								[class.btn-outline-success]="entryVm.derivedStatus === 'ACCEPTED'"
								(click)="assignShiftBtnClicked(entryVm)"
							>
								Assign shift to {{ entryVm.employeeName }}
							</button>
						</div>
					</div>
				</app-form-expander>
			</div>
		</form>

		<!-- Filters -->
		<div *ngIf="true">
			<hr *ngIf="isNew" style="margin-top: 25px" />
			<app-form-expander [label]="'Employee Picker'" [centerOnExpansion]="true">
				<div class="options-block" style="margin-top: 26px">
					<div *ngIf="filter.activeStatus" class="float-right link-text" (click)="filter.activeStatus = null">clear</div>
					<div class="options-title-inline" [class.filter-block-active]="filter.activeStatus">Employee Status</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('emp_active_status')"></i>
					<p-select
						appScrollLockSelect
						id="employeeStatus"
						placeholder="Any Status"
						[options]="employeeStatusOptions"
						[(ngModel)]="filter.activeStatus"
					></p-select>
				</div>

				<div class="filter-block">
					<div class="filter-group">
						<div class="options-title-inline">Distance from Job</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('filter_locDistance')"></i>
						<div *ngIf="filter.locDistance" class="link-text float-right" (click)="filter.locDistance = null">clear</div>

						<div *ngIf="isDistanceFilterAvailable" class="input-group mb-3">
							<span class="input-group-text tts-height" id="dist-addon">Distance</span>
							<input
								type="number"
								inputmode="decimal"
								class="form-control"
								style="text-align: right"
								placeholder="enter distance in"
								[(ngModel)]="filter.locDistance"
							/>
							<select
								class="form-select tts-height"
								[(ngModel)]="filter.locDistanceUnits"
								style="max-width: 80px"
								(ngModelChange)="convertDistance()"
							>
								<option value="KM">km</option>
								<option value="MI">mi</option>
							</select>
						</div>
						<div class="filter-unavailable" *ngIf="!isDistanceFilterAvailable">Unavailable - no address for job</div>
					</div>

					<!-- Department Dropdown -->
					<div class="options-block">
						<div *ngIf="filter.departments.length > 0" class="float-right link-text" (click)="filter.departments = []">clear</div>

						<div class="options-title-inline" [class.filter-block-active]="filter.departments.length > 0">Department</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('department')"></i>
						<p-multiselect
							appScrollLockMultiSelect
							id="deptMultiselect"
							placeholder="Any Department"
							selectedItemsLabel="Departments Selected: {0}"
							[showToggleAll]="false"
							[options]="departmentDropdownOptions"
							[(ngModel)]="filter.departments"
							[filter]="true"
						></p-multiselect>
					</div>

					<div class="tag-dd-block filter-group">
						<div class="options-title-inline">Employee Tags</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('filter_empTags')"></i>

						<div *ngIf="filter.employeeTags.length > 0" class="link-text float-right" (click)="filter.employeeTags = []">clear</div>
						<app-multi-select-details
							*ngIf="isEmployeeTagFilterAvailable"
							[showFullList]="false"
							[type]="'STRING'"
							[notFoundMsg]="'Unknown Tag'"
							placeholder="Select Employee Tags"
							selectedItemsLabel="Tags Selected: {0}"
							[options]="employeeTagMultiSelect"
							[model]="filter.employeeTags"
							(modelChange)="filter.employeeTags = $event"
						>
						</app-multi-select-details>
						<div class="filter-unavailable" *ngIf="!isEmployeeTagFilterAvailable">Unavailable - no employee tags</div>
					</div>
				</div>
				<button class="btn btn-block btn-outline-secondary" style="margin-top: 25px" (click)="processFilter()">Generate List</button>
			</app-form-expander>
		</div>
	</div>

	<!-- Show Filter Results -->
	<div *ngIf="showFilterResults">
		<!-- Matching Employees -->
		<app-activity-banner [isProcessing]="isMatching" [message]="'Matching Employees ...'"></app-activity-banner>

		<!-- Filter Response List -->
		<div *ngIf="!isMatching">
			<div class="result-header">{{ filterResponse.length }} employee{{ filterResponse.length === 1 ? '' : 's' }} found</div>
			<div *ngFor="let frw of filterResponse">
				<div class="card raised-card response-card" [class.card-selected]="frw.selected">
					<div class="response-card-body">
						<div class="float-right d-flex justify-content-between" style="cursor: pointer">
							<div class="select-icon" (click)="frw.selected = !frw.selected"><i *ngIf="frw.selected" class="fa fa-check"></i></div>
							<div class="expand-icon" (click)="frw.expanded = !frw.expanded">
								<i *ngIf="!frw.expanded" class="fas fa-chevron-right"></i>
								<i *ngIf="frw.expanded" class="fas fa-chevron-down"></i>
							</div>
						</div>
						<div class="name-label">{{ frw.record.employee.name }}</div>
						<div class="sub-ital-label">{{ frw.distLabel }}</div>
						<!-- <div *ngIf="isCurrentlyTargeted(frw)">Employee is currently targeted for offer</div> -->
						<div style="margin-top: 12px" *ngIf="frw.tags.length > 0">
							<div
								*ngFor="let tag of frw.tags"
								data-bs-html="true"
								class="badge form-tag mb-1 mr-1 item-tooltip"
								[class.form-tag-expire-set]="tag.expire"
								[class.form-tag-expired]="tag.isExpired"
								[title]="tag.expireTooltip"
							>
								<span class="tag-label">{{ (tag.label ?? '').toUpperCase() }}</span>
							</div>
						</div>
						<!-- <pre><code>{{ frw.record | json }}</code></pre> -->
						<div *ngIf="frw.expanded">
							<hr />
							<div class="detail-block" style="margin-bottom: 12px">
								<div>
									<div class="float-right">
										<div
											class="badge badge-secondary ctc-call-btn"
											style="margin-right: 6px"
											(click)="clickToCall(frw.record.employee, 'TEXT')"
										>
											Text
										</div>
										<div class="badge badge-secondary ctc-call-btn" (click)="clickToCall(frw.record.employee, 'CALL')">Call</div>
									</div>
									<div>{{ frw.record.employee.phone_number_e164 | displayPhone }}</div>
								</div>
							</div>
							<div class="detail-block"><strong>Details:</strong> {{ frw.record.employee.employee_details ?? 'no detail information' }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="result-header">
				{{ selectedFilterResponseCards.length }} of {{ filterResponse.length }} employee{{ filterResponse.length === 1 ? '' : 's' }} selected
			</div>

			<!-- Filter Response Buttons -->
			<div class="row">
				<div class="col-6">
					<button class="btn btn-block btn-outline-secondary" style="margin-top: 25px" (click)="cancelFilterBtnClicked()">Cancel</button>
				</div>
				<div class="col-6">
					<button
						[disabled]="!hasSelectedFilterCards"
						class="btn btn-block btn-success"
						style="margin-top: 25px"
						(click)="applyFilterBtnClicked()"
					>
						Add Employees
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="isSendingAnnouncement">
	<app-announcements-scheduled-edit
		[dialogManager]="dialogManager"
		[providedTargetEmpIds]="offerForm.get('employee_ids').value"
		[autoCloseDialog]="false"
		(recordSaved)="sendAnnouncementFinished()"
	></app-announcements-scheduled-edit>
</div>
