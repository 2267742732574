import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'

import { TransactionLogRecord } from '@app/models'
import { CoreService } from '@app/services'

import { log, DateTimeHelper, MapHelper, DisplayHelper } from '@app/helpers'

import moment from 'moment-timezone'

type GPSDetailsViewType = 'MAP' | 'DETAILS'

@Component({
	selector: 'app-shift-details-dialog',
	templateUrl: './shift-details-dialog.component.html',
	styleUrls: ['./shift-details-dialog.component.scss'],
	standalone: false,
})
export class ShiftDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() transId: number
	@Input() linkType: string
	@Input() gpsHtml: string
	@Output() closeDialog: EventEmitter<boolean> = new EventEmitter()

	trans: TransactionLogRecord
	inOut: 'IN' | 'OUT' = null
	address: string
	showLegendHighlight = false
	showGpsTimeInfo = false

	clusterer: any

	isMobile = false

	private centerLat: number
	private centerLng: number

	private gpsCheckinMarker: google.maps.marker.AdvancedMarkerElement
	private gpsCheckoutMarker: google.maps.marker.AdvancedMarkerElement
	private expectedLocationMarker: google.maps.marker.AdvancedMarkerElement
	private jobSiteMarkers: Array<google.maps.marker.AdvancedMarkerElement> = []

	public startInMapView = false
	public currentView: GPSDetailsViewType = 'MAP'

	topZIndex = google.maps.Marker.MAX_ZINDEX

	@ViewChild('gmap', { static: true }) gmapElement: any
	map: google.maps.Map

	constructor(private coreSrvc: CoreService) {
		this.startInMapView = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().openTimeEntryDetailsInMapView
		this.currentView = this.startInMapView ? 'MAP' : 'DETAILS'
		this.isMobile = this.coreSrvc.devDetect.isMobile()

		const adminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.showGpsTimeInfo = adminPrefs.transDisplayGpsTimeInfo
	}

	get hasCheckinMarker(): boolean {
		return !!this.trans?.geo_start_latitude
	}

	get hasCheckoutMarker(): boolean {
		return !!this.trans?.geo_end_latitude
	}

	ngOnInit() {
		const transId = this.transId
		this.trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		log('Transaction', this.trans)
		const inOut = this.linkType === 'start' ? 'IN' : 'OUT'
		this.inOut = inOut
		this.setupMapData()
		setTimeout(() => {
			if (this.isMapValid()) {
				this.initMap()
				this.addGpsLocationMarker('IN')
				this.addGpsLocationMarker('OUT')
				this.addJobSiteMarker()
				this.highlightJobSite()
				this.openGpsLocationMarker(this.inOut)
				this.zoomToFitAllMarkers()
			}
		}, 600)
	}

	ngAfterViewInit() {
		const element: any = $('#shiftDetailsDialog')
		element.modal('show')
		// DisplayHelper.setHtmlScrolling(false)
	}

	ngOnDestroy() {
		const element: any = $('#shiftDetailsDialog')
		element.modal('hide')
		// DisplayHelper.setHtmlScrolling(true)
	}

	setupMapData() {
		const trans = this.trans
		if (!trans) {
			return
		}

		if (this.linkType === 'start') {
			this.centerLat = trans.geo_start_latitude
			this.centerLng = trans.geo_start_longitude
		} else {
			this.centerLat = trans.geo_end_latitude
			this.centerLng = trans.geo_end_longitude
		}

		if (!this.centerLat && !this.centerLng) {
			this.currentView = 'DETAILS'
		} else {
			this.currentView = this.startInMapView ? 'MAP' : 'DETAILS'
		}
	}

	isMapValid(): boolean {
		return !!this.trans.geo_start_latitude || !!this.trans.geo_end_latitude // !!this.centerLat && !!this.centerLng
	}

	initMap() {
		const showExtraControls = true // !this.isMobile
		const hasCenter = this.centerLng && this.centerLng
		const noMapCenter = MapHelper.getNoMapCenter(this.coreSrvc.dbSrvc)
		const mapProp = {
			mapId: 'proximityMap',
			gestureHandling: 'greedy',
			center: hasCenter ? new google.maps.LatLng(this.centerLat, this.centerLng) : new google.maps.LatLng(noMapCenter.lat, noMapCenter.lng),
			zoom: hasCenter ? 16 : noMapCenter.zoom,
			zoomControl: showExtraControls,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			clickableIcons: false,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
			},
		}
		this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp)

		this.map.addListener('click', () => {
			this.closeAllInfoWindows()
		})
	}

	addGpsLocationMarker(inOut: 'IN' | 'OUT') {
		const trans = this.trans
		const hasInOut = inOut === 'IN' ? !!trans.actual_start : !!trans.actual_end
		const hasGps = inOut === 'IN' ? !!trans.geo_start_latitude : !!trans.geo_end_latitude
		if (!trans || !hasInOut || !hasGps) return

		const delay = this.coreSrvc.dbSrvc.settingSrvc.getCompany().gps_allowed_delay
		const includeImages = true // Global.coreSrvc.devDetect.isDesktop()
		const is12Hours = DateTimeHelper.format12Hour

		const result = MapHelper.buildTransInOutMarker(this.map, inOut, trans, delay, includeImages, is12Hours, true)
		const marker = result.marker
		const infoWindow = result.infoWindow

		marker.addListener('click', () => {
			if (this.isInfoWindowOpen(infoWindow)) {
				infoWindow.close()
			} else {
				this.closeAllInfoWindows()
				this.openInfoWindow(marker)
			}
		})

		// Save copy of infoWindow in marker and keep ref for marker
		marker['infoWindow'] = infoWindow
		marker['isClustered'] = false
		if (inOut === 'IN') {
			this.gpsCheckinMarker = marker
		} else {
			this.gpsCheckoutMarker = marker
		}
	}

	formatTimestamp(dateTime: string) {
		const mom = moment(dateTime)
		if (mom.isValid()) {
			const is12Hours = DateTimeHelper.format12Hour
			const timezone = this.trans.timezone
			if (is12Hours) {
				return mom.tz(timezone).format('ddd MMM Do [<br>] h:mm a z')
			}
			return mom.tz(timezone).format('ddd MMM Do [<br>] HH:mm z')
		}
		return null
	}

	openGpsLocationMarker(inOut: 'IN' | 'OUT') {
		if (!this.startInMapView) {
			this.panToExpectedLocation()
			return
		}
		if (inOut === 'IN') {
			if (!this.gpsCheckinMarker) {
				return
			}
		} else {
			if (!this.gpsCheckoutMarker) {
				return
			}
		}
		// if (!this.gpsLocationMarker) {
		// 	return
		// }
		const marker = inOut === 'IN' ? this.gpsCheckinMarker : this.gpsCheckoutMarker
		const infoWindow = marker['infoWindow']
		setTimeout(() => {
			this.openInfoWindow(marker)
		}, 250)
	}

	addJobSiteMarker() {
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(this.trans.jobsite_id)
		if (!site || !site.geo_latitude) return

		const result = MapHelper.buildJobSiteMarker(this.map, site)
		const marker = result.marker
		const infoWindow = result.infoWindow

		marker.addListener('click', () => {
			if (this.isInfoWindowOpen(infoWindow)) {
				infoWindow.close()
			} else {
				this.closeAllInfoWindows()
				this.openInfoWindow(marker)
			}
		})

		// Save copy of infoWindow in marker and save ref to marker
		marker['infoWindow'] = infoWindow
		marker['jobSiteId'] = site.id
		marker['isClustered'] = true
		this.jobSiteMarkers.push(marker)
	}

	highlightJobSite() {
		const trans = this.trans
		const jobSiteId = trans ? trans.jobsite_id : null

		if (jobSiteId) {
			log('Got ID', jobSiteId)
			this.jobSiteMarkers.forEach((marker) => {
				if (marker['jobSiteId'] === jobSiteId) {
					log('Marking a job site')
					// marker.setIcon('/assets/img/marker-green.png')
					this.showLegendHighlight = true
					this.expectedLocationMarker = marker
					marker['isClustered'] = false
				}
			})
		}
	}

	zoomToFitAllMarkers() {
		if (this.currentView === 'DETAILS') return
		const markers = []
		if (this.gpsCheckinMarker) {
			markers.push(this.gpsCheckinMarker)
		}
		if (this.gpsCheckoutMarker) {
			markers.push(this.gpsCheckoutMarker)
		}
		if (this.expectedLocationMarker) {
			markers.push(this.expectedLocationMarker)
		}
		const bounds = new google.maps.LatLngBounds()
		for (const marker of markers) {
			const position = marker.getPosition()
			bounds.extend(position)
		}
		this.map?.fitBounds(bounds)
		setTimeout(() => {
			const currentZoom = this.map.getZoom() ?? 17
			this.map.setZoom(currentZoom - 1)
		}, 500)
	}

	showOnMap(event: any) {
		this.closeAllInfoWindows()
		this.currentView = 'MAP'
		this.coreSrvc.zone.run(() => {
			setTimeout(() => {
				const inOut = event.type
				this.panToCurrentLocation(inOut)
			}, 200)
		})
	}

	openInfoWindow(marker: google.maps.marker.AdvancedMarkerElement) {
		log('open info window')
		const infoWindow = marker['infoWindow']
		if (infoWindow) {
			infoWindow.open(this.map, marker)
			setTimeout(() => {
				MapHelper.initializeImageScroller()
				setTimeout(() => {
					MapHelper.updateImageScroller()
				}, 125)
			}, 125)
		}
	}

	panToCurrentLocation(inOut: 'IN' | 'OUT') {
		this.closeAllInfoWindows()
		const marker = inOut === 'IN' ? this.gpsCheckinMarker : this.gpsCheckoutMarker
		if (!marker) {
			log('No marker to pan to')
			return
		}
		marker.zIndex = this.topZIndex++
		const latLng = marker.position
		this.map.panTo(latLng)
		const infoWindow = marker['infoWindow']
		this.openInfoWindow(marker)
	}

	panToExpectedLocation() {
		this.closeAllInfoWindows()
		const marker = this.expectedLocationMarker
		if (!marker) {
			return
		}
		marker.zIndex = this.topZIndex++
		const latLng = marker.position
		this.map.panTo(latLng)
		// this.map.setZoom(17)
		const infoWindow = marker['infoWindow']
		this.openInfoWindow(marker)
	}

	closeAllInfoWindows() {
		this.gpsCheckinMarker?.['infoWindow']?.close()
		this.gpsCheckoutMarker?.['infoWindow']?.close()
		this.jobSiteMarkers?.forEach((marker) => {
			marker['infoWindow'].close()
		})
	}

	isInfoWindowOpen(infoWindow) {
		const map = infoWindow.getMap()
		return map !== null && typeof map !== 'undefined'
	}

	onClose() {
		this.closeDialog.emit(true)
	}

	checkInOutTimestampFormatter(): string {
		if (!this.trans) {
			return
		}
		let timestamp = null
		if (this.linkType === 'start') {
			timestamp = this.trans.actual_start
		} else {
			timestamp = this.trans.actual_end
		}
		if (timestamp) {
			const tsMom = moment(timestamp).tz(this.trans.timezone)
			const is12Hours = DateTimeHelper.format12Hour
			if (is12Hours) {
				return tsMom.format('[@] h:mm a z')
			} else {
				return tsMom.format('[@] HH:mm z')
			}
		}
	}

	public toggleView(viewType: GPSDetailsViewType): void {
		log('Set view type', viewType)
		this.currentView = viewType
	}

	public getEventType(inOut: 'IN' | 'OUT'): string {
		const isTravel = this.trans?.travel_job
		return inOut === 'IN' ? (isTravel ? 'Travel Start' : 'Check-In') : isTravel ? 'Travel End' : 'Check-Out'
	}
}

// https://github.com/Concept211/Google-Maps-Markers
