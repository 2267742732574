import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterContentInit } from '@angular/core'
import { DialogManager, CompanyRecord, HelpDialogMessage } from '@app/models'
import { CoreService } from '@app/services'
import { FormHelper, Helper, log } from '@app/helpers'
import { CountryCode, isValidNumberForRegion, parseNumber, formatNumber, ParsedNumber } from 'libphonenumber-js'
import _ from 'lodash'

export class BlacklistPhoneEntry {
	name = ''
	number = ''
	isEmployee = false

	constructor() {}
}

@Component({
	selector: 'app-call-blocking',
	templateUrl: './call-blocking.component.html',
	styleUrls: ['./call-blocking.component.scss'],
	standalone: false,
})
export class CallBlockingComponent implements OnInit, AfterContentInit, OnDestroy {
	isUpdating = false
	hasListChanged = false

	company: CompanyRecord
	useWhitelist: boolean
	blackList: Array<BlacklistPhoneEntry> = []
	blockCellCalls = true
	hasEmployeesLinkedToJobSites = false

	countryCode: CountryCode = 'US'
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)

	@Input() dialogManager = new DialogManager()
	@ViewChild('newNumber') newNumber: ElementRef

	// private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.setupComponent()
		this.checkForLinkedEmployees()
	}

	ngOnInit(): void {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy(): void {
		// this.subs.unsubscribe()
		this.dialogManager.resetActions()
	}

	private setupComponent() {
		this.company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.useWhitelist = this.company.useWhitelist
		const blockedNumbers = this.company.blocked_numbers
		if (blockedNumbers) {
			const blackList = this.company.blocked_numbers.split(',')
			this.blackList = _.sortBy(blackList).map((num) => this.makePhoneEntry(num))
		}
		this.blockCellCalls = !this.company.allow_cell_calls
		this.countryCode = this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso as CountryCode
	}

	private checkForLinkedEmployees() {
		const empMobileNumbers = this.coreSrvc.dbSrvc.empSrvc.getAllEmployees().map((emp) => emp.phone_number_e164)
		empMobileNumbers.forEach((num) => {
			const linkedSites = this.coreSrvc.dbSrvc.siteSrvc.jobSitesForEmpMobileNumberE164(num)
			if (linkedSites.length > 0) {
				this.hasEmployeesLinkedToJobSites = true
			}
		})
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.onSave()
	}

	// setupSubscriptions() {
	// 	this.subs.add(this.dialogManager.onSave.subscribe(event => {
	// 		this.onSave()
	// 	}))
	// }

	formatPhone(input: HTMLInputElement) {
		const countryCode = this.countryCode
		const number = input.value
		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			input.value = formatNumber(parsedNumber, 'NATIONAL')
		}
	}

	private makePhoneEntry(num: string): BlacklistPhoneEntry {
		const entry = new BlacklistPhoneEntry()
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(num)
		entry.number = num
		entry.name = emp ? emp.name : ''
		entry.isEmployee = emp ? true : false
		return entry
	}

	public onAddNumber(newNumber): boolean {
		log('Attempting to add', newNumber.value)
		const number = newNumber.value
		const countryCode: CountryCode = this.countryCode
		const parsedNumber = parseNumber(number, countryCode) as ParsedNumber

		if (number.length < 3 || !isValidNumberForRegion(number, countryCode)) {
			alert('Phone number ' + number + ' is not valid for the region you have selected.')
			return false
		}

		const internationalNumber = formatNumber(parsedNumber, 'E.164')
		const employee = this.coreSrvc.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(internationalNumber)
		const entry = this.makePhoneEntry(internationalNumber)

		// if (employee) {
		// 	this.coreSrvc.dbSrvc.notifySrvc.notify('info', 'Employee Number', 'You added a current employee phone number. If that was not your intention, please remove them before saving.', 5)
		// }

		this.blackList.unshift(entry)

		newNumber.value = ''
		this.hasListChanged = true
		return true
	}

	public onRemoveNumber(idx: number): boolean {
		this.blackList.splice(idx, 1)
		this.hasListChanged = true
		return false
	}

	public isEmployee(idx: number): boolean {
		return this.blackList[idx].isEmployee
	}

	public isPhoneValid(input: HTMLInputElement): boolean {
		const countryCode = this.countryCode
		const number = input.value
		if (number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			return true
		}
		return false
	}

	private onSave() {
		// Guard against double submission
		if (this.isUpdating) return

		const record = new CompanyRecord(this.company)
		FormHelper.trimOnlyWhitespace(this.company)

		// See if the input is in DOM and if it has a value, add it to list
		if (this.newNumber) {
			const phoneInput = this.newNumber.nativeElement
			if (phoneInput.value) {
				this.onAddNumber(phoneInput)
			}
		}

		const numbers = this.blackList.map((elm) => elm.number)

		record.useWhitelist = this.useWhitelist
		record.blocked_numbers = numbers.join(',')
		record.allow_cell_calls = !this.blockCellCalls

		log('Saving company Record', record)
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('company', record).then((success) => {
			if (success) {
				this.dialogManager.isDialogVisible = false
			}
		})
	}

	public toggleBlockCalls() {
		if (!this.hasEmployeesLinkedToJobSites) {
			this.blockCellCalls = !this.blockCellCalls
		}
	}
	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'useWhitelist':
				help.header = 'Restrict Calls'
				help.message =
					'When checked, ONLY phone numbers which have been linked to a job site will be allowed. When this setting is enabled, even calls from an employee mobile phone will be blocked unless they have also been linked to one or more job sites.'
				break
			case 'blockCellCalls':
				help.header = 'Block Mobile Phones'
				help.message = 'Check this box to prevent employees calling into the system from their registered mobile phone numbers.'
				break
			default:
				help.header = 'Unknown Option'
				help.message = 'No help topic has been defined for this option. Please notify support.'
				break
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
