import { Directive, ElementRef, Renderer2, OnChanges, Input, OnInit } from '@angular/core'
import { CoreService } from '@app/services'

@Directive({
	selector: '[appAdpIvrVisible]',
	standalone: false,
})
export class AppAdpIvrVisibleDirective implements OnInit, OnChanges {
	isAdpIvrAdmin = false

	/**
	 * Determines the behavior:
	 * - `true`: Makes the element visible if `isAdpIvrAdmin` is true.
	 * - `false`: Makes the element hidden if `isAdpIvrAdmin` is true.
	 */
	@Input() appAdpIvrVisible = true // Default: makes the element visible when admin.

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private coreSrvc: CoreService,
	) {
		this.isAdpIvrAdmin = this.coreSrvc.dbSrvc.settingSrvc.isAdpIvrAdmin()
	}

	ngOnInit(): void {
		this.updateVisibility()
	}

	ngOnChanges(): void {
		this.updateVisibility()
	}

	private updateVisibility(): void {
		if (this.isAdpIvrAdmin === this.appAdpIvrVisible) {
			this.renderer.removeStyle(this.el.nativeElement, 'display')
		} else {
			this.renderer.setStyle(this.el.nativeElement, 'display', 'none')
		}
	}
}
