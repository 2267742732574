import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DateTimeHelper, DisplayHelper, GeneralTableHelper, TableActionFormatter, log } from '@app/helpers'
import { DialogManager, DatabaseTableName, CrudAction, EditCompleteEvent, EditFormAction, ComponentBridgeName, AdpIvrApiLogRecord } from '@app/models'
import { CoreService } from '@app/services'

import { Router } from '@angular/router'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'
import _ from 'lodash'

enum AdpIvrApiLogTableColumnIndex {
	id = 0,
	created,
	operation,
	employeeName,
	employeeOid,
	uuid,
	ivrCallId,
	responseStatus,
	actions,
}

@Component({
	selector: 'app-adp-ivr-api-log-table',
	standalone: false,

	templateUrl: './adp-ivr-api-log-table.component.html',
	styleUrl: './adp-ivr-api-log-table.component.scss',
})
export class AdpIvrApiLogTableComponent implements OnInit, OnDestroy, AfterViewInit {
	resource: DatabaseTableName = 'adp_ivr_api_log'
	tableName = 'adpIvrApiLogTable'
	bridgeName: ComponentBridgeName = `ngBridgeAdpIvrApiLogTable`

	isGlobalAccount = false

	detailsAction = { recordId: null }
	detailsDialogManager = new DialogManager('detailsDialogManager')

	list: Array<AdpIvrApiLogRecord> = []

	public debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private dataTable // : DataTables.DataTable;
	private defaultSortOrder = [[AdpIvrApiLogTableColumnIndex.created, 'desc']]

	private subs = new Subscription()

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	public editAction = { recordId: null, action: 'edit' as EditFormAction }
	public editDialogManager = new DialogManager(`${this.tableName}EditDialog`)

	public deleteAction = { tableName: this.resource as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()

		this.setupSubscriptions()
		this.setupLocalPrefsDialog()
		this.loadData()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	get viewManager() {
		return this.coreSrvc.dbSrvc.adpSrvc.adpIvrApiLogViewManager
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.subs.unsubscribe()
		this.dataTable['fixedHeader'].disable()
		window[this.bridgeName] = null
	}

	public ngAfterViewInit() {
		this.initTable()

		$(`#${this.tableName}_filter input`).attr(`placeholder`, ` filter`)
		$(`#${this.tableName}_filter input`).addClass(`search-field-input`)

		setTimeout(() => {
			$(`#clear-search-icon`).detach().appendTo(`#${this.tableName}_filter label`)
		})

		$(`#${this.tableName}_filter input`).on('keyup', () => {
			this.debounceRedrawTable()
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupLocalPrefsDialog(): void {}

	private handleScreenSizeChanges = () => {
		if (this.dataTable) {
			this.dataTable.columns.adjust().responsive.recalc()
			this.dataTable.fixedHeader.adjust()
		}
	}

	public clearSearch(): boolean {
		this.dataTable
			?.search('')
			.order([[AdpIvrApiLogTableColumnIndex.created, 'desc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	public canPerformAction(action: CrudAction, isMyRecord: boolean) {
		// return this.accessHelper.canPerformAction(action, isMyRecord)
		return true
	}
	public isMyRecord(id: number): boolean {
		return true
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public openPrefsDialog(): void {
		this.coreSrvc.notifySrvc.notify('error', 'Not implemented', 'This feature is note yet implemented.', 3)
		return
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrApiLogRecords()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const created = true
		const operation = true
		const employeeName = !this.isGlobalAccount
		const employeeOid = true
		const uuid = true
		const ivrCallId = true
		const responseStatus = true

		this.dataTable.clear()
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.created, created)
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.operation, operation)
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.employeeName, employeeName)
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.employeeOid, employeeOid)
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.uuid, uuid)
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.ivrCallId, ivrCallId)
		GeneralTableHelper.updateColumn(this.dataTable, AdpIvrApiLogTableColumnIndex.responseStatus, responseStatus)

		this.dataTable.rows.add(this.list)
		this.dataTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.dataTable.page(0)
		if (reDraw) {
			this.dataTable.draw(false)
		}
	}

	public fetchAndReload() {
		const options = this.makeRequestOptions()
		this.coreSrvc.dbSrvc.readTable(this.resource, options).then((readSuccess) => {
			this.updateTable()
		})
	}

	public redrawTable() {
		this.dataTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.coreSrvc.displaySrvc.enableAllTooltips()
	}

	private makeRequestOptions(): Object {
		const dayViewDate = this.viewManager.dayViewDate
		if (dayViewDate) {
			const dateStr = moment(dayViewDate).format('YYYY-MM-DD')
			const timezone = this.viewManager.timezone
			const start = moment.tz(dateStr, timezone).startOf('day').toISOString()
			const end = moment.tz(dateStr, timezone).endOf('day').toISOString()
			return { start_date: start, end_date: end }
		}
		return {}
	}

	public openDetailsDialog(id: number) {
		this.detailsAction.recordId = id
		this.detailsDialogManager.headerLabel = 'Record Details'
		this.detailsDialogManager.cancelBtnLabel = 'Close'
		this.detailsDialogManager.isSubmitBtnVisible = false
		this.detailsDialogManager.isDialogVisible = true
	}

	public createRecord() {
		log('createRecord')
		// if (!this.canPerformAction(CrudAction.create, null)) { this.accessHelper.notifyOperationNotAuthorized(); return }
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.headerLabel = 'Add Record'
		this.editDialogManager.isDialogVisible = true
	}

	public editRecord(id: number) {
		log('editRecord', id)
		this.editAction.recordId = id
		this.editAction.action = 'edit'
		this.editDialogManager.headerLabel = 'Edit Record'
		this.editDialogManager.isDialogVisible = true
	}

	public cloneRecord(id: number) {
		log('cloneRecord', id)
		this.editAction.recordId = id
		this.editAction.action = 'clone'
		this.editDialogManager.headerLabel = 'Clone Record'
		this.editDialogManager.isDialogVisible = true
	}

	public recordUpdated(event: EditCompleteEvent) {
		// If id is not null, this was an edit and we refresh just the row
		// If id is null, this was an insert and we reload the whole table
		const id = event.recordId
		if (id) {
			this.loadData()
			const record = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrApiLogRecordForId(id)
			const idx = this.list.indexOf(record)
			this.dataTable.rows(idx).invalidate().draw(false)
		} else {
			this.fetchAndReload()
		}
	}

	public deleteRecord(id: number) {
		log('deleteRecord called')
		const record = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrApiLogRecordForId(id)
		if (record) {
			this.deleteAction.recordId = record.id
			this.deleteAction.recordLabel = 'Record Description' // record.description
			this.deleteAction.tableName = this.resource
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	public deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	public deleteActionComplete() {
		this.loadData()
		this.updateTable()
		this.deleteAction.showDeleteRecordDialog = false
	}

	private formatDate(date: string, tz: string) {
		return moment.tz(date, tz).format('YYYY-MM-DD')
	}

	private formatTime(date: string, tz: string) {
		const format12Hr = DateTimeHelper.format12Hour
		const mom = moment.tz(date, tz)
		return format12Hr ? mom.format('h:mm a') : mom.format('HH:mm')
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		const mythis = this
		this.dataTable = $(`#${this.tableName}`).DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: false,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: true,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [AdpIvrApiLogTableColumnIndex.created],
				},
				{
					// ID
					targets: AdpIvrApiLogTableColumnIndex.id,
					visible: false,
					searchable: false,
					title: 'ID',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						return `${rec.id}`
					},
				},

				{
					// Created
					targets: AdpIvrApiLogTableColumnIndex.created,
					visible: true,
					searchable: true,
					title: 'Created',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						const created = rec.created
						const date = this.formatDate(rec.created, this.viewManager.timezone)
						const time = this.formatTime(rec.created, this.viewManager.timezone)
						const result = `<span style="display: none">${created}</span>${date}<br>${time}`
						return `
                    <div class="dtr-control-content">
                      ${result}
                    </div>`
					},
				},
				{
					// Operation
					targets: AdpIvrApiLogTableColumnIndex.operation,
					visible: true,
					searchable: true,
					title: 'Operation',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						const operation = rec.operationLabel
						return `
                    <span class="table-tag" style="width: 100px">
                      ${operation}
                    </span>`
					},
				},

				{
					// Employee Name
					targets: AdpIvrApiLogTableColumnIndex.employeeName,
					visible: !this.isGlobalAccount,
					searchable: !this.isGlobalAccount,
					title: 'Employee Name',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						const employee = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(rec.employee_id)
						return `
                    <span>
                      ${employee?.name}
                    </span>`
					},
				},
				{
					// Employee OID
					targets: AdpIvrApiLogTableColumnIndex.employeeOid,
					visible: true,
					searchable: true,
					title: 'Employee OID',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						return `
                    <span>
                      ${rec.employee_adp_oid}
                    </span>`
					},
				},
				{
					// UUID
					targets: AdpIvrApiLogTableColumnIndex.uuid,
					visible: true,
					searchable: true,
					title: 'UUID',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						return `
                    <div>
                      ${rec.uuid}
                    </div>`
					},
				},

				{
					// IVR Call ID
					targets: AdpIvrApiLogTableColumnIndex.ivrCallId,
					visible: true,
					searchable: true,
					title: 'IVR Call ID',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						return `
                    <div>
                      ${rec.ivr_call_id}
                    </div>`
					},
				},

				{
					// Response Status
					targets: AdpIvrApiLogTableColumnIndex.responseStatus,
					visible: true,
					searchable: true,
					title: 'Status',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						const status = rec.response_status
						const result =
							status === 'SUCCESS'
								? `<span class="table-tag" style="background-color: green; width: 100px">${status}</span>`
								: `<span>${status}</span>`
						return result
					},
				},

				{
					// Actions
					targets: AdpIvrApiLogTableColumnIndex.actions,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					title: 'Actions',
					data: null,
					render: (rec: AdpIvrApiLogRecord, type, full, meta) => {
						const viewLink = TableActionFormatter.viewLink(this.bridgeName, 'openDetailsDialog', rec.id, true)
						return `<span class="act-ico-wrap">${viewLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('vac-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.dataTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter(`${this.tableName}`, this.dataTable)
	}
}
