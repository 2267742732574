import { AfterContentInit, Component, Input } from '@angular/core'
import {
	BaseRecord,
	CardViewAction,
	ComponentBridgeName,
	DatabaseTableName,
	DialogAuditAction,
	DialogManager,
	EditCompleteEvent,
	EditFormAction,
} from '@app/models'
import { CoreService } from '@app/services'

import { log } from '@app/helpers'

@Component({
	selector: 'app-base-list',
	standalone: false,

	templateUrl: './base-list.component.html',
	styleUrl: './base-list.component.scss',
})
export class BaseListComponent implements AfterContentInit {
	resource: DatabaseTableName = 'none'
	searchText = ''

	isLoading = true
	editAction = { recordId: null, action: 'edit' as EditFormAction, isEditing: false }
	auditAction: DialogAuditAction = { resource: this.resource, recordId: null, isAuditing: false }

	list: Array<BaseRecordWrapper> = []

	@Input() dialogManager = new DialogManager()

	constructor(private coreSrvc: CoreService) {
		this.loadData() // Used for testing
		// this.fetchAndReload()
	}

	get searchResults(): Array<BaseRecordWrapper> {
		return this.list.filter((r) => r.matchesSearch)
	}

	ngAfterContentInit(): void {
		this.dialogManager.submitBtnAction = () => this.newRecord()
	}

	private loadData(): void {
		this.list = this.coreSrvc.dbSrvc.baseSrvc.getBaseRecords().map((record) => new BaseRecordWrapper(record))
		if (this.searchText) this.performSearch(this.searchText)
		this.isLoading = false
	}

	private fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			this.coreSrvc.dbSrvc.readTable(this.resource).then(() => {
				this.loadData()
				resolve(true)
			})
		})
	}

	private resetDialog(): void {
		this.editAction = { recordId: null, action: 'edit', isEditing: false }
		this.auditAction = { resource: 'none', recordId: null, isAuditing: false }
		this.dialogManager.popStateAndApply()

		// Need some time for view to start rendering before we can restore scroll position
		setTimeout(() => {
			this.dialogManager.restoreScrollPosition('main')
		})
	}

	public newRecord() {
		const vm = new BaseRecordWrapper(new BaseRecord())
		this.prepareDialogForEdit(vm, 'new', 'New Record')
	}

	public actionIconClicked(event: Event, action: CardViewAction, vm: BaseRecordWrapper) {
		event.stopPropagation()
		switch (action) {
			case 'EDIT':
				this.prepareDialogForEdit(vm, 'edit', 'Edit Record')
				break
			case 'CLONE':
				this.prepareDialogForEdit(vm, 'clone', 'Clone Record')
				break
			case 'DELETE':
				this.list.forEach((r) => (r.isConfirmingDelete = false))
				vm.isConfirmingDelete = true
				break
			case 'AUDIT':
				this.prepareForRecordAudit(vm)
				break
			case 'EXPAND':
				vm.isExpanded = !vm.isExpanded
				break
		}
	}

	private prepareDialogForEdit(vm: BaseRecordWrapper, action: EditFormAction, header: string): void {
		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.headerLabel = header
		this.editAction.recordId = vm.record.id
		this.editAction.action = action
		this.editAction.isEditing = true
	}

	private prepareForRecordAudit(vm: BaseRecordWrapper): void {
		this.auditAction.recordId = vm.record.id
		this.auditAction.resource = this.resource

		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnLabel = 'Done'

		this.auditAction.isAuditing = true
	}

	public recordUpdated(event: EditCompleteEvent) {
		const action = event.action

		if (action === 'new' || action === 'clone') {
			this.fetchAndReload().then(() => {
				this.resetDialog()
			})
		}

		if (action === 'edit') {
			const recordId = event.recordId
			const record = this.coreSrvc.dbSrvc.baseSrvc.getBaseRecordById(recordId)
			const vm = this.list.find((r) => r.record.id === record.id)
			vm.record = record
			vm.updateModel()
			this.resetDialog()
		}
	}

	public confirmDelete(vm: BaseRecordWrapper): void {
		vm.isDeleting = true
		setTimeout(() => {
			this.list = this.list.filter((r) => r !== vm)
			vm.isConfirmingDelete = false
		}, 1000)
	}

	///////////////////////
	// Search Management //
	///////////////////////

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchText = lcText
		this.list.forEach((vm) => {
			vm.peformSearch(lcText)
		})
	}
	public toggleExpansion(isExpanded: boolean) {
		this.list.forEach((vm) => (vm.isExpanded = isExpanded))
	}
}

class BaseRecordWrapper {
	record: BaseRecord
	isExpanded = false
	isConfirmingDelete = false
	isDeleting = false

	description = ''
	extendedInfo = ''

	// Search properties
	searchText = ''
	matchesSearch = true
	searchTextDescription = ''
	searchTextExtendedInfo = ''

	constructor(record: BaseRecord) {
		this.record = record
		this.updateModel()
	}

	public updateModel() {
		const record = this.record

		// Setup model properties
		this.description = record.description ?? 'List Item Description'
		this.extendedInfo = 'Here is some example text to display in the extended info section'

		// Setup search text properties
		this.searchTextDescription = this.description.toLowerCase()
		this.searchTextExtendedInfo = this.extendedInfo.toLowerCase()
	}

	public peformSearch(searchText: string) {
		this.searchText = searchText
		if (!searchText) {
			this.matchesSearch = true
			return
		}
		this.matchesSearch = false

		const descriptionMatches = this.searchTextDescription.includes(searchText)
		const extendedInfoMatches = this.searchTextExtendedInfo.includes(searchText)

		if (descriptionMatches) this.matchesSearch = true
		if (extendedInfoMatches) {
			this.matchesSearch = true
			this.isExpanded = true
		}

		// See onboarding definition or onboard log list dialogs for a search example
	}
}
