import { Component, OnDestroy } from '@angular/core'
import { AccessHelper, DateTimeHelper, log } from '@app/helpers'
import { CrudAction, DialogManager, EmployeeRecord, OnboardUpdateViewManager } from '@app/models'
import { CoreService } from '@app/services'
import { Subscription } from 'rxjs'

import _ from 'lodash'

@Component({
	selector: 'app-onboard-update-list',
	standalone: false,

	templateUrl: './onboard-update-list.component.html',
	styleUrl: './onboard-update-list.component.scss',
})
export class OnboardUpdateListComponent implements OnDestroy {
	isLoading = true
	showMoreInfo = false
	timeFormatter = DateTimeHelper.getTimeFormat()

	private accessHelper: AccessHelper

	public onboardLogListAction = { recordId: null }
	public onboardLogListDialogManager = new DialogManager('onboardLogListDialog')

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	public list: Array<OnboardUpdateWrapper> = []
	private subs = new Subscription()

	orderByOptions = [
		{ label: 'Employee Name', value: 'EMP_NAME' },
		{ label: 'Response Date', value: 'EMP_LAST_UPDATE' },
	]

	orderByLastResponseOptions = [
		{ label: 'Oldest to Newest', value: 'asc' },
		{ label: 'Newest to Oldest', value: 'desc' },
	]

	constructor(private coreSrvc: CoreService) {
		// Setup preferences dialog
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		this.sectionPrefsDialogManager.isSubmitBtnVisible = false
		this.sectionPrefsDialogManager.cancelBtnLabel = 'Close'
		this.sectionPrefsDialogManager.cancelBtnAction = () => this.handlePrefsDialogClose()

		// Setup subscriptions
		this.subs.add(this.coreSrvc.dbSrvc.onboardSrvc.onboardUpdateListNeedsRefresh.subscribe(() => this.handleOnboardListNeedsRefresh()))

		// Update UI
		this.isLoading = true
		this.updateUI()
	}

	get lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.empSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	get viewManager(): OnboardUpdateViewManager {
		return this.coreSrvc.dbSrvc.onboardSrvc.viewManager
	}

	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	public fetchAndReload() {
		this.coreSrvc.dbSrvc.readTable('employee').then(() => this.updateUI())
	}

	public setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'employee')
	}

	public canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	public updateUI() {
		let list = this.coreSrvc.dbSrvc.empSrvc.getPendingOnboardUpdateRecords().map((emp) => new OnboardUpdateWrapper(emp))

		// When sorting by employee name, the sortOrder option will be hidden and always be 'asc'
		const iterates = this.viewManager.filterOptions.iterate === 'EMP_NAME' ? 'emp.name' : 'empLastUpdate'

		// If sorting by employee, always use name. If by last response, then use user selection
		const order = this.viewManager.filterOptions.iterate === 'EMP_LAST_UPDATE' ? this.viewManager.filterOptions.order : 'asc'
		list = _.orderBy(list, iterates, order)

		this.list = list
		this.isLoading = false
	}

	public handleOnboardListNeedsRefresh() {
		this.coreSrvc.dbSrvc.readTable('employee').then(() => {
			this.updateUI()
		})
	}

	public handleImageLoadError(vm: OnboardUpdateWrapper) {
		vm.hasImageError = true
	}

	public handleLogListDialogOpen(vm: OnboardUpdateWrapper) {
		this.onboardLogListAction.recordId = vm.emp.id
		this.onboardLogListDialogManager.cancelBtnLabel = 'Close'
		this.onboardLogListDialogManager.isSubmitBtnVisible = false
		this.onboardLogListDialogManager.headerLabel = vm.emp.name
		this.onboardLogListDialogManager.isDialogVisible = true
	}

	public handlePrefsDialogOpen() {
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	public handlePrefsDialogClose() {
		this.updateUI()
		this.sectionPrefsDialogManager.isDialogVisible = false
	}

	public handleFilterChange() {
		this.updateUI()
	}
}

class OnboardUpdateWrapper {
	selected = false
	emp: EmployeeRecord

	placeholderImage = '/assets/img/profile-placeholder.jpg'

	isProcessing = false
	hasImageError = false
	skipped = false

	empLastUpdate: string // ISO Date

	constructor(emp: EmployeeRecord) {
		this.emp = emp
		const skipCount = emp.onboard_updates.skippedPending ?? 0
		this.skipped = skipCount > 0

		this.empLastUpdate = emp.onboard_updates.employee_last_update
		if (this.empLastUpdate) this.empLastUpdate += 'Z' // Force UTC becasue it's JOOQ enbeded in JSON
	}
	get profileUrl(): string {
		return this.emp.getPfpImageFile()?.getPfpImageUrl() ?? this.placeholderImage
	}
}
