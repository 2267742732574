<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Data ...'"></app-activity-banner>

<div *ngIf="!isLoading && !editAction.isEditing">
	<div *ngIf="list.length === 0" class="alert alert-info alert-bg-gray" style="margin-bottom: 20px">
		This section displays information requests for use in the onboarding system. Each card includes a description of the request, instructions for
		the employee to follow, and general configuration information.
	</div>

	<div *ngIf="list.length > 0" style="margin-bottom: 16px">
		<div style="margin-bottom: 6px">
			<app-search-filter [debounceTimer]="350" [placeholder]="'filter requests'" (search)="performSearch($event)"></app-search-filter>
		</div>
		<div style="text-align: right">
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span> |
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span>
		</div>
	</div>

	<!-- No Records -->
	<div *ngIf="list.length === 0 || searchResults.length === 0" class="no-data-block">No Records Available</div>

	<!-- Records list -->
	<div *ngFor="let vm of list; let idx = index">
		<div *ngIf="vm.matchesSearch" class="card list-card-no-shadow" [class.list-card-disabled]="!vm.record.enabled">
			<div class="card-body list-card-body">
				<div class="list-card-header" (click)="vm.isExpanded = !vm.isExpanded">
					<div class="float-right lc-icon-wrap">
						<i
							class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover lc-act-ico"
							title="Edit Record"
							(click)="actionIconClicked($event, 'EDIT', vm)"
						></i>
						<i
							class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover lc-act-ico"
							title="Delete Record"
							(click)="actionIconClicked($event, 'DELETE', vm)"
						></i>
						<i
							class="far fa-database act-ico act-ico-dark-hover lc-act-ico"
							[class.act-ico-highlight]="vm.record.updated"
							title="Audit Record"
							(click)="actionIconClicked($event, 'AUDIT', vm)"
						></i>
						<i
							class="fas act-ico act-ico-dark-hover lc-act-ico"
							[class.fa-chevron-right]="!vm.isExpanded"
							[class.fa-chevron-down]="vm.isExpanded"
							[class.act-ico-highlight]
							title="Expand Record"
							(click)="actionIconClicked($event, 'EXPAND', vm)"
						></i>
					</div>
					<div class="lc-title tts-hl-2">Info Request #{{ idx + 1 }}</div>
					<div class="lc-subtitle">{{ vm.record.requestType }}</div>
					<div class="lc-description">{{ vm.record.description }}</div>
				</div>
				<div *ngIf="vm.isExpanded && !vm.isConfimingDelete">
					<div class="alert alert-bg-gray emp-instructions">{{ vm.record.emp_instructions }}</div>
					<!-- Extended Info -->
					<div style="margin-top: 12px">
						<!-- Minimum file count -->
						<div class="lc-flag-item">
							<div class="float-right lc-flag-icon">
								{{ vm.record.file_count }}
							</div>
							<div class="lc-flag-label">Minimum files required</div>
						</div>
						<!-- Requires admin approval -->
						<div class="lc-flag-item" [class.tts-hl-green]="vm.record.requires_approval" [class.tts-hl-red]="!vm.record.requires_approval">
							<div class="float-right lc-flag-icon">
								<i class="far" [class.fa-check]="vm.record.requires_approval" [class.fa-ban]="!vm.record.requires_approval"></i>
							</div>
							<div class="lc-flag-label">Require admin approval</div>
						</div>
						<!-- Requires admin approval -->
						<div class="lc-flag-item" [class.tts-hl-green]="vm.record.expires" [class.tts-hl-red]="!vm.record.expires">
							<div class="float-right lc-flag-icon">
								<i class="far" [class.fa-check]="vm.record.expires" [class.fa-ban]="!vm.record.expires"></i>
							</div>
							<div class="lc-flag-label">Require expiration on approval</div>
						</div>
					</div>
				</div>

				<!-- Delete Confirmation -->
				<div *ngIf="vm.isConfimingDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary delete-confirm-left-btn" (click)="vm.isConfimingDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger delete-confirm-right-btn" (click)="confirmDelete(vm)">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<span *ngIf="vm.isDeleting"><i class="fa fa-loader fa-spin"></i></span>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-onboard-definition-edit
	*ngIf="editAction.isEditing"
	[recordId]="editAction.recordId"
	[action]="editAction.action"
	[dialogManager]="dialogManager"
	(recordUpdated)="recordUpdated($event)"
>
</app-onboard-definition-edit>
