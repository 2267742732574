import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import {
	DialogManager,
	AdpIvrCompanyRecord,
	DatabaseTableName,
	EditFormAction,
	EditCompleteEvent,
	HelpDialogMessage,
	DataAccessRequest,
} from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-adp-ivr-company-edit',
	standalone: false,

	templateUrl: './adp-ivr-company-edit.component.html',
	styleUrl: './adp-ivr-company-edit.component.scss',
})
export class AdpIvrCompanyEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'adp_ivr_company'
	isLoading = true
	isUpdating = false
	form: UntypedFormGroup

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: AdpIvrCompanyRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<EditCompleteEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		this.setupComponent()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {}

	public ngOnDestroy(): void {}

	public setupComponent() {
		this.coreSrvc.dbSrvc.readTable('adp_ivr_company').then(() => {
			this.record = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrCompany()
			this.setupDialogManager()
			this.setupForm()
			this.isLoading = false
		})
	}

	public setupForm() {
		const record = this.record

		this.form = this.fb.group({
			id: [record?.id],
			labor_level_prompt: [record?.labor_level_prompt, Validators.required],
			work_rule_prompt: [record?.work_rule_prompt, Validators.required],
			pay_code_prompt: [record?.pay_code_prompt, Validators.required],
		})
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.form)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): AdpIvrCompanyRecord {
		const record = new AdpIvrCompanyRecord(this.record)

		record.labor_level_prompt = this.form.get('labor_level_prompt').value
		record.work_rule_prompt = this.form.get('work_rule_prompt').value
		record.pay_code_prompt = this.form.get('pay_code_prompt').value

		// Update any values changed by the form here

		return record
	}

	public toggleCheckbox(prop: string) {
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	private scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
