import { log } from '@app/helpers'
import { Global } from './global'
import { CompanyActivityLogRecord } from './visualization'
import { TransactionLogRecord } from './transaction'

export type VizHealthCenterCategoryType = 'PEOPLE' | 'WORK' | 'SCHEDULING' | 'OPERATIONS' | 'INTEGRATIONS' | 'ADMIN'
export type VizHealthCenterSectionType =
	| 'EMPLOYEES'
	| 'SUPERVISORS'
	| 'CONTACTS'
	| 'JOB_SITES'
	| 'JOBS'
	| 'TOURS'
	| 'NOTIFICATIONS'
	| 'SCHEDULES'
	| 'SHIFTS'
	| 'TIME_OFF'
	| 'TIME_ENTRIES'
	| 'SHIFT_REPORTS'
	| 'INSIGHT_REPORTS'
	| 'COMM_LOG'
	| 'AUDIT_LOG'
	| 'ANNOUNCEMENTS'
	| 'SETTINGS'
	| 'BILLING'
	| 'STATIONS'
	| 'PAY_RATES'
	| 'MISCELLANEOUS'

// Define the HealthAlertKey union type. This is not the prop name from company activity log. It is the key used
// by the alert cards in the alerts array of the section object.

type HealthCenterAlertKey =
	// People > Employees
	| 'employeeHasAddress'
	| 'employeeHasEmail'

	// People > Supervisors
	| 'userHasEmail'

	// People > Contacts (Individuals)
	| 'contactHasContactMethod'
	| 'contactHasEmail'
	| 'contactHasPhone'

	// People > Contacts (Organizations)
	| 'orgHasContactMethod'
	| 'orgHasEmail'
	| 'orgHasPhone'

	// Work > Jobs / Sites
	| 'jobHasAddress'

	// Work > Tours
	| 'tourHasLinkedJob'
	// | 'tourHasPublicIdentifier'
	// | 'tourHasCheckpointRequirements'

	// Work > Notifications
	| 'notificationHasLinkedJob'
	| 'scheduleAtLeastOneSetup'

	// Scheduling > Schedules
	| 'scheduleJobsHaveLinkedSchedules'

	// Operations > Time Entries
	| 'transNoShowRatio'
	| 'transMissingClockOutRatio'
	| 'transClockInNotification'
	| 'transClockOutNotification'
	| 'transCheckpointNotification'

	// Admin
	| 'adminCompanyHasPhysicalAddress'

// Defines the format for the data stored in the health center prefs

export class VisualizationPrefs {
	version = 1
	enabled: {
		[key in HealthCenterAlertKey]?: boolean
	} = {}
	contributors: {
		[key in HealthCenterAlertKey]?: boolean
	} = {}

	constructor(record?: VisualizationPrefs) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	static buildFromJson(jsonStr: string): VisualizationPrefs {
		try {
			const data = JSON.parse(jsonStr)
			return new VisualizationPrefs(data)
		} catch (error) {
			return new VisualizationPrefs()
		}
	}
}

//////////////
// CATEGORY //
//////////////

export class VizHealthCenterCategory {
	type: VizHealthCenterCategoryType = null
	label = ''
	iconClass = 'fal fa-octagon-exclamation'
	sections: Array<VizHealthCenterSection> = []
	score: number = 100

	expanded = true

	constructor(record?: VizHealthCenterCategory) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.sections = record.sections.map((rec) => new VizHealthCenterSection(rec))
		}
	}

	get contributor(): boolean {
		return this.sections.some((section) => section.contributor)
	}

	get alerts(): Array<VizHealthCenterSectionAlert> {
		return this.sections.flatMap((section) => section.alerts)
	}

	get matchesFilter(): boolean {
		return this.sections.some((section) => section.matchesFilter)
	}

	applySearchFilter(text: string) {
		this.sections.forEach((section) => {
			section.applySearchFilter(text)
		})
	}

	updateScore(records: Array<CompanyActivityLogRecord>) {
		for (const item of this.sections) {
			item.updateScore(records)
		}

		// Sections which do not contribute to the score are filtered out
		const sections = this.sections.filter((s) => s.contributor)

		if (sections.length === 0) {
			this.score = 100
		} else {
			const totalScore = sections.reduce((acc, item) => acc + item.score, 0)
			this.score = Math.ceil(totalScore / sections.length)
		}
	}
}

/////////////
// SECTION //
/////////////

export class VizHealthCenterSection {
	type: VizHealthCenterCategoryType
	label = ''
	iconClass = 'fal fa-octagon-exclamation'

	alerts: Array<VizHealthCenterSectionAlert> = []
	score = 100

	expanded = false

	constructor(record?: VizHealthCenterSection) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.alerts = record.alerts.map((rec) => new VizHealthCenterSectionAlert(rec))
		}
	}

	get contributor(): boolean {
		return this.alerts.some((alert) => alert.contributor)
	}

	get matchesFilter(): boolean {
		return this.alerts.some((alert) => alert.matchesFilter)
	}

	applySearchFilter(text: string) {
		this.alerts.forEach((alert) => {
			alert.applySearchFilter(text)
		})
	}

	updateScore(records: Array<CompanyActivityLogRecord>) {
		for (const alert of this.alerts) {
			alert.updateScore(records)
		}

		// Alerts which do not contribute to the score are filtered out
		const alerts = this.alerts.filter((a) => a.contributor)

		if (alerts.length === 0) {
			this.score = 100
		} else {
			const totalScore = alerts.reduce((acc, alert) => acc + alert.score, 0)
			this.score = Math.ceil(totalScore / alerts.length)
		}
	}
}

///////////////////
// SECTION ALERT //
///////////////////

export class VizHealthCenterSectionAlert {
	key: HealthCenterAlertKey
	label: string
	description: string
	enabled = true
	contributor = true
	links: Array<IVizHealthAlertLink> = null
	tips: Array<IVizHealthAlertTip> = null
	matchesFilter = true

	score = 100
	formula: (records: Array<CompanyActivityLogRecord>) => number

	constructor(record?: VizHealthCenterSectionAlert) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	applySearchFilter(text: string) {
		if (!text) {
			this.matchesFilter = true
			return
		}
		if (this.label.toLowerCase().includes(text.toLowerCase()) || this.description.toLowerCase().includes(text.toLowerCase())) {
			this.matchesFilter = true
		} else {
			this.matchesFilter = false
		}
	}

	updateScore(records: Array<CompanyActivityLogRecord>) {
		this.score = this.enabled ? Math.ceil(this.formula(records)) : 100
	}
}

//////////////////////////
// HEALTH SCORE MANAGER //
//////////////////////////

export class VizHealthCenterManager {
	healthCenterActivityLogList: Array<CompanyActivityLogRecord> = []
	healthCenterCategories: Array<VizHealthCenterCategory> = []

	get healthScore(): number {
		if (this.healthCenterCategories.length === 0) return 100
		const totalScore = this.healthCenterCategories.reduce((acc, item) => acc + item.score, 0)
		const score = Math.ceil(totalScore / this.healthCenterCategories.length)
		return score
	}

	public setupManager() {
		const healthRecords = Global.coreSrvc.dbSrvc.vizSrvc.getHealthCenterActivityLogRecords()
		this.healthCenterCategories = VizHealthHelper.buildSections(healthRecords)
	}

	public getAllAlerts(): Array<VizHealthCenterSectionAlert> {
		return this.healthCenterCategories.flatMap((category) => category.sections.flatMap((section) => section.alerts))
	}

	public getAlertForKey(key: HealthCenterAlertKey): VizHealthCenterSectionAlert {
		return this.getAllAlerts().find((alert) => alert.key === key)
	}

	public configureAlertContributorStatusFromPrefs(prefs: VisualizationPrefs) {
		const enabledKeys = prefs.enabled
		const contributorKeys = prefs.contributors

		const alerts = this.getAllAlerts()
		for (const alert of alerts) {
			alert.enabled = enabledKeys.hasOwnProperty(alert.key) ? enabledKeys[alert.key] : alert.enabled
			alert.contributor = alert.enabled ? (contributorKeys.hasOwnProperty(alert.key) ? contributorKeys[alert.key] : alert.contributor) : false
		}

		// Update the score
		const healthRecords = Global.coreSrvc.dbSrvc.vizSrvc.getHealthCenterActivityLogRecords()
		this.updateScore(healthRecords)
	}

	public applySearchFilter(text: string) {
		this.healthCenterCategories.forEach((category) => {
			category.applySearchFilter(text)
		})
	}

	public updateScore(records: Array<CompanyActivityLogRecord>) {
		this.healthCenterCategories.forEach((category) => category.updateScore(records))
	}

	public expandAllItems() {
		this.healthCenterCategories.forEach((section) => {
			section.expanded = true
			section.sections.forEach((item) => {
				item.expanded = true
			})
		})
	}

	public collapseAllItems() {
		this.healthCenterCategories.forEach((section) => {
			section.expanded = false
			section.sections.forEach((item) => {
				item.expanded = false
			})
		})
	}

	public resetListView() {
		this.healthCenterCategories.forEach((section) => {
			section.expanded = true
			section.sections.forEach((item) => {
				item.expanded = false
			})
		})
	}
}

//////////////////
// HELPER CLASS //
//////////////////

export interface IVizHealthAlertLink {
	label: string
	path: string
	preRoute?: () => void
}

export interface IVizHealthAlertTip {
	header: string
	message: string
	preRoute?: () => void
}

interface IVizHealthCenterCategory {
	// Sections which do not contribute to the score are not shown
	// Alerts which do not contribute to the score are still shown

	type: VizHealthCenterCategoryType
	label: string
	iconClass: string
	sections: Array<{
		type: VizHealthCenterSectionType
		label: string
		iconClass: string
		alerts: Array<{
			key: HealthCenterAlertKey
			label: string
			enabled: boolean // Determines whether the alert should be shown in the list
			contributor: boolean // Determines whether the alert should contribute to the score
			description: string
			links: Array<IVizHealthAlertLink>
			tips?: Array<IVizHealthAlertTip>
			formula: (record: Array<CompanyActivityLogRecord>) => number
		}>
	}>
}

export class VizHealthHelper {
	// The tips prop can hold tips that will be shared among multiple alerts
	static tips = {
		adjustTimeEntryHighlights: {
			header: 'Highlight Issues',
			message: `Highlight options are available to help you flag issues when viewing time entries.<br><br>Options include highlighting early or late check-ins, early or late check-outs, short or long breaks, and short or long shifts.<br><br>To adjust these preferences, click <a href="/#/admin/settings">here</a> to access the Settings Control Panel. From there, select <b>Portal Preferences</b>, and scroll down to the <b>Time Entries</b> section to configure your preferences.`,
		},
	}

	static setupData: Array<IVizHealthCenterCategory> = [
		// People Category
		{
			type: 'PEOPLE',
			label: 'People',
			iconClass: 'fal fa-user',
			sections: [
				{
					type: 'EMPLOYEES',
					label: 'Employees',
					iconClass: 'fal fa-user',
					alerts: [
						{
							key: 'employeeHasAddress',
							label: 'Physical Addresses',
							description: `Each employee should have a physical address setup. Various GPS features use this to determine where the employee is located. One example is if you wish to send announcements related to an employee's distance from a job site.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Employees', path: '/admin/employees' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'employee_has_address_count', 'employee_count')
							},
						},
						{
							key: 'employeeHasEmail',
							label: 'Email Addresses',
							description: `Each employee should have an email address setup. Certain notifications from the system are only sent to employees with an email address.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Employees', path: '/admin/employees' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'employee_has_email_count', 'employee_count')
							},
						},
					],
				},
				{
					type: 'SUPERVISORS',
					label: 'Supervisors',
					iconClass: 'fal fa-user',
					alerts: [
						{
							key: 'userHasEmail',
							label: 'Email Addresses',
							description: `Each supervisor should have an email address setup. Certain notifications from the system are only sent to supervisors with an email address.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Supervisors', path: '/admin/users' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'user_has_email_count', 'user_count')
							},
						},
					],
				},
				{
					type: 'CONTACTS',
					label: 'Contacts',
					iconClass: 'fal fa-address-card',
					alerts: [
						{
							key: 'contactHasContactMethod',
							label: 'Contact Methods (Individuals)',
							description: `Setting up either an email addresses or a phone number for contacts allows you to initiate contact from the admin portal.`,
							enabled: true,
							contributor: true,
							links: [
								{
									label: 'Contacts',
									path: '/admin/contacts',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView = 'INDIVIDUALS'
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const result = VizHealthHelper.ratioFromLastLogRecord(records, 'contact_has_contact_method_count', 'contact_count')
								return result ? result : 0
							},
						},
						{
							key: 'contactHasPhone',
							label: 'Phone Numbers (Individuals)',
							description: `Setting up phone numbers for contacts allows you to use the system click-to-call feature to initiate a call from the admin portal.`,
							enabled: true,
							contributor: false,
							links: [
								{
									label: 'Contacts',
									path: '/admin/contacts',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView = 'INDIVIDUALS'
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'contact_has_phone_count', 'contact_count')
							},
						},
						{
							key: 'contactHasEmail',
							label: 'Email Addresses (Individuals)',
							description: `Setting up email addresses for contacts allows you to initiate email from the admin portal.`,
							enabled: true,
							contributor: false,
							links: [
								{
									label: 'Contacts',
									path: '/admin/contacts',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView = 'INDIVIDUALS'
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'contact_has_email_count', 'contact_count')
							},
						},
						{
							key: 'orgHasContactMethod',
							label: 'Contact Methods (Organizations)',
							description: `Setting up either an email addresses or a phone number for contacts allows you to initiate contact from the admin portal.`,
							enabled: true,
							contributor: true,
							links: [
								{
									label: 'Contacts',
									path: '/admin/contacts',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView = 'ORGANIZATIONS'
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const result = VizHealthHelper.ratioFromLastLogRecord(records, 'organization_has_contact_method_count', 'organization_count')
								return result ? result : 0
							},
						},
						{
							key: 'orgHasPhone',
							label: 'Phone Numbers (Organizations)',
							description: `Setting up phone numbers for contacts allows you to use the system click-to-call feature to initiate a call from the admin portal.`,
							enabled: true,
							contributor: false,
							links: [
								{
									label: 'Contacts',
									path: '/admin/contacts',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView = 'ORGANIZATIONS'
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'organization_has_phone_count', 'organization_count')
							},
						},
						{
							key: 'orgHasEmail',
							label: 'Email Addresses (Organizations)',
							description: `Setting up email addresses for contacts allows you to initiate email from the admin portal.`,
							enabled: true,
							contributor: false,
							links: [
								{
									label: 'Contacts',
									path: '/admin/contacts',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView = 'ORGANIZATIONS'
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'organization_has_email_count', 'organization_count')
							},
						},
					],
				},
			],
		},

		// Work Category
		{
			type: 'WORK',
			label: 'Work',
			iconClass: 'fal fa-tasks-alt',
			sections: [
				{
					type: 'JOBS',
					label: 'Jobs',
					iconClass: 'fal fa-tasks-alt',
					alerts: [
						{
							key: 'jobHasAddress',
							label: 'Physical Addresses',
							description: `Each job site should have a physical address setup. Various GPS features use this to determine where the job is located.`,
							enabled: true,
							contributor: true,
							links: [], // Setup in builder because of merged job site issue
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'location_has_address_count', 'location_count')
							},
						},
					],
				},
				{
					type: 'TOURS',
					label: 'Tours',
					iconClass: 'fal fa-map-pin',
					alerts: [
						{
							key: 'tourHasLinkedJob',
							label: 'Linked Jobs',
							description: `Tours should be linked to at least one job unless they are being used as a template for creating other tours.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Tours', path: '/admin/tours' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'tour_has_linked_job_count', 'tour_count')
							},
						},
					],
				},
				{
					type: 'NOTIFICATIONS',
					label: 'Notifications',
					iconClass: 'fal fa-bell',
					alerts: [
						{
							key: 'notificationHasLinkedJob',
							label: 'Linked Notifications',
							description: `Notification profiles should be linked to at least one job unless they are being used as a template for creating other notification profiles.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Notifications', path: '/admin/notifications' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'np_has_linked_job_count', 'np_count')
							},
						},
					],
				},
			],
		},

		// Scheduling
		{
			type: 'SCHEDULING',
			label: 'Scheduling',
			iconClass: 'fal fa-calendar',
			sections: [
				{
					type: 'SCHEDULES',
					label: 'Schedules',
					iconClass: 'fal fa-calendar',
					alerts: [
						{
							key: 'scheduleJobsHaveLinkedSchedules',
							label: 'Scheduled Jobs',
							description: `Schedules offer advanced features such as pre-shift and tardy notifications, designed to help ensure that your shifts are adequately covered. By setting up schedules, you can take advantage of these notifications to improve shift management. This metric monitors the percentage of jobs that have an associated schedule, helping you assess how effectively schedules are being utilized in your operations.`,
							enabled: true,
							contributor: true,
							links: [
								{ label: 'Jobs', path: '/admin/jobs' },
								{
									label: 'Schedules',
									path: '/admin/scheduler',
									preRoute: () => {
										Global.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.setCurrentView('SERIES')
									},
								},
							],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								return VizHealthHelper.ratioFromLastLogRecord(records, 'job_has_linked_schedule_count', 'job_count')
							},
						},
					],
				},
			],
		},

		// Operations
		{
			type: 'OPERATIONS',
			label: 'Operations',
			iconClass: 'fal fa-shield-check',
			sections: [
				{
					type: 'TIME_ENTRIES',
					label: 'Time Entries',
					iconClass: 'fal fa-clock',
					alerts: [
						{
							key: 'transNoShowRatio',
							label: 'No Shows',
							description: `This metric looks at the number of no shows over the last 7 days. The more no shows you have, the lower your score. No shows can be mitigated by using notifications to alert your employees of their upcoming shifts.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Notifications', path: '/admin/notifications' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const transCount = VizHealthHelper.sumFromLogRecords(records, 'transaction_count_daily')
								const transNoShowCount = VizHealthHelper.sumFromLogRecords(records, 'trans_noshow_count_daily')
								return transCount > 0 ? 100 - Math.ceil((transNoShowCount / transCount) * 100) : 100
							},
						},
						{
							key: 'transMissingClockOutRatio',
							label: 'Missing Clock-out',
							description: `This metric looks at the number of missing clock-outs over the last 7 days. The more you have, the lower your score. Missing clock-outs can be mitigated by working with problem employees to make sure they understand how to use the system.`,
							enabled: true,
							contributor: true,
							links: null,
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const transCount = VizHealthHelper.sumFromLogRecords(records, 'transaction_count_daily')
								const transMissingCount = VizHealthHelper.sumFromLogRecords(records, 'trans_missing_checkout_count_daily')
								return transCount > 0 ? 100 - Math.ceil((transMissingCount / transCount) * 100) : 100
							},
						},
						{
							key: 'transClockInNotification',
							label: 'Clock-in Notifications',
							description: `This metric looks at the number of clock-in notifications sent to employees and admins over the last 7 days. The more notifications sent, the lower your score.`,
							enabled: true,
							contributor: false,
							links: null,
							tips: [VizHealthHelper.tips.adjustTimeEntryHighlights],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const transCount = VizHealthHelper.sumFromLogRecords(records, 'transaction_count_daily')
								const empNotified = VizHealthHelper.sumFromLogRecords(records, 'trans_notified_emp_late_checkin_count_daily')
								const supNotified = VizHealthHelper.sumFromLogRecords(records, 'trans_notified_sup_late_checkin_count_daily')
								const empWarnedRatio = empNotified > 0 ? Math.ceil((empNotified / transCount) * 100) : 0
								const supWarnedRatio = supNotified > 0 ? Math.ceil((supNotified / transCount) * 100) : 0
								const combinedRatio = Math.ceil((empWarnedRatio + supWarnedRatio) / 2)
								return transCount > 0 ? 100 - combinedRatio : 100
							},
						},
						{
							key: 'transClockOutNotification',
							label: 'Clock-out Notifications',
							description: `This metric looks at the number of clock-out notifications sent to employees or admins over the last 7 days. The more notifications sent, the lower your score.`,
							enabled: true,
							contributor: false,
							links: null,
							tips: [VizHealthHelper.tips.adjustTimeEntryHighlights],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const transCount = VizHealthHelper.sumFromLogRecords(records, 'transaction_count_daily')
								const empNotified = VizHealthHelper.sumFromLogRecords(records, 'trans_notified_emp_late_checkout_count_daily')
								const supNotified = VizHealthHelper.sumFromLogRecords(records, 'trans_notified_sup_late_checkout_count_daily')
								const empWarnedRatio = empNotified > 0 ? Math.ceil((empNotified / transCount) * 100) : 0
								const supWarnedRatio = supNotified > 0 ? Math.ceil((supNotified / transCount) * 100) : 0
								const combinedRatio = Math.ceil((empWarnedRatio + supWarnedRatio) / 2)
								return transCount > 0 ? 100 - combinedRatio : 100
							},
						},
						{
							key: 'transCheckpointNotification',
							label: 'Checkpoint Notifications',
							description: `This metric looks at the number of checkpoint notifications sent to employees or admins over the last 7 days. The more notifications sent, the lower your score.`,
							enabled: true,
							contributor: false,
							links: null,
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const transCount = VizHealthHelper.sumFromLogRecords(records, 'transaction_count_daily')
								const empNotified = VizHealthHelper.sumFromLogRecords(records, 'trans_notified_emp_late_checkpoint_daily')
								const supNotified = VizHealthHelper.sumFromLogRecords(records, 'trans_notified_sup_late_checkpoint_daily')
								const empWarnedRatio = empNotified > 0 ? Math.ceil((empNotified / transCount) * 100) : 0
								const supWarnedRatio = supNotified > 0 ? Math.ceil((supNotified / transCount) * 100) : 0
								const combinedRatio = Math.ceil((empWarnedRatio + supWarnedRatio) / 2)
								return transCount > 0 ? 100 - combinedRatio : 100
							},
						},
					],
				},
				// {
				// 	type: 'SHIFT_REPORTS',
				// 	label: 'Shift Reports',
				// 	iconClass: 'fal fa-pie-chart',
				// 	alerts: [],
				// },
				// {
				// 	type: 'INSIGHT_REPORTS',
				// 	label: 'Insight Reports',
				// 	iconClass: 'fal fa-pie-chart',
				// 	alerts: [],
				// },
				// {
				// 	type: 'ANNOUNCEMENTS',
				// 	label: 'Announcements',
				// 	iconClass: 'fal fa-bullhorn',
				// 	alerts: [],
				// },
			],
		},

		// Admin
		{
			type: 'ADMIN',
			label: 'Admin',
			iconClass: 'fal fa-cog',
			sections: [
				{
					type: 'SETTINGS',
					label: 'Settings',
					iconClass: 'fal fa-cog',
					alerts: [
						{
							key: 'adminCompanyHasPhysicalAddress',
							label: 'Company Address',
							description: `Your company should have a physical address setup. This can be added using the <b>Company Profile</b> option in the <b>Settings</b> control panel.`,
							enabled: true,
							contributor: true,
							links: [{ label: 'Settings', path: '/admin/settings' }],
							formula: (records: Array<CompanyActivityLogRecord>) => {
								const hasAddress = Global.coreSrvc.dbSrvc.settingSrvc.getCompany().address_json
								return hasAddress ? 100 : 0
							},
						},
					],
				},
				// {
				// 	type: 'BILLING',
				// 	label: 'Billing',
				// 	iconClass: 'fal fa-credit-card',
				// 	alerts: [],
				// },
				// {
				// 	type: 'STATIONS',
				// 	label: 'Stations',
				// 	iconClass: 'fal fa-computer-classic',
				// 	alerts: [],
				// },
				// {
				// 	type: 'PAY_RATES',
				// 	label: 'Pay Rates',
				// 	iconClass: 'fal fa-money-check-edit-alt',
				// 	alerts: [],
				// },
			],
		},
	]

	// Turns the setup data into an array of section objects which can be rendered
	static buildSections(records: Array<CompanyActivityLogRecord>): Array<VizHealthCenterCategory> {
		// Handle the job address link issue related to merged job sites
		const jobLink = Global.areJobSitesMerged ? { label: 'Jobs', path: '/admin/jobs' } : { label: 'Job Sites', path: '/admin/jobsites' }
		const jobHasAddressAlert = VizHealthHelper.getAlertSetupData('jobHasAddress')

		if (jobHasAddressAlert) jobHasAddressAlert.links = [jobLink]

		const categories = this.setupData.map((category) => new VizHealthCenterCategory(category as any))
		for (const section of categories) section.updateScore(records)
		return categories
	}

	static transformDatapoint(records: CompanyActivityLogRecord[], property: keyof CompanyActivityLogRecord, action: 'SUM' | 'AVG'): number {
		const total = records.reduce((acc, record) => acc + (record[property] as number), 0)

		if (action === 'SUM') {
			return total
		} else if (action === 'AVG') {
			return total / records.length
		} else {
			throw new Error('Invalid action')
		}
	}

	static sumFromLogRecords(records: CompanyActivityLogRecord[], property: keyof CompanyActivityLogRecord): number | null {
		// Given a property and an array of records, return the sum of the property
		const total = records.reduce((acc, record) => acc + (record[property] as number), 0)
		if (total === 0) return null
		return total
	}

	static averageFromLogRecords(records: CompanyActivityLogRecord[], property: keyof CompanyActivityLogRecord): number | null {
		// Given a property and an array of records, return the average of the property
		const total = records.reduce((acc, record) => acc + (record[property] as number), 0)
		const count = records.length
		if (count === 0) return null
		return Math.ceil(total / count)
	}

	static valueFromLastLogRecord(records: CompanyActivityLogRecord[], property: keyof CompanyActivityLogRecord): number | null {
		const lastRecord = records[records.length - 1]
		if (!lastRecord || lastRecord[property] === null || lastRecord[property] === undefined) return null
		return lastRecord[property] as number
	}

	static ratioFromLastLogRecord(
		records: CompanyActivityLogRecord[],
		propA: keyof CompanyActivityLogRecord,
		propB: keyof CompanyActivityLogRecord,
	): number {
		const lastRecord = records[records.length - 1]
		if (!lastRecord || lastRecord[propA] === null || lastRecord[propA] === undefined || lastRecord[propB] === 0) return 100
		const result = Math.ceil((((lastRecord[propA] as number) ?? 0) / (lastRecord[propB] as number)) * 100)
		return result ? result : 0
	}

	static getAlertSetupData(alertKey: HealthCenterAlertKey): any {
		return VizHealthHelper.setupData
			.map((category) => category.sections)
			.flat()
			.map((section) => section.alerts)
			.flat()
			.find((alert) => alert.key === alertKey)
	}
}
