import { Injectable } from '@angular/core'

// Publicly exposed service references
import {
	ADPService,
	AnnouncementService,
	BillingService,
	CommLogService,
	ContactService,
	EmployeeChecklistService,
	EmployeeLoginService,
	EmployeesService,
	ExceptionsService,
	FileImportService,
	HelpVideoService,
	JobSitesService,
	JobsService,
	LambdaService,
	NotificationProfileService,
	OnboardingService,
	OrganizationService,
	PayRatesService,
	QuickbooksService,
	ReportsService,
	SchedulerService,
	SecureFileService,
	SettingsService,
	StationService,
	TransactionService,
	VacationService,
	WhenIWworkService,
	WhenToWworkService,
} from '@app/services'

// Private references
import { NotificationsService } from '@app/services'

import {
	DatabaseTableName,
	DataAccessRequest,
	BulkReadDataAccessRequest,
	BulkReadWFromTableWithIds,
	BulkReadWithIdsDataAccessRequest,
	ComponentBridgeName,
} from '@app/models'

import { log, RecordBuilder } from '@app/helpers'
import { environment } from '@env/environment'

import { v4 as uuid } from 'uuid'
import { AuditLogService } from './audit-log.service'
import { ActiveCallService } from './active-call.service'
import { IncidentLogService } from './incident-log.service'
import { WaypointLogService } from './waypoint-log.service'
import { TourService } from './tour.service'
import { CallCenterService } from './call-center.service'
import { ProjectService } from './project.service'
import { VisualizationService } from './visualization.service'
import { BreakProfileService } from './break-profile.service'
import { BaseService } from './base.service'
import { CompanyService } from './company.service'

// Notes on the Database Service
// When reading incident_log, an optional parameter of transaction_log_id, job_id, or job_site_id is used to fetch
// a list of records matching the query.

@Injectable({
	providedIn: 'root',
})
export class DatabaseService {
	mergeVacations = true
	uuid = uuid()

	constructor(
		// *** IMPORTANT - Add to Global class and service barrels *** //
		// Move loading of services to establish load order to App Component / Core Service

		public lambdaSrvc: LambdaService,

		public activeCallSrvc: ActiveCallService,
		public adpSrvc: ADPService,
		public annSrvc: AnnouncementService,
		public auditLogSrvc: AuditLogService,
		public baseSrvc: BaseService,
		public billSrvc: BillingService,
		public breakSrvc: BreakProfileService,
		public callCenterSrvc: CallCenterService,
		public commLogSrvc: CommLogService,
		public companySrvc: CompanyService,
		public contactSrvc: ContactService,
		public empSrvc: EmployeesService,
		public empChklstSrvc: EmployeeChecklistService,
		public empLoginSrvc: EmployeeLoginService,
		public excptSrvc: ExceptionsService,
		public fiSrvc: FileImportService,
		public helpVidSrvc: HelpVideoService,
		public incidentLogSrvc: IncidentLogService,
		public jobSrvc: JobsService,
		public npSrvc: NotificationProfileService,
		public onboardSrvc: OnboardingService,
		public orgSrvc: OrganizationService,
		public payRatesSrvc: PayRatesService,
		public projSrvc: ProjectService,
		public qbSrvc: QuickbooksService,
		public reportSrvc: ReportsService,
		public secFileSrvc: SecureFileService,
		public schedulerSrvc: SchedulerService,
		public settingSrvc: SettingsService,
		public siteSrvc: JobSitesService,
		public stationSrvc: StationService,
		public tourSrvc: TourService,
		public tranSrvc: TransactionService,
		public vacSrvc: VacationService,
		public vizSrvc: VisualizationService,
		public wpLogSrvc: WaypointLogService,
		public w2wSrvc: WhenToWworkService,
		public wiwSrvc: WhenIWworkService,

		// Private use of other services
		private notifySrvc: NotificationsService,
	) {
		log('Database Services Created')
	}

	/**
	 * Clear service data for all sections - Used during redirects
	 */

	clearServiceData() {
		log('Clearing service data')
		this.activeCallSrvc.clearData()
		this.adpSrvc.clearData()
		this.annSrvc.clearData()
		this.auditLogSrvc.clearData()
		this.billSrvc.paypalClearData()
		this.breakSrvc.clearData()
		this.callCenterSrvc.clearData()
		this.commLogSrvc.clearData()
		this.contactSrvc.clearData()
		this.empSrvc.clearData()
		this.empChklstSrvc.clearData()
		this.empLoginSrvc.clearData()
		this.excptSrvc.clearData()
		this.incidentLogSrvc.clearData()
		this.jobSrvc.clearData()
		this.npSrvc.clearData()
		this.onboardSrvc.clearData()
		this.orgSrvc.clearData()
		this.payRatesSrvc.clearData()
		this.qbSrvc.clearData()
		this.reportSrvc.clearData()
		this.secFileSrvc.clearData()
		this.schedulerSrvc.clearData()
		this.settingSrvc.clearSettingsData()
		this.siteSrvc.clearData()
		this.stationSrvc.clearData()
		this.tourSrvc.clearData()
		this.tranSrvc.clearData()
		this.vacSrvc.clearData()
		this.vizSrvc.clearData()
		this.w2wSrvc.clearData()
		this.wiwSrvc.clearData()
	}

	/**
	 * Read multiple tables from database
	 * @param tableList: Array of database table names
	 * @param duration: Option duration which if specified gets new and updated records (PT60M)
	 */

	bulkRead(tableList: Array<DatabaseTableName>, duration?: string): Promise<boolean> {
		const request = new BulkReadDataAccessRequest(tableList)
		return this.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				if (duration) {
					log('Bulk Read With Duration', result)
				} else {
					log('Bulk Read Response', result)
				}
				const data = result.data
				const tableData = data.tabledata
				for (const tableName in tableData) {
					if (tableList.find((elm) => elm === tableName)) {
						const recordData = tableData[tableName]
						const records = RecordBuilder.buildRecords(recordData)
						if (duration) {
							this.addOrUpdateServiceRecords(tableName as DatabaseTableName, records)
						} else {
							this.loadServiceRecords(tableName as DatabaseTableName, records)
						}
					}
				}
				return Promise.resolve(true)
			})
			.catch((error) => {
				log('ERROR - bulkRead:', error)
				return Promise.resolve(false)
			})
	}

	bulkReadWithIds(list: Array<BulkReadWFromTableWithIds>): Promise<boolean> {
		const request = new BulkReadWithIdsDataAccessRequest(list)
		log('Bulk Read With IDs Request', request)
		return this.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				log('Bulk Read With Ids Result', result)
				const data = result.data
				const tableData = data.tabledata
				const tableList = list.map((elm) => elm.table)
				for (const tableName in tableData) {
					if (tableList.find((elm) => elm === tableName)) {
						const recordData = tableData[tableName]
						const records = RecordBuilder.buildRecords(recordData)
						this.addOrUpdateServiceRecords(tableName as DatabaseTableName, records)
					}
				}
				return Promise.resolve(true)
			})
			.catch((error) => {
				log('ERROR - bulkRead:', error)
				return Promise.resolve(false)
			})
	}

	readRecord(tableName: DatabaseTableName, id: number): Promise<'UPDATED' | 'DELETED'> {
		const request = new DataAccessRequest(tableName, null, { id: id }, null)
		return this.lambdaSrvc.dataAccess(request).then((result) => {
			const records = result.data as Array<any>
			if (records.length > 0) {
				this.addOrUpdateServiceRecords(tableName, records)
				return Promise.resolve('UPDATED')
			} else {
				this.removeLocalRecord(tableName, id)
				this.notifySrvc.notify('error', 'Record Unavailable', 'This record is no longer available.', 3)
				return Promise.resolve('DELETED')
			}
		})
	}

	/**
	 * Read individual tables from database
	 * @param tableName: Database table name
	 * @param options: Used to configure read request
	 */

	readTable(tableName: DatabaseTableName, options?: Object): Promise<boolean> {
		const request = new DataAccessRequest(tableName, null, options, null)
		return this.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				log('Read Table', tableName, result)
				const records = result.data
				this.loadServiceRecords(tableName, records)
				return Promise.resolve(true)
			})
			.catch((error) => {
				log('ERROR - readTable:', error)
				return Promise.resolve(false)
			})
	}

	/**
	 * Create new record in the database
	 * @param tableName: Database table name
	 * @param record: Record to add to the database
	 */

	insertRecord(tableName: DatabaseTableName, record: any): Promise<boolean> {
		const request = new DataAccessRequest(tableName, 'insert', null, record)
		return this.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				const records = result.data
				this.addOrUpdateServiceRecords(tableName, records)
				// this.postProcessInsertOrUpdate(tableName, records)
				log('Insert Record Result', records)
				return Promise.resolve(true)
			})
			.catch((error) => {
				log('ERROR - insertRecord:', error)
				return Promise.resolve(false)
			})
	}

	/**
	 * Update a record in the database
	 * @param tableName: Database table name
	 * @param record: Record to update in the database
	 */

	updateRecord(tableName: DatabaseTableName, record: any): Promise<boolean> {
		log('RECORD TO SEND', record)
		const request = new DataAccessRequest(tableName, 'update', null, record)
		// log('LAMBDA REQUEST SENT', request)
		return this.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				const records = result.data
				log('UPDATING RECORDS', records)
				this.addOrUpdateServiceRecords(tableName, records)
				// this.postProcessInsertOrUpdate(tableName, records)
				return Promise.resolve(true)
			})
			.catch((error) => {
				log('ERROR - updateRecord:', error)
				return Promise.resolve(false)
			})
	}

	/**
	 * Delete a record in the database
	 * @param tableName: Database table name
	 * @param recordId: ID of record to delete
	 */

	deleteRecord(tableName: DatabaseTableName, recordId: number, options?: Object): Promise<boolean> {
		const request = options
			? new DataAccessRequest(tableName, 'delete', { id: recordId, ...options }, null)
			: new DataAccessRequest(tableName, 'delete', { id: recordId }, null)
		log('Delete Operation Request', request)
		return this.lambdaSrvc.dataAccess(request).then((result) => {
			return Promise.resolve(true)
		})
		// .catch((error) => {
		// 	log('ERROR - deleteRecord:', error)
		// 	return Promise.resolve(false)
		// })
	}

	/**
	 * Remove a record from the local database
	 * @param tableName: Database table name
	 * @param recordId: ID of record to delete
	 */

	removeLocalRecord(tableName: DatabaseTableName, recordId: number) {
		switch (tableName) {
			case 'adp_ivr_company':
				this.adpSrvc.removeLocalAdpIvrCompanyeRecord(recordId)
				break
			case 'adp_ivr_phone_number':
				this.adpSrvc.removeLocalAdpIvrPhoneNumberRecord(recordId)
				break
			case 'adp_ivr_profile':
				this.adpSrvc.removeLocalAdpIvrProfileRecord(recordId)
				break
			case 'adp_ivr_prompt_code':
				this.adpSrvc.removeLocalAdpIvrPromptCodeRecord(recordId)
				break
			case 'announcements_scheduled':
				this.annSrvc.removeLocalScheduledAnnouncementRecord(recordId)
				break
			case 'employee':
				this.empSrvc.removeLocalEmployeeRecord(recordId)
				break
			case 'employee_job_pay_rate':
				this.payRatesSrvc.removeLocalEmpJobPayRateRecord(recordId)
				break
			case 'company_activity_log':
				this.vizSrvc.removeLocalCompanyActivityLogRecord(recordId)
				break
			case 'contact':
				this.contactSrvc.removeLocalContactRecord(recordId)
				break
			case 'dashboard':
				this.vizSrvc.removeLocalDashboardRecord(recordId)
				break
			case 'employee_checklist':
				this.empChklstSrvc.removeLocalEmpChecklistRecord(recordId)
				break
			case 'incident_log':
				this.incidentLogSrvc.removeLocalIncidentLogRecord(recordId)
				break
			case 'job':
				this.jobSrvc.removeLocalJobRecord(recordId)
				break
			case 'notification_profile':
				this.npSrvc.removeLocalprofileRecord(recordId)
				break
			case 'onboard_definition':
				this.onboardSrvc.removeOnboardDefinitionRecord(recordId)
				break
			case 'onboard_log':
				this.onboardSrvc.removeOnboardLogRecord(recordId)
				break
			case 'reports_delete':
				this.reportSrvc.removeLocalReportsRecord(recordId)
				break
			case 'reports_log_delete':
				this.reportSrvc.removeLocalHistoryRecord(recordId)
				break
			case 'skill':
				this.callCenterSrvc.removeLocalSkillRecord(recordId)
				break
			case 'station':
				this.stationSrvc.removeLocalStationRecord(recordId)
				break
			case 'supervisor_group':
				this.settingSrvc.removeLocalUserGroupRecord(recordId)
				break
			case 'supervisor_role':
				this.settingSrvc.removeLocalUserRoleRecord(recordId)
				break
			case 'tour':
				this.tourSrvc.removeLocalTourRecord(recordId)
				break
			case 'transaction_log':
				this.tranSrvc.removeLocalRecord(recordId)
				break
			case 'users':
				this.settingSrvc.removeLocalUserRecord(recordId)
				break
			case 'vacation':
				this.vacSrvc.removeLocalVacationRecord(recordId)
				break
			case 'vendor_client_org':
				this.orgSrvc.removeLocalOrganizationRecord(recordId)
				break
			case 'vendor_client_job_pay_rate':
				this.payRatesSrvc.removeLocalOrgJobPayRateRecord(recordId)
				break
			default:
				alert(`Removal from local service for ${tableName} not yet implemented`)
		}
	}

	// postProcessInsertOrUpdate(tableName: TableName, records: Array<Object>) {
	// 	log('Gonna load the parent site record here', records)
	// 	return
	// 	const isJobSiteMerged = this.settingSrvc.getCompany()?.merge_jobsite
	// 	const count = records.length
	// 	const record = count === 1 ? records[0] : null
	// 	switch (tableName) {
	// 		case 'job':
	// 			if (isJobSiteMerged && record) {
	// 				const siteId = (record as JobRecord)?.location_id
	// 				if (siteId) {
	// 					this.readRecord('location', siteId)
	// 				}
	// 			}
	// 			break
	// 	}
	// }

	/**
	 * Update local copy of database records for a given table
	 * @param tableName: Database table name
	 * @param records: Array of parsed records to store locally.
	 */

	private loadServiceRecords(tableName: DatabaseTableName, records: any[]) {
		log('Load records', tableName, records)
		switch (tableName) {
			case 'active_call_legs':
				this.activeCallSrvc.setActiveCallRecords(records)
				break
			case 'adp_ivr_api_log':
				this.adpSrvc.setAdpIvrApiLogRecords(records)
				break
			case 'adp_ivr_company':
				this.adpSrvc.setAdpIvrCompanyRecords(records)
				break
			case 'adp_department_code':
				this.adpSrvc.setAdpDepartmentCodeRecords(records)
				break
			case 'adp_earnings_code':
				this.adpSrvc.setAdpEarningsCodeRecords(records)
				break
			case 'adp_ivr_phone_number':
				this.adpSrvc.setAdpIvrPhoneNumberRecords(records)
				break
			case 'adp_ivr_profile':
				this.adpSrvc.setAdpIvrProfileRecords(records)
				break
			case 'adp_ivr_prompt_code':
				this.adpSrvc.setAdpIvrPromptCodeRecords(records)
				break
			case 'adp_payroll_group_code':
				this.adpSrvc.setAdpPayrollGroupCodeRecords(records)
				break
			case 'adp_rate_code':
				this.adpSrvc.setAdpRateCodeRecords(records)
				break
			case 'adp_sync':
				this.adpSrvc.setAdpSyncRecords(records)
				break
			case 'adp_log':
				this.adpSrvc.setAdpLogRecords(records)
				break
			case 'announcements':
				this.annSrvc.setCheckinAnnouncementRecords(records)
				break
			case 'announcements_scheduled':
				this.annSrvc.setScheduledAnnouncementRecords(records)
				break
			case 'click_to_call_log':
				this.commLogSrvc.setAgentRecords(records)
				break
			case 'company':
				this.settingSrvc.setCompanyRecords(records)
				break
			case 'company_activity_log':
				this.vizSrvc.setCompanyActivityLogRecords(records)
				break
			case 'company_globals':
				this.settingSrvc.setGlobalCompanyRecords(records)
				break
			case 'company_switch_list':
				this.settingSrvc.setAllowedCompaniesRecords(records)
				break
			case 'contact':
				this.contactSrvc.setContactRecords(records)
				break
			case 'dashboard':
				this.vizSrvc.setDashboardRecords(records)
				break
			case 'employee_job_pay_rate':
				this.payRatesSrvc.setEmpJobPayRateRecords(records)
				break
			case 'employee':
				this.empSrvc.setEmployeeRecords(records)
				break
			case 'employee_login':
				this.empLoginSrvc.setEmployeeLoginRecords(records)
				break
			case 'employee_checklist':
				this.empChklstSrvc.setEmployeeChecklistRecords(records)
				break
			case 'file_import_sync':
				this.fiSrvc.setFIWSyncRecords(records)
				break
			case 'file_import_log':
				this.fiSrvc.setFILogRecords(records)
				break
			case 'file_uploads':
				this.secFileSrvc.setSecureFileRecords(records)
				break
			case 'help_video':
				this.helpVidSrvc.setHelpVideoRecords(records)
				break
			case 'incident_log':
				this.incidentLogSrvc.setIncidentLogRecords(records)
				break
			case 'ivr_call_log':
				this.commLogSrvc.setIvrRecords(records)
				break
			case 'job':
				this.jobSrvc.setJobRecords(records)
				break
			case 'location':
				this.siteSrvc.setJobSiteRecords(records)
				break
			case 'location_exception':
				this.excptSrvc.setLocationExceptionRecords(records)
				break
			case 'notification_profile':
				this.npSrvc.setProfileRecords(records)
				break
			case 'onboard_definition':
				this.onboardSrvc.setOnboardDefinitionRecords(records)
				break
			case 'onboard_log':
				this.onboardSrvc.setOnboardLogRecords(records)
				break
			case 'phone_number':
				this.settingSrvc.setPhoneRecords(records)
				break
			case 'qbo_customer':
				this.qbSrvc.setQBOCustomerRecords(records)
				break
			case 'qbo_vendor':
				this.qbSrvc.setQBOVendorRecords(records)
				break
			case 'qbo_service_item':
				this.qbSrvc.setQBOServiceItemRecords(records)
				break
			case 'qbo_sync':
				this.qbSrvc.setQBOSyncRecords(records)
				break
			case 'reports':
				this.reportSrvc.setReportRecords(records)
				break
			case 'reports_log':
				this.reportSrvc.setReportHistoryRecords(records)
				break
			case 'reports_definition':
				this.reportSrvc.setReportTypeRecords(records)
				break
			case 'schedule_recur':
				this.schedulerSrvc.setSchedulerRecords(records)
				break
			case 'open_shifts':
				this.schedulerSrvc.setOpenShiftRecords(records)
				break
			case 'schedule_change_request':
				this.schedulerSrvc.setChangeRequestRecords(records)
				break
			case 'skill':
				this.callCenterSrvc.setSkillRecords(records)
				break
			case 'sms_log':
				this.commLogSrvc.setSmsRecords(records)
				break
			case 'station':
				this.stationSrvc.setStationRecords(records)
				break
			case 'supervisor_role':
				this.settingSrvc.setUserRoleRecords(records)
				break
			case 'supervisor_group':
				this.settingSrvc.setUserGroupRecords(records)
				break
			case 'timezones':
				this.settingSrvc.setTimezoneRecords(records)
				break
			case 'tour':
				this.tourSrvc.setTourRecords(records)
				break
			case 'transaction_log':
				this.tranSrvc.setTransactionRecords(records)
				break
			case 'transaction_exception':
				this.excptSrvc.setTransactionExceptionRecords(records)
				break // MARK FOR CLEANUP
			case 'users':
				this.settingSrvc.setUserRecords(records)
				break
			case 'vacation':
				this.vacSrvc.setVacationRecords(records)
				break
			case 'vendor_client_job_pay_rate':
				this.payRatesSrvc.setOrgJobPayRateRecords(records)
				break
			case 'vendor_client_org':
				this.orgSrvc.setOrganizationRecords(records)
				break
			case 'waypoint_log':
				this.wpLogSrvc.setWaypointLogRecords(records)
				break
			case 'wiw_sync':
				this.wiwSrvc.setWIWSyncRecords(records)
				break
			case 'wiw_log':
				this.wiwSrvc.setWIWLogRecords(records)
				break
			case 'google_cal_sync':
				this.w2wSrvc.setW2WSyncRecords(records)
				break
			case 'google_sync_log':
				this.w2wSrvc.setW2WSyncLogRecords(records)
				break
			default:
				break
		}
	}

	private addOrUpdateServiceRecords(tableName: DatabaseTableName, records: any[]) {
		log('Add or update records')
		switch (tableName) {
			case 'active_call_legs':
				this.activeCallSrvc.addOrUpdateActiveCallRecords(records)
				break
			case 'adp_sync':
				log('dbSrvc: Updated ADP Sync', records)
				break
			case 'adp_ivr_api_log':
				this.adpSrvc.addOrUpdateAdpIvrApiLogRecords(records)
				break
			case 'adp_ivr_company':
				this.adpSrvc.addOrUpdateAdpIvrCompanyRecords(records)
				break
			case 'adp_ivr_phone_number':
				this.adpSrvc.addOrUpdateAdpIvrPhoneNumberRecords(records)
				break
			case 'adp_ivr_profile':
				this.adpSrvc.addOrUpdateAdpIvrProfileRecords(records)
				break
			case 'adp_ivr_prompt_code':
				this.adpSrvc.addOrUpdateAdpIvrPromptCodeRecords(records)
				break
			case 'announcements':
				this.annSrvc.addOrUpdateCheckinAnnouncementRecords(records)
				break
			case 'announcements_scheduled':
				this.annSrvc.addOrUpdateScheduledAnnouncementRecords(records)
				break
			case 'checkpoint':
				log('Checkpoints updated', records)
				break
			case 'click_to_call_log':
				this.commLogSrvc.addOrUpdateAgentRecords(records)
				break
			case 'company':
				this.settingSrvc.addOrUpdateCompanyRecords(records)
				break
			case 'dashboard':
				this.vizSrvc.addOrUpdateDashboardRecords(records)
				break
			case 'company_activity_log':
				this.vizSrvc.addOrUpdateCompanyActivityLogRecords(records)
				break
			case 'contact':
				this.contactSrvc.addOrUpdateContactRecords(records)
				break
			case 'employee_job_pay_rate':
				this.payRatesSrvc.addOrUpdateEmpJobPayRateRecords(records)
				break
			case 'employee':
				this.empSrvc.addOrUpdateEmployeeRecords(records)
				break
			case 'employee_login':
				this.empLoginSrvc.addOrUpdateEmployeeLoginRecords(records)
				break
			case 'employee_checklist':
				this.empChklstSrvc.addOrUpdateEmployeeChecklistRecords(records)
				break
			case 'file_uploads':
				this.secFileSrvc.addOrUpdateSecureFileeRecords(records)
				break
			case 'incident_log':
				this.incidentLogSrvc.addOrUpdateIncidentLogRecords(records)
				break
			case 'ivr_call_log':
				this.commLogSrvc.addOrUpdateIvrRecords(records)
				break
			case 'help_video':
				this.helpVidSrvc.addOrUpdateHelpVideoRecords(records)
				break
			case 'job':
				this.jobSrvc.addOrUpdateJobRecords(records)
				break
			case 'location':
				this.siteSrvc.addOrUpdateJobSiteRecords(records)
				break
			case 'notification_profile':
				this.npSrvc.addOrUpdateProfileRecords(records)
				break
			case 'onboard_definition':
				this.onboardSrvc.addOrUpdateOnboardDefinitionRecords(records)
				break
			case 'onboard_log':
				this.onboardSrvc.addOrUpdateOnboardLogRecords(records)
				break
			case 'qbo_sync':
				this.qbSrvc.setQBOSyncRecords(records) // Do not need to add/update since only one record
				break
			case 'schedule_recur':
				this.schedulerSrvc.addOrUpdateSchedulerRecords(records)
				break
			case 'schedule_change_request':
				this.schedulerSrvc.addOrUpdateChangeRequestRecords(records)
				break
			case 'schedule_log':
				log('NOOP - Updated Schedule Log Records', records)
				break
			case 'schedule_os_offer':
				log('NOOP - Updated Schedule OS Offer Record', records)
				break
			case 'schedule_os_offer_entry':
				log('NOOP - Updated Schedule OS Offer Entry Record', records)
				break
			case 'skill':
				this.callCenterSrvc.addOrUpdateSkillRecords(records)
				break
			case 'sms_log':
				this.commLogSrvc.addOrUpdateSmsRecords(records)
				break
			case 'station':
				this.stationSrvc.addOrUpdateStationRecords(records)
				break
			case 'supervisor_role':
				this.settingSrvc.addOrUpdateUserRoleRecords(records)
				break
			case 'supervisor_group':
				this.settingSrvc.addOrUpdateUserGroupRecords(records)
				break
			case 'tour':
				this.tourSrvc.addOrUpdateTourRecords(records)
				break
			case 'transaction_log':
				this.tranSrvc.addOrUpdateTransactionRecords(records)
				break
			case 'users':
				this.settingSrvc.addOrUpdateUserRecords(records)
				break
			case 'users_skill':
				log('NOOP - Updated Users Skill Record', records)
				break
			case 'vacation':
				this.vacSrvc.addOrUpdateVacationRecords(records)
				break
			case 'vendor_client_job_pay_rate':
				this.payRatesSrvc.addOrUpdateOrgJobPayRateRecords(records)
				break
			case 'vendor_client_org':
				this.orgSrvc.addOrUpdateOrganizationRecords(records)
				break
			case 'vendor_client_contact':
				log('Not using a service for vendor_client_contact at this time')
				break
			case 'wiw_sync':
				log('dbSrvc: Updated WIW Sync', records)
				break
			default:
				if (environment.development) {
					this.notifySrvc.notify(
						'error',
						'Beta Tester Notice',
						`Please notify TTS support that the table "${tableName}" has not been optimized for adding or updating records in the admin portal.`,
					)
				}
				break
		}
	}

	public switchCompany(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const request = new DataAccessRequest('company_switch_list', 'switch_company', { id: id })
			this.lambdaSrvc.dataAccess(request).then((result) => {
				return resolve(result)
			})
		})
	}
}
