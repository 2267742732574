import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import {
	UserRecord,
	AccessPermissions,
	AccessPermission,
	DialogControlEvent,
	IAccessPermissionItem,
	OwnerPermission,
	SelectorPermission,
	OptionPermission,
	PermissionTableName,
	UserRoleRecord,
	HelpDialogMessage,
} from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'
import { Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api'

interface IAccessPermissionConfig {
	show: boolean
	access: AccessPermission
	owner: OwnerPermission
	showAccess: boolean
	showOwner: true
}

@Component({
	selector: 'app-user-permissions',
	templateUrl: './user-permissions.component.html',
	styleUrls: ['./user-permissions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class UserPermissionsComponent implements OnInit, OnDestroy {
	isUpdating = false
	IsEditingMyUser = false
	isAdminPrimaryOrInternal = false
	isAdminInternal = false
	isAdminManager = false

	useSimplePermissions = false
	accessPermissions = new AccessPermissions()

	rolesDropdown: SelectItem[]
	selectedRole

	allowedToModify: Array<PermissionTableName> = [] // Options available for modification

	permissionOptions: Array<IAccessPermissionItem> = []

	multiConfig: any = {
		show: false,
		access: new AccessPermission(),
		owner: new OwnerPermission(),
		showAccess: true,
		showOwner: true,
	}
	roleConfig = { show: false }
	selectedCategories: Array<IAccessPermissionItem> = []

	jsonResult

	user: UserRecord // Set when accessing from User table
	role: UserRoleRecord // Set when accessing from Roles table

	// Will receive either a userId or a roleId to determine what record to manange

	@Input() userId: number
	@Input() roleId: number
	@Output() saveComplete = new EventEmitter<boolean>()

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.setSaveState(true)

		// Check for access to Supervisor permissions
		this.isAdminPrimaryOrInternal = this.coreSrvc.dbSrvc.settingSrvc.isPrimaryOrInternalUser()
		this.isAdminInternal = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.isAdminManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.useSimplePermissions = prefs.userEnableSimplePermissions
	}

	assignedRoleName = null
	get isViewingFromUserTableWithRoleAssigned(): boolean {
		return !!this.user?.supervisor_role_id
	}
	get isViewingFromUserTableWithoutRoleAssigned(): boolean {
		return !!this.user && !this.user.supervisor_role_id
	}

	get isViewingSystemRoleFromRolesTableAndNotInternalUser(): boolean {
		return !this.isAdminInternal && !!this.role?.isSystemRole()
	}

	private filterPermissionsForRole() {
		return this.accessPermissions.permissionItems.filter((i) => this.allowedToModify.includes(i.table))
	}

	// Check if a given section should be displayed
	canModifyPermissions(item: IAccessPermissionItem): boolean {
		const table = item.table
		return this.allowedToModify.includes(table)
	}

	setSaveState(enabled: boolean) {
		const saveBtnState = enabled ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('adminPerms', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'adminPerms' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.submit()
			}
		}
	}

	ngOnInit() {
		this.setupRolesDropdown()
		this.setupBlocksAllowedForModification()
		this.setupAccessPermissions()
		this.performMyUserCheck()
		this.permissionOptions = this.filterPermissionsForRole()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private setupRolesDropdown() {
		this.rolesDropdown = this.coreSrvc.dbSrvc.settingSrvc.getUserRolesDropdownData(false, '')

		// const isManagerRoleEnabled = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().userEnableManagerRole
		// const role = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role
		// let rolesDropdown = AccessRole.getRolesDropdownData(role)
		// if (!isManagerRoleEnabled) {
		// 	rolesDropdown = rolesDropdown.filter((rdd) => rdd.value !== 'MANAGER')
		// }
		// this.rolesDropdown = rolesDropdown

		// // Setup ability to confirure by role
		// if (this.isAdminManager) this.roleConfig.show = true
	}

	private setupBlocksAllowedForModification() {
		// Setup blocks allowed to be modified - If we have a userId it's being modified
		// from user record. Otherwise it's from the Role Edit and should be set as if a PRIMARY
		const role = this.userId ? this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role : 'PRIMARY'

		this.allowedToModify = this.accessPermissions.getAllowedPermissionsForRole(role)
		const isMerged = this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
		if (isMerged) this.allowedToModify = this.allowedToModify.filter((key) => key !== 'site')
		log('allowedToModify', this.allowedToModify)
	}

	private setupAccessPermissions() {
		if (this.userId) {
			const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(this.userId)
			if (user) {
				this.user = user
				const userAccessPermissions = new AccessPermissions(user.permissions_json)
				this.accessPermissions = userAccessPermissions
				const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(user.supervisor_role_id)
				if (role) {
					this.assignedRoleName = role.description
				}
				return
			}
		}
		if (this.roleId) {
			const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(this.roleId)
			if (role) {
				this.role = role
				const roleAccessPermissions = new AccessPermissions(role.permissions_json)
				this.accessPermissions = roleAccessPermissions
				return
			}
		}
		// If no user or role then default access permissions are set during initialization
	}

	// Perform a check to see if I'm editing my own user
	private performMyUserCheck() {
		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		if (myUser.id === this.userId) {
			this.IsEditingMyUser = true
		}
	}

	// Manage role configuration
	toggleRoleConfigBlock() {
		this.roleConfig.show = !this.roleConfig.show
	}

	// Manage individual permissions

	togglePermission(item: IAccessPermissionItem, type: 'access' | 'owner' | 'multi', option: string, fromCheckbox?: boolean) {
		// log(type, option, fromCheckbox)
		const access: AccessPermission = item.access
		const owner: OwnerPermission = item.owner

		// Manage model if click came from checkbox or label
		if (!fromCheckbox) {
			if (this.isOptionDisabled(item, type, option)) {
				return
			}
			if (type === 'access') {
				access[option] = !access[option]
			}
			if (type === 'owner') {
				owner[option] = !owner[option]
			}
		}

		// When access permissions are modified
		if (type === 'access') {
			// When No Access is toggled. If set to true, clear all crud, if false, then make full access
			// regardless of setting, clear any selector restrictions and options restrictions
			//
			// if (option === 'noAccess') { this.clearAllCrud(item); this.restrictSelectors(item, false) }
			if (option === 'noAccess') {
				const isNoAccess = access.noAccess
				log('Current No Access Setting', isNoAccess)
				if (isNoAccess === false) {
					this.setFullAccess(item)
				} else {
					this.clearAllCrud(item)
				}
				this.setOptionPermissions(item, true) // Reset all options to default
				this.setSelectorPermissions(item, false) // Set all selectors to not filter for ownership
			}
			// When Supervisor is toggled
			if (option === 'supervisor') {
				this.enableAllCrud(item)
			}
			// When CRUD action toggled on for all records, turn it on for my records
			if (option === 'create' || option === 'read' || option === 'update' || (option === 'delete' && access[option])) {
				owner[option] = true
			}
			// When read is toggled off, toggle off other options
			if (option === 'read' && !access.read) {
				access.create = false
				access.update = false
				access.delete = false
			}
			// When not showing owner, copy the all records permissions
			if (!item.showOwner) {
				owner.create = access.create
				owner.read = access.read
				owner.update = access.update
				owner.delete = access.delete
			}
		}

		// When owner permissions are modified
		if (type === 'owner') {
			// When read is toggled off, toggle off other options
			if (option === 'read' && !owner.read) {
				owner.create = false
				owner.update = false
				owner.delete = false
			}
		}

		// If using simple permissions, modify will manage create and delete permissions
		if (this.useSimplePermissions) {
			access.create = access.update
			access.delete = access.update
			owner.create = owner.update
			owner.delete = owner.update
		}

		// Set the selectors based on current permissions
		this.updateSelectors(item)
	}

	updateSelectors(item: IAccessPermissionItem) {
		const permissions = item.access
		const owner = item.owner
		if (!permissions.create && !permissions.update && !owner.create && !owner.update) {
			this.setSelectorPermissions(item, false)
		}
	}

	private setSelectorPermissions(item: IAccessPermissionItem, restrict: boolean) {
		const selectors = item.selectors
		if (selectors) {
			for (const selector of selectors) {
				selector.restricted = restrict
			}
		}
	}

	private setOptionPermissions(item: IAccessPermissionItem, enabled: boolean) {
		const options = item.options
		if (options) {
			for (const option of options) {
				option.enabled = enabled
			}
		}
	}

	private enableAllCrud(item: IAccessPermissionItem) {
		const accessPerms = item.access
		const owner = item.owner

		accessPerms.create = true
		accessPerms.read = true
		accessPerms.update = true
		accessPerms.delete = true

		owner.create = true
		owner.read = true
		owner.update = true
		owner.delete = true
	}

	private clearAllCrud(item: IAccessPermissionItem) {
		const accessPerms = item.access
		const owner = item.owner

		accessPerms.create = false
		accessPerms.read = false
		accessPerms.update = false
		accessPerms.delete = false

		owner.create = false
		owner.read = false
		owner.update = false
		owner.delete = false
	}

	showResultClicked() {
		const jsonResult = this.accessPermissions.getJsonString()
		this.jsonResult = JSON.parse(jsonResult)
	}

	submit() {
		log('Saving Permissions')
		if (this.isValidForSave()) {
			const user = this.user
			user.permissions_json = this.accessPermissions.getJsonString()
			this.isUpdating = true
			this.cd.detectChanges()
			this.coreSrvc.dbSrvc.updateRecord('users', user).then((result) => {
				this.saveComplete.emit(true)
			})
		}
	}

	isOptionDisabled(item: IAccessPermissionItem, type: string, option: string): boolean {
		// If a role is assigned we cannot modify any permissions
		if (this.isViewingFromUserTableWithRoleAssigned) return true
		if (this.isViewingSystemRoleFromRolesTableAndNotInternalUser) return true

		if (!item) {
			alert('Missing Item')
		}
		const owner = item.owner
		const access = item.access
		if (option !== 'noAccess' && access.noAccess) {
			return true
		}
		if (type === 'access' || type === 'multi') {
			if (option === 'create') {
				if (!access.read) {
					return true
				}
			}
			if (option === 'update') {
				if (!access.read) {
					return true
				}
			}
			if (option === 'delete') {
				if (!access.read) {
					return true
				}
			}
		}
		if (type === 'owner') {
			// if (!item.owner) { return true }
			if (option === 'create') {
				if (access.create || !owner.read) {
					return true
				}
			}
			if (option === 'read') {
				if (access.read) {
					return true
				}
			}
			if (option === 'update') {
				if (access.update || !owner.read) {
					return true
				}
			}
			if (option === 'delete') {
				if (access.delete || !owner.read) {
					return true
				}
			}
		}
		return false
	}

	isValidForSave(): boolean {
		const supPermission = this.accessPermissions.getAccessPermissionsFor('users')
		if (this.IsEditingMyUser && (!supPermission.create || !supPermission.read || !supPermission.update || !supPermission.delete)) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Invalid Permissions',
				'You must retain full access for Supervisor permissions when modifying your own user.',
			)
			return false
		}
		return true
	}

	togglePermissionsBlock(item: IAccessPermissionItem) {
		if (item.showDetails) {
			item.showDetails = false
			return
		}
		this.closeAllPermissionBlocks()
		this.multiConfig.show = false
		item.showDetails = true
	}

	closeAllPermissionBlocks() {
		const items = this.accessPermissions.permissionItems
		for (const item of items) {
			item.showDetails = false
		}
	}

	setFullAccess(item: IAccessPermissionItem) {
		const access = item.access
		access.create = true
		access.read = true
		access.update = true
		access.delete = true
		access.noAccess = false
		const owner = item.owner
		if (owner) {
			owner.create = true
			owner.read = true
			owner.update = true
			owner.delete = true
		}
		const selectors = item.selectors
		if (selectors) {
			for (const selector of selectors) {
				selector.restricted = false
			}
		}
	}

	setReadOnly(item: IAccessPermissionItem) {
		const access = item.access
		access.create = false
		access.read = true
		access.update = false
		access.delete = false
		access.noAccess = false
		const owner = item.owner
		if (owner) {
			// Check why this is duplicated. Either it's a bug or unnecessary leftover check
			if (owner) {
				owner.create = false
				owner.read = true
				owner.update = false
				owner.delete = false
			}
		}
	}

	isFullAccess(item: IAccessPermissionItem): boolean {
		const access = item.access
		const arePermsFullAccess = access.create && access.read && access.update && access.delete
		let areSelectorsFullAccess = true
		const selectors = item.selectors
		if (selectors) {
			for (const selector of selectors) {
				if (selector.restricted) {
					areSelectorsFullAccess = false
				}
			}
		}
		const miscOptions = item.options
		let areOptionsFullAccess = true
		if (miscOptions) {
			for (const option of miscOptions) {
				if (!option.enabled) {
					areOptionsFullAccess = false
				}
			}
		}
		return arePermsFullAccess && areSelectorsFullAccess && areOptionsFullAccess
	}

	isReadOnly(item: IAccessPermissionItem) {
		const access = item.access
		const isPermsReadOnly = access.read && !access.create && !access.delete && !access.update && !access.noAccess
		const owner = item.owner
		if (owner) {
			const isOwnerReadOnly = owner.read && !owner.create && !owner.delete && !owner.update
			return isPermsReadOnly && isOwnerReadOnly
		}
		return isPermsReadOnly
	}

	isNoAccess(item: IAccessPermissionItem) {
		const access = item.access
		const owner = item.owner
		const noAccessAll = !access.read && !access.create && !access.delete && !access.update
		const noAccessMine = !owner.read && !owner.create && !owner.delete && !owner.update
		return noAccessAll && noAccessMine
	}

	hasOpenBlock() {
		const permsList = this.accessPermissions.permissionItems
		const openItems = permsList.filter((i) => i.showDetails)
		if (openItems.length > 0) {
			return true
		}
		return false
	}

	selectedCrudOptions(item: IAccessPermissionItem) {
		const options = []
		if (item.access.read) {
			options.push('View')
		}
		if (item.access.create) {
			options.push('Create')
		}
		if (item.access.update) {
			options.push('Modify')
		}
		if (item.access.delete) {
			options.push('Delete')
		}
		if (options.length > 0) {
			return 'Access: ' + options.join(' / ')
		}
		return 'Access: None'
	}

	toggleMultiConfig() {
		this.multiConfig.show = !this.multiConfig.show
		if (this.multiConfig.show) {
			// this.clearAllExcept('none', this.multiConfig.permissions)
			this.closeAllPermissionBlocks()
		}
	}

	applyMultiConfigPermissions() {
		if (this.selectedCategories.length === 0) {
			this.coreSrvc.notifySrvc.notify('error', 'No Categories Selected', 'Please select the categories you want to apply these permissions to.', 4)
			return
		}
		const access = this.multiConfig.access
		const owner = this.multiConfig.owner
		// log('Selected Categories', this.selectedCategories)
		for (const item of this.selectedCategories) {
			item.access.create = access.create
			item.access.read = access.read
			item.access.update = access.update
			item.access.delete = access.delete
			item.access.noAccess = access.noAccess
			item.access.supervisor = access.supervisor

			// If owner permissions are available use them, otherwise set to access permissions
			if (item.showOwner) {
				item.owner.create = owner.create
				item.owner.read = owner.read
				item.owner.update = owner.update
				item.owner.delete = owner.delete
			} else {
				item.owner.create = item.access.create
				item.owner.read = item.access.read
				item.owner.update = item.access.update
				item.owner.delete = item.access.delete
			}
			const selectors = item.selectors
			if (selectors) {
				for (const selector of selectors) {
					if (item.access.create || item.access.update) {
						selector.restricted = false
					} else {
						selector.restricted = true
					}
				}
			}
		}
		this.coreSrvc.notifySrvc.notify('success', 'Permissions Changed', `Permissions have been changed but will not be applied unless saved.`, 5)
	}

	public selectorNameFormatter(permission: SelectorPermission): string {
		switch (permission.type) {
			case 'EMP':
				return 'Employee Selector' // General: Employee selector
			case 'SITE':
				return 'Job Site Selector' // General: Job Site selector
			case 'JOB':
				return 'Job Selector' // General: Job selector
			case 'SUP':
				return 'Supervisor Selector' // General: Supervisor selector
			// case 'ELN':
			// 	return 'Linked Employees Selector' // Job Sites: Linked Employees selecotr
			case 'EMS':
				return 'Supervisor Selector' // Employees: Supervisor selector
			case 'RPO':
				return 'Report Owner Selector'
			default:
				return 'Unknown Selector'
		}
	}

	public selectorDescriptionFormatter(permission): string {
		switch (permission.type) {
			case 'EMP':
				return 'Filter for ownership'
			case 'SITE':
				return 'Filter for ownership'
			case 'JOB':
				return 'Filter for ownership'
			case 'SUP':
				return 'Filter for ownership'
			// case 'ELN':
			// 	return 'Filter for ownership'
			case 'EMS':
				return 'Filter for ownership'
			case 'RPO':
				return 'Filter for ownership'
			default:
				return 'Unknown selector option'
		}
	}

	// ACTACD - General Settings
	// ACTCPP - Portal Preferences
	// ACTCES - Employee Scoring
	// ACTCEA - Employee Application
	// ACTCCB - Inbound Call Blocking
	// ACTCCL - Checkout Checklists
	// ACTCAF - Archived Files

	public optionDescriptionFormatter(permission): string {
		switch (permission.type) {
			case 'GBLACP':
				return 'Agent Control Panel'
			case 'ACTCAD':
				return 'General / Profile'
			case 'ACTCES':
				return 'Employee Scoring'
			case 'ACTCEA':
				return 'Employee Application'
			case 'ACTCCB':
				return 'Inbound Call Blocking'
			case 'ACTCCL':
				return 'Checkout Checklists'
			case 'ACTCPP':
				return 'Portal Preferences'
			case 'ACTCAF':
				return 'Archived Files'
			case 'ACTCWO':
				return 'Work Orders'
			case 'ACTCCS':
				return 'Call Center'
			case 'ACTCDA':
				return 'Data / Analytics'
			case 'ACTMCL':
				return 'Modify Company Logo'
			case 'CLGAAL':
				return 'Access Call/Text Log'
			case 'SUPROL':
				return 'Configure Supervisor Roles'
			case 'SUPPRF':
				return 'Configure Supervisor Prefs'
			case 'SUPLEM':
				return 'Configure Linked Employee'
			case 'TEEEAN':
				return 'Edit Admin Notes'
			default:
				return 'Unknown Option'
		}
	}
	// Used for custom selector restriction checkboxes

	toggleSelectorPermission(item: IAccessPermissionItem, selector: SelectorPermission, fromCheckbox?: boolean) {
		if (this.isSelectorDisabled(item)) {
			return
		}
		if (!fromCheckbox) {
			selector.restricted = !selector.restricted
		}
	}

	isSelectorDisabled(item: IAccessPermissionItem) {
		if (this.isViewingFromUserTableWithRoleAssigned) return true
		if (this.isViewingSystemRoleFromRolesTableAndNotInternalUser) return true

		const access = item.access
		const owner = item.owner
		const canAccess = access.create || access.update || owner.create || owner.update
		if (canAccess) {
			return false
		} else {
			return true
		}
	}

	// Used for custom permission options checkboxes

	togglePermOptionPermission(item: IAccessPermissionItem, option: OptionPermission, fromCheckbox?: boolean) {
		if (this.isPermOptionDisabled(item)) {
			return
		}
		if (!fromCheckbox) {
			option.enabled = !option.enabled
		}
	}

	isPermOptionDisabled(item: IAccessPermissionItem) {
		if (this.isViewingFromUserTableWithRoleAssigned) return true
		return false
	}

	disableSelectors(item: IAccessPermissionItem) {
		const selectors = item.selectors
		if (selectors) {
			for (const selector of selectors) {
				selector.restricted = true
			}
		}
	}

	// Take the role selected in dropdown and create a new permissions object with it.
	applyRole() {
		log('Selected Role', this.selectedRole)
		const selectedRole = this.selectedRole
		const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(selectedRole)
		if (role) {
			this.accessPermissions = new AccessPermissions(role.permissions_json)
		}
	}

	resetPermissions() {
		this.accessPermissions = new AccessPermissions()
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'EMP':
				help.header = 'Ownership Filter'
				help.message = `Check this box to filter the employee dropdown to only show records owned by this supervisor.`
				break
			case 'SITE':
				help.header = 'Ownership Filter'
				help.message = `Check this box to filter the job site dropdown to only show records owned by this supervisor.`
				break
			case 'JOB':
				help.header = 'Ownership Filter'
				help.message = `Check this box to filter the job dropdown to only show records owned by this supervisor.`
				break
			case 'SUP':
				help.header = 'Ownership Filter'
				help.message = `Check this box to filter the supervisor dropdown to only show records owned by this supervisor.`
				break
			// case 'ELN':
			// 	help.header = 'Ownership Filter'
			// 	help.message = `When editing job sites, all employees are listed in the 'Link Employee Mobile Numbers' selector. Check this box to list only employees assigned to this supervisor.`
			// 	break
			case 'EMS':
				help.header = 'Ownership Filter'
				help.message = `When editing employees, all supervisors are listed in the <b>Employee Supervisor</b> selector. Check this box to list only supervisors assigned to this manager or hide this selector for standard supervisors and automatically assign them to the employee.`
				break
			case 'RPO':
				help.header = 'Ownership Filter'
				help.message = `Check this box to limit who can be designated as the owner of this report. Supervisors will be able to assign themself or make a report global. Manages will be able to assign reports to their supervisors.`
				break
			case 'GBLACP':
				help.header = 'Agent Control Panel'
				help.message = `Allow this supervisor to access the <b>Agent Control Panel</b> in the navigation bar.`
				break
			case 'ACTCAD':
				help.header = 'General / Profile'
				help.message = `Allow this supervisor to configure <b>General Settings</b> and <b>Company Profile</b> options in the <b>Settings</b> control panel.`
				break
			case 'ACTCPP':
				help.header = 'Portal Preferences'
				help.message = `Allow this supervisor to configure <b>Portal Preferences</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCES':
				help.header = 'Employee Scoring'
				help.message = `Allow this supervisor to configure <b>Employee Scoring</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCEA':
				help.header = 'Employee Application'
				help.message = `Allow this supervisor to configure <b>Employee Application</b> preferences in the <b>Settings</b> control panel.`
				break
			case 'ACTCCB':
				help.header = 'Call Blocking'
				help.message = `Allow this supervisor to configure <b>Inbound Call Blocking</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCCL':
				help.header = 'Checkout Checklists'
				help.message = `Allow this supervisor to configure <b>Checkout Checklists</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCAF':
				help.header = 'Archived Files'
				help.message = `Allow this supervisor to manage <b>Archived Files</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCWO':
				help.header = 'Work Orders'
				help.message = `Allow this supervisor to manage <b>Work Orders</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCCS':
				help.header = 'Call Center'
				help.message = `Allow this supervisor to manage <b>Call Center</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTCDA':
				help.header = 'Data / Analytics'
				help.message = `Allow this supervisor to manage <b>Data / Analytics</b> in the <b>Settings</b> control panel.`
				break
			case 'ACTMCL':
				help.header = 'Modify Logo'
				help.message = `Allow this supervisor to upload a company logo or replace the existing logo with a new one.`
				break
			case 'CLGAAL':
				help.header = 'Call/Text Log'
				help.message = `Allow access to call and text logs when Telephone Timesheets Call Center features are being used.`
				break
			case 'SUPPRF':
				help.header = 'Supervisor Prefs'
				help.message = `Allow this supervisor to configure portal preferences for individual supervisors in the supervisors section.`
				break
			case 'SUPROL':
				help.header = 'Supervisor Roles'
				help.message = `Allow this supervisor to manage supervisor roles and configure the assigned role for individual supervisors.`
				break
			case 'SUPLEM':
				help.header = 'Linked Employee'
				help.message = `Allow this supervisor to manage the linked employee option of other supervisors.`
				break
			case 'TEEEAN':
				help.header = 'Admin Notes'
				help.message = `Allow this supervisor to edit admin notes.`
				break

			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
