import { Component, OnInit, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

import { AppVersion, GenericEvent, PhoneNumberRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log, DisplayHelper, PhoneHelper } from '@app/helpers'

import { Subscription } from 'rxjs'
import { Router } from '@angular/router'

import moment from 'moment-timezone'
import { environment } from '@env/environment'

interface CallInNumber {
	number: string
	label: string
}

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	standalone: false,
})
export class FooterComponent implements OnInit, OnDestroy {
	isReady = false
	chatbotAvailable = false
	currentAppVersion = AppVersion.version

	showSupportInfo = false

	callInNumbers: Array<CallInNumber> = []
	phoneNumbers: Array<PhoneNumberRecord> = []
	countryCode = 'US'

	logoUrl = 'https://assets.telephonetimesheets.com/global/tts-logo.png'

	companyName = ''
	companyId = ''
	adpIvrInfo = ''

	copyrightYear = moment().format('YYYY')

	private subs = new Subscription()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.subs.add(this.coreSrvc.eventSrvc.genericEvents.subscribe((event) => this.processGenericEvents(event)))
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe((event) => this.handleComponentSetup()))
	}

	ngOnInit() {
		this.router.events.subscribe(() => (this.showSupportInfo = false))
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	processGenericEvents(event: GenericEvent) {
		if (event.key === 'currentAppVersion') this.currentAppVersion = event.data
	}

	private handleComponentSetup() {
		// Setup the logo
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		const role = user?.role

		const isInternalUser = role === 'INTERNAL'
		const isPrimaryUser = role === 'PRIMARY'
		const isAdpIvrAdmin = this.coreSrvc.dbSrvc.settingSrvc.isAdpIvrAdmin()

		// Setup company info
		this.countryCode = company?.country_iso ?? 'US'
		this.logoUrl = company?.logo_url ?? 'https://assets.telephonetimesheets.com/global/tts-logo.png'
		this.companyName = company?.name ?? ''
		this.companyId = isInternalUser ? ` (${company?.id})` : ''
		this.adpIvrInfo = isAdpIvrAdmin ? company?.external_id : ''

		// Check for chatbot availability
		this.chatbotAvailable = isInternalUser || (isPrimaryUser && prefs?.globalEnableChatbot)

		this.setupPhoneNumbers()

		this.isReady = true
	}

	private setupPhoneNumbers() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const companyId = company?.id
		const globalId = company?.global_id
		const result = this.coreSrvc.dbSrvc.settingSrvc
			.getCallInPhoneNumberRecords()
			.filter((pn) => pn.company_id === companyId || pn.company_id === globalId)
		const dispatchNumbers = result.filter((pn) => pn.route_application === 'DISPATCH' && pn.company_id === companyId)
		const ttsProdNumber = result.filter((pn) => pn.route_application === 'TTS_PROD')
		const otherNumbers = result.filter((pn) => pn.route_application !== 'TTS_PROD')
		const sortedResult = ttsProdNumber.length > 0 ? [...ttsProdNumber, ...dispatchNumbers] : [...otherNumbers]
		this.callInNumbers = sortedResult.map((pn) => ({
			number: PhoneHelper.formatPhoneFromE164(pn.number_e164),
			label: pn.phoneNumberLabel,
		}))
	}

	testBtnClicked() {
		this.coreSrvc.dbSrvc.readTable('company')
	}

	openManual() {
		window.open('https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/', '_blank')
	}

	gotoChatbot() {
		this.router.navigate(['/admin/chatbot'])
	}

	reloadApplication() {
		localStorage.setItem('manualUpdateCheck', 'requested')
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
	}

	resetApplication() {
		this.router.navigate(['/reset'])
	}
}
