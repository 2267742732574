<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction('create')"
				aria-hidden="true"
				title="Add Station"
				(click)="createRecord()"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="openPrefsDialog()" title="Manage Prefs"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<div class="sec-head-ico-wrap">
				<i class="fal fa-computer-classic sec-head-ico"></i>
			</div>
			<span>Stations</span>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="fa fa-refresh sub-head-ico" (click)="reloadData()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated }}</div>
		</div>
	</div>

	<div class="alert alert-info alert-bg-gray" style="line-height: 1.4rem; margin: 22px 0px">
		<b>Stations</b> can manage individual employees at specific workstations, multiple employees sharing multiple workstations, or even act as a
		common punch clock for the entire organization.
		<span *ngIf="!showMoreInfo" class="link-text" (click)="toggleTooltips()">show more</span>

		<div *ngIf="showMoreInfo">
			<hr />
			Stations allow employees to clock in and out using devices you provide, rather than their personal cell phones. You can manage the list of
			employees and jobs available for each station, then use the information below to setup your devices. Please use the
			<b>Contact Support</b> link at the bottom of this page if you have any questions about how to setup or manage stations.<br /><br />

			<h4 class="tts-hl-2">Station Modes</h4>
			<div style="margin-top: 12px">
				<b>Personal Mode</b> is designed for individual stations where a single employee clocks in at a time, typically using the computer they
				work on. This could be a tablet, notebook, or desktop computer. Once clocked in, employees have full access to the same features available
				when using their personal cell phones, including schedule and timecard information.

				<div style="color: firebrick; margin-top: 8px">
					<b>Personal Mode</b> stations should be unique. Do not use the same setup code on more than one device.
				</div>
				<br />

				<b>Kiosk Mode</b> functions like a shared punch clock, similar to those found in break rooms. It allows employees to clock in and out,
				start or stop breaks, submit checkpoints, and file reports. After each action, the kiosk resets for the next employee. Unlike Personal
				Mode, Kiosk Mode does not provide access to personal employee information such as schedules or timecards, and the same station setup code
				may be used to configure multiple devices.
			</div>

			<hr />

			<h4 class="tts-hl-2">Getting Started</h4>
			<b style="color: chocolate">Step One:</b> To setup a station, you will install the Timeclock web app on the device employees will use to
			clock in and out. Using a web browser on this device, first go to the <b class="tts-hl-gold">Web App URL</b> shown below.

			<div style="margin: 12px 0px">
				<b class="tts-hl-gold">Web App URL:</b><br /><span>{{ appUrl }}</span>
			</div>

			Depending on the device you wish to use, the instructions for installing a web app will vary and can be found online by searching for
			<b>install web app</b> along with the type of device, such as <b>iPhone</b>, <b>iPad</b>, <b>Android</b>, <b>Mac</b>, <b>Windows</b>, etc. If
			you would like assistance with this step, please reach out to us using the <b>Contact Support</b> link at the bottom of this page.

			<hr />

			<div class="row">
				<div class="col-md-6 col-sm-12">
					<div class="card-image">
						<h4 class="tts-hl-2">Setup a Station</h4>

						<div>
							<div class="setup-info">
								<b style="color: chocolate">Step Two:</b> Once you have added the Timeclock web app to your device, launch it using the icon
								that was installed. From the registration screen, you will want to select the <b>setup station</b> link which is available
								towards the bottom.
							</div>
							<div class="help-img-wrap">
								<img class="help-img" src="/assets/img/help/station_app_url.png" style="width: 100%" />
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="card-image">
						<h4 class="tts-hl-2">Assign a Station</h4>

						<div>
							<div class="setup-info">
								<b style="color: chocolate">Step Three:</b> On the station setup screen, enter the setup code for the station you wish to use
								and click the <b>setup station</b> button. You can now use this device as a Timeclock Station. Station setup codes are case
								sensitive and each station will have a unique setup code.
							</div>
							<div class="help-img-wrap">
								<img class="help-img" src="/assets/img/help/station_setup_url.png" style="width: 100%" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<b style="color: firebrick">Warning:</b> Be careful who you share station setup codes with, as they can be used multiple times to setup a
			station on any device with a web browser.
		</div>

		<div *ngIf="showMoreInfo" class="link-text" style="margin-top: 12px" (click)="toggleTooltips()">hide details</div>
	</div>

	<app-station-table #stationTable></app-station-table>
</div>
