import { Injectable, EventEmitter } from '@angular/core'
import { OnboardDefinitionRecord, OnboardLogRecord, OnboardUpdateViewManager } from '@app/models'

import { log } from '@app/helpers'
import { Subject } from 'rxjs'

import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	onboardDefinitionDataLoaded = false
	onboardDefinitionList: Array<OnboardDefinitionRecord> = []
	onboardDefinitionListChange = new EventEmitter<Array<OnboardDefinitionRecord>>()
	onboardDefinitionListLastUpdated: Date

	onboardLogDataLoaded = false
	onboardLogList: Array<OnboardLogRecord> = []
	onboardLogListChange = new EventEmitter<Array<OnboardLogRecord>>()
	onboardLogListLastUpdated: Date

	public onboardUpdateListNeedsRefresh = new Subject<boolean>()

	viewManager = new OnboardUpdateViewManager()

	constructor() {
		log('Creating OnboardingService')
	}

	clearData() {
		this.onboardDefinitionList = []
		this.onboardDefinitionDataLoaded = false
		this.onboardDefinitionListLastUpdated = null

		this.onboardLogList = []
		this.onboardLogDataLoaded = false
		this.onboardLogListLastUpdated = null
	}

	////////////////////////
	// Onboard Definition //
	////////////////////////

	getOnboardDefinitionRecords(): Array<OnboardDefinitionRecord> {
		const result = _.sortBy(this.onboardDefinitionList, 'description')
		return result
	}

	getOnboardDefinitionRecordById(id: number) {
		return this.onboardDefinitionList.find((rec) => rec.id === id)
	}

	removeOnboardDefinitionRecord(recordId: number) {
		this.onboardDefinitionList = this.onboardDefinitionList.filter((rec) => rec.id !== recordId)
	}

	setOnboardDefinitionRecords(records: Array<OnboardDefinitionRecord>) {
		this.onboardDefinitionListLastUpdated = new Date()
		this.onboardDefinitionList = records.map((rec) => new OnboardDefinitionRecord(rec))
		this.onboardDefinitionDataLoaded = true
		this.onboardDefinitionListChange.next(this.onboardDefinitionList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateOnboardDefinitionRecords(records: Array<OnboardDefinitionRecord>) {
		const newRecords = records.map((rec) => new OnboardDefinitionRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.onboardDefinitionList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.onboardDefinitionList.push(newRecord)
			}
		}
		this.onboardDefinitionListLastUpdated = new Date()
		this.onboardDefinitionListChange.next(this.onboardDefinitionList)
	}

	/////////////////
	// Onboard Log //
	/////////////////

	getOnboardLogRecords(): Array<OnboardLogRecord> {
		const result = _.orderBy(this.onboardLogList, 'employee_last_update', 'desc')
		return result
	}

	getOnboardLogRecordById(id: number) {
		return this.onboardLogList.find((rec) => rec.id === id)
	}

	removeOnboardLogRecord(recordId: number) {
		this.onboardLogList = this.onboardLogList.filter((rec) => rec.id !== recordId)
	}

	setOnboardLogRecords(records: Array<OnboardLogRecord>) {
		this.onboardLogListLastUpdated = new Date()
		this.onboardLogList = records.map((rec) => new OnboardLogRecord(rec))
		this.onboardLogDataLoaded = true
		this.onboardLogListChange.next(this.onboardLogList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateOnboardLogRecords(records: Array<OnboardLogRecord>) {
		const newRecords = records.map((rec) => new OnboardLogRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.onboardLogList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.onboardLogList.push(newRecord)
			}
		}
		this.onboardLogListLastUpdated = new Date()
		this.onboardLogListChange.next(this.onboardLogList)
	}
}
