import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { ApprovalStateType } from '@app/models'
import { SelectItem } from 'primeng/api'

@Component({
	selector: 'app-approval-state',
	standalone: false,

	templateUrl: './approval-state.component.html',
	styleUrl: './approval-state.component.scss',
})
export class ApprovalStateComponent {
	approvalStateOptions: Array<SelectItem> = []

	@Input() state: ApprovalStateType
	@Input() selectedState: ApprovalStateType
	@Output() selectedStateChange = new EventEmitter<ApprovalStateType>()

	@Input() managedByName: string
	@Input() lastManagedDate: Date
	@Input() managedDateFormatter = 'YYYY-MM-DD [/] ' + DateTimeHelper.getTimeFormat() // 'MMM Do, YYYY'

	constructor() {
		this.setupApprovalStatusDropdown()
	}

	public setupApprovalStatusDropdown() {
		const options = [
			{ label: 'Request Approved', value: 'APPROVED' },
			{ label: `Request Denied`, value: 'REJECTED' },
			{ label: `Request Pending`, value: 'PENDING' },
			{ label: `Request Expired`, value: 'EXPIRED' },
		]
		this.approvalStateOptions = options
	}

	public handleDropdownStateChange(event) {
		this.selectedState = event.value
		this.selectedStateChange.emit(this.selectedState)
	}

	public setApprovalState(state?: ApprovalStateType) {
		if (state) this.state = state
		this.selectedStateChange.emit(state)
	}
}
