<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-title-wrap d-flex flex-row mb-2 justify-content-between flex-wrap">
			<div>
				<i class="far fa-user sec-head-ico" aria-hidden="true"></i>
				<span>Profile Updates</span>
			</div>
			<div *ngIf="true" class="bulk-btns">
				<button class="btn btn-sm btn-outline-secondary" style="margin-right: 8px" (click)="clearSelectionBtnClicked()">Clear Selection</button>
				<button class="btn btn-sm btn-outline-secondary" style="margin-right: 8px" (click)="selectAllBtnClicked()">Select All</button>
				<button [disabled]="!hasSelected" class="btn btn-sm btn-danger" style="margin-right: 8px" (click)="bulkActionBtnClicked('REJECT')">
					Reject Selected
				</button>
				<button [disabled]="!hasSelected" class="btn btn-sm btn-success" (click)="bulkActionBtnClicked('APPROVE')">Approve Selected</button>
			</div>
		</div>
	</div>

	<div>
		<div class="alert alert-info alert-bg-gray">
			Click on individual profile cards to toggle selection. Selected profiles will will show a green checkmark. Use the
			<strong>Reject Selected</strong> or <strong>Approve Selected</strong> buttons to perform the indicated action on any selected profiles. Use
			the <strong>Reject</strong> or <strong>Approve</strong> buttons on individual profile cards to perform the indicated action for that specific
			profile.
		</div>
	</div>

	<div class="d-flex flex-row mb-2 justify-content-center flex-wrap">
		<div *ngFor="let card of list" class="pfp-card-wrap">
			<div class="card pfp-card" [class.pfp-card-selected]="card.selected" (click)="toggleCard(card)">
				<img
					[src]="card.hasImageError ? card.placeholderImage : card.profileUrl"
					width="300px"
					class="card-img-top"
					alt="Profile Picture"
					(error)="handleImageLoadError(card)"
				/>
				<div class="card-body">
					<div class="emp-name trunc-ellipsis">{{ card.emp.name }}</div>
					<div class="sup-name">{{ card.empSupName }}</div>
					<div style="margin-top: 12px">
						<i *ngIf="card.isProcessing" class="fas fa-loader fa-spin float-end is-processing"></i>
						<i *ngIf="!card.isProcessing && card.selected" class="fa fa-check float-end is-checked"></i>
						<div>
							<button class="btn btn-sm btn-outline-danger" style="margin-right: 16px" (click)="pfpRejected(card)">Reject</button>
							<button class="btn btn-sm btn-outline-success" (click)="pfpApproved(card)">Approve</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- No Records -->
	<div *ngIf="list.length === 0" class="no-records">No Pending Updates</div>
</div>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Click To Call"
	[(visible)]="confirmDialog.showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div style="width: 300px">
		<div class="bulk-dialog-copy-wrap">
			Are you sure you want to
			<span [class.approve]="confirmDialog.action === 'APPROVE'" [class.reject]="confirmDialog.action === 'REJECT'">{{
				confirmDialog.action === 'REJECT' ? 'reject' : 'approve'
			}}</span>
			all selected profile pictures? This action cannot be undone.
		</div>
		<div style="margin-top: 28px">
			<div class="d-flex justify-content-between">
				<button class="btn btn-sm btn-outline-secondary" (click)="confirmDialog.showDialog = false">Cancel</button>
				<button
					*ngIf="!confirmDialog.isProcessing"
					class="btn btn-sm"
					[class.btn-success]="confirmDialog.action === 'APPROVE'"
					[class.btn-danger]="confirmDialog.action === 'REJECT'"
					(click)="confirmBulkAction()"
				>
					{{ confirmDialog.action === 'APPROVE' ? 'Approve' : 'Reject' }}
				</button>
				<div *ngIf="confirmDialog.isProcessing"><i class="fas fa-loader fa-spin bulk-spinner"></i></div>
			</div>
		</div>
	</div>
</p-dialog>
