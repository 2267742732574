import { parseNumber, ParsedNumber, formatNumber, isValidNumber } from 'libphonenumber-js'
import { log } from './logger'
import { Global } from '@app/models/global'

export class DisplayHelper {
	static get isNotStandalone(): boolean {
		// if (window.navigator.hasOwnProperty('standalone') && !window.navigator['standalone'])
		if ('standalone' in window.navigator && !window.navigator.standalone) {
			return true
		}
		return false
	}

	static isRunningStandalone(): boolean {
		return (
			(window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
			(window.matchMedia && window.matchMedia('(display-mode: fullscreen)').matches) ||
			window.navigator['standalone'] === true ||
			document.referrer.startsWith('android-app://')
		)
	}

	/**
	 * Truncates a string at a given cutoff length
	 * @param str String value to truncate
	 * @param cutoff Length at which to cutoff string
	 * @returns A truncated string with appended ellipsis
	 */

	static truncateString(str: string, cutoff: number): string {
		if (!str) {
			return ''
		}
		if (str.length > cutoff) {
			return str.substr(0, cutoff) + '…'
		}
		return str
	}

	/**
	 * Truncates a string to best fit various device screen sizes
	 * @param str String value to truncate
	 * @returns A truncated string with appended ellipsis
	 */

	static truncateForDisplay(str: string): string {
		let result = str
		const width = $(window).width()
		if (width < 325) {
			result = this.truncateString(str, 19)
			return result
		}
		if (width < 365) {
			result = this.truncateString(str, 21)
			return result
		}
		if (width < 380) {
			result = this.truncateString(str, 23)
			return result
		}
		if (width < 570) {
			result = this.truncateString(str, 33)
			return result
		}
		return result
	}

	static truncateForDropdown(str: string): string {
		let result = str
		const width = $(window).width()
		if (width < 325) {
			result = this.truncateString(str, 27)
			return result
		}
		if (width < 365) {
			result = this.truncateString(str, 30)
			return result
		}
		if (width < 380) {
			result = this.truncateString(str, 35)
			return result
		}
		if (width < 420) {
			result = this.truncateString(str, 40)
			return result
		}
		return this.truncateString(str, 45)
	}

	/**
	 * Truncates individual components of a string to best fit various device screen sizes
	 * @param str String value to truncate
	 * @returns A string with individual words truncated based on screen size
	 */

	static truncateForWrapDisplay(str: string): string {
		if (!str || !(Object.prototype.toString.call(str) === '[object String]')) {
			return ''
		}
		const components = str.split(' ')
		const truncComp = components.map((c) => this.truncateForDisplay(c))
		return truncComp.join(' ')
	}

	/**
	 * Formats E.164 phone number string to national display format
	 * @param phoneE164 Phone number string in E.164 format
	 * @returns Formatted phone number if valid or input provided if not
	 */

	static displayPhone(phoneE164: any): string {
		if (!phoneE164) {
			return ''
		}
		const parsedNumber = parseNumber(phoneE164) as ParsedNumber
		if (!parsedNumber.country) {
			return phoneE164
		}
		if (isValidNumber(parsedNumber)) {
			return formatNumber(parsedNumber, 'NATIONAL')
		} else {
			return phoneE164
		}
	}

	static replaceNewlinesWithBr(text: string): string {
		if (!text) return ''

		return text.replace(/\n/g, '<br />')
	}

	static formatAdminNotesForPreWrap(text: string): string {
		// if (!text) return ''
		// const addPadding = text.replace(/\n\[/g, '\n\n[')
		// return addPadding.replace(/\] \[/g, ']\n[')

		// Will do timestamp \n source - note
		if (!text) return ''
		const split = text.replace(/\]\n/g, '] - ')
		const addPadding = split.replace(/\n\[/g, '\n\n[')
		return addPadding.replace(/\] \[/g, ']\n[')
	}

	static matchAll(pattern: RegExp, haystack: string) {
		const regex = new RegExp(pattern, 'g')
		const matches = []

		const match_result = haystack.match(regex)

		for (const index in match_result) {
			if (match_result[index]) {
				const item = match_result[index]
				matches[index] = item.match(new RegExp(pattern))
			}
		}
		return matches
	}

	static getEmbededVariables(input: string): Array<string> {
		const matches = DisplayHelper.matchAll(/\[[^\][]*]/, input)
		const processedMatches = []
		for (const match of matches) {
			let processed = match.input
			processed = processed.replace('[', '').replace(']', '')
			processedMatches.push(processed)
		}
		return processedMatches
	}

	static setHtmlScrolling(scrolling: boolean) {
		if (scrolling) {
			$('html').css('overflow-y', 'scroll')
		} else {
			$('html').css('overflow-y', 'hidden')
		}
	}

	static scrollToTop(style: 'smooth' | 'auto' | 'instant' = 'auto') {
		const typeErasedStyle = style as 'auto'
		window.scrollTo({ top: 0, behavior: typeErasedStyle })
	}

	static scrollToTopBySelector(selector: string) {
		const container = document.querySelector(selector)
		container?.scrollIntoView()
	}

	static scrollToTopInstant() {
		window.scrollTo({ top: 0, behavior: 'instant' as 'auto' })
	}

	// This tweak fixes an issue with safari mobile (non-standalone) where scrolling hides browser chrome
	// and messes with the viewport. After route change, the banner would be partially hidden. This
	// prompots browser to refresh after route changes. Not sure why it needs to be 1 pixel off the top
	// but scrolling to 0 does NOT work.
	static scrollToTopViewportAdjust(delay: number = 250, behavior: 'smooth' | 'auto' | 'instant' = 'auto') {
		setTimeout(() => {
			window.scrollTo({ top: 1, behavior: behavior as 'auto' })
			window.requestAnimationFrame(() => {
				window.scrollTo({ top: 1, behavior: behavior as 'auto' })
			})
		}, delay)
	}

	// Do not prepend the # symbol for an id when passing into this method
	static scrollIntoViewById(id: string, position: ScrollLogicalPosition = 'center', delay = 250) {
		setTimeout(() => {
			const container = document.getElementById(id)
			container?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}, delay)
	}

	// May get errors if the selector isn't escaped
	static scrollIntoViewBySelector(selector: string, position: ScrollLogicalPosition = 'center', delay = 250) {
		// Try using escapedSelector in document.querySelector(escapedSelector) call
		// const escapedSelector = selector.replace(/^(\d)/, "\\3$1 ")
		setTimeout(() => {
			const container = document.querySelector(selector)
			container?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}, delay)
	}

	static changeWheelSpeed(container, speedY) {
		var scrollY = 0
		var handleScrollReset = function () {
			scrollY = container.scrollTop
		}
		var handleMouseWheel = function (e) {
			e.preventDefault()
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0
			} else {
				var limitY = container.scrollHeight - container.clientHeight
				if (scrollY > limitY) {
					scrollY = limitY
				}
			}
			container.scrollTop = scrollY
		}

		var removed = false
		container.addEventListener('mouseup', handleScrollReset, false)
		container.addEventListener('mousedown', handleScrollReset, false)
		container.addEventListener('mousewheel', handleMouseWheel, false)

		return function () {
			if (removed) {
				return
			}
			container.removeEventListener('mouseup', handleScrollReset, false)
			container.removeEventListener('mousedown', handleScrollReset, false)
			container.removeEventListener('mousewheel', handleMouseWheel, false)
			removed = true
		}
	}

	static setupDefaultTableEvents(table: any) {
		// Add page recalc
		table.on('page', () => {
			log('Recalculating table layout')
			table.columns.adjust().responsive.recalc()
			table['fixedHeader'].adjust()
		})

		// Add response restoration of tooltips
		table.on('responsive-display', (e, datatable, row, showHide, update) => {
			Global.coreSrvc.displaySrvc.enableAllTooltips()
		})
	}

	static setupTableFilterHighlighter(tableName: string, table: any) {
		// return
		const tableElem = $(`#${tableName}`)
		tableElem?.on('search.dt', () => {
			if (table?.search()) {
				// $('.dataTables_filter input').css({ color: 'white', background: '#59b759' })
				$('.dataTables_filter input').css({ color: 'white', background: 'chocolate' })
			} else {
				$('.dataTables_filter input').css({ color: 'black', background: 'white' })
			}
		})
	}

	static areToastMessagesOnScreen(): boolean {
		return $('p-toastitem').length > 0
	}

	static isUpdateToastMessageOnScreen(): boolean {
		const updateMessage = 'An update is available'
		let found = false

		$('.p-toast-detail').each((index, element) => {
			if ($(element).text().includes(updateMessage)) {
				found = true
				return false // Exit the .each loop early
			}
		})

		return found
	}

	static getAccountStatusAlertInfo(status: string): any {
		switch (status) {
			case 'BILLING_OK':
				return { label: 'Billing OK', alert: 'SUCCESS', color: 'green' }
			case 'PREPAID':
				return { label: 'Prepaid', alert: 'SUCCESS', color: 'slategray' }
			case 'INVOICE':
				return { label: 'Invoice', alert: 'SUCCESS', color: 'slategray' }
			case 'ADP_INVOICE':
				return { label: 'Invoice (ADP)', alert: 'SUCCESS', color: 'slategray' }

			case 'TRIAL':
				return { label: 'Trial', alert: 'WARNING', color: '#664d02' }
			case 'TESTING':
				return { label: 'Testing', alert: 'WARNING', color: '#664d02' }
			case 'BILLING_NEED_INFO':
				return { label: 'Information Needed', alert: 'WARNING', color: '#664d02' }

			case 'BILLING_PAYMENT_FAILED':
				return { label: 'Payment Failed', alert: 'DANGER', color: 'firebrick' }
			case 'BILLING_PORTAL_LOCKED':
				return { label: 'Admin Portal Locked', alert: 'DANGER', color: 'firebrick' }
			case 'BILLING_ACCOUNT_LOCKED':
				return { label: 'Account Locked', alert: 'DANGER', color: 'firebrick' }
			case 'LOCKED_SUSPENDED':
				return { label: 'Locked / Suspended', alert: 'DANGER', color: 'firebrick' }

			case 'CANCEL_PENDING':
				return { label: 'Cancel Pending', alert: 'DANGER', color: 'firebrick' }
			case 'DELETED':
				return { label: 'Deleted', alert: 'DANGER', color: 'firebrick' }

			default:
				return { label: 'Unknown', alert: 'GRAY', color: 'slategray' }
		}
	}

	static replaceUnderscoreAndCapitalize(str: string): string {
		return str
			.replace(/_/g, ' ')
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}

	static capitalizeEachWord(input: string): string {
		if (typeof input !== 'string' || input.length === 0) {
			return ''
		}
		return input
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}

	static capitalizeSingleWord(input: string): string {
		if (!input) return ''
		const lc = input.toLowerCase()
		return lc.charAt(0).toUpperCase() + lc.slice(1)
	}

	static pluralize(word: string, count: number, includeCount: boolean): string {
		const pluralized = count === 1 ? word : word + 's'
		return includeCount ? `${count} ${pluralized}` : pluralized
	}

	static padNumber(num: number, width: number): string {
		const numStr = String(num) // convert number to string
		const paddedNumStr = numStr.padStart(width, '0') // pad string with "0" characters to the left until it reaches the desired width
		return paddedNumStr
	}

	static truncateStringWithElide(str: string, maxLength: number, elide: string = '...'): string {
		if (str.length <= maxLength) {
			return str
		}

		const elideLength = elide.length
		const truncatedLength = maxLength - elideLength

		const beginning = str.slice(0, Math.ceil(truncatedLength / 2))
		const end = str.slice(str.length - Math.floor(truncatedLength / 2))

		return `${beginning}${elide}${end}`
	}

	static textAreaResizer(elm: HTMLElement) {
		elm.style.height = 'auto'
		elm.style.height = elm.scrollHeight + 'px'
	}

	static updateDynamicTextAreas(delay: number) {
		setTimeout(() => {
			$('.options-textarea-dynamic').each((idx, elm) => {
				DisplayHelper.textAreaResizer(elm as HTMLElement)
			})
		}, delay)
	}

	static setBodyBackgroundForAuthFlow() {
		if (window.innerWidth < 1023) {
			$('body').css('background-color', '#efefef')
		} else {
			$('body').css('background-color', '#ffffff')
		}
	}

	static progressBreakpointColor(score: number): string {
		if (score > 75) return '#7ea763'
		if (score > 50 && score < 76) return '#bdb050'
		if (score < 51) return '#ba696f'
		return ' #7ea763'
	}

	static interpolateColor(score: number, startHex: string, endHex: string, hSkew: number = 0): string {
		const percent = (100 - score) / 100

		// Convert hex to HSL (inline)
		const hexToHsl = (hex: string) => {
			let r = 0,
				g = 0,
				b = 0
			if (hex.length == 4) {
				r = parseInt(hex[1] + hex[1], 16)
				g = parseInt(hex[2] + hex[2], 16)
				b = parseInt(hex[3] + hex[3], 16)
			} else if (hex.length == 7) {
				r = parseInt(hex[1] + hex[2], 16)
				g = parseInt(hex[3] + hex[4], 16)
				b = parseInt(hex[5] + hex[6], 16)
			}
			;(r /= 255), (g /= 255), (b /= 255)
			const max = Math.max(r, g, b),
				min = Math.min(r, g, b)
			let h = 0,
				s = 0,
				l = (max + min) / 2

			if (max !== min) {
				const d = max - min
				s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
				switch (max) {
					case r:
						h = (g - b) / d + (g < b ? 6 : 0)
						break
					case g:
						h = (b - r) / d + 2
						break
					case b:
						h = (r - g) / d + 4
						break
				}
				h /= 6
			}

			return {
				h: Math.round(h * 360),
				s: Math.round(s * 100),
				l: Math.round(l * 100),
			}
		}

		// Get the HSL values of both colors
		const startHsl = hexToHsl(startHex)
		const endHsl = hexToHsl(endHex)

		// Interpolate the hue between the start and end colors
		const hue = startHsl.h + (endHsl.h - startHsl.h) * percent + hSkew
		const saturation = startHsl.s // Keep saturation fixed
		const lightness = startHsl.l // Keep lightness fixed

		return `hsl(${hue},${saturation}%,${lightness}%)`
	}
}
