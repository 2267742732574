<div [id]="tagId" class="card" [class.card-expanded]="item.isExpanded" [class.card-highlighted]="highlightCard">
	<div style="line-height: 1.5em">
		<!-- File Name -->
		<div class="sfli-header tts-hl-2" (click)="item.isExpanded = !item.isExpanded" (click)="setupImage()">
			<div class="sfli-expand-icon float-right" style="margin-left: 12px">
				<i class="far" [class.fa-chevron-right]="!item.isExpanded" [class.fa-chevron-down]="item.isExpanded"></i>
			</div>
			<div class="sfli-name" [class.sfli-name-trunc]="!item.isExpanded"><i class="far fa-file sfli-icon"></i>{{ item.record.file_name }}</div>
		</div>
	</div>

	<!-- Notes -->
	<div *ngIf="item.record.notes && item.isExpanded" class="sfli-notes">{{ item.record.notes }}</div>

	<!-- Metadata -->
	<div class="sfli-metadata" style="margin-top: 12px">
		<div class="float-right">{{ item.created }}</div>
		<div class="sfli-label">Uploaded</div>
	</div>
	<div *ngIf="item.notifyOnDate" class="sfli-metadata">
		<div class="float-right">{{ item.notifyOnDate }}</div>
		<div class="sfli-label">Notify On</div>
	</div>
	<div *ngIf="item.lastNotifiedDate" class="sfli-metadata">
		<div class="float-right">{{ item.lastNotifiedDate }}</div>
		<div class="sfli-label">Last Notified</div>
	</div>
	<div *ngIf="item.record.expiration" class="sfli-metadata" [class.sfli-expired]="item.isExpired">
		<div class="float-right">{{ item.expiration }}</div>
		<div class="sfli-label">{{ item.isExpired ? 'Expired On' : 'Expires On' }}</div>
	</div>

	<div *ngIf="item.isExpanded" style="margin-top: 12px">
		<!-- Action Icons -->
		<div>
			<div class="icon-box sfli-action-icons float-right">
				<div class="icon-wrap">
					<!-- View Button -->
					<i *ngIf="canViewItem(item)" title="View File" class="far fa-eye sfli-act-icon act-ico-clr-blue" (click)="viewItem.next(item)"></i>
				</div>
				<div class="icon-wrap">
					<!-- Download Button -->
					<i
						*ngIf="!item.isDownloading"
						title="Download File"
						class="far fa-download sfli-act-icon act-ico-clr-blue"
						(click)="downloadBtnClicked(item)"
					></i>
					<i *ngIf="item.isDownloading" title="Download File" class="fa fa-loader fa-spin sfli-act-icon act-ico-clr-blue"></i>
				</div>
				<div class="icon-wrap">
					<!-- Edit Button -->
					<i title="Edit Info" class="far fa-edit sfli-act-icon act-ico-clr-green" (click)="editItem.next(item)"></i>
				</div>
				<div class="icon-wrap">
					<!-- Delete Button -->
					<i title="Delete File" class="far fa-trash-alt sfli-act-icon act-ico-clr-red" (click)="item.isConfirmingDelete = true"></i>
				</div>
				<div class="icon-wrap">
					<i
						class="far fa-database action-i action-i-audit"
						[class.action-i-orange]="item.record.updated"
						title="Audit Record"
						(click)="auditRecord.next(item)"
					></i>
				</div>
			</div>
			<div class="sfli-file-extension" style="height: 32px">
				<span>{{ item.extension }}</span>
				<span class="sfli-file-size">({{ item.fileSize }})</span>
			</div>

			<div *ngIf="!item.isConfirmingDelete && hasLinkLoadBeenAttempted" class="linked-item-wrapper">
				<!-- Credentialed File Preview - Image loads fine -->
				<div *ngIf="!linkedItem.hasImageError" [hidden]="!linkedItem.isReady" class="card-image-wrap" (click)="showLinkedItem(linkedItem)">
					<img
						[src]="linkedItem.url"
						class="card-img"
						alt="Profile Picture"
						(error)="handleImageLoadError(linkedItem)"
						(load)="linkedItem.isReady = true"
					/>
					<div class="image-label">{{ linkedItem.sfli.record.file_name }}</div>
				</div>
				<!-- Credentialed File Preview - Image load encounters error-->
				<div *ngIf="linkedItem.hasImageError">
					<div class="card-image-wrap" style="color: gray" (click)="showLinkedItem(linkedItem)">
						<div class="image-error">
							<i class="fa-duotone fa-solid fa-image-slash error-icon"></i>
							<div>Non-image {{ linkedItem.sfli.extension ? linkedItem.sfli.extension : '' }} file</div>
						</div>
						<div class="image-label">{{ linkedItem.sfli.record.file_name }}</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="item.isConfirmingDelete" class="delete-confirm-block">
			<hr class="delete-confirm-hr" />
			<div class="float-right">
				<button class="btn btn-sm btn-outline-secondary delete-confirm-left-btn" (click)="item.isConfirmingDelete = false">Cancel</button>
				<button class="btn btn-sm btn-outline-danger delete-confirm-right-btn" (click)="confirmDelete(item)">
					<span *ngIf="!item.isDeleting">Delete</span>
					<span *ngIf="item.isDeleting"><i class="fa fa-loader fa-spin"></i></span>
				</button>
			</div>
			<div class="delete-confirm-label">Confirm Delete</div>
		</div>
	</div>
</div>
