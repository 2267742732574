import { DateTimeHelper } from '@app/helpers/datetime'
import { EditFormAction } from './form'
import { ScheduleLogRecord } from './schedule-log'
import moment from 'moment-timezone'

export type OpenShiftOfferStatusType = 'SUBMITTED' | 'OPEN' | 'OPEN_NEEDS_APPROVAL' | 'FILLED_APPROVED' | 'FILLED_AUTO'

export class OpenShiftOfferRecord {
	id: number
	uuid: string
	schedule_recur_id: number
	schedule_log_id: number
	company_id: number

	job_date: string
	description: string
	details: string
	employee_details: string
	submitted_by: number
	employee_ids: Array<number> = []
	auto_fill: boolean = true
	status: OpenShiftOfferStatusType

	created: string
	updated: string

	filter_json: string // OpenShiftOfferFilter
	schedule_os_offer_entry: Array<OpenShiftOfferEntry> = []

	constructor(record?: OpenShiftOfferRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
		}
	}
}

export class OpenShiftOfferEditConfig {
	action: EditFormAction
	offer: OpenShiftOfferRecord
	schedLog: ScheduleLogRecord
}

export class OpenShiftOfferFilter {
	activeStatus: 'ACTIVE' | 'INACTIVE' | null = 'ACTIVE'
	employeeTags: Array<string> = []
	departments: Array<string> = []
	locDistance: number = null
	locDistanceUnits: 'MI' | 'KM' = 'MI'

	constructor(json?: string) {
		if (json) {
			const jsonFilter = JSON.parse(json)
			for (const attr in jsonFilter) {
				if (jsonFilter.hasOwnProperty(attr)) {
					this[attr] = jsonFilter[attr]
				}
			}
		}
	}

	getDistanceInMeters(): number {
		if (!this.locDistance) return null
		if (this.locDistanceUnits === 'KM') {
			return this.locDistance * 1000
		} else {
			return this.locDistance * 1609.34
		}
	}
}

// BEGIN - SHARED BETWEEN EMP APP / ADMIN PORTAL
export type OpenShiftOfferEntryDerivedStatusType = 'UNREAD' | 'READ' | 'DECLINED' | 'ACCEPTED' | 'PENDING' | 'SCHEDULED'
export type OpenShiftOfferResponseAction = 'ACCEPT' | 'DECLINE' | 'REPLY'

export class OpenShiftOfferEntry {
	id: number
	company_id: number
	schedule_os_offer_id: number
	employee_id: number

	created: string // UTC Date
	updated: string // UTC Date

	scheduled: string // UTC Date
	accepted: string // UTC Date
	declined: string // UTC Date
	read: string // UTC Date

	notes: string
	audit_json: Array<OpenShiftOfferAuditInfoItem> = []

	constructor(record?: OpenShiftOfferEntry) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			const currentAuditJson = this.audit_json ?? []
			this.audit_json = currentAuditJson.map((item) => new OpenShiftOfferAuditInfoItem(item))
		}
	}

	public getDerivedStatus(): OpenShiftOfferEntryDerivedStatusType {
		if (this.scheduled) return 'SCHEDULED'
		if (this.accepted) return 'ACCEPTED'
		if (this.declined) return 'DECLINED'
		if (this.read) return 'READ'
		return 'UNREAD'
	}
}

export class OpenShiftOfferAuditInfoItem {
	ts: string
	notes: string = null
	action: OpenShiftOfferResponseAction
	constructor(data?: OpenShiftOfferAuditInfoItem) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
	get formattedDate(): string {
		return DateTimeHelper.format12Hour ? moment(this.ts).format('MMM Do @ h:mm a') : moment(this.ts).format('MMM Do @ HH:mm')
	}
}
// END - SHARED BETWEEN EMP APP / ADMIN PORTAL
