import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { DataAccessRequest, OnboardLogRecord, SecureFileListItem } from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-onboard-log-edit-image-list',
	standalone: false,

	templateUrl: './onboard-log-edit-image-list.component.html',
	styleUrl: './onboard-log-edit-image-list.component.scss',
})
export class OnboardLogEditImageListComponent implements OnInit {
	secureFileList: Array<SecureFileListItem> = []
	linkedItemList: Array<LinkedImageWrapper> = []

	@Input() record: OnboardLogRecord
	@Output() highlightIds = new EventEmitter<Array<number>>()

	constructor(private coreSrvc: CoreService) {}

	ngOnInit(): void {
		const onboardLogId = this.record.id
		const options = { onboard_log_id: onboardLogId }
		this.coreSrvc.dbSrvc.readTable('file_uploads', options).then((success) => {
			this.secureFileList = this.coreSrvc.dbSrvc.secFileSrvc.getSecureFileList().map((rec) => new SecureFileListItem(rec))
			this.setupImages()
		})
	}

	private setupImages() {
		// When no more files to process, emit the list of file IDs so parent can pass them to the secure files list
		// component in order to highlight the files linked to this onboard log request
		if (this.secureFileList.length === 0) {
			const fileIds = this.linkedItemList.map((li) => li.sfli.record.id)
			this.highlightIds.emit(fileIds)
			return
		}
		const file = this.secureFileList.shift()
		this.getCredentialedLinkForItem(file).then((url) => {
			const linkedImage = new LinkedImageWrapper(file, url)
			this.linkedItemList.push(linkedImage)
			this.setupImages()
		})
	}

	private getCredentialedLinkForItem(item: SecureFileListItem): Promise<string> {
		return new Promise((resolve, reject) => {
			// const options = { id: item.record.id } // DEPRECATED 2025-02-01 - Switch to uuid
			const options = { uuid: item.record.uuid }
			const request = new DataAccessRequest('none', 'file_uploads_url', options)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const url = result?.data?.url as string
				resolve(url)
			})
		})
	}

	public showLinkedItem(item: LinkedImageWrapper) {
		const sfli = item.sfli
		const opener = window.open()
		this.getCredentialedLinkForItem(sfli).then((url) => {
			opener.location.assign(url)
		})
	}

	public handleImageLoadError(li: LinkedImageWrapper) {
		li.hasImageError = true
	}
}

class LinkedImageWrapper {
	sfli: SecureFileListItem
	url: string

	isReady = false
	hasImageError = false

	constructor(record: SecureFileListItem, url: string) {
		this.sfli = record
		this.url = url
	}
}
