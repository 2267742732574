import { DateTimeHelper } from '@app/helpers/datetime'
import { log } from '@app/helpers/logger'

export class AdpRateCodeSelectItem {
	label: string
	value: number
	data: AdpRateCodeRecord
}

export class AdpDepartmentCodeRecord {
	id: number
	company_id: number
	codeValue: string
	shortName: string
	created: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class AdpEarningsCodeRecord {
	id: number
	company_id: number
	codeValue: string
	shortName: string
	created: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class AdpPayrollGroupCodeRecord {
	id: number
	company_id: number
	codeValue: string
	shortName: string
	created: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class AdpRateCodeRecord {
	id: number = null
	company_id: number = null
	codeValue: string = null
	shortName: string = null
	created: string = null

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class AdpPayCycle {
	code: string // "BIWEEKLY"
	codeValue: string // "B"
	openCalendarCheckDate: string // "2021-01-26"
	openCalendarEndDate: string // "2021-01-24"
	openCalendarStartDate: string // "2021-01-11"
	shortName: string // "Biweekly"

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}
}

export class AdpSyncRecord {
	id: number
	company_id: number
	employee_sync: string // date string
	transaction_log_sync: string // date string
	transaction_log_lock: string // date string
	created: string // date string
	employee_sync_errors: number
	transaction_log_sync_errors = 0
	updated: string // date string
	o2_client_id: string
	o2_client_secret: string
	organization_oid: string
	payroll_reg: number
	payroll_ot: number
	payroll_hol: number
	payroll_othol: number

	payroll_period: string
	payroll_start_date: string
	payroll_group_code: string

	pto_personal: number
	pto_sick: number
	pto_holiday: number
	pto_bereavement: number

	product: string = ''

	emp_sync_delay: string = 'PT8H'

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.employee_sync = DateTimeHelper.stripUtcTag(this.employee_sync)
		this.transaction_log_sync = DateTimeHelper.stripUtcTag(this.transaction_log_sync)
		this.transaction_log_lock = DateTimeHelper.stripUtcTag(this.transaction_log_lock)
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
	}

	isWfnIntegrated(): boolean {
		return this.product && this.product.startsWith('WFN') ? true : false
	}
}

export class AdpWfnGroupCode {
	id: number
	description: string
	code: string
	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

export class AdpIvrPhoneNumberRecord {
	id: number
	company_id: number
	external_id: string
	description: string
	created: string
	updated: string
	phone_e164: string
	ivr_profile: number

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}

export interface IAdpLaborLevelTransferListItem {
	name: string
	profileId: number
	promptOrder: number
}

export class AdpIvrProfileRecord {
	id: number
	company_id: number
	external_id: string
	description: string
	created: string
	updated: string
	allow_clocking: boolean
	allow_multiple_clocking: boolean
	allow_labor_level_transfers: boolean
	allow_work_rule_transfers: boolean
	allow_pay_code_transactions: boolean
	enable_call_tracking: boolean
	enable_phone_restrictions: boolean
	labor_level_transfer_list: Array<IAdpLaborLevelTransferListItem> = []
	work_rule_transfer_list: Array<any> = []
	pay_code_transaction_list: Array<any> = []

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}

export class AdpIvrPromptCodeRecord {
	id: number
	company_id: number
	external_id: string
	ivr_profile: number

	created: string // ISO Date
	updated: string // ISO Date

	description: string
	code_type: 'BUSINESS_STRUCTURE' | 'WORK_RULE' | 'PAY_CODE'
	code_value: string
	prompt_order: number

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	get codeTypeLable(): string {
		switch (this.code_type) {
			case 'BUSINESS_STRUCTURE':
				return 'Business Structure'
			case 'WORK_RULE':
				return 'Work Rule'
			case 'PAY_CODE':
				return 'Pay Code'
			default:
				return 'Unknown'
		}
	}
}

export class AdpIvrCompanyRecord {
	id: number
	company_id: number
	created: string // ISO Date
	updated: string // ISO Date

	labor_level_prompt: string
	work_rule_prompt: string
	pay_code_prompt: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}

export type AdpIvrApiOperationType = 'PUNCH' | 'LABOR_LEVEL' | 'WORK_RULE' | 'PAY_CODE'

export class AdpIvrApiLogRecord {
	id: number
	company_id: number
	employee_id: number

	created: string // ISO Date
	updated: string // ISO Date

	uuid: string
	operation: AdpIvrApiOperationType
	request_json: string
	employee_adp_oid: string
	adp_request_json: string
	adp_response_json: string
	ivr_call_id: string
	response_json: string
	response_status: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	get operationLabel(): string {
		switch (this.operation) {
			case 'PUNCH':
				return 'Punch'
			case 'LABOR_LEVEL':
				return 'Labor Level'
			case 'WORK_RULE':
				return 'Work Rule'
			case 'PAY_CODE':
				return 'Pay Code'
			default:
				return 'Unknown'
		}
	}
}

export class AdpIvrApiLogViewManager {
	localStorageKey = 'AdpIvrApiLogViewManager'
	version = 1

	dayViewDate: Date
	timezone: string
	isProcessing = false

	constructor() {
		this.restore()
	}

	private restore() {
		try {
			const record = JSON.parse(localStorage.getItem(this.localStorageKey))
			if (record) {
				for (const attr in record) {
					if (record.hasOwnProperty(attr)) {
						this[attr] = record[attr]
					}
				}
				const dayViewDateStr = record['dayViewDate']
				this.dayViewDate = dayViewDateStr ? new Date(dayViewDateStr) : null
			}
		} catch (err) {
			log(`Error restoring ${this.localStorageKey}`, err)
		}
	}

	public save() {
		const saveData = {
			version: this.version,
			localStorageKey: this.localStorageKey,
			dayViewDate: this.dayViewDate,
			timezone: this.timezone,
		}
		localStorage.setItem(this.localStorageKey, JSON.stringify(saveData))
	}
}
