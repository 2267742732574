import { Component, Input, OnInit } from '@angular/core'
import { AdpIvrApiLogRecord, DialogManager } from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-adp-ivr-api-log-details',
	standalone: false,

	templateUrl: './adp-ivr-api-log-details.component.html',
	styleUrl: './adp-ivr-api-log-details.component.scss',
})
export class AdpIvrApiLogDetailsComponent implements OnInit {
	@Input() dialogManager: DialogManager
	@Input() recordId: number

	isLoading = true

	record: AdpIvrApiLogRecord
	request: any = null
	response: any = null
	adpRequest: any = null
	adpResponse: any = null

	constructor(private coreSrvc: CoreService) {}

	ngOnInit(): void {
		this.coreSrvc.dbSrvc.readRecord('adp_ivr_api_log', this.recordId).then(() => {
			this.record = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrApiLogRecordForId(this.recordId)

			this.request = this.record.request_json ? JSON.parse(this.record.request_json) : null
			this.response = this.record.response_json ? JSON.parse(this.record.response_json) : null
			this.adpRequest = this.record.adp_request_json ? JSON.parse(this.record.adp_request_json) : null
			this.adpResponse = this.record.adp_response_json ? JSON.parse(this.record.adp_response_json) : null

			this.isLoading = false
		})
	}
}
