<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating || isManagingFiles">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<div class="alert alert-bg-gray" style="margin-bottom: 25px">
			<div class="def-description">{{ record.description }}</div>
			<div class="pre-wrap">{{ empInstructions }}</div>
		</div>

		<div *ngIf="record.skipped" class="skip-banner">Employee skipped this request</div>
		<div *ngIf="record.approval_state === 'EXPIRED'" class="expired-banner">This request has expired</div>

		<div class="options-block" [class.input-required]="expirationRequired" style="margin-bottom: 30px">
			<span *ngIf="expirationRequired" class="float-right">(required)</span>
			<div class="options-title-inline">Expiration Date</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('expiration')"></i>

			<app-date-time-picker
				[useInlineClearIcon]="true"
				[currentDate]="form.get('expiration').value"
				[pickerLabel]="'Expiration Date'"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Start Date'"
				[format]="'ddd MMM Do, YYYY'"
				[useInlineClearIcon]="true"
				(pickerDidClose)="form.get('expiration').setValue($event)"
				(pickerDidChange)="form.get('expiration').setValue($event)"
			>
			</app-date-time-picker>
		</div>

		<app-approval-state
			[selectedState]="form.get('approval_state').value"
			(selectedStateChange)="form.get('approval_state').setValue($event)"
			[managedByName]="managedByName"
			[lastManagedDate]="lastManagedDate"
		></app-approval-state>

		<div *ngIf="record.skipped" class="options-block">
			<div class="options-title-inline">Employee Notes</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employee_notes')"></i>
			<div class="input-disabled" style="white-space: pre-wrap">{{ record.employee_notes || 'No notes submitted by employee' }}</div>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Admin Notes</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('admin_notes')"></i>
			<textarea
				appTextAreaResizer
				type="text"
				class="options-textarea options-textarea-resizer"
				formControlName="admin_notes"
				autocomplete="off"
			></textarea>
		</div>
	</form>
</div>

<div *ngIf="isManagingFiles">
	<app-secure-files-list [dialogManager]="dialogManager" [highlightIds]="highlightIds"></app-secure-files-list>
</div>

<div *ngIf="!isManagingFiles && !isUpdating" style="margin-top: 30px">
	<app-onboard-log-edit-image-list [record]="record" (highlightIds)="highlightIds = $event"></app-onboard-log-edit-image-list>
	<button class="btn btn-block btn-outline-secondary" (click)="startManagingFiles()">Manage Employee Files</button>
</div>

<!-- <div class="pre-code">{{ form.value | json }}</div> -->
