import { Component } from '@angular/core'
import { DateTimeHelper } from '@app/helpers'
import { TrackCodeLabel } from '@app/models/track'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-decode-emp-app-errors',
	standalone: false,

	templateUrl: './decode-emp-app-errors.component.html',
	styleUrl: './decode-emp-app-errors.component.scss',
})
export class DecodeEmpAppErrorsComponent {
	constructor(private coreSrvc: CoreService) {}

	empAppErrorInput = ''
	empAppErrorResults: Array<any> = []

	public decodeEmpAppResults() {
		const input = this.empAppErrorInput
		const regex = /[A-Za-z0-9+/]{100,}/g

		const encodedTextBlocks = input.match(regex)

		const decodedBlocks = encodedTextBlocks.map((etb) => atob(etb)).map((dtb) => JSON.parse(dtb))
		const tcLabel = new TrackCodeLabel()
		decodedBlocks.forEach((block) => {
			const currentArray = block.track ? [...block.track] : []
			if (currentArray)
				block.track = currentArray.map((data) => {
					const comps = data.split(':')
					const dur = comps[1]
					const key = comps[0]
					const name = tcLabel.lookup(key)
					return `${name} (${dur} sec)`
				})
		})
		this.empAppErrorResults = decodedBlocks
	}

	public showEmpAppResultMap(result: any) {
		const gps = `${result.gps}`

		if (gps.includes(',')) {
			// Open a new tab with the Google search page for the specified query
			const mapsLink = `https://www.google.com/maps?q=${gps}`
			window.open(mapsLink, '_blank')
		} else {
			this.coreSrvc.notifySrvc.notify('info', 'No GPS', 'No GPS data available to map.')
		}
	}

	public formatTimeFromErrorResult(result: any) {
		const date = result.lastRoute.date
		return date ? DateTimeHelper.standardDateTimeFromDateString(date) : ''
	}
}
