import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core'
import { log } from '@app/helpers'
import {
	DashboardEditRecordUpdateEvent,
	DialogManager,
	EditFormAction,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	VisualizationViewTabState,
	VizCardCounterDataSource,
	VizCardGraphDataSource,
	VizDashboard,
	VizDashboardConfirmAction,
	VizDashboardDataSourceRow,
} from '@app/models'
import { CoreService } from '@app/services'
import { VisualizationService } from '@app/services/backend/visualization.service'
import { FormActionType } from 'aws-sdk/clients/amplifyuibuilder'
import { DeviceDetectorService } from 'ngx-device-detector'
import { ContextMenu } from 'primeng/contextmenu'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-viz-section-header',
	templateUrl: './viz-section-header.component.html',
	styleUrl: './viz-section-header.component.scss',
	standalone: false,
})
export class VizSectionHeaderComponent implements OnDestroy {
	@Output() toggleHelp: EventEmitter<boolean> = new EventEmitter()

	switcherConfig = new SectionSwitcherConfig()

	myUserId = null
	editPermissionTooltip = 'Edit permission status'

	shouldShowInternalHighlight = false

	actionDialog = { show: false, title: '', action: '', create: { type: 'COUNTER' } }
	actionListContextMenu: any = [
		{
			label: 'Add',
			icon: 'far fa-plus',
			items: [
				{
					label: 'Dashboard',
					icon: 'far fa-table-columns',
					command: (event) => this.confirmAction('ADD_DASHBOARD'),
				},
				{
					label: 'Data Source Row',
					icon: 'far fa-database',
					command: (event) => this.confirmAction('ADD_DATASOURCE'),
				},
			],
		},
		{
			separator: true,
			// visible: () => this.coreSrvc.devDetect.isDesktop(),
		},
		{
			label: 'Edit',
			icon: 'far fa-pencil',
			command: (event) => this.handleEditOrCloneDashboard('edit'),
		},
		{
			label: 'Rename',
			icon: 'far fa-pencil',
			command: (event) => this.confirmAction('RENAME_DASHBOARD'),
		},
		{
			label: 'Clone',
			icon: 'far fa-clone',
			command: (event) => this.handleEditOrCloneDashboard('clone'),
		},
		{
			separator: true,
			// visible: () => this.coreSrvc.devDetect.isDesktop(),
		},
		{
			label: 'Save',
			icon: 'far fa-upload',
			command: (event) => this.confirmAction('SAVE_DASHBOARD'),
			disabled: () => !this.hasDashboardBeenModified,
			// visible: () => this.devDetect.isDesktop(),
		},

		{
			label: 'Restore',
			icon: 'far fa-undo',
			command: (event) => this.confirmAction('RESTORE_DASHBOARD'),
			disabled: () => !this.hasDashboardBeenModified,
			// visible: () => this.devDetect.isDesktop(),
		},
		{
			separator: true,
		},
		{
			label: 'Reset',
			icon: 'far fa-power-off',
			command: (event) => this.confirmAction('RESET_DASHBOARD'),
			// visible: () => this.devDetect.isDesktop(),
		},
		{
			label: 'Delete',
			icon: 'far fa-trash-alt',
			command: (event) => this.confirmAction('DELETE_DASHBOARD'),
		},
		{
			separator: true,
			visible: () => !this.devDetect.isDesktop(),
		},
		{
			label: 'Close Menu',
			icon: 'far fa-circle-x',
			command: (event) => this.alCm.hide(),
			visible: () => !this.devDetect.isDesktop(),
		},
	]

	editDashboardAction = { action: 'edit' as EditFormAction, record: null }
	editDashboardDialogManager = new DialogManager('ConfigureDashboardDialog')

	@ViewChild('alCm') alCm!: ContextMenu

	subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		// Setup tab switcher
		this.setupTabSwitcher()

		// Setup owneership tooltip
		this.myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		this.updateDashboardOwnerTooltip()

		// Setup internal highlight indicator
		const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		this.shouldShowInternalHighlight = isInternalUser && prefs?.globalEnableAnalytics
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}
	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	get dashboards(): Array<VizDashboard> {
		return this.coreSrvc.dbSrvc.vizSrvc.dashboards
	}
	get currentDashboard(): VizDashboard {
		return this.coreSrvc.dbSrvc.vizSrvc.currentDashboard
	}
	get hasDashboardBeenModified(): boolean {
		return this.vizSrvc.hasCurrentDashboardBeenModified
	}

	public openContextMenu(event: MouseEvent, cm: any) {
		cm.show(event)
		event.preventDefault() // Prevent default to avoid actual context menu on right click
	}

	////////////////
	// NAVIGATION //
	////////////////

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const healthTab = new SectionSwitcherTab('HEALTH_CENTER', 'Health Center')
		const dashboardsTab = new SectionSwitcherTab('DASHBOARDS', 'Dashboards')
		const config = new SectionSwitcherConfig([healthTab, dashboardsTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.vizSrvc.viewManager.currentView)
		this.subs.add(
			this.vizSrvc.tabStateDidChange.subscribe((state: VisualizationViewTabState) => {
				this.switcherConfig.setTab(state)
				this.vizSrvc.viewManager.save()
			}),
		)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		this.vizSrvc.setCurrentTab(tab.id as VisualizationViewTabState)
	}

	public handleDashboardDropdownChange(dashboard: VizDashboard) {
		this.vizSrvc.setCurrentDashboardIndexToDashboardId(dashboard.record.id)
		this.vizSrvc.saveCurrentDashboardIdAndIndex()
		this.updateDashboardOwnerTooltip()
	}

	public updateDashboardOwnerTooltip() {
		this.editPermissionTooltip = this.isMyDashboard(this.currentDashboard) ? 'Edit permission granted' : 'Edit permission denied'
	}

	public previousDashboard() {
		const idx = this.dashboards.indexOf(this.currentDashboard)
		if (idx > 0) {
			this.vizSrvc.currentDashboardIndex = idx - 1
		} else {
			this.vizSrvc.currentDashboardIndex = this.dashboards.length - 1
		}
		this.vizSrvc.saveCurrentDashboardIdAndIndex()
	}

	public nextDashboard() {
		const idx = this.dashboards.indexOf(this.currentDashboard)
		if (idx < this.dashboards.length - 1) {
			this.vizSrvc.currentDashboardIndex = idx + 1
		} else {
			this.vizSrvc.currentDashboardIndex = 0
		}
		this.vizSrvc.saveCurrentDashboardIdAndIndex()
	}

	////////////////////
	// MANAGE ACTIONS //
	////////////////////

	public isMyDashboard(dashboard: VizDashboard): boolean {
		return dashboard.isMyDashboard()
	}

	public isActionPermitted(action: VizDashboardConfirmAction, showAlert: boolean = true) {
		const isActionPermitted = action === 'ADD_DASHBOARD' || action === 'CLONE_DASHBOARD' || action === 'RESTORE_DASHBOARD'
		if (!isActionPermitted) {
			if (!this.isMyDashboard(this.currentDashboard)) {
				if (showAlert)
					this.coreSrvc.notifySrvc.notify(
						'error',
						'Not Allowed',
						'This dashboard is owned by another user. You must first clone the dashboard before you can save changes.',
					)
				return false
			}
		}
		return true
	}

	public confirmAction(action: VizDashboardConfirmAction) {
		// Unless we are adding a new dashboard, check to see we own it.
		if (!this.isActionPermitted(action, true)) return

		if (action === 'ADD_DATASOURCE') {
			this.actionDialog.create.type = 'COUNTER'
		}
		if (action === 'DELETE_DASHBOARD' && this.dashboards.length < 2) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'You must have at least one dashboard.', 3)
			return
		}
		this.actionDialog.title = action === 'ADD_DASHBOARD' ? '' : this.currentDashboard.name
		this.actionDialog.action = action
		this.actionDialog.show = true
	}

	public performAction(action: VizDashboardConfirmAction) {
		switch (action) {
			case 'ADD_DASHBOARD':
				this.handleAddDashboard(action)
				break
			case 'ADD_DATASOURCE':
				this.handleAddDatasource(action)
				break
			case 'RENAME_DASHBOARD':
				this.handleSaveOrRenameDashboard(action)
				break
			case 'SAVE_DASHBOARD':
				this.handleSaveOrRenameDashboard(action)
				break
			case 'DELETE_DASHBOARD':
				this.handleDeleteDashboard(action)
				break
			case 'RESET_DASHBOARD':
				this.handleResetDashboard(action)
				break
			case 'RESTORE_DASHBOARD':
				this.handleReloadOrRestorDashboard(action)
				break
		}
		this.actionDialog.show = false
	}

	/////////////////////
	// ADD DATA SOURCE //
	/////////////////////

	private handleAddDatasource(action: VizDashboardConfirmAction) {
		if (this.actionDialog.create.type === 'COUNTER') {
			// Create the row view model for the card row
			const cards = [
				new VizCardCounterDataSource(),
				new VizCardCounterDataSource(),
				new VizCardCounterDataSource(),
				new VizCardCounterDataSource(),
			]
			const dashboardRow = new VizDashboardDataSourceRow('COUNTER', cards)
			this.currentDashboard.data.unshift(dashboardRow)
		}
		if (this.actionDialog.create.type === 'GRAPH') {
			// Create the row view model for the first graph row
			const graphs = [new VizCardGraphDataSource(), new VizCardGraphDataSource()]
			const dashboardRow = new VizDashboardDataSourceRow('GRAPH', graphs)
			this.currentDashboard.data.unshift(dashboardRow)
		}
		this.vizSrvc.markCurrentDashboardAsModified()
	}

	///////////////////////////////////
	// ADD / SAVE / DELETE DASHBOARD //
	///////////////////////////////////

	private handleAddDashboard(action: VizDashboardConfirmAction) {
		this.vizSrvc.addNewDashboard(this.actionDialog.title)
		const record = this.currentDashboard.buildUpdateRecord()
		this.coreSrvc.dbSrvc.insertRecord('dashboard', record).then((InsertSuccess) => {
			if (InsertSuccess) {
				this.handlePostActionCompleteMessage(action)
				const lastRecord = this.vizSrvc.getMyLastCreatedRecord()
				this.currentDashboard.record.id = lastRecord.id
				log('CURRENT DASHBOARD', this.currentDashboard)
			}
		})
	}

	private handleSaveOrRenameDashboard(action: VizDashboardConfirmAction) {
		this.currentDashboard.name = this.actionDialog.title
		const record = this.currentDashboard.buildUpdateRecord()

		if (record.id) {
			this.coreSrvc.dbSrvc.updateRecord('dashboard', record).then((updateSuccess) => {
				log('Update Successful', updateSuccess)
				if (updateSuccess) {
					this.handlePostActionCompleteMessage(action)
				}
			})
		} else {
			this.coreSrvc.dbSrvc.insertRecord('dashboard', record).then((InsertSuccess) => {
				log('Insert Successful', InsertSuccess)
				// If we're renaming the default unsaved dashboard we need to replace it with the saved version
				if (InsertSuccess) {
					const lastDashboardRecordAdded = this.vizSrvc.getMyLastCreatedRecord()
					this.currentDashboard.record = lastDashboardRecordAdded
					this.vizSrvc.dashboardsNeedUpdate.next(true)

					this.handlePostActionCompleteMessage(action)
				}
			})
		}
		this.vizSrvc.clearCurrentDashboardModificationStatus()
	}

	private handleDeleteDashboard(action: VizDashboardConfirmAction) {
		const recordId = this.currentDashboard.record?.id
		// Get the current dashboard index to delete
		const deleteIndex = this.vizSrvc.currentDashboardIndex
		// Set the current index to the first dashboard in the list
		this.vizSrvc.currentDashboardIndex = 0
		// Delete the dashboard the user was currently viewing and post notification
		this.dashboards.splice(deleteIndex, 1)

		if (recordId) {
			this.coreSrvc.dbSrvc.deleteRecord('dashboard', recordId).then((deleteSuccess) => {
				log('Delete Successful', deleteSuccess)
				if (deleteSuccess) {
					this.handlePostActionCompleteMessage(action)
				}
			})
		} else {
			this.handlePostActionCompleteMessage(action)
		}
	}

	///////////////////////////////
	// RESET / RESTORE DASHBOARD //
	///////////////////////////////

	// This method resets the current dashoard to the default setup
	private handleResetDashboard(action: VizDashboardConfirmAction) {
		this.currentDashboard.setupDefaultDisplayData()
		this.vizSrvc.clearCurrentDashboardModificationStatus()
		this.handlePostActionCompleteMessage(action)
	}

	////////////////////////////
	// EDIT / CLONE DASHBOARD //
	////////////////////////////

	// This method opens the edit dashboard dialog
	private handleEditOrCloneDashboard(formAction: FormActionType) {
		if (formAction === 'edit') {
			// Unless we are adding a new dashboard, check to see we own it.
			if (!this.isActionPermitted('EDIT_DASHBOARD', true)) return

			this.editDashboardDialogManager.headerLabel = 'Dashboard Settings'
			this.editDashboardAction.action = 'edit'
			this.editDashboardAction.record = this.currentDashboard.record
			this.editDashboardDialogManager.isDialogVisible = true
		}

		if (formAction === 'clone') {
			const record = this.currentDashboard.buildUpdateRecord()
			this.editDashboardDialogManager.headerLabel = 'Clone Dashboard'
			this.editDashboardAction.action = 'clone'
			this.editDashboardAction.record = record
			this.editDashboardDialogManager.isDialogVisible = true
		}
	}

	//////////////////////
	// UPDATE DASHBOARD //
	//////////////////////

	// This method handles events from the edit dashboard dialog.
	public handleDashboardRecordUpdated(event: DashboardEditRecordUpdateEvent) {
		if (event.recordId) {
			this.handleReloadOrRestorDashboard('EDIT_DASHBOARD')
		} else {
			if (event.action === 'edit') {
				this.handlePostActionCompleteMessage('EDIT_DASHBOARD')
			}
			if (event.action === 'clone') {
				const lastDashboardAdded = this.vizSrvc.getMyLastCreatedRecord()
				const newDasboard = new VizDashboard(lastDashboardAdded)
				this.dashboards.push(newDasboard)
				this.vizSrvc.setCurrentDashboardIndexToDashboardId(lastDashboardAdded.id)
				this.vizSrvc.dashboardsNeedUpdate.next(true)

				this.handlePostActionCompleteMessage('CLONE_DASHBOARD')
			}
		}
	}

	// Call this method after performing some action in order to read the new record and setup the data
	private handleReloadOrRestorDashboard(action: VizDashboardConfirmAction) {
		const recordId = this.currentDashboard.record?.id
		if (recordId) {
			const currentDashboard = this.dashboards.find((d) => d.record.id === recordId)
			this.coreSrvc.dbSrvc.readRecord('dashboard', recordId).then((result) => {
				const newDashRecord = this.vizSrvc.getDashboardRecords().find((d) => d.id === recordId)
				if (newDashRecord) {
					const newDasboard = new VizDashboard(newDashRecord)
					currentDashboard.name = newDasboard.name
					currentDashboard.data = newDasboard.data
					currentDashboard.record = newDasboard.record
					this.vizSrvc.dashboardsNeedUpdate.next(true)
					this.handlePostActionCompleteMessage(action)
				}
			})
		} else {
			this.currentDashboard.setupDefaultDisplayData()
		}
		this.vizSrvc.clearCurrentDashboardModificationStatus()
	}

	///////////////////////////
	// POST ACTION COMPLETE  //
	///////////////////////////

	private handlePostActionCompleteMessage(action: VizDashboardConfirmAction) {
		switch (action) {
			case 'ADD_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Added', 'A new dashboard has been added.', 2)
				break
			case 'EDIT_DASHBOARD':
			case 'SAVE_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Saved', 'Your dashboard has been saved.', 2)
				break
			case 'CLONE_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Saved', 'Your dashboard has been cloned.', 2)
				break
			case 'RENAME_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Renamed', 'Your dashboard has been renamed.', 2)
				break
			case 'DELETE_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Deleted', 'Your dashboard has been deleted.', 2)
				break
			case 'RESET_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Reset', 'Your dashboard has been reset.', 2)
				this.vizSrvc.markCurrentDashboardAsModified()
				break
			case 'RESTORE_DASHBOARD':
				this.coreSrvc.notifySrvc.notify('info', 'Dashboard Restored', 'Your dashboard has been restored.', 2)
				break
		}
		this.updateDashboardOwnerTooltip()
	}

	///////////////////
	// HEALTH CENTER //
	///////////////////

	public expandAllHealthSections() {
		this.vizSrvc.healthCenterManager.expandAllItems()
	}

	public collapseAllHealthSections() {
		this.vizSrvc.healthCenterManager.collapseAllItems()
	}

	public showDefaultView() {
		this.vizSrvc.healthCenterManager.resetListView()
	}

	public toggleNonContributors() {
		this.vizSrvc.viewManager.contribFilterActive = !this.vizSrvc.viewManager.contribFilterActive
		this.coreSrvc.notifySrvc.clear()
		if (this.vizSrvc.viewManager.contribFilterActive) {
			this.coreSrvc.notifySrvc.notify('info', 'Apply Filter', 'Displaying only metrics which contribute to your score.', 9)
		} else {
			this.coreSrvc.notifySrvc.notify('info', 'Clear Filter', 'Displaying all usage metrics.', 9)
		}
	}
}
