import { Injectable, EventEmitter } from '@angular/core'
import { AllowedCompaniesHelper, BaseRecord, BaseViewManager, CompanySelectorState, IVRCallList } from '@app/models'

import { DisplayHelper, log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	selector = new CompanySelectorState()

	constructor() {
		log('Creating CompanyService')
	}

	get canShowCompanySelector(): boolean {
		const user = Global.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		if (user) {
			const allowedCompanies = user.allowed_companies
			if (allowedCompanies.length > 1 || allowedCompanies.includes(0)) {
				return true
			}
		}
		return false
	}

	clearData() {
		this.selector = new CompanySelectorState()
	}

	setupAllowedCompaniesDropdown() {
		const company = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const isInternalUser = Global.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.role === 'INTERNAL'
		const isAdpIvrAdmin = Global.coreSrvc.dbSrvc.settingSrvc.isAdpIvrAdmin()

		this.selector.currentCompanyId = company.id
		this.selector.selectedCompanyId = company.id

		const allowedCompanies = Global.coreSrvc.dbSrvc.settingSrvc.getAllowedCompanies()
		if (isInternalUser) {
			const company = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()
			IVRCallList.storeCallInInfoFromCompanyList([company])
		} else {
			IVRCallList.storeCallInInfoFromCompanyList(allowedCompanies)
		}
		const companyList = allowedCompanies.map((rec) => ({
			label: `${rec.name} ${isAdpIvrAdmin && rec.external_id ? '[' + rec.external_id + ']' : ''} ${isInternalUser ? '(' + rec.id + ')' : ''}`,
			value: rec.id,
			data: {
				externalId: rec.external_id,
				accountStatus: rec.account_status,
				fullLabel: '',
				colorClass: AllowedCompaniesHelper.getAllowedCompaniesColorClass(rec.account_status),
				iconClass: AllowedCompaniesHelper.getAllowedCompaniesIconClass(rec.account_status),
			},
		}))
		const elideWidth = this.getAllowedCompaniesDropdownElideWidth()
		companyList.forEach((item) => {
			const currentLabel = item.label
			item.data.fullLabel = currentLabel

			if (isAdpIvrAdmin) {
			} else {
				item.label = DisplayHelper.truncateStringWithElide(currentLabel, elideWidth, '...')
			}

			switch (item.data.accountStatus) {
				case 'TESTING':
			}
		})
		log('Allowed Companies', companyList)
		const prunedList = companyList.filter((rec) => rec.data.accountStatus !== 'DELETED')
		this.selector.companiesDropdown = _.sortBy(prunedList, 'label')
	}

	getAllowedCompaniesDropdownElideWidth(): number {
		const width = window.innerWidth
		if (width >= 375 && width <= 768) {
			return Math.floor(width / 10.5)
		}
		return 30
	}
}
