<div class="section-table">
	<table [id]="tableName" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>
	<!-- <app-table-footer-buttons
		[filename]="'base_reports'"
		[exportBtnLabel]="'Export Records'"
		[table]="tableName"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons> -->
</div>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-base-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(recordUpdated)="recordUpdated($event)"
	>
	</app-base-edit>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<!-- List Dialog -->
<app-modal-dialog *ngIf="detailsDialogManager.isDialogVisible" [dialogManager]="detailsDialogManager">
	<app-adp-ivr-api-log-details [dialogManager]="detailsDialogManager" [recordId]="detailsAction.recordId"></app-adp-ivr-api-log-details>
</app-modal-dialog>
