<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<!-- <i class="far fa-cog sec-act-ico" (click)="handlePrefsDialogOpen()" title="Manage Preferences"></i> -->
			<i class="far fa-question-circle sec-act-ico" (click)="showMoreInfo = !showMoreInfo" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<div>
				<i class="far fa-user sec-head-ico" aria-hidden="true"></i>
				<span>Onboarding Updates</span>
			</div>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="far fa-refresh sub-head-ico" (click)="fetchAndReload()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated }}</div>
		</div>
	</div>

	<div class="alert alert-info alert-bg-gray" style="line-height: 1.4rem; margin-top: 10px">
		This section allows you to manage employee responses to information requests generated by the onboarding system.
		<span *ngIf="!showMoreInfo" class="link-text" (click)="showMoreInfo = !showMoreInfo">show more</span>

		<div *ngIf="showMoreInfo">
			<hr />

			<div style="margin-top: 18px">
				You can create and configure these requests under <b>Admin > Settings > Employee Onboarding</b>. While primarily managed by the onboarding
				system, this process is designed to collect documents from employees at any time to ensure compliance with company policies, payroll
				requirements, and state regulations.
			</div>

			<div style="margin-top: 18px">
				<b>Sorting the review list</b><br />
				When an employee submits a response to an information request, it will be added to the review list. You can sort the list by
				<b>Employee Name</b>, or <b>Response Date</b>. The response date option looks at the last time the employee interacted with any onboarding
				request. This allows you to easily sort by either the newest or oldest responses.
			</div>

			<div *ngIf="showMoreInfo" class="link-text" style="margin-top: 12px" (click)="showMoreInfo = !showMoreInfo">hide details</div>
		</div>
	</div>

	<div class="d-flex flex-row mb-2 justify-content-start align-items-center flex-wrap">
		<div class="d-flex flex-row mb-2 justify-content-evenly align-items-center flex-wrap">
			<div class="filter-label">Sort By</div>
			<div class="filter-select">
				<p-select
					appScrollLockSelect
					[options]="orderByOptions"
					[(ngModel)]="viewManager.filterOptions.iterate"
					(onChange)="handleFilterChange()"
				></p-select>
			</div>
		</div>

		<div
			*ngIf="viewManager.filterOptions.iterate === 'EMP_LAST_UPDATE'"
			class="d-flex flex-row mb-2 justify-content-evenly align-items-center flex-wrap"
		>
			<div class="filter-label">Order</div>
			<div class="filter-select">
				<p-select
					appScrollLockSelect
					[options]="orderByLastResponseOptions"
					[(ngModel)]="viewManager.filterOptions.order"
					(onChange)="handleFilterChange()"
				></p-select>
			</div>
		</div>
	</div>

	<div *ngIf="list.length > 0" class="d-flex flex-row mb-2 justify-content-start flex-wrap list-wrap">
		<div *ngFor="let vm of list" class="ou-card-wrap">
			<div class="card ou-card" [class.is-skipped]="vm.skipped" (click)="handleLogListDialogOpen(vm)">
				<img
					[src]="vm.hasImageError ? vm.placeholderImage : vm.profileUrl"
					width="300px"
					class="card-img-top"
					alt="Profile Picture"
					(error)="handleImageLoadError(vm)"
				/>
				<div class="card-body">
					<div class="emp-name trunc-ellipsis">{{ vm.emp.name }}</div>
					<div class="date-wrap">
						<div class="float-right card-date">{{ vm.empLastUpdate | dateFormatter: timeFormatter }}</div>
						<div class="card-date">{{ vm.empLastUpdate | dateFormatter: 'ddd MMM Do, YYYY' }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- No Records -->
	<div *ngIf="isLoading" class="no-data-block"><i class="fas fa-loader fa-spin"></i> Refreshing Data ...</div>
	<div *ngIf="list.length === 0 && !isLoading" class="no-data-block">No Pending Updates</div>

	<!-- Preferences Dialog -->
	<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
		<div class="options-block">
			<div class="options-title-inline">Sort By</div>
			<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('orderBy')"></i> -->
			<p-select appScrollLockSelect [options]="orderByOptions" [(ngModel)]="viewManager.filterOptions.iterate"></p-select>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Sort Order</div>
			<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('orderByDate')"></i> -->
			<p-select appScrollLockSelect [options]="orderByLastResponseOptions" [(ngModel)]="viewManager.filterOptions.order"></p-select>
		</div>
	</app-modal-dialog>

	<!-- Onboard Log List Dialog -->
	<app-modal-dialog *ngIf="onboardLogListDialogManager.isDialogVisible" [dialogManager]="onboardLogListDialogManager">
		<app-onboard-log-list [dialogManager]="onboardLogListDialogManager" [empId]="onboardLogListAction.recordId" [records]></app-onboard-log-list>
	</app-modal-dialog>
</div>
