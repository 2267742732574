import { Component, AfterViewInit, ViewChild } from '@angular/core'

import { CoreService, DatabaseService } from '@app/services'
import {
	EmployeesViewTabState,
	CrudAction,
	BulkActionManager,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	SectionListCountManager,
	EmployeesViewManager,
} from '@app/models'

import { DateTimeHelper, DisplayHelper } from '@app/helpers'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AccessHelper } from '@app/helpers/access'
import { EmployeeTableComponent } from './employee-table/employee-table.component'
import { environment } from '@env/environment'
import { BatchActionManager } from '@app/models/batch'

@Component({
	selector: 'app-employees',
	templateUrl: './employees.component.html',
	styleUrls: ['./employees.component.scss'],
	standalone: false,
})
export class EmployeesComponent implements AfterViewInit {
	environment = environment

	showTooltips = false

	switcherConfig = new SectionSwitcherConfig()

	supId: number
	managedSupIds: Array<number> = []
	accessHelper: AccessHelper
	isManager = false
	isRestricted = false

	// currentView: EmpTableViewState = EmpTableViewState.Active

	CrudAction = CrudAction

	@ViewChild('empTable') empTable: EmployeeTableComponent

	constructor(
		private coreSrvc: CoreService,
		private deviceSrvc: DeviceDetectorService,
	) {
		// this.currentView = this.coreSrvc.dbSrvc.empSrvc.currentView
		this.setupAccessPermissions()
		this.setupTabSwitcher()
	}

	get viewManager(): EmployeesViewManager {
		return this.coreSrvc.dbSrvc.empSrvc.viewManager
	}

	get batchManager(): BatchActionManager {
		return this.coreSrvc.dbSrvc.empSrvc.batchManager
	}

	// get listCountManager(): SectionListCountManager {
	// 	return this.coreSrvc.dbSrvc.empSrvc.listCountManager
	// }

	get listCountManager(): SectionListCountManager {
		return this.coreSrvc.dbSrvc.empSrvc.listCountManager
	}

	get bulkActionManager(): BulkActionManager {
		return this.coreSrvc.dbSrvc.empSrvc.bulkActionManager
	}

	get subSectionTitle(): string {
		const currentView = this.viewManager.currentView
		return DisplayHelper.capitalizeSingleWord(currentView)
	}

	// get empCount(): number { return this.coreSrvc.dbSrvc.empSrvc.activeCount(this.supId) }

	ngAfterViewInit() {
		this.enableTooltips()
		this.fetchAndReloadData()
	}

	setupAccessPermissions() {
		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		this.managedSupIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
		this.isManager = myUser.role === 'MANAGER'
		this.accessHelper = new AccessHelper(this.coreSrvc, 'employee')
		const permissions = this.accessHelper.getPermissionsFor('employee')
		if (!permissions.access.read && permissions.owner.read) {
			const supId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
			this.supId = supId
			this.isRestricted = true
		}
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, true)
	}

	showNoDataTip(): boolean {
		if (this.allCount() === 0) {
			return true
		}
		return false
	}

	fetchAndReloadData() {
		// this.coreSrvc.dbSrvc.readTable('employee_login').then((readEmpLoginSuccess) => {
		this.coreSrvc.dbSrvc.bulkRead(['employee_login', 'employee', 'supervisor_group']).then((readEmpSuccess) => {
			if (readEmpSuccess) {
				this.empTable?.updateTable()
			}
			// if (this.allCount() === 0) { this.showTooltips = true }
		})
		// })
	}

	lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.empSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	activeCount(): number {
		return this.isManager && this.isRestricted
			? this.coreSrvc.dbSrvc.empSrvc.getManagedActiveEmployees(this.managedSupIds).length
			: this.coreSrvc.dbSrvc.empSrvc.getActiveEmployees(this.supId).length
	}
	inactiveCount(): number {
		return this.isManager && this.isRestricted
			? this.coreSrvc.dbSrvc.empSrvc.getManagedInctiveEmployees(this.managedSupIds).length
			: this.coreSrvc.dbSrvc.empSrvc.getInactiveEmployees(this.supId).length
	}
	allCount(): number {
		return this.isManager && this.isRestricted
			? this.coreSrvc.dbSrvc.empSrvc.getManagedAllEmployees(this.managedSupIds).length
			: this.coreSrvc.dbSrvc.empSrvc.getAllEmployees(this.supId).length
	}

	currentCount(): number {
		const active = this.coreSrvc.dbSrvc.empSrvc.showActive
		if (active) {
			return this.coreSrvc.dbSrvc.empSrvc.getActiveEmployees().length
		} else {
			return this.coreSrvc.dbSrvc.empSrvc.getInactiveEmployees().length
		}
	}

	showActive(): boolean {
		return this.coreSrvc.dbSrvc.empSrvc.showActive
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const activeTab = new SectionSwitcherTab('ACTIVE', 'Active')
		activeTab.count = () => this.activeCount()
		const inactiveTab = new SectionSwitcherTab('INACTIVE', 'Inactive')
		inactiveTab.count = () => this.inactiveCount()
		const allTab = new SectionSwitcherTab('ALL', 'All')
		allTab.count = () => this.allCount()
		const config = new SectionSwitcherConfig([activeTab, inactiveTab, allTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.viewManager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		this.setDisplayState(tab.id as EmployeesViewTabState)
	}

	private setDisplayState(state: EmployeesViewTabState) {
		// this.coreSrvc.dbSrvc.empSrvc.viewManager.currentView = state
		this.viewManager.currentView = state
		this.empTable?.setPage(0)
		this.empTable.updateTable()
	}
	// End Tab Switcher Methods

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		this.updateFixedHeader()
		return false
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#employeesTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	togglePrefsPanel(): void {
		this.empTable.sectionPrefsDialogManager.isDialogVisible = true
	}

	createRecord() {
		this.empTable.createRecord()
	}

	private enableTooltips() {
		if (this.deviceSrvc.isDesktop()) {
			const tooltips: any = $('[data-toggle="tooltip"]')
			tooltips.tooltip()
		}
	}
}
