<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Records ...'"></app-activity-banner>

<div [hidden]="isLoading || editAction.isEditing || auditAction.isAuditing">
	<div *ngIf="list.length > 0" style="margin-bottom: 16px">
		<div style="margin-bottom: 6px">
			<app-search-filter [debounceTimer]="350" [placeholder]="'filter records'" (search)="performSearch($event)"></app-search-filter>
		</div>
		<div style="text-align: right">
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span> |
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span>
		</div>
	</div>

	<!-- No Records -->
	<div *ngIf="list.length === 0 || searchResults.length === 0" class="no-data-block">No Records Available</div>

	<div *ngFor="let vm of list">
		<div *ngIf="vm.matchesSearch" class="card list-card list-card-no-shadow">
			<div class="card-body list-card-body">
				<div class="list-card-header" (click)="vm.isExpanded = !vm.isExpanded">
					<div class="float-right lc-icon-wrap">
						<i
							class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover lc-act-ico"
							title="Edit Record"
							(click)="actionIconClicked($event, 'EDIT', vm)"
						></i>
						<!-- <i
							class="far fa-copy act-ico act-ico-dark-hover lc-act-ico"
							title="Clone Record"
							(click)="actionIconClicked($event, 'CLONE', vm)"
						></i> -->
						<i
							class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover lc-act-ico"
							title="Delete Record"
							(click)="actionIconClicked($event, 'DELETE', vm)"
						></i>
						<i
							class="far fa-database act-ico act-ico-dark-hover lc-act-ico"
							[class.act-ico-highlight]="vm.record.updated"
							title="Audit Record"
							(click)="actionIconClicked($event, 'AUDIT', vm)"
						></i>
						<i
							class="fas act-ico act-ico-dark-hover lc-act-ico"
							[class.fa-chevron-right]="!vm.isExpanded"
							[class.fa-chevron-down]="vm.isExpanded"
							[class.act-ico-highlight]
							title="Expand Record"
							(click)="actionIconClicked($event, 'EXPAND', vm)"
						></i>
					</div>
					<div class="lc-title tts-hl-2">{{ vm.profileName }}</div>
					<div class="lc-subtitle">{{ vm.externalId }}</div>
				</div>
				<div *ngIf="vm.isExpanded && !vm.isConfirmingDelete" style="line-height: 1.5rem">
					<hr />

					<!-- Allow Clocking -->
					<div class="pf-flag-item" [class.pf-check-color]="vm.record.allow_clocking" [class.pf-ban-color]="!vm.record.allow_clocking">
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.allow_clocking" [class.fa-ban]="!vm.record.allow_clocking"></i>
						</div>
						<div class="pf-flag-label">Allow clocking</div>
					</div>

					<!-- Allow multiple clocking -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_multiple_clocking"
						[class.pf-ban-color]="!vm.record.allow_multiple_clocking"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.allow_multiple_clocking" [class.fa-ban]="!vm.record.allow_multiple_clocking"></i>
						</div>
						<div class="pf-flag-label">Allow multiple clocking</div>
					</div>

					<!-- Allow pay code transactions -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_pay_code_transactions"
						[class.pf-ban-color]="!vm.record.allow_pay_code_transactions"
					>
						<div class="float-right pf-flag-icon">
							<i
								class="far"
								[class.fa-check]="vm.record.allow_pay_code_transactions"
								[class.fa-ban]="!vm.record.allow_pay_code_transactions"
							></i>
						</div>
						<div class="pf-flag-label">Allow pay code transactions</div>
					</div>

					<!-- Allow labor level transfers -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_labor_level_transfers"
						[class.pf-ban-color]="!vm.record.allow_labor_level_transfers"
					>
						<div class="float-right pf-flag-icon">
							<i
								class="far"
								[class.fa-check]="vm.record.allow_labor_level_transfers"
								[class.fa-ban]="!vm.record.allow_labor_level_transfers"
							></i>
						</div>
						<div class="pf-flag-label">Allow labor level transfers</div>
					</div>

					<!-- Allow work rule transfers -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_work_rule_transfers"
						[class.pf-ban-color]="!vm.record.allow_work_rule_transfers"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.allow_work_rule_transfers" [class.fa-ban]="!vm.record.allow_work_rule_transfers"></i>
						</div>
						<div class="pf-flag-label">Allow work rule transfers</div>
					</div>

					<!-- Enable call tracking -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.enable_call_tracking"
						[class.pf-ban-color]="!vm.record.enable_call_tracking"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.enable_call_tracking" [class.fa-ban]="!vm.record.enable_call_tracking"></i>
						</div>
						<div class="pf-flag-label">Enable call tracking</div>
					</div>

					<!-- Enable phone restrictions -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.enable_phone_restrictions"
						[class.pf-ban-color]="!vm.record.enable_phone_restrictions"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.enable_phone_restrictions" [class.fa-ban]="!vm.record.enable_phone_restrictions"></i>
						</div>
						<div class="pf-flag-label">Enable phone restrictions</div>
					</div>
				</div>
				<div *ngIf="vm.isConfirmingDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="vm.isConfirmingDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(vm)" style="width: 64px">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<i class="fa fa-loader fa-spin" *ngIf="vm.isDeleting"></i>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Edit View -->
<app-adp-ivr-profile-edit
	*ngIf="editAction.isEditing"
	[action]="editAction.action"
	[dialogManager]="dialogManager"
	[recordId]="editAction.recordId"
	(recordUpdated)="recordUpdated($event)"
></app-adp-ivr-profile-edit>

<!-- Audit View -->
<app-audit-log-record-view
	*ngIf="auditAction.isAuditing"
	[resource]="auditAction.resource"
	[recordId]="auditAction.recordId"
></app-audit-log-record-view>
