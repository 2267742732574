<div>
	<div class="tts-hl-2" style="font-size: 1.3em; font-weight: 600; margin-top: 15px">Decode Emp App Error Reports</div>

	<textarea class="form-control cdk-textarea-autosize" style="margin-bottom: 12px" [(ngModel)]="empAppErrorInput"></textarea>
	<div class="d-flex justify-content-between">
		<button class="btn btn-sm btn-outline-secondary btn-block" style="margin-right: 8px" (click)="empAppErrorInput = ''; empAppErrorResults = []">
			Clear
		</button>
		<button class="btn btn-sm btn-outline-success btn-block" style="margin-left: 8px" (click)="decodeEmpAppResults()">Decode</button>
	</div>

	<div *ngFor="let result of empAppErrorResults">
		<div class="card" style="margin-top: 20px">
			<div style="padding: 8px">
				<pre><code>{{ result | json }}</code></pre>
				<div class="d-flex justify-content-between flex-wrap">
					<div *ngIf="result.lastRoute?.date" style="padding: 6px 0px">{{ formatTimeFromErrorResult(result) }}</div>
					<button *ngIf="result?.gps" class="btn btn-sm btn-secondary" (click)="showEmpAppResultMap(result)">Show Map</button>
				</div>
			</div>
		</div>
	</div>
</div>
