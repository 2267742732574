import { String } from 'aws-sdk/clients/apigateway'
import { MailingAddress } from './address'
import { DateTimeHelper } from '@app/helpers/datetime'
import { log } from '@app/helpers/logger'

import _ from 'lodash'
import { AdminPrefs, EmpAppPrefs } from './prefs'
import { DistanceUnitFormat } from './report-user'

export type LanguageCode = 'en' | 'es' | 'fr' | 'cn'
export type AccountBillingType =
	| 'PAYPAL'
	| 'STRIPE_MASTER'
	| 'STRIPE_SUB'
	| 'STRIPE_MASTER_INVOICE'
	| 'STRIPE_SUB_INVOICE'
	| 'STRIPE_MASTER_INVOICE_CARD_DELETE'
	| 'STRIPE_SUB_INVOICE_CARD_DELETE'
	| 'ADP_IVR'
	| 'ADP_MARKETPLACE'
// Company

export type CompanyAccountStatus =
	| 'TESTING'
	| 'TRIAL'
	| 'BILLING_OK'
	| 'BILLING_PAYMENT_FAILED'
	| 'BILLING_PORTAL_LOCKED'
	| 'BILLING_ACCOUNT_LOCKED'
	| 'BILLING_NEED_INFO'
	| 'CANCEL_PENDING'
	| 'PREPAID'
	| 'LOCKED_SUSPENDED'
	| 'INVOICE'
	| 'DELETED'
	| 'ADP_INVOICE'

export class CompanyRecord {
	////////////////////////
	// Primary Keys / IDs //
	////////////////////////

	id: number
	global_id: number
	adp_id: number
	external_id: string
	file_import_id: number
	uuid: string
	wiw_id: number
	paypal_credit_card_id: number
	qbo_id: number

	//////////////////////
	// General Settings //
	//////////////////////

	allowAutoCreate: boolean // Autocreate employees
	useJobCodes: boolean // Use job codes
	allow_invalid_job_code: boolean // Use job codes > Allow default job
	allow_unscheduled_job: boolean // Use job codes > Allow unscheduled jobs
	overage: boolean // Track budgeted time overage
	travel: boolean // Enable travel tracking
	travel_target: 'ALL' | 'SELECTED' = 'ALL' // Enable travel tracking > For all employees / Only for selected employees
	ivr_sms_breaks: boolean // Enable IVR and SMS breaks
	confirm_breaks: boolean // Require break confirmation
	ivr_external_id: boolean // Allow employees to access the system using their external ID
	schedule_overlap: boolean // Allow employee schedules to overlap
	emp_schedule_notify: boolean // Send schedule change notifications to employees
	language_json: string // System prompt language options
	default_supervisor_id: number // Supervisor for Autocreaded Employees
	emp_vacation_alert: string // Employee vacation clock-in alerts

	/////////////////////////////////////////
	// General Settings - Internal Options //
	/////////////////////////////////////////

	industry: string // Industry
	default_units: DistanceUnitFormat = 'IMPERIAL' // Default Units
	account_status: CompanyAccountStatus // Account Status
	wkst: number // Scheduling Week Start (Hidden)
	cost_user_month: number // Cost per user/month
	prepaid_balance: number // Prepaid balance

	// General Timers
	auto_logout: number // Portal Inactivity Automatic Logout Timer
	checklist_timer: string = null // IVR Required Checklist Automatic Checkout Window
	gps_allowed_delay: string // GPS Response Time Timeout Window
	create_no_show: string // Automatic No Show Timeout Window
	b2b_shift_auto: boolean // Back-to-back auto shift check in

	// System Options
	fuzzy_match_distance: number = null

	// Check In/Out Confirmation
	ivr_require_key_check_in: boolean // Require keypress for check-in
	require_key_delay: string = null // Require key press for IVR check-out

	// Notification Options
	min_notification_interval: string = null // Minimum Interval (hr:min)
	max_notification_duration: string = null // Maximum Duration (hr:min)
	max_daily_hours: string // Duration (hr:min) - Sup will be notified if emp works longer than this
	additional_details_notify: boolean // Enable notification details
	sup_notify_ongoing: boolean // Enable supervisor notifications for no shows when scheduled employee is checked into another shift
	schedule_change_notifications: boolean // Enable Schedule change notification email list in Schedule edit dialog
	notify_sms_using_schedule: boolean // Enable supervisor SMS notifications based on employee schedule

	// Break Options
	break_start_reminder: string = null // Break start reminder (hr:min)
	break_early_warning: string = null // Short break warning (hr:min)
	break_reminder: string = null // Long break warning (hr:min)
	break_type_prompt: boolean = false

	// Time entry options
	hours_rounding: string = null // Hours Rounding (hr:min)
	round_up_timer: string = null // Round up timer (hr:min)
	roundup_note_timer: string = null // Round up note timer (hr:min)
	round_down_timer: string = null // Round down timer (hr:min)
	rounddown_note_timer: string = null // Round down note timer (hr:min)
	check_in_out_window: string = null // Check-in/out window (hr:min)
	auto_close_open_trans: string = null // Auto close duration (hr:min)
	job_length_max: number = null // Maximum job length (hr:min)
	image_dup_check: number = null // Image dup check window (days)
	validate_qr_codes: boolean = false // Enable photo QR code validation

	// Overtime Options
	ot_enable: boolean = false // Enable overtime protection
	ot_dow_start: number = 1 // Day of Week Start (ISO day value)
	ot_week_count: number = 1 // Weeks in overtime period
	ot_threshold_week: string = 'PT40H' // Max hours per week (hr:min)
	ot_threshold_day: string = 'PT8H' // Max hours per day (hr:min)

	// Report Options
	emp_cost_multiplier: number // Employee cost multiplier
	midnight_splits: boolean // Split reports on day boundary
	useIncidents: boolean // Enable IVR incident reports

	// Click-to-Call / Call Center
	c2c_enable: boolean // Enable outbound Click-to-Call
	agent_logout_timer: number = 16 // Active agent automatic signout timer (hrs)

	// Text / Messaging Options
	sms_responder_schedule: boolean // Emp can retrieve schedule via SMS
	sms_responder_time: boolean // Emp can retrieve timecard via SMS
	sms_char_limit: number = 140 // Admin portal max SMS length

	// Miscellaneous Internal Options
	onboard_required: boolean // Enables onboarding for new employees
	pureMobile: boolean // Enable pure mobile
	force_job_codes: boolean // Force job codes
	gps_prompt_extra: boolean // GPS prompt extra - adds reminder in IVR to click GPS share link
	useCheckpoints: boolean // Use checkpoints (mobile)
	useCheckpointsLL: boolean // Use checkpoints (landline)
	useMultiDay: boolean // Enable multi-day jobs
	ncci_codes: boolean // Enable NCCI codes
	prevent_auto_inactive: boolean // Prevent automatic inactivation
	reject_inactive_employee: boolean // Reject inactive employees
	pay_rate: boolean // Enable bill/pay rates
	work_tax_location: boolean // Enable work tax locations
	paid_holiday: boolean // Enable paid holidays
	use_emp_photos: boolean // Enable profile photos
	ssr_public_admin_notes: boolean // Enable admin notes in public shift summary reports
	useQBOCustLocations: boolean // Enable importing QBO customer locations
	useQBOCustOrg: boolean // Allow importing QBO customers to Organization > Clients

	//////////////////////
	// UNSORTED / NO UI //
	//////////////////////

	account_billing: AccountBillingType // Determines billing type
	billing_day: number // Day of month to bill
	billing_emails: string // Billing emails - managed in Admin > Billing > Contacts
	billing_phones: string // Billing phones - managed in Admin > Billing > Contacts
	invoice_due_days: number // Defaults to 7

	logo_url: string // URL to company logo
	emp_app_domain: string // Custom domain for employee application

	country_iso: string // Region
	merge_jobsite: boolean // Determines whether job sites are managed separately or with job

	trans_lock_date: string = null // '2023-11-01' - Managed in time entries
	trial_expires: string // Date trial expires - Do not manually adjust

	created: string // Date record created
	updated: string // Date record updated

	////////////////////////// SEPARATE SECTIONS /////////////////////////

	/////////////////////
	// Company Profile //
	/////////////////////
	name: string // Company name
	address_json: string // JSON storage for company address
	timezone: string // Default company timezone
	// default_supervisor_id: number // Listed under general settings but also available in company profile
	supervisor_phone: string // Default supervisor phone number (DEPRECATED)
	supervisor_phone_e164: string // Default supervisor phone number
	supervisor_email: string // Default supervisor email

	//////////////////////
	// Employee Scoring //
	//////////////////////
	emp_score_config: string // JSON storage for employee scoring

	//////////////////////////
	// Employee Application //
	//////////////////////////
	web_prefs_json: string // JSON storage for employee application preferences

	////////////////////////
	// Portal Preferences //
	////////////////////////
	admin_prefs_json: string // JSON storage for portal preferences

	/////////////////////
	// Data / Analytics //
	/////////////////////
	analytics_prefs_json: string // JSON storage for data / analytics

	///////////////////////////
	// Inbound Call Blocking //
	///////////////////////////
	useWhitelist: boolean
	blocked_numbers: string
	allow_cell_calls: boolean

	/////////////////
	// Call Center //
	/////////////////

	// OUTBOUND
	callout_callerid: string // Outbound Caller ID default
	callout_callerid_e164: string // Outbound Caller ID default

	c2c_caller_id_e164: string // Outbound Caller ID
	c2c_record_enable: boolean // Record all outbound calls
	c2c_email: string // Email Outbound Call Recordings (comma separated email list)
	c2c_preamble_message: string // Outbound Call Preamble

	callout_context: string // Not shown in UI

	// INBOUND
	c2c_phone_e164: string // Dispatch phone number

	cc_enable: boolean // Call center enabled
	cc_record_enable: boolean // Record all inbound calls
	cc_preamble_message: string // Inbound Call Preamble
	cc_email: string // Email Inbound Call Recordings (comma separated email list)
	cc_vm_enable: boolean // Enable voicemail
	cc_vm_message: string // voicemail message
	cc_vm_email: string // Email voicemail recordings (comma separated email list)

	////////////////
	// DEPRECATED //
	////////////////
	checkout_checklist_json: string // DEPRECATED - Handled by employee surveys
	checkout_checklist_required: boolean // DEPRECATED - Handled by employee surveys

	next_job: boolean // DEPRECATED 2023-05-20 - Used in emp app to show next job button - IVR use unknown
	useEmployeeJobCounts: boolean // DEPRECATED 2023-05-20 - Used to show count for any employee schedules
	additional_timezones: string // DEPRECATED 2023-05-20 - Never implemented in admin portal
	pure_schedule: boolean // DEPRECATED 2023-05-20
	useNewScheduler: boolean // DEPRECATED 2023-05-20
	gps_use_emp_app: boolean // DEPRECATED - Used with old GPS sharing page
	system_message: boolean // DEPRECATED
	gps_auto_location: boolean // DEPRECATED 2024-08-20 - Used when GPS sharing was a separate page, before emp app sharing

	constructor(record: CompanyRecord) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)

		// Deprecated properties set to hard coded value
		this.pure_schedule = true
		this.useNewScheduler = true
		this.next_job = false
		this.useEmployeeJobCounts = true
	}

	getUpdateRecord(): CompanyRecord {
		return new CompanyRecord(this)
	}

	currencySymbol(): string {
		switch (this.country_iso) {
			case 'GB':
				return '£'
			default:
				return '$'
		}
	}
	getCompanyEmpAppPrefs(): EmpAppPrefs {
		let result = null
		if (this.web_prefs_json) {
			try {
				result = JSON.parse(this.web_prefs_json)
			} catch (error) {
				log('Error parsing company web prefs', error)
			}
		}
		return new EmpAppPrefs(result)
	}
	getCompanyAdminPrefs(): AdminPrefs {
		let result = null
		if (this.admin_prefs_json) {
			try {
				result = JSON.parse(this.admin_prefs_json)
			} catch (error) {
				log('Error parsing company admin prefs', error)
			}
		}
		return new AdminPrefs(result)
	}

	getCheckoutChecklist(): Array<string> {
		const chklstJson = this.checkout_checklist_json
		if (!chklstJson) {
			return []
		}
		try {
			const parsedList = JSON.parse(chklstJson)
			if (_.isArray(parsedList)) {
				return parsedList
			}
		} catch {
			return []
		}
	}

	getLanguageJson() {
		return JSON.parse(this.language_json)
	}

	getAddress(): MailingAddress {
		return MailingAddress.buildFromJsonSting(this.address_json)
	}

	getRRuleWkstAsIso(): number {
		let dow = this.wkst || 0
		return dow + 1
	}

	getOtDowStartIso(): number {
		return this.ot_dow_start
	}
}

export class AllowedCompaniesHelper {
	public static getAllowedCompaniesColorClass(status: CompanyAccountStatus): string {
		switch (status) {
			case 'TESTING':
				return 'png-clr png-clr-blue'
			case 'TRIAL':
				return 'png-clr png-clr-gold'
			case 'BILLING_OK':
				return 'png-clr png-clr-green'
			case 'INVOICE':
				return 'png-clr png-clr-teal'
			case 'BILLING_NEED_INFO':
				return 'png-clr png-clr-chocolate'
			case 'BILLING_PAYMENT_FAILED':
			case 'BILLING_PORTAL_LOCKED':
			case 'BILLING_ACCOUNT_LOCKED':
			case 'LOCKED_SUSPENDED':
				return 'png-clr png-clr-firebrick'
			default:
				return 'png-clr'
		}
	}

	public static getAllowedCompaniesIconClass(status: CompanyAccountStatus): string {
		switch (status) {
			case 'TESTING':
				return 'fa fa-flask aci-pad'
			case 'TRIAL':
				return 'fa fa-tag aci-pad'
			case 'BILLING_OK':
				return 'fa fa-check aci-pad'
			case 'INVOICE':
				return 'far fa-file-invoice aci-pad'
			case 'BILLING_NEED_INFO':
				return 'far fa-info-circle aci-pad'
			case 'BILLING_PAYMENT_FAILED':
			case 'BILLING_PORTAL_LOCKED':
			case 'BILLING_ACCOUNT_LOCKED':
			case 'LOCKED_SUSPENDED':
				return 'fa fa-exclamation-triangle aci-pad'
			default:
				return ''
		}
	}
}

export class IVRCallList {
	static clearCallInInfoForCompanyList() {
		localStorage.removeItem('TTSAdmin-CallInList')
	}

	static storeCallInInfoFromCompanyList(companyList: Array<CompanyRecord>) {
		const phoneList = companyList.map((rec) => IVRCallListItem.buildFromParams(rec.name, rec.callout_callerid_e164))
		localStorage.setItem('TTSAdmin-CallInList', JSON.stringify(phoneList))
	}

	static retrieveFromLocalStorage() {
		const jsonString = localStorage.getItem('TTSAdmin-CallInList')
		if (jsonString) {
			const parsedList: Array<any> = JSON.parse(jsonString)
			return parsedList.map((li) => IVRCallListItem.buildFromJson(li))
		} else {
			return []
		}
	}
}

export class IVRCallListItem {
	companyName: string
	ivrNumber: string

	static buildFromParams(companyName: string, ivrNumber: string): IVRCallListItem {
		const callListItem = new IVRCallListItem()
		callListItem.companyName = companyName
		callListItem.ivrNumber = ivrNumber
		return callListItem
	}

	static buildFromJson(data: any): IVRCallListItem {
		const callListItem = new IVRCallListItem()
		callListItem.companyName = data.companyName
		callListItem.ivrNumber = data.ivrNumber
		return callListItem
	}
}

// Maintain state for company service / company selector
export class CompanySelectorState {
	companiesDropdown = []
	selectedCompanyId: number = null
	currentCompanyId: number = null
}
