// Angular
import { BrowserModule, Title } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { LocationStrategy, HashLocationStrategy } from '@angular/common'
import { SharedModule } from '@app/shared.module'

// Application
import { AppComponent } from '@app/app.component'
import { AppRoutingModule } from '@app/app-routing.module'
import { appDirectives, appPipes, appComponents, standaloneComponents } from '@app/app.imports'

import { Chart, registerables } from 'chart.js'
import autocolors from 'chartjs-plugin-autocolors';
import { AdpIvrApiLogDetailsComponent } from './admin/adp-ivr-api-log/adp-ivr-api-log-details/adp-ivr-api-log-details.component'

@NgModule({
	declarations: [...appDirectives, ...appPipes, ...appComponents, AdpIvrApiLogDetailsComponent],
	imports: [AppRoutingModule, BrowserModule, SharedModule.forRoot(), ...standaloneComponents],
	providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, Title],
	exports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {
		Chart.register(...registerables, autocolors)
	}
}
