<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Data ...'"></app-activity-banner>

<div *ngIf="!isLoading">
	<div class="options-block">
		<div class="options-title">Employee OID</div>
		<div>{{ record.employee_adp_oid }}</div>
	</div>
	<div class="options-block">
		<div class="options-title">UUID</div>
		<div>{{ record.uuid }}</div>
	</div>

	<hr />

	<app-form-expander [label]="'Request'" [isExpanded]="false">
		<div class="pre-code">{{ request ? (request | json) : 'No Data' }}</div>
	</app-form-expander>
	<app-form-expander [label]="'Response'" [isExpanded]="false">
		<div class="pre-code">{{ response ? (response | json) : 'No Data' }}</div>
	</app-form-expander>
	<app-form-expander [label]="'ADP Request'" [isExpanded]="false">
		<div class="pre-code">{{ adpRequest ? (adpRequest | json) : 'No Data' }}</div>
	</app-form-expander>
	<app-form-expander [label]="'ADP Response'" [isExpanded]="false">
		<div class="pre-code">{{ adpResponse ? (adpResponse | json) : 'No Data' }}</div>
	</app-form-expander>
</div>
