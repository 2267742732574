export class BannerViewManager {
	version = 1
	groupAllBanners = true
	aggregator = new BannerStatus()
	pendingTimeOff = new BannerStatus()
	pendingSchedules = new BannerStatus()
	onboardingUpdates = new BannerStatus()
	openShifts = new BannerStatus()
	profileUpdate = new BannerStatus()
	jobsiteNumbers = new BannerStatus()
	w2wErrors = new BannerStatus()
}

export class BannerStatus {
	isAvailable = true
	notificationCount = 0
	isWarn = false
	isDanger = false
	isCritical = false

	hasBeenAccepted = false
}
