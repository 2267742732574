import { mkConfig, generateCsv, download } from 'export-to-csv'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Global } from './global'
import { log } from '@app/helpers'
import { AuditLog, AuditLogTrackType } from './auditlog'

export type DatabaseTableName =
	| ''
	| 'none'
	| 'active_call_legs'
	| 'adp_department_code'
	| 'adp_earnings_code'
	| 'adp_ivr_api_log'
	| 'adp_ivr_company'
	| 'adp_ivr_prompt_code'
	| 'adp_ivr_profile'
	| 'adp_ivr_prompt_code'
	| 'adp_ivr_phone_number'
	| 'adp_payroll_group_code'
	| 'adp_rate_code'
	| 'adp_sync'
	| 'adp_log'
	| 'announcements'
	| 'announcements_scheduled'
	| 'audit_log'
	| 'billing_log'
	| 'bot_definition'
	| 'break_log'
	| 'break_profile'
	| 'checkpoint'
	| 'click_to_call_log'
	| 'client'
	| 'company'
	| 'company_activity_log'
	| 'company_globals'
	| 'company_invites'
	| 'company_switch_list'
	| 'contact'
	| 'dashboard'
	| 'dup_images'
	| 'employee_job_pay_rate'
	| 'employee'
	| 'employee_checklist'
	| 'employee_login'
	| 'enrollment'
	| 'file_import_sync'
	| 'file_import_log'
	| 'file_uploads'
	| 'global_employee'
	| 'google_cal_sync'
	| 'google_sync_log'
	| 'help_video'
	| 'job'
	| 'incident_log'
	| 'ivr_call_log'
	| 'ivr_call_event_log'
	| 'location'
	| 'location_exception'
	| 'miscellaneous' // Only used for permissions
	| 'ncci_codes'
	| 'notification_profile'
	| 'onboard_definition'
	| 'onboard_log'
	| 'open_shifts'
	| 'phone_number'
	| 'phone_number_skill'
	| 'project'
	| 'qbo_customer'
	| 'qbo_service_item'
	| 'qbo_sync'
	| 'qbo_vendor'
	| 'reports'
	| 'reports_definition'
	| 'reports_delete'
	| 'reports_log'
	| 'reports_log_delete'
	| 'schedule_change_request'
	| 'schedule_log'
	| 'schedule_os_offer'
	| 'schedule_os_offer_entry'
	| 'schedule_recur'
	| 'schedule_view'
	| 'skill'
	| 'sms_log'
	| 'station'
	| 'supervisor_group'
	| 'supervisor_role'
	| 'timezones'
	| 'tour'
	| 'transaction_log'
	| 'transaction_exception'
	| 'users'
	| 'users_skill'
	| 'vacation'
	| 'vendor_client_contact'
	| 'vendor_client_job_pay_rate'
	| 'vendor_client_org'
	| 'waypoint_log'
	| 'wiw_employee'
	| 'wiw_log'
	| 'wiw_sync'
	| 'wo_config'

export class AuditTableNameMapper {
	static defaultFormatter(table: DatabaseTableName) {
		if (!table) {
			return 'None'
		}
		const comps = table.split('_')
		return comps.map((c) => c.charAt(0).toUpperCase() + c.slice(1)).join(' ')
	}
	static resourceNameForRecord(record: AuditLog) {
		if (record.operation === 'track') {
			const trackData = record.data ? JSON.parse(record.data) : null
			const type: AuditLogTrackType = trackData.type
			return AuditTableNameMapper.trackEventNameForType(type)
		}
		const table = record.resource
		switch (table) {
			case 'break_log':
				return 'Breaktime'
			case 'click_to_call_log':
				return 'Call Log'
			case 'incident_log':
				return 'Shift Report'
			case 'location':
				return 'Job Site'
			case 'reports':
				return 'Report'
			case 'schedule_view':
				return 'Schedule'
			case 'transaction_log':
				return 'Time Entry'
			default:
				return AuditTableNameMapper.defaultFormatter(table)
		}
	}
	static trackEventNameForType(type: AuditLogTrackType) {
		switch (type) {
			case 'CALLCENTER':
				return 'Call Center'
			case 'AUTHLINKACCESS':
				return 'Emp App Auth Link'
			case 'AUDIOACCESS':
				return 'Call Log Audio Access'
			case 'SECUREFILEACCESS':
				return 'Secure File Access'
			default:
				return 'Unknown Track Event'
		}
	}

	static trackEventInfoAlertHtml(type: AuditLogTrackType) {
		let msg = 'Unknown tracking event'
		switch (type) {
			case 'CALLCENTER':
				msg = 'This event tracks activity related to the call center.'
				break
			case 'AUDIOACCESS':
				msg = 'This event tracks when a supervisor accesses a call log audio file.'
				break
			case 'AUTHLINKACCESS':
				msg =
					'This event tracks when a supervisor accesses the employe app authentication link to either copy it to the clipboard or send it via text message to an employee.'
				break
			case 'SECUREFILEACCESS':
				msg = 'This event tracks when a supervisor accesses a secure file.'
		}
		return `
		<div class="alert alert-info alert-bg-gray" style="margin-top:12px">
			<div style="font-weight: 600">${AuditTableNameMapper.trackEventNameForType(type)}</div>
			<div style="margin-top:12px">${msg}</div>
		</div>`
	}
}

export class TableRenderCache {
	cache = {}

	invalidateCache() {
		const keyCount = Object.keys(this.cache).length
		this.cache = {}
	}

	invalidateRecord(recId: number, column: string) {
		const key = `${recId}-${column}`
		this.cache[key] = null
	}

	setRenderData(recId: number, column: string, data: string) {
		const key = `${recId}-${column}`
		if (this.cache[key]) {
			return
		} else {
			this.cache[key] = data
		}
	}

	getRenderData(recId: number, column: string): string {
		const key = `${recId}-${column}`
		return this.cache[key]
	}
}

class TableSaverHeaderOrder {
	static defaultList = ['id', 'created', 'updated']
	static getHeaderOrderForTable(table: string) {
		switch (table) {
			default:
				return TableSaverHeaderOrder.defaultList
		}
	}
}

export class TableSaver {
	records: Array<any> = []

	constructor(records: Array<any>) {
		this.records = records
	}

	get headers(): string[] {
		return this.records.length > 0 ? Object.keys(this.records[0]) : []
	}

	exportToCsv(filename: string, table: string, order: Array<string> = ['id', 'created', 'updated']) {
		const timestamp = moment().format('YYYY-MM-DD_HH_mm_ss')
		const options = {
			// showTitle: true,
			// title: 'My Awesome CSV',
			filename: filename + '_' + timestamp,
			fieldSeparator: ',',
			quoteCharacter: '"',
			decimalSeparator: '.',
			useTextFile: false,
			useBom: true,
			// useKeysAsHeaders: true,
			showColumnHeaders: true,
			columnHeaders: TableSaver.orderHeaders(this.headers, table),
		}
		const csvConfig = mkConfig(options)
		const converted: Array<any> = this.records.map((rec) => new JsonStringifier(rec, null)) // Add table as second parameter to perform lookups
		const csv = generateCsv(csvConfig)(converted)
		download(csvConfig)(csv)
	}

	static orderHeaders(array, table) {
		const order = TableSaverHeaderOrder.getHeaderOrderForTable(table)
		const sortedArray = [...array]

		// Sort the array based on the order array
		sortedArray.sort((a, b) => {
			const indexA = order.indexOf(a)
			const indexB = order.indexOf(b)

			if (indexA === -1 && indexB === -1) {
				// If both elements are not in order, keep their original order
				return array.indexOf(a) - array.indexOf(b)
			}

			if (indexA === -1) return 1 // If not in order, move to the end
			if (indexB === -1) return -1 // If not in order, move to the end

			return indexA - indexB
		})

		return sortedArray
	}

	static rearrangeProperties(data, order) {
		return data.map((obj) => {
			const newObj = {}
			order.forEach((key) => {
				if (obj.hasOwnProperty(key)) {
					newObj[key] = obj[key]
				}
			})
			// Add remaining properties not in the order array
			for (const key in obj) {
				if (!newObj.hasOwnProperty(key)) {
					newObj[key] = obj[key]
				}
			}
			log('NEW OBJ', newObj)
			return newObj
		})
	}
}

class JsonStringifier {
	constructor(record: any, table: string) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = this.resolve(table, attr, record)
				}
			}
		}
	}

	resolve(table: string, attr: string, record: any) {
		const property = table + ':' + attr
		const value = record[attr]

		if (table === 'employeesTable') {
			if (attr === 'supervisor') {
				const user = Global.coreSrvc.dbSrvc.settingSrvc.getUserForId(value)
				return user ? `${user.first_name} ${user.last_name}` : 'Unknown Supervisor'
			}
			if (attr === 'supervisor_ids') {
				log('VALUE', value)
				return (value ?? [])
					.map((id) => Global.coreSrvc.dbSrvc.settingSrvc.getUserForId(id))
					.map((user) => (user ? user.first_name + ' ' + user.last_name : 'Unknown Supervisor'))
					.join(',')
			}
		}

		const isObject = _.isObject(value)
		return isObject ? JSON.stringify(value) : value
	}
}
