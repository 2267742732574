<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i class="far fa-question-circle sec-act-ico" (click)="toggleSectionHelp()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap" [class.sec-head-title-highlight]="viewManager.dayViewDate">
			<div *ngIf="!viewManager.dayViewDate">
				<i class="far fa-database sec-head-ico"></i>
				<span>IVR API Log</span>
			</div>

			<div *ngIf="viewManager.dayViewDate" style="cursor: pointer; display: inline">
				{{ viewManager.dayViewDate | dateFormatter: 'ddd, MMM Do' }}
				<div class="sec-title-ico-wrap">
					<app-date-time-picker
						[useTemplate]="true"
						[currentDate]="viewManager.dayViewDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDayViewDate($event)"
						><i class="fal fa-calendar sec-title-ico"></i
					></app-date-time-picker>
				</div>
			</div>
		</div>

		<div class="sub-head-wrap">
			<!-- <div class="float-right link-text" (click)="openListDialog()">open list dialog</div> -->

			<div class="sub-head-ico-wrap">
				<!-- Day View Icon -->
				<div class="ico-inline-block" title="Toggle Day View" (click)="toggleDayView()">
					<i *ngIf="!viewManager.dayViewDate" class="far fa-calendar sub-head-ico"></i>
					<i *ngIf="viewManager.dayViewDate" class="far fa-calendar-check sub-head-ico sub-head-ico-highlight"></i>
				</div>

				<!-- Prev / Next Icons -->
				<div *ngIf="viewManager.dayViewDate" class="ico-inline-block">
					<i class="far fa-arrow-left sub-head-ico" (click)="previousDay()" title="Next"></i>
					<i class="far fa-arrow-right sub-head-ico" (click)="nextDay()" title="Previous"></i>
				</div>

				<!-- Refresh Icon -->
				<i class="fa fa-refresh sub-head-ico" (click)="fetchAndReload()" title="Refresh Data"></i>
				<div class="updated-wrap">{{ lastUpdated }}</div>
			</div>
		</div>
	</div>

	<div class="option-dropdown-wrap">
		<div *ngIf="viewManager.isProcessing" class="processing-status"><i class="fas fa-loader fa-spin"></i> Processing...</div>
		<div
			*ngIf="!viewManager.isProcessing"
			[class.option-dropdown-mobile]="devDetect.isMobile()"
			[class.d-flex]="!devDetect.isMobile()"
			[class.justify-content-between]="!devDetect.isMobile()"
		>
			<div class="selector-wrap">
				<div class="option-dropdown">
					<p-select
						appScrollLockSelect
						[options]="timezoneOptions"
						[(ngModel)]="viewManager.timezone"
						[filter]="true"
						filterBy="label"
						(onChange)="fetchAndReload()"
					></p-select>
				</div>
			</div>
			<div *ngIf="!devDetect.isMobile()" class="option-wrap-title-right">IVR API Log</div>
		</div>
	</div>

	<app-adp-ivr-api-log-table #adpIvrApiLogTable></app-adp-ivr-api-log-table>
</div>
