import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DisplayHelper, log } from '@app/helpers'
import { AuditActionEvent, DataAccessRequest, Global, SecureFileListItem } from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-secure-files-list-item',
	templateUrl: './secure-files-list-item.component.html',
	styleUrls: ['./secure-files-list-item.component.scss'],
	standalone: false,
})
export class SecureFilesListItemComponent implements OnInit {
	tagId = Global.createUuid()

	hasLinkLoadBeenAttempted = false
	linkedItem: LinkedImageWrapper = null

	@Input() item: SecureFileListItem
	@Input() highlightCard: boolean
	@Output() viewItem = new EventEmitter<SecureFileListItem>()
	@Output() downloadItem = new EventEmitter<SecureFileListItem>()
	@Output() editItem = new EventEmitter<SecureFileListItem>()
	@Output() deleteItem = new EventEmitter<SecureFileListItem>()
	@Output() unlinkItem = new EventEmitter<SecureFileListItem>()
	@Output() auditRecord = new EventEmitter<SecureFileListItem>()

	constructor(private coreSrvc: CoreService) {}

	ngOnInit(): void {}

	public canViewItem(item: SecureFileListItem): boolean {
		const extension = item.record.file_extension
		switch (extension) {
			case 'txt':
			case 'pdf':
			case 'png':
			case 'gif':
			case 'jpg':
			case 'jpeg':
				return true
			default:
				return false
		}
	}

	public downloadBtnClicked(item: SecureFileListItem) {
		item.isDownloading = true
		this.downloadItem.next(item)
	}

	public confirmDelete(item: SecureFileListItem) {
		item.isDeleting = true
		this.deleteItem.next(item)
	}

	public async setupImage() {
		if (this.hasLinkLoadBeenAttempted) {
			DisplayHelper.scrollIntoViewById(this.tagId, 'center', 300)
			return
		}

		const file = this.item
		const url = await this.getCredentialedLinkForItem(file)

		this.linkedItem = new LinkedImageWrapper(file, url)
		this.hasLinkLoadBeenAttempted = true

		DisplayHelper.scrollIntoViewById(this.tagId, 'center', 300)
	}

	private async getCredentialedLinkForItem(item: SecureFileListItem): Promise<string> {
		// const options = { id: item.record.id } - DEPRECATED 2025-02-01 - Switch to uuid
		const options = { uuid: item.record.uuid }
		const request = new DataAccessRequest('none', 'file_uploads_url', options)
		let result = await this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request)
		const url = result?.data?.url as string
		return url
	}

	// Converted to async / await on 2025-02-01
	// public setupImage() {
	// 	if (this.hasLinkLoadBeenAttempted) {
	// 		DisplayHelper.scrollIntoViewById(this.tagId, 'center', 300)
	// 		return
	// 	}

	// 	const file = this.item
	// 	this.getCredentialedLinkForItem(file).then((url) => {
	// 		this.linkedItem = new LinkedImageWrapper(file, url)
	// 		this.hasLinkLoadBeenAttempted = true

	// 		DisplayHelper.scrollIntoViewById(this.tagId, 'center', 300)
	// 	})
	// }

	// private getCredentialedLinkForItem(item: SecureFileListItem): Promise<string> {
	// 	return new Promise((resolve, reject) => {
	// 	const options = { id: item.record.id } - DEPRECATED 2025-02-01 - Switch to uuid
	//		// const options = { uuid: item.record.uuid }
	// 		const request = new DataAccessRequest('none', 'file_uploads_url', options)
	// 		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
	// 			const url = result?.data?.url as string
	// 			resolve(url)
	// 		})
	// 	})
	// }

	public showLinkedItem(item: LinkedImageWrapper) {
		const sfli = item.sfli
		const opener = window.open()
		this.getCredentialedLinkForItem(sfli).then((url) => {
			opener.location.assign(url)
		})
	}

	public handleImageLoadError(li: LinkedImageWrapper) {
		li.hasImageError = true
	}
}

class LinkedImageWrapper {
	sfli: SecureFileListItem
	url: string

	isReady = false
	hasImageError = false

	constructor(record: SecureFileListItem, url: string) {
		this.sfli = record
		this.url = url
	}
}
