import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { DateTimeHelper, DropdownHelper, DropdownHelperConfig, FormHelper, log } from '@app/helpers'
import {
	ClickToCallGlobalConfig,
	ClickToCallRecord,
	DataAccessRequest,
	DialogManager,
	DistanceHelper,
	EditFormAction,
	EmployeeRecord,
	HelpDialogMessage,
	OpenShiftOfferEditConfig,
	OpenShiftOfferEntry,
	OpenShiftOfferEntryDerivedStatusType,
	OpenShiftOfferFilter,
	OpenShiftOfferRecord,
	RecordTag,
} from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService, DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

import { environment } from '@env/environment'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Global } from '@app/models/global'
import { MultiSelectDetailsComponent } from '@app/components/multi-select-details/multi-select-details.component'

@Component({
	selector: 'app-open-shift-offer',
	templateUrl: './open-shift-offer.component.html',
	styleUrls: ['./open-shift-offer.component.scss'],
	standalone: false,
})
export class OpenShiftOfferEditComponent implements OnInit, AfterViewInit, AfterContentInit {
	isUpdating = false
	isMatching = false
	isSendingAnnouncement = false
	offerForm: UntypedFormGroup

	filter: OpenShiftOfferFilter
	employeeStatusOptions: Array<SelectItem> = []
	employeeTagMultiSelect: Array<SelectItem> = []

	filterResponse: Array<OpenShiftOfferFilterResponseWrapper> = []
	showFilterResults = false

	selectedEmployees
	employeeDropdownOptions: Array<SelectItem> = []
	departmentDropdownOptions: Array<SelectItem> = []

	offerEntryList: Array<OpenShiftOfferEntryViewModel> = []
	statusSummary = { unread: 0, read: 0, replied: 0, declined: 0, accepted: 0 }

	@Input() action: EditFormAction = 'new'
	@Input() offer: OpenShiftOfferRecord
	@Input() schedLog: ScheduleLogRecord
	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<OpenShiftOfferRecord>()

	@ViewChild('selectedEmployeesMultiselect') selectedEmployeesMultiselect: MultiSelectDetailsComponent

	constructor(
		private fb: FormBuilder,
		private coreSrvc: CoreService,
	) {}

	get selectedFilterResponseCards(): Array<OpenShiftOfferFilterResponseWrapper> {
		return this.filterResponse.filter((frw) => frw.selected)
	}
	get hasSelectedFilterCards(): boolean {
		return this.selectedFilterResponseCards.length > 0
	}

	get isDistanceFilterAvailable(): boolean {
		const siteId = this.schedLog.jobsite_id
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
		if (site && site.geo_latitude && site.geo_longitude) {
			return true
		}
		return false
	}

	get isEmployeeTagFilterAvailable(): boolean {
		const filterCount = this.filter?.employeeTags.length ?? 0
		const tagCount = this.employeeTagMultiSelect.length
		return filterCount + tagCount > 0
	}

	public ngOnInit(): void {
		const config = this.dialogManager.dialogData as OpenShiftOfferEditConfig
		this.action = config.action
		this.offer = config.offer
		this.schedLog = config.schedLog
		this.setupEmployeeDropdown()
		this.setupForm()

		log('Offer Record', this.offer)

		// Finish setup of offer entry view models
		const offerList = this.offer.schedule_os_offer_entry
			.map((entry) => new OpenShiftOfferEntry(entry))
			.map((entry) => new OpenShiftOfferEntryViewModel(this.coreSrvc.dbSrvc, entry))

		const sortedList = this.sortOfferEntries(offerList)

		this.offerEntryList = sortedList
		this.offerEntryList.forEach((item) => {
			if (item.entry.read) {
				this.statusSummary.read++
			} else {
				this.statusSummary.unread++
			}
			if (item.entry.audit_json.length > 0) this.statusSummary.replied++
			if (item.entry.declined && !item.entry.accepted) this.statusSummary.declined++
			if (item.entry.accepted) this.statusSummary.accepted++
		})

		// Setup employee status options
		this.employeeStatusOptions = [
			{ label: 'Active', value: 'ACTIVE' },
			{ label: 'Inactive', value: 'INACTIVE' },
			{ label: 'Any Status', value: null },
		]

		this.setupFilter()
	}

	public ngAfterViewInit() {}

	public ngAfterContentInit() {
		this.setupDialogManager()
	}

	get offerEntries(): Array<OpenShiftOfferEntry> {
		return this.offer?.schedule_os_offer_entry ?? []
	}

	get isNew(): boolean {
		return !this.offerForm?.get('id').value
	}

	get showOpenShiftCheckbox(): boolean {
		return false // this.isNew && !this.schedLog?.open_shift
	}

	get isFormValid(): boolean {
		return this.offerForm.get('employee_ids').value?.length > 0
	}

	get hasAnyoneAccepted(): boolean {
		return this.offerEntries.filter((osoe) => !!osoe.accepted).length > 0
	}

	// get filterDistancePlaceholder(): string {
	// 	return this.filter.locDistanceUnits === 'MI' ? 'enter distance in miles' : 'enter distance in kilometers'
	// }

	private sortOfferEntries(list: Array<OpenShiftOfferEntryViewModel>): Array<OpenShiftOfferEntryViewModel> {
		const acceptedOffers = list.filter((osoe) => osoe.derivedStatus === 'ACCEPTED')
		const repliedOffers = list.filter((osoe) => osoe.derivedStatus === 'READ' && osoe.hasAuditEntries)
		const readOffers = list.filter((osoe) => osoe.derivedStatus === 'READ' && !osoe.hasAuditEntries)
		const unreadOffers = list.filter((osoe) => osoe.derivedStatus === 'UNREAD')
		const declinedOffers = list.filter((osoe) => osoe.derivedStatus === 'DECLINED')

		const groupedList = [...acceptedOffers, ...repliedOffers, ...unreadOffers, ...readOffers, ...declinedOffers]

		return _.orderBy(groupedList, ['employeeName'], ['asc'])
	}

	private setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	private setupForm() {
		const offer = this.offer
		const schedLog = this.schedLog
		this.offerForm = this.fb.group({
			id: [offer.id ?? null],
			schedule_log_id: [schedLog.id],
			description: [offer.description],
			employee_details: [offer.employee_details],
			details: [offer.details],
			auto_fill: [offer.auto_fill],
			status: [offer.status],
			employee_ids: [offer.employee_ids ?? []],

			// Form flags / not actual properties
			open_shift: [true],
		})
	}

	private setupEmployeeDropdown() {
		const permission = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('schedule_recur')
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'MULTISELECT')
		config.includeIds = this.offer.employee_ids ?? []
		config.isRestricted = !permission.access.read
		config.includeInactive = true
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildEmployeeMenuOptions()
		this.employeeDropdownOptions = options
	}

	private setupFilter() {
		const filterJson = this.offer.filter_json

		log('FILTERJSON', filterJson)
		// If the record has filter options set then use them, otherwise create a new filter
		if (filterJson) {
			this.filter = new OpenShiftOfferFilter(filterJson)
		} else {
			const units = this.coreSrvc.dbSrvc.settingSrvc.getCompany().default_units
			const distUnits = units === 'IMPERIAL' ? 'MI' : 'KM'
			this.filter = new OpenShiftOfferFilter()
			this.filter.locDistanceUnits = distUnits
		}

		// Setup departments dropdown
		const includeDepts = this.filter?.departments ?? []
		this.departmentDropdownOptions = this.coreSrvc.dbSrvc.empSrvc.getDepartmentsDropdown(includeDepts)

		// Setup employees tag filter
		const includeTags = this.filter.employeeTags ?? []
		this.employeeTagMultiSelect = this.coreSrvc.dbSrvc.empSrvc.getTagDropdownData(includeTags)
	}

	private submit() {
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.offerForm)

		const record = this.makeUpdateRecord()
		log('Update Record', record)
		this.isUpdating = true
		if (this.isNew) {
			this.coreSrvc.dbSrvc
				.insertRecord('schedule_os_offer', record)
				.then((success) => {
					if (success) {
						this.coreSrvc.notifySrvc.notify(
							'success',
							'Offer Submitted',
							`A shift offer is being prepared and employees matching the parameters set will be notified of it's availability.`,
						)
						this.recordUpdated.next(this.offer)
					} else {
						this.isUpdating = false
					}
				})
				.catch((error) => {
					this.isUpdating = false
				})
		} else {
			this.coreSrvc.dbSrvc
				.updateRecord('schedule_os_offer', record)
				.then((success) => {
					if (success) {
						this.recordUpdated.next(this.offer)
					} else {
						this.isUpdating = false
					}
				})
				.catch((error) => {
					this.isUpdating = false
				})
		}
	}

	private makeUpdateRecord(): OpenShiftOfferRecord {
		const record = new OpenShiftOfferRecord(this.offerForm.value)
		record.filter_json = JSON.stringify(this.filter)
		return record
	}

	public toggleCheckbox(prop: string) {
		this.offerForm.get(prop).setValue(!this.offerForm.get(prop).value)
	}

	public assignShiftBtnClicked(entryVm: OpenShiftOfferEntryViewModel) {
		if (this.isUpdating) return
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnLabel = 'Close'
		this.isUpdating = true
		const record = {
			id: entryVm.entry.id,
			scheduled: moment().toISOString(),
		}
		// const request = new DataAccessRequest('schedule_os_offer_entry', 'update', null, record)
		this.coreSrvc.dbSrvc.updateRecord('schedule_os_offer_entry', record).then((result) => {
			log('Assignment Result', result)
			this.recordUpdated.next(this.offer)
		})
		// this.coreSrvc.notifySrvc.notify(
		// 	'success',
		// 	'Assign Shift',
		// 	'Thist functionality is not yet implemented. Need operation to perform assignment and cleanup.'
		// )
	}

	// REMOVE AFTER TESTING - DEPRECATED 20240608
	// public copyLink() {
	// 	const osoId = this.offerForm.get('id').value
	// 	if (osoId) {
	// 		const empIds = this.offer.employee_ids ?? []
	// 		const firstEmpId = empIds[0]
	// 		log('First employee', firstEmpId)
	// 		const empLogin = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginRecordByEmpId(firstEmpId)
	// 		if (empLogin) {
	// 			const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
	// 			log('Employee login', empLogin)
	// 			const token = empLogin.token
	// 			const domain = environment.empAppDomain
	// 			const isLocalHost = window.location.href.includes('localhost')
	// 			const currentDomain = isLocalHost ? 'localhost:4500' : domain
	// 			const prefix = isLocalHost ? 'http://' : 'https://'
	// 			const link = `${prefix}${currentDomain}/#/auth?token=${token}&cid=${companyId}&osoid=${osoId}`

	// 			navigator.clipboard.writeText(link)
	// 			this.coreSrvc.notifySrvc.notify(
	// 				'success',
	// 				'Link Copied',
	// 				'The link for this offer for the first employee in the list has been copied to your clipboard.',
	// 				3,
	// 			)
	// 		}
	// 	}
	// }

	public processFilter() {
		this.isMatching = true
		const options = {
			schedule_log_id: this.schedLog.id,
			active_status: this.filter.activeStatus,
			employee_tags: JSON.stringify(this.filter.employeeTags),
			departments: JSON.stringify(this.filter.departments),
			loc_dist_max: this.filter.getDistanceInMeters(),
		}
		const request = new DataAccessRequest('none', 'os_filter_employees', options)
		log('Lambda Request', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Lambda Result', result)
			const data = result.data as Array<any>
			this.filterResponse = data
				.map((rec) => new OpenShiftOfferFilterResponseRecord(rec))
				.map((rec) => new OpenShiftOfferFilterResponseWrapper(rec, this.filter.locDistanceUnits))
			if (data.length === 0) {
				this.coreSrvc.notifySrvc.notify('info', 'No matches', `No employees matched the filter options selected.`, 4)
				return
			}

			this.dialogManager.isBackBtnVisible = true
			this.dialogManager.isCancelBtnVisble = false
			this.dialogManager.isSubmitBtnVisible = false
			this.dialogManager.backBtnAction = () => this.cancelFilterBtnClicked()

			this.showFilterResults = true
			setTimeout(() => {
				this.isMatching = false
				setTimeout(() => {
					this.coreSrvc.displaySrvc.enableAllTooltips()
				}, 150)
			}, 750)
		})
	}

	public applyFilterBtnClicked() {
		const selectedFromFilter = this.filterResponse.filter((rec) => rec.selected).map((rec) => rec.record.employee_id)
		const formArray = this.offerForm.get('employee_ids').value
		let empCount = 0

		for (const empId of selectedFromFilter) {
			if (!formArray.includes(empId)) {
				formArray.push(empId)
				empCount++
			}
		}

		const currentCount = (this.offerForm.get('employee_ids').value ?? []).length
		const existCount = this.selectedFilterResponseCards.length - empCount
		log('Targeted/Response/Selected', currentCount, this.filterResponse.length, this.selectedFilterResponseCards.length)

		if (empCount == 0) {
			let message = `No employees added to the targeted employees list.`
			if (existCount > 0) message += ` ${existCount} employee${existCount === 1 ? ' was' : 's were'} already in the list.`
			this.coreSrvc.notifySrvc.notify('info', 'No Change', message)
		} else {
			let message = `${empCount} employee${empCount === 1 ? '' : 's'} added to the targeted employees list.`
			if (existCount > 0) message += ` ${existCount} employee${existCount === 1 ? ' was' : 's were'} already in the list.`
			this.coreSrvc.notifySrvc.notify('info', 'Employees Added', message)
		}

		this.selectedEmployeesMultiselect.updateState()
		this.resetToMainView()
	}

	public cancelFilterBtnClicked() {
		this.resetToMainView()
	}

	public resetToMainView() {
		this.isMatching = false
		this.showFilterResults = false
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.isBackBtnVisible = false
		this.filterResponse = []
	}

	public isCurrentlyTargeted(frw: OpenShiftOfferFilterResponseWrapper): boolean {
		const empId = frw.record.employee_id
		return this.offerForm?.get('employee_ids').value ?? false
	}

	public clickToCall(emp: EmployeeRecord, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(emp.id)
		const name = record.first + ' ' + record.last
		let number = record.phone_number_e164
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('EMPLOYEEPHONE', name, null, number, null)
			c2cRecord.destEmpId = emp.id
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
	}

	public sendAnnouncement() {
		this.dialogManager.saveScrollPosition('osOffer')
		this.dialogManager.pushState()
		this.dialogManager.headerLabel = 'Send Announcement'
		this.dialogManager.cancelBtnAction = () => this.sendAnnouncementCancelled()
		this.isSendingAnnouncement = true
	}
	public sendAnnouncementCancelled() {
		this.restoreDialogState()
	}
	public sendAnnouncementFinished() {
		this.restoreDialogState()
	}
	private restoreDialogState() {
		this.dialogManager.popStateAndApply()
		this.isSendingAnnouncement = false
		setTimeout(() => {
			this.dialogManager.restoreScrollPosition('osOffer')
		}, 100)
	}

	convertDistance() {
		const distance = this.filter.locDistance
		const units = this.filter.locDistanceUnits
		if (!distance) {
			this.filter.locDistance = null
			return
		}

		switch (units) {
			case 'KM':
				log('Switch to KM', distance, units)
				this.filter.locDistance = parseFloat(DistanceHelper.converter.setValue(distance, 'MI').getDistanceValue('KM').toFixed(2))
				break
			case 'MI':
				log('Switch to MI', distance, units)
				this.filter.locDistance = parseFloat(DistanceHelper.converter.setValue(distance, 'KM').getDistanceValue('MI').toFixed(2))
				break
		}
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'auto_fill':
				help.header = 'Auto-fill Shift'
				help.message =
					'When checked, the system will automatically assign this open shift to the first employee who accepts the offer. If this option is not checked, multiple employees may accept the shift but it will not be filled until a supervisor manually fills the shift.'
				break
			case 'emp_active_status':
				help.header = 'Employee Status'
				help.message = `Select an employee status or use 'Any Status' if you wish to target both active and inactive employees. Generally you will want to target only active employees.`
				break
			case 'department':
				help.header = 'Department'
				help.message = 'Select departments if you wish to narrow your target audience to only the selected departments.'
				break
			case 'details':
				help.header = 'Admin Notes'
				help.message =
					'These notes are only available to supervisors in the admin portal. Use this field to keep notes related to fulfillment of this shift.'
				break
			case 'employee_details':
				help.header = 'Offer Notice'
				help.message =
					'This notice is displayed to the employee when they view the open shift offer. Use this field to provide any extra information they might need to decide whether or not they would be able to fill the shift.'
				break
			case 'employee_ids':
				help.header = 'Target Employees'
				help.message =
					'Select individual employees you wish to target for this open shift offer. Use the Employee picker below to add employees to the list who match certain criteria.'
				break
			case 'open_shift':
				help.header = 'Mark Open'
				help.message = 'When checked, the shift this offer applies to will be marked as open so it can be tracked in the the open shifts list.'
				break
			case 'filter_locDistance':
				help.header = 'Distance'
				help.message = 'When set, an employee must be no more than this distance from the job site to be included in the generated list.'
				break
			case 'filter_empTags':
				help.header = 'Employee Tags'
				help.message = 'When employee tags are selected, an employee must have all the selected tags to be included in the generated list.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class OpenShiftOfferEntryViewModel {
	updatedTimestamp = ''
	updated = ''
	employeeName = ''
	derivedStatus: OpenShiftOfferEntryDerivedStatusType = 'UNREAD'
	entry: OpenShiftOfferEntry

	constructor(dbSrvc: DatabaseService, entry: OpenShiftOfferEntry) {
		this.entry = entry
		this.updatedTimestamp = entry.updated ?? entry.created
		this.updated = DateTimeHelper.format12Hour
			? moment(this.updatedTimestamp).format('MMM Do @ h:mm a')
			: moment(this.updatedTimestamp).format('MMM Do @ HH:mm')
		this.employeeName = dbSrvc.empSrvc.getEmployeeById(entry.employee_id).name
		this.derivedStatus = entry.getDerivedStatus()
	}

	get hasAuditEntries(): boolean {
		return this.entry.audit_json.length > 0
	}
}

class OpenShiftOfferFilterResponseRecord {
	employee_id: number
	dist_to_loc: number
	employee: EmployeeRecord

	constructor(record: OpenShiftOfferFilterResponseRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.employee = Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.employee_id)
		}
	}
}
class OpenShiftOfferFilterResponseWrapper {
	record: OpenShiftOfferFilterResponseRecord
	tags: Array<RecordTag> = []
	selected = true
	expanded = false
	distLabel = ''

	constructor(record: OpenShiftOfferFilterResponseRecord, displayUnit: 'MI' | 'KM') {
		this.record = record
		if (record.employee?.tags_json) {
			const tagsJson = JSON.parse(record.employee.tags_json as any as string)
			this.tags = tagsJson.tags.map((rec) => new RecordTag(rec))
		}
		this.setupDistanceLabel(displayUnit)
	}

	setupDistanceLabel(displayUnit: 'MI' | 'KM') {
		// if (!this.record.dist_to_loc) return
		// const units = Global.coreSrvc.dbSrvc.settingSrvc.getCompany().default_units
		// const displayUnit = units === 'IMPERIAL' ? 'MI' : 'KM'
		const distInMeters = this.record.dist_to_loc
		if (distInMeters) {
			const convertedDist = DistanceHelper.converter.setValue(distInMeters, 'M').getDistanceValue(displayUnit).toFixed(2)
			this.distLabel = `${convertedDist} ${displayUnit.toLowerCase()} from job site`
		} else {
			this.distLabel = this.record.employee.address_1 ? '' : 'No employee address'
		}
	}
}
