<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Data ...'"></app-activity-banner>
<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div *ngIf="!isLoading" [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Labor Level Prompt -->
		<div class="options-block" [class.input-required]="!form.get('labor_level_prompt').value">
			<div *ngIf="form.get('labor_level_prompt').value" class="float-right link-text" (click)="form.get('labor_level_prompt').setValue('')">
				clear
			</div>
			<div class="options-title-inline">Labor Level Prompt</div>
			<div *ngIf="!form.get('labor_level_prompt').value" class="float-right">(required)</div>
			<div>
				<textarea
					appTextAreaResizer
					class="form-control options-textarea-resizer"
					formControlName="labor_level_prompt"
					placeholder="Enter prompt"
				></textarea>
			</div>
		</div>

		<!-- Work Rule Prompt -->
		<div class="options-block" [class.input-required]="!form.get('work_rule_prompt').value">
			<div *ngIf="form.get('work_rule_prompt').value" class="float-right link-text" (click)="form.get('work_rule_prompt').setValue('')">clear</div>
			<div class="options-title-inline">Work Rule Prompt</div>
			<div *ngIf="!form.get('work_rule_prompt').value" class="float-right">(required)</div>
			<div>
				<textarea
					appTextAreaResizer
					class="form-control options-textarea-resizer"
					formControlName="work_rule_prompt"
					placeholder="Enter prompt"
				></textarea>
			</div>
		</div>

		<!-- Pay Code Prompt -->
		<div class="options-block" [class.input-required]="!form.get('pay_code_prompt').value">
			<div *ngIf="form.get('pay_code_prompt').value" class="float-right link-text" (click)="form.get('pay_code_prompt').setValue('')">clear</div>
			<div class="options-title-inline">Pay Code Prompt</div>
			<div *ngIf="!form.get('pay_code_prompt').value" class="float-right">(required)</div>
			<div>
				<textarea
					appTextAreaResizer
					class="form-control options-textarea-resizer"
					formControlName="pay_code_prompt"
					placeholder="Enter prompt"
				></textarea>
			</div>
		</div>
	</form>
</div>
