import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { AuditLog, AuditLogDataAccessRequest, DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-audit-log-record-view',
	standalone: false,

	templateUrl: './audit-log-record-view.component.html',
	styleUrl: './audit-log-record-view.component.scss',
})
export class AuditLogRecordViewComponent implements OnInit {
	isLoading = true

	@Input() resource: DatabaseTableName = 'none'
	@Input() recordId: number = 0

	records: AuditLog[] = []
	record: AuditLog = null

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.loadData()
	}

	loadData() {
		const options = { resource: this.resource, resource_id: this.recordId }
		const request = new AuditLogDataAccessRequest(options)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			this.records = data.map((rec) => new AuditLog(rec))

			if (this.records.length > 0) {
				this.record = this.records[this.records.length - 1]
			}

			setTimeout(() => {
				this.isLoading = false
				this.cd.markForCheck()
				log('History Results', data)
			}, 500)
		})
	}
}
