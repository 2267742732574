import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'

////////////
// GUARDS //
////////////

import { AuthGuard } from '@app/guards/auth.guard'
import { AccessGuard } from '@app/guards/access.guard'
import { DataLoadedGuard } from '@app/guards/data-loaded.guard'

//////////
// BASE //
//////////

import { BaseComponent } from '@app/admin/base/base.component'

////////////
// LAYOUT //
////////////

import { AboutComponent } from '@app/public/about/about.component'
import { AppShellComponent } from '@app/app.shell'
import { AdminHomeComponent } from '@app/admin/home/admin-home.component'
import { DashboardComponent } from '@app/admin/dashboard/dashboard.component'

////////////
// PUBLIC //
////////////

import { PublicHomeComponent } from '@app/public/home/home.component'
import { PublicLandingComponent } from '@app/public/landing/landing.component'
import { ShiftReportPublicViewComponent } from '@app/components/shift-report/shift-report-public-view/shift-report-public-view.component'

////////////////////
// AUTHENTICATION //
////////////////////

import { LoginComponent } from '@app/public/authenticate/login/login.component'
import { RegisterComponent } from '@app/public/authenticate/register/registration.component'
import { ForgotPassword2Component, ForgotPasswordStep1Component } from '@app/public/authenticate/forgot/forgotPassword.component'
import { RegistrationConfirmationComponent } from '@app/public/authenticate/confirm/confirmRegistration.component'
import { LogoutComponent } from '@app/public/authenticate/logout/logout.component'
import { ResendCodeComponent } from '@app/public/authenticate/resend/resendCode.component'
import { InactiveLogoutComponent } from '@app/public/authenticate/inactive/inactive-logout.component'

////////////
// PEOPLE //
////////////

import { EmployeesComponent } from '@app/admin/employees/employees.component'
import { EmployeeDeleteComponent } from '@app/admin/employees/employee-delete/employee-delete.component'
import { OnboardUpdateListComponent } from '@app/admin/employees/onboard-update-list/onboard-update-list.component'
import { ProfileUpdateListComponent } from '@app/admin/employees/profile-update-list/profile-update-list.component'
import { ContactComponent } from '@app/admin/contacts/contact.component'
import { UsersComponent } from '@app/admin/users/users.component'

//////////
// WORK //
//////////

import { JobSitesComponent } from '@app/admin/job-sites/job-sites.component'
import { JobSiteDeleteComponent } from '@app/admin/job-sites/job-site-delete/job-site-delete.component'
import { JobsComponent } from '@app/admin/jobs/jobs.component'
import { JobDeleteComponent } from '@app/admin/jobs/job-delete/job-delete.component'
import { ProjectsComponent } from '@app/admin/projects/projects.component'
import { BreaksComponent } from '@app/admin/breaks/breaks.component'
import { ToursComponent } from '@app/admin/tours/tours.component'
import { NotificationsComponent } from '@app/admin/notifications/notifications.component'

////////////////
// SCHEDULING //
////////////////

import { SchedulerComponent } from '@app/admin/scheduler/scheduler.component'

////////////////
// OPERATIONS //
////////////////

import { TransactionsComponent } from '@app/admin/transactions/transactions.component'
import { ShiftReportsComponent } from '@app/admin/reports-shift/shift-reports.component'
import { InsightReportsComponent } from '@app/admin/reports-insight/insight-reports.component'
import { VisualizationComponent } from '@app/admin/visualization/visualization.component'
import { CommLogComponent } from '@app/admin/comm-log/comm-log.component'
import { AuditLogComponent } from '@app/admin/audit-log/audit-log.component'
import { AdpIvrApiLogComponent } from '@app/admin/adp-ivr-api-log/adp-ivr-api-log.component'
import { AnnouncementsComponent } from '@app/admin/announcements/announcements.component'

//////////////////
// INTEGRATIONS //
//////////////////
import { ADPIntegrationComponent } from '@app/admin/integrations/adp/adpintegration.component'
import { ADPMarketplaceLoginComponent } from '@app/public/authenticate/adp-login/adp-login.component'
import { FileImportIntegrationComponent } from '@app/admin/integrations/fileimport/file-import-integration.component'
import { QuickbooksIntegrationComponent } from '@app/admin/integrations/quickbooks/integration.component'
import { WhenToWorkIntegrationComponent } from '@app/admin/integrations/whentowork/w2wintegration.component'
import { WhenIWorkIntegrationComponent } from '@app/admin/integrations/wheniwork/wiwintegration.component'
import { IntegrtionSyncLogComponent } from '@app/admin/integrations/sync-log/sync-log.component'

///////////
// ADMIN //
///////////

import { SettingsComponent } from '@app/admin/settings/settings.component'
import { SettingsBillingInformationComponent } from '@app/admin/settings/billing-information/settings-billing-information.component'
import { StationComponent } from '@app/admin/stations/station.component'
import { PayRatesComponent } from '@app/admin/pay-rates/pay-rates.component'
import { SettingsCloseAccountComponent } from '@app/admin/settings/close-account/settings-close-account.component'

///////////////////
// MISCELLANEOUS //
///////////////////

import { ExceptionsComponent } from '@app/admin/exceptions/exceptions.component'
import { RedirectComponent } from '@app/public/redirect/redirect.component'
import { RedirectRecordComponent } from '@app/public/redirect/redirect-record/redirect-record.component'
import { NotProvisionedComponent } from '@app/public/not-provisioned/not-provisioned.component'
import { UnsupportedBrowserComponent } from '@app/components/unsupported-browser/unsupported-browser.component'
import { MaintenanceComponent } from '@app/components/maintenance/maintenance.component'
import { ChatBotComponent } from '@app/components/chat-bot/chat-bot.component'
import { ResetApplicationComponent } from '@app/components/reset-application/reset-application.component'

/////////////////////////////////////////////////////////////////////////////////////////////////////

const publicRoutes: Routes = [
	///////////////////
	// PUBLIC ROUTES //
	///////////////////

	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full',
	},
	{
		path: 'home',
		component: PublicHomeComponent,
		children: [
			{ path: 'unsupported-browser', component: UnsupportedBrowserComponent },
			{ path: 'not-provisioned', component: NotProvisionedComponent },
			{ path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
			{ path: 'inactive', component: InactiveLogoutComponent },
			{ path: 'login', component: LoginComponent },
			{ path: 'logout', component: LogoutComponent },
			{ path: 'register', component: RegisterComponent },
			{ path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent },
			{ path: 'resendCode', component: ResendCodeComponent },
			{ path: 'forgotPasswordConfirm', component: ForgotPassword2Component },
			{ path: 'forgotPassword', component: ForgotPasswordStep1Component },
			{ path: '', component: PublicLandingComponent },
		],
	},
	{
		path: 'adp/marketplace/login',
		pathMatch: 'full',
		component: ADPMarketplaceLoginComponent,
	},
	{
		path: 'logout',
		pathMatch: 'full',
		component: LogoutComponent,
	},
	{
		path: 'logout/inactive',
		component: LogoutComponent,
	},
	{
		path: 'maintenance',
		component: MaintenanceComponent,
	},
	{
		path: 'reset',
		pathMatch: 'full',
		component: ResetApplicationComponent,
	},
	{
		path: 'redirect/params',
		pathMatch: 'full',
		component: RedirectRecordComponent,
	},
	{
		path: 'redirect/record/:recordId/:section/:companyId',
		pathMatch: 'full',
		component: RedirectRecordComponent,
	},
	{
		path: 'redirect',
		pathMatch: 'full',
		component: RedirectComponent,
	},
	{
		path: 'reports',
		children: [
			{ path: ':reportType/:uuid', pathMatch: 'full', component: ShiftReportPublicViewComponent },
			{
				path: '',
				pathMatch: 'full',
				redirectTo: '/home',
			},
			{
				path: '**',
				redirectTo: '/home',
			},
		],
	},
]

// For permissions managed routes, you need to setup permissions in models/permissions.ts file.
// In order to use AccessGuard to check user permissions for access to a route, it must have
// a TableName entry in keys array. If a permission block for any of those keys is accessbile
// to the user, then the route is accessible.

// TableName refers to a backend table name. If there is no backend table for a permission block, a
// pseudo name will need to be added to the TableName class and should follow the naming convention of
// x_pseudo_table_name. This would be used when protecting a route that doesn't map to a back end table.

// Next add entry to permissionItems array in AccessPermissions class.
// Next add to necessary role access lists in getAllowedPermissionsForRole() method of AccessPermissions.
// Next add AccessGuard to canActivate and data: { keys: ['<TableName>'] } to route item. If multiple
// tables are listed, read access to either table will allow access to that route.

// To use UserPermissions aggregator, add property with PermissionTableName to UserPermissionsList class.
// This is optional and is used mainly with AccessHelper. You can get permissions for a specific table
// through other API on AccessPermissions class.

const secureRoutes: Routes = [
	//////////////////
	// ADMIN ROUTES //
	//////////////////

	{
		path: 'admin',
		component: AdminHomeComponent,
		canActivate: [AuthGuard],
		children: [
			/////////////
			// GENERAL //
			/////////////

			// Base Setup component template
			// {
			// 	path: 'base',
			// 	component: BaseComponent,
			// 	canActivate: [AuthGuard, DataLoadedGuard],
			// },

			// Base Template
			{
				path: 'base',
				component: BaseComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			// Exceptions
			{
				path: 'exceptions',
				component: ExceptionsComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			// Chatbot
			{
				path: 'chatbot',
				component: ChatBotComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			////////////
			// PEOPLE //
			////////////

			// Employees
			{
				path: 'employees',
				component: EmployeesComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['employee'] },
			},
			{
				path: 'employees/delete/:employeeId',
				component: EmployeeDeleteComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},
			{
				path: 'employees/onboarding/updates',
				component: OnboardUpdateListComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['employee'] },
			},
			{
				path: 'employees/profile/updates',
				component: ProfileUpdateListComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['employee'] },
			},

			// Contacts - Key shared with both individual and organization views
			{
				path: 'contacts',
				component: ContactComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['vendor_client_org'] },
			},

			// Users
			{
				path: 'users',
				component: UsersComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['users'] },
			},

			//////////
			// WORK //
			//////////

			// Jobsites
			{
				path: 'jobsites',
				component: JobSitesComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['location'] },
			},
			{
				path: 'jobsites/delete/:jobSiteId',
				component: JobSiteDeleteComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			// Jobs
			{
				path: 'jobs',
				component: JobsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['job'] },
			},
			{
				path: 'jobs/filter/:filterField/:filterId',
				component: JobsComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},
			{
				path: 'jobs/delete/:jobId',
				component: JobDeleteComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			// Projects
			{
				path: 'projects',
				component: ProjectsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
			},

			// Breaks
			{
				path: 'breaks',
				component: BreaksComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
			},

			// Tours
			{
				path: 'tours',
				component: ToursComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
			},

			// Notifications
			{
				path: 'notifications',
				component: NotificationsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['notification_profile'] },
			},

			////////////////
			// SCHEDULING //
			////////////////

			// Schedule
			{
				path: 'scheduler',
				component: SchedulerComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['schedule_recur'] },
			},
			{
				path: 'scheduler/filter/:filterField/:filterId',
				component: SchedulerComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			////////////////
			// OPERATIONS //
			////////////////

			// Transactions
			{
				path: 'transactions',
				component: TransactionsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['transaction_log'] },
			},

			// Employee Shift Reports
			{
				path: 'reports/employee',
				component: ShiftReportsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['incident_log'] },
			},

			// Business Insight Reports
			{
				path: 'reports/business',
				component: InsightReportsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['reports'] },
			},

			// Analytics
			{
				path: 'analytics',
				component: VisualizationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
			},

			// Comm Logs
			{
				path: 'comm-log',
				component: CommLogComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['ivr_call_log'] },
			},

			// Audit Log
			{
				path: 'auditlog',
				component: AuditLogComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['audit_log'] },
			},
			// ADP IVR API Log
			{
				path: 'adp-ivr-api-log',
				component: AdpIvrApiLogComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				// data: { keys: ['adp_ivr_api_log'] },
			},
			{
				path: 'auditlog/:resource/:resourceId',
				component: AuditLogComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			// Announcements
			{
				path: 'announcements',
				component: AnnouncementsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['announcements'] },
			},

			//////////////////
			// INTEGRATIONS //
			//////////////////

			// ADP Integration
			{
				path: 'integrations/adp',
				component: ADPIntegrationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['adp_sync'] },
			},

			// QuickBooks Integration
			{
				path: 'integrations/quickbooks',
				component: QuickbooksIntegrationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['qbo_sync'] },
			},

			// File Import Integration
			{
				path: 'integrations/fileimport',
				component: FileImportIntegrationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['file_import_sync'] },
			},

			// When To Work Integration
			{
				path: 'integrations/whentowork',
				component: WhenToWorkIntegrationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['google_cal_sync'] },
				// Need to add AccessGuard and data: { keys: [''] } when sync table is know
			},

			// When I Work Integration
			{
				path: 'integrations/wheniwork',
				component: WhenIWorkIntegrationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['wiw_sync'] },
			},

			// Integration Log Viewer
			{
				path: 'integrations/log/:service/:tableName',
				component: IntegrtionSyncLogComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			///////////
			// ADMIN //
			///////////

			// Billing (Keep before settings to match route first)
			{
				path: 'settings/billing',
				component: SettingsBillingInformationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['billing_log'] },
			},

			// Settings
			{
				path: 'settings',
				component: SettingsComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['company'] },
			},

			// Stations
			{
				path: 'stations',
				component: StationComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
			},

			// Payrates
			{
				path: 'payrates',
				component: PayRatesComponent,
				canActivate: [AuthGuard, AccessGuard, DataLoadedGuard],
				data: { keys: ['employee_job_pay_rate'] },
			},

			// Close Account
			{
				path: 'close-account',
				component: SettingsCloseAccountComponent,
				canActivate: [AuthGuard, DataLoadedGuard],
			},

			///////////////
			// DASHBOARD //
			///////////////
			{
				path: 'dashboard',
				component: DashboardComponent,
				canActivate: [AuthGuard],
			},

			///////////////
			// CATCH ALL //
			///////////////
			{
				path: '',
				component: DashboardComponent,
			},
			{
				path: '**',
				redirectTo: '',
			},
		],
	},
]

const appRoutes: Routes = [
	{
		path: '',
		component: AppShellComponent,
		children: [
			...publicRoutes,
			...secureRoutes,
			{
				path: '',
				component: PublicHomeComponent,
			},
			{
				path: '**',
				redirectTo: '',
				pathMatch: 'full',
			},
		],
	},
]

const aggregatedRoutes = [...publicRoutes, ...secureRoutes]

@NgModule({
	imports: [RouterModule.forRoot(aggregatedRoutes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
